import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/design/parts/parts.component';


@Component({
  selector: 'app-workspace',
  template: `
  <div class="workspace">
    <img src="{{src}}" width="100%;" />
</div>`,
  styles: [`
.workspace {
  display: flex;
  margin: 0;
  padding: 2em;
}
`]
})


export class ImageComponent implements OnInit {

  src: any;


  constructor(
    public dialogRef: MatDialogRef<ImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.src = this.data.src;
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}
