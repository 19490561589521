import { Injectable } from '@angular/core';
import { ProjectService } from 'src/app/service/project.service';
import { HttpClient } from '@angular/common/http';
import { Papa } from 'ngx-papaparse';
import { rejects } from 'assert';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SpanService {
  project: any;
  fileloc: any;
  csvreq: any;
  ratio = { port: null, land: null };
  spanPort = { span: [], spanSDST: [] };
  spanLand = { span: [], spanSDST: [] };
  product = 'apex';


  constructor(public projectService: ProjectService, private http: HttpClient, private papa: Papa) { }

  initialize(cal: any) {
	 this.fileloc = '../../assets/csvfolder/' + cal + '/';
    this.project = this.projectService.getProject();
    this.csvreq = {
      roofzone: this.project.zonetype, windspeed: this.project.speed,
      windexposure: this.project.exposure, snowload: this.project.snow,
      roofpitch: this.project.pitch, pv: this.project.pv, rt: this.project.rt,
      rooftype: this.project.type, panelarea: this.project.panelarea,
      width: this.project.width, length: this.project.length,
      asce: this.project.asce, mrh: this.project.mrh, state: this.project.state
    };
    switch (this.project.pitch) {
      case '30.3':
        this.csvreq.roofpitch = '33.7';
        break;
      case '36.9':
        this.csvreq.roofpitch = '39.8';
        break;
      case '42.5':
        this.csvreq.roofpitch = '45.0';
        break;
    }
    if (this.csvreq.pv === '90') {
      if (this.csvreq.panelarea > 18.9) {
        this.csvreq.pv = '72';
        this.ratio.land = 40 / this.csvreq.width;
        this.ratio.port = 77.5 / this.csvreq.length;
      } else {
        this.csvreq.pv = '60';
        this.ratio.land = 40 / this.csvreq.width;
        this.ratio.port = 68 / this.csvreq.length;
      }
    } else {
      this.ratio.land = 1;
      this.ratio.port = 1;
    }

    this.csvreq.rooftype = (this.csvreq.asce === '10') ? '' : this.csvreq.rooftype + '_';
    this.csvreq.mrh = (cal === 'mini2' || this.csvreq.asce === '22') ? this.csvreq.mrh + '_' : '';
	this.csvreq.mrh = (this.csvreq.asce === '22') ? this.csvreq.mrh.replace('_','') : this.csvreq.mrh;
  }


  initializeCan(cal: any, prj: any) {
    const state = this.projectService.getProject().state;
    const newProvinces = state === 'NB' || state === 'PEI' ? 'new' : 'main';
    this.fileloc = '../../assets/csvfolder/canada/' + cal + '/' + newProvinces + '/';

    this.csvreq = {
      roofzone: prj.zone, roofterrain: prj.terr,
      roofpitch: prj.pitch, pv: prj.pv, windcolumn: 0,
      snowload: prj.snow, rt: prj.rt, basicwind: null,
      width: prj.width, length: prj.length,
    };

    if (this.csvreq.pv === '90') {
      if (this.csvreq.panelarea > 18.9) {
        this.csvreq.pv = '72';
        this.ratio.land = 40 / this.csvreq.width;
        this.ratio.port = 77.5 / this.csvreq.length;
      } else {
        this.csvreq.pv = '60';
        this.ratio.land = 40 / this.csvreq.width;
        this.ratio.port = 68 / this.csvreq.length;
      }
    } else {
      this.ratio.land = 1;
      this.ratio.port = 1;
    }

    const projectWind = parseFloat(prj.wind).toFixed(2);
    switch (projectWind) {
      case '0.20':
        this.csvreq.windcolumn = 4;
        break;
      case '0.30':
        this.csvreq.windcolumn = 5;
        break;
      case '0.40':
        this.csvreq.windcolumn = 6;
        break;
      case '0.50':
        this.csvreq.windcolumn = 7;
        break;
      case '0.60':
        this.csvreq.windcolumn = 8;
        break;
      case '0.70':
        this.csvreq.windcolumn = 9;
        break;
      case '0.80':
        this.csvreq.windcolumn = 10;
        break;
      case '0.90':
        this.csvreq.windcolumn = 11;
        break;
      case '1.00':
        this.csvreq.windcolumn = 12;
        break;
      case '1.10':
        this.csvreq.windcolumn = 13;
        break;
    }

  }


  async getParsedData(req: any, callBack: any) {
    this.http.get(req, { responseType: 'text' })
      .subscribe(
        data => {
          this.papa.parse(data, {
            complete: results => {
              callBack(results.data);
            }
          });
        },
        error => {
          console.log(error);
        }
      );
  }


  searchData(parsed: any, orientation: string, cal: any) {
    let searching: any;
    let found: any;
    const span = [];
    const spanSDST = [];
    const indexFound = [];
	if(this.csvreq.asce == 22 ) {
			for (let i = 0; i < parsed.length; i++) {
		  searching = parsed[i];
		  if (this.csvreq.windspeed === searching[1] &&
			this.csvreq.windexposure === searching[2] &&
			this.csvreq.mrh === searching[3] &&
			this.csvreq.snowload === searching[4] &&
			this.csvreq.roofpitch === (this.roundoff(searching[5]))) {
		   // this.csvreq.roofpitch === (searching[5])) {
			indexFound.push(i);
		  }
		}
	}	
	else {
		for (let i = 0; i < parsed.length; i++) {
		searching = parsed[i];
			if (this.csvreq.windspeed === searching[1] &&
			this.csvreq.windexposure === searching[2] &&
			this.csvreq.snowload === searching[4] &&
			this.csvreq.roofpitch === (this.roundoff(searching[5]))) {
			// this.csvreq.roofpitch === (searching[5])) {
			indexFound.push(i);
			}
		}
	}
    // Get info for all zones
    const num = (cal !== 'mini') ? 3 : (this.csvreq.asce === '10') ? 2 : (this.csvreq.asce === '16') ? 3 : 1;
    for (let j = 0; j < num; j++) {
      found = parsed[indexFound[j]];
      if (orientation === 'port') {
        span.push((found[7] * this.ratio.port).toFixed(2));
        spanSDST.push((found[8] * this.ratio.port).toFixed(2));
      } else if (orientation === 'land') {
		span.push((found[7] * this.ratio.land).toFixed(2));
        spanSDST.push((found[8] * this.ratio.land).toFixed(2));
      } else {
        console.log(new Error('Error getting the requested panels orientation!'));
      }
    }
    return { span, spanSDST };
  }


  // Canadian Data
  searchDataCan(parsed: any, orientation: string) {
    let searching: any;
    let found: any;
    const span = [];
    const indexFound = [];

    for (let i = 0; i < parsed.length; i++) {
      searching = parsed[i];
      if (this.csvreq.snowload === searching[0] &&
        this.csvreq.roofterrain === searching[1] &&
        this.csvreq.roofpitch === searching[2] &&
        this.csvreq.roofzone === searching[3]) {
        indexFound.push(i);
      }
    }
    // Get info for all zones
    const num = indexFound.length;
    for (let j = 0; j < num; j++) {
      found = parsed[indexFound[j]];
      const finalParsed = found[this.csvreq.windcolumn] === '-' ? '0.0' : found[this.csvreq.windcolumn];
      if (orientation === 'port') {
        span.push(Number(finalParsed * this.ratio.port));
      } else if (orientation === 'land') {
        span.push(Number(finalParsed * this.ratio.land));
      } else {
        console.log(new Error('Error getting the requested panels orientation!'));
      }
    }
    return span;
  }

  roundoff(roofpitch: any) {
    return (parseFloat(roofpitch)).toFixed(1);
  }

  getMaxSpanPort(rt: any, cal: any) {
    this.initialize(cal);
	let fileNamePort;
	if(this.csvreq.asce == 22) {
	 fileNamePort = this.fileloc + this.csvreq.asce + '_' + 
      this.csvreq.rooftype + rt + '_' + this.csvreq.pv + '.csv';
	}
    else {
	fileNamePort = this.fileloc + this.csvreq.asce + '_' + this.csvreq.mrh +
      this.csvreq.rooftype + rt + '_' + this.csvreq.pv + '.csv';
    }
	return new Promise((resolve: any, reject: any) => {
      this.getParsedData(fileNamePort, ((dataPort: any) => {
        resolve(this.searchData(dataPort, 'port', cal));
        reject(new Error('Error getting the max span'));
      }));
    });
  }

  getMaxSpanLand(rt: any, cal: any) {
    this.initialize(cal);
	let fileNameLand;
	if(this.csvreq.asce == 22) {
     fileNameLand = this.fileloc + this.csvreq.asce + '_' + 
      this.csvreq.rooftype + rt + '_' + this.csvreq.pv + '_Landscape.csv';
	  }
	  else {
	  fileNameLand = this.fileloc + this.csvreq.asce + '_' + this.csvreq.mrh +
      this.csvreq.rooftype + rt + '_Landscape.csv';
	  }
    return new Promise((resolve: any, reject: any) => {
      this.getParsedData(fileNameLand, ((dataLand: any) => {
        resolve(this.searchData(dataLand, 'land', cal));
        reject(new Error('Error getting the max span'));
      }));
    });
  }

  getMaxSpanPortCan(rt: any, cal: any, project: {}, clip: any) {
    this.initializeCan(cal, project);
    const fileNamePort = (clip) ? this.fileloc + rt + '_' + this.csvreq.pv + '_' + clip + '.csv'
      : this.fileloc + rt + '_' + this.csvreq.pv + '.csv';
    return new Promise((resolve: any, reject: any) => {
      this.getParsedData(fileNamePort, ((dataPort: any) => {
        resolve(this.searchDataCan(dataPort, 'port'));
        reject(new Error('Error getting the max span'));
      }));
    });
  }


  getMaxSpanLandCan(rt: any, cal: any, project: {}, clip: any) {
    this.initializeCan(cal, project);
    const fileNameLand = (clip) ? this.fileloc + rt + '_Landscape' + '_' + clip + '.csv' : this.fileloc + rt + '_Landscape.csv';
    return new Promise((resolve: any, reject: any) => {
      this.getParsedData(fileNameLand, ((dataLand: any) => {
        resolve(this.searchDataCan(dataLand, 'land'));
        reject(new Error('Error getting the max span'));
      }));
    });
  }


}
