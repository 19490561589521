// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // access_token: 'M1mgGaIUyTu13QWk8E07Q9G3C1EP4X',
  appVersion: require('../../package.json').version ,
  production: false,
  firebase: {
    apiKey: 'AIzaSyDLmRStJazZEQ7tLjF_K4CdW0PeznZAvIE',
    authDomain: 'roof-tech-db.firebaseapp.com',
    databaseURL: 'https://roof-tech-db.firebaseio.com',
    projectId: 'roof-tech-db',
    storageBucket: 'issues-files',
    messagingSenderId: '702565872107',
    appId: '1:702565872107:web:ac4f2d749ba9676883a9ae',
    measurementId: 'G-Z8MET2LE3M'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
