<div id="myHeader">
    <nav class="navbar navbar-expand-sm">
        <div class="navbar-nav mr-auto nav-logo">
            <a class="nav-item nav-link" *ngIf="router.url != '/postsignup'" routerLink="/"><img
                    src="../../../../assets/images/logo.png" height="50px" alt="Italian Trulli">
            </a>
            <a class="nav-item nav-link" *ngIf="router.url == '/postsignup'" routerLink="/postsignup"><img
                    src="../../../../assets/images/logo.png" height="50px" alt="Italian Trulli">
            </a>
            <button class="navbar-toggler d-md-block d-lg-none" (click)="toggleNavbar = !toggleNavbar">
                <i class="fas fa-bars" style="font-size:26px; color:white;"></i>
            </button>
        </div>


        <!--  <div class="navbar-nav ml-auto menu-items" [ngStyle]="{'display': toggleNavbar ? 'contents' : 'none'}" style="margin-left: 55px;">  -->
        <div class="navbar-nav ml-auto menu-items" [ngClass]="{'hide-men': toggleNavbar == false}">
            <div *ngIf="router.url != '/postsignup'" class="navbar-nav ml-auto menu-items"
                [ngClass]="{'hide-men': toggleNavbar == false}">
                <a *ngIf="showForAdminUser" class="nav-item nav-link" routerLink="/admin"
                    (click)="toggleNavbar = false">
                    <h4>Admin Management</h4>
                </a>


                <a class="nav-item nav-link" routerLink="/calspan" (click)="toggleNavbar = false">
                    <h4>Span Calculator</h4>
                </a>
                <a *ngIf="showForAdminUser" class="nav-item nav-link" routerLink="bomcreator"
                    (click)="toggleNavbar = false">
                    <h4>BOM Creator</h4>
                </a>
                <a *ngIf="regis_status && (auth.user$ | async)" class="nav-item nav-link" routerLink=""
                    (click)="newproject()">
                    <h4>New Project</h4>
                </a>
                <a *ngIf="regis_status && (auth.user$ | async)" class="nav-item nav-link" routerLink="/yourproject"
                    (click)="toggleNavbar = false">
                    <h4>Your Projects</h4>
                </a>
                <a *ngIf="!(auth.user$ | async)" class="nav-item nav-link" routerLink="/login"
                    (click)="toggleNavbar = false">
                    <h4>Login</h4>
                </a>
                <a *ngIf="!(auth.user$ | async)" class="nav-item nav-link" routerLink="/signup"
                    (click)="toggleNavbar = false">
                    <h4>Register</h4>
                </a>



                <li ngbDropdown *ngIf="auth.user$ | async as user" class="nav-item dropdown">


                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-user" style="font-size:24px"></i> <span style="margin-left: 5px;">
                            {{user.email}}</span></button>


                    <div class="btn-group loggedistyle">

                        <div class="dropdown-menu dropdown-menu-right" style="min-width: 230px;">
                            <a class="dropdown-item" (click)="settings()">Settings</a>
                            <a class="dropdown-item" (click)="onChangePassword()">Change Password</a>
                            <a class="dropdown-item" (click)="onDisableAccount()">Disable Your Account</a>
                            <a class="dropdown-item" (click)="logout()">Log Out</a>
                        </div>
                    </div>
                </li>

                <!-- <a class="nav-item nav-link" routerLink="/" (click)="toggleNavbar = false">
                    <h4>About</h4>
                </a> -->

            </div>
        </div>
    </nav>
</div>

<div
    *ngIf="router.url == '/projects' || router.url == '/map' || router.url == '/design' || router.url == '/parts' || router.url == '/engineering'; design">
    <div #design class="navbar">

        <!-- <div class="col-sm title-left light">
            <p>APEX Project Name</p>
        </div> -->
        <div class="col-sm header-title light">
            <mat-form-field>
                <input matInput placeholder="Project Name" [ngModel]="title" (keyup)="titleUpdated($event)">
                <mat-icon matSuffix id="editicon">mode_edit</mat-icon>
            </mat-form-field>
        </div>
        <div class="col-sm header-title light">
            <h4>{{ panel + " Modules, "}} {{ (panel == '--') ? '-- KW' : calculateOutput() + " KW" }}</h4>
        </div>


        <a mat-raised-button class="head-save" (click)="saveProject()">Save</a>

        <div class="col-sm projectNav dark">
            <a mat-button [routerLink]="['./design']" routerLinkActive="active-link"
                [ngClass]="{'disabled' : designenablecheck() }">Design</a>
        </div>
        <div class="col-sm projectNav dark">
            <a mat-button [routerLink]="['./parts']" routerLinkActive="active-link"
                [ngClass]="{'disabled' : panel == 0 || router.url == '/map' || this.designFailed}">Parts</a>
        </div>
        <div class="col-sm projectNav dark">
            <a mat-button [routerLink]="['./engineering']" routerLinkActive="active-link"
                [ngClass]="{'disabled' : panel == 0 || router.url == '/design' || router.url == '/map'}">Engineering</a>
        </div>

    </div>
</div>


<div class="alert alert-success" *ngIf="alertbox">
    <p><strong>Reset Email Sent !!</strong> </p>
</div>