import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-alert',
  template: `
<div class="overlay">
  <mat-dialog-content>
    <p>Are you sure you want to delete your workspace?</p>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="confirmDelete(true)">DELETE</button>
    <button mat-button mat-dialog-close (click)="confirmDelete(false)">CANCEL</button>
</mat-dialog-actions>
</div>`,
  styles: [`

`]
})


export class ConfirmComponent {
  action: boolean;

  constructor(public dialogRef: MatDialogRef<ConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  confirmDelete(confirm: any) {
    this.action = confirm;
  }

  onClose(): void {
    this.dialogRef.close(this.action);
  }

}
