import { Component, AfterViewInit, ViewChild, ElementRef, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ModuleComponent } from 'src/app/design/information/module/module.component';
import { LocationComponent } from 'src/app/design/information/location/location.component';
import { RoofComponent } from 'src/app/design/information/roof/roof.component';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import * as L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-geodesy';
import { MapService } from 'src/app/service/map.service';
import { icon } from 'leaflet';
import 'leaflet-simple-map-screenshoter';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/service/project.service';
import { AuthService } from 'src/app/service/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { IssuesComponent } from '@src/app/issues/issues.component';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { HelpComponent } from 'src/app/design/draw/confirm/help.component';
import * as firebase from 'firebase/app';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})

export class MapComponent implements AfterViewInit, OnInit, OnDestroy {

  @ViewChild('map') mapElement: ElementRef;

  currentClicked: any;
  lastClicked: any;
  overlayRefs: any = [];
  opened = [true, false, false];
  map: L.Map;
  marker: L.Marker;
  featureGroup = this.mapService.getFeatureGroup();
  workspace = this.mapService.getMap().workspace;
  mapOptions: L.MapOptions;
  drawOptions: any;
  eveSide = [];
  menuItems = [];
  polyline: L.Polyline;
  layerHistory: L.Polygon;
  loading = false;
  boundary: any;
  sw: any;
  se: any;
  areacount = 0;
  zonesub: any;
  private project: any;
  blankArea: any;
  evearr = [];
  stepsubs: Subscription;
  isguide = true;
  step = '';
  addareabool = false;
  country_bool = this.projectService.getProject().country;


  constructor(
    private overlay: Overlay,
    public mapService: MapService,
    public projectService: ProjectService,
    private router: Router,
    private ngZone: NgZone,
    public auth: AuthService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }


  ngOnInit() {
    this.project = this.projectService.getProject();
    this.country_bool = this.country_bool ==  undefined ? 'us' : this.country_bool;
    if (this.workspace === 1) {
      this.mapOptions = this.mapService.leafletOptions;
      this.drawOptions = this.mapService.leafletDrawOptions;
      this.projectService.setProject('panels', this.projectService.getmoduleblock().length);
    } else {
      this.mapOptions = this.mapService.leafletOptionsBlank;
      this.drawOptions = this.mapService.leafletDrawOptionsBlank;
      this.auth.admincheck();
    }
    this.mapService.laststep = 'step1';
    this.startintroguide();
  }


  ngAfterViewInit() {
    this.stepsubs = this.mapService.currentMessage.subscribe(step => {
      this.step = step;
      if (step === 'step1') {
        this.toggleOverlay(0);
      } else if (step === 'step2') {
        this.toggleOverlay(1);
      } else if (step === 'step3') {
        this.toggleOverlay(2);
      } else if (step === 'step4') {
        this.toggleOverlay(3);
      }
    });
    if (this.step === 'step0' || this.step === 'step6') {
      this.onClickOverlay();
    }
  }

  startintroguide() {
    if (this.mapService.laststep === 'step1' || this.mapService.laststep === 'step6'
      || this.mapService.laststep === 'step7' || this.mapService.laststep === 'step8') {
      this.opened[0] = false; this.mapService.laststep = 'step1';
    } else if (this.mapService.laststep === 'step2') { this.opened[1] = false; } else if (this.mapService.laststep === 'step3') {
      this.opened[2] = false;
    }
    this.mapService.startnext(this.mapService.laststep);
  }

  // intro code
  onNext(step) {
    this.mapService.startnext(step);
  }
  onPrev(step) {
    this.mapService.startnext(step);
  }
  closeintro(step) {
    this.mapService.laststep = step;
    this.mapService.startnext('finished');
  }


  ngOnDestroy() {
    this.projectService.postprojnotif();
    this.snackBar.dismiss();
    // phase 2
    this.stepsubs.unsubscribe();
    if (this.router.url !== '/design' && this.projectService.issaved) {
   //   this.projectService.postedid = [];
      this.projectService.navigatecount = 0;
//      this.projectService.deleteunsavedprojects(this.project.areaid, this.project.id);
    }
    this.map.remove();
    for (let i = 0; i < this.opened.length; i++) {
      if (this.overlayRefs[i] !== undefined) {
        this.closeOverlays(i);
      }
    }
  }

  openpopup() {
    const dialogRef = this.dialog.open(IssuesComponent);
  }



  toggleOverlay(view: any) {
    for (let i = 0; i < this.opened.length; i++) {
      if (i === view) {
        this.opened[i] = !this.opened[i];
      } else {
        this.opened[i] = false;
      }
    }
    this.onClickOverlay();
  }

  toggleOverlayComponent(index: number) {
    switch (index) {
      case 0:
        return ModuleComponent;
      case 1:
        return LocationComponent;
      case 2:
        return RoofComponent;
    }
  }

  onClickOverlay() {
    for (let i = 0; i < this.opened.length; i++) {
      if (this.opened[i]) {
        this.overlayRefs[i] = this.createOverlay();
        this.showOverlay(this.overlayRefs[i], this.toggleOverlayComponent(i));
        this.overlayRefs[i].backdropClick().subscribe(() => {
          if (this.overlayRefs[i] !== undefined) {
            this.closeOverlays(i);
          }
        });
      } else {
        if (this.overlayRefs[i] !== undefined) {
          this.closeOverlays(i);
        }
      }
    }
  }

  closeOverlays(indexes: any) {
    this.overlayRefs[indexes].dispose();
  }

  createOverlay() {
    return this.overlay.create({
      positionStrategy: this.overlay.position().flexibleConnectedTo(this.mapElement)
        .withPositions([{
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        }, {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        }]),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
  }

  showOverlay(overlayRef: any, view: any) {
    overlayRef.attach(new ComponentPortal(view));
  }


  onMapReady(map: L.Map) {
    if (this.workspace === 1) {
      // map loaded, store in map variable
      this.map = map;

      // Set the location
      this.map.locate({ setView: true });

      this.map.on('locationfound', (location) => {
        const latlng = {
          lat: location.latlng.lat,
          lng: location.latlng.lng
        };

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          { location: latlng },
          (
            results: google.maps.GeocoderResult[],
            status: google.maps.GeocoderStatus
          ) => {
            if (status === 'OK') {
              if (results[0]) {
                this.projectService.setProject('state', results[0].address_components[4].short_name);
                this.projectService.setProject('zip', results[0].address_components[6].long_name);
                this.projectService.setProject('address', results[0].formatted_address);
              } else {
                console.log('No results found');
              }
            } else {
              console.log('Geocoder failed due to: ' + status);
            }
          }
        );

      });

      const attrOptions = {
        prefix: 'Google'
      };
      L.control.attribution(attrOptions).addTo(this.map);
      // Creating scale control
      L.control.scale().addTo(this.map);
      this.createLayerHistory();
    } else {
      // For Blank page, make a rectangle rooft area
      // phase 2
      this.map = map;
      this.boundary = this.map.getBounds();
      const center = this.map.getCenter();
      this.blankArea = L.rectangle(this.boundary, { color: '#eb4031', weight: 7 }).bindTooltip('Roof Area 1').addTo(map);
      this.blankArea.id = this.project.id + 1;
      this.map.setView(center, (this.map.getZoom() - 4));
      this.onCreateMenu(this.blankArea, this.project.id + 1);
      this.sw = this.boundary.getSouthWest();
      this.se = this.boundary.getSouthEast();
      // sw,se
      const eve = new L.Polyline([this.sw, this.se], { color: '#eb4031', weight: 12 }).addTo(map);
      this.evearr.push(eve);
      const idlength = this.project.areaid === undefined ? 1 : this.project.areaid.length;
      for (let x = 1; x < idlength; x++) {
        this.areacount++;
        this.addareasubfunc();
      }
    }
  }

  // phase 2
  addarea() 
  {
	let mapcond = this.country_bool == 'canada' ? (this.project.terr.length == 0 ? false : true) : true	
	this.checkForms() 
   if(this.project.state.length && this.project.rt.length && mapcond) {
		if (this.areacount === 5) {
		  this.snackBar.open('Could not add more roof area', 'OK', {
			duration: 3000,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });
		} else {
		  this.addareabool = true;
		  this.projectService.createareaid();
		  this.areacount = this.project.areaid.length - 1;
		  this.projectService.createareadb().then(() => {
			this.addareabool = false;
			this.addareasubfunc();
		  });
		}
   }	
  }

  addareasubfunc() {
    const toplefty = this.boundary.getNorthEast().lat;
    const toprighttx = this.boundary.getNorthEast().lng + 0.05 * this.areacount;
    const bottomlefty = this.boundary.getSouthWest().lat;
    const topleftx = this.boundary.getSouthWest().lng + 0.05 * this.areacount;
    const blackarea = L.rectangle([[toplefty, toprighttx], [bottomlefty, topleftx]], { color: '#eb4031', weight: 7 }).addTo(this.map);
    const latlngarr = [[this.sw.lat, this.sw.lng + 0.05 * this.areacount], [this.se.lat, this.se.lng + 0.05 * this.areacount]];
    const seceve = new L.Polyline(latlngarr, { color: '#eb4031', weight: 12 });
    seceve.addTo(this.map);
    this.onCreateMenu(blackarea, this.project.areaid[this.areacount]);
    this.evearr.push(seceve);
    const servicearr = [this.projectService.getroofareaname(this.project.areaid[0]).pipe(take(1)),
    this.projectService.getroofareaname(this.project.areaid[this.areacount]).pipe(take(1))];
    this.zonesub = forkJoin(servicearr).subscribe(
      (data: any) => {
        this.blankArea.bindTooltip(data[0].zonetitle).addTo(this.map);
        const tempcount = data[1].zonetitle;
        blackarea.bindTooltip(tempcount).addTo(this.map);
      },
      err => console.log(err)
    );
  }

  createLayerHistory() {
    const historyLayer = this.mapService.getMap().layer;
    const historyLine = this.mapService.getMap().layerEveSide;
    if (historyLayer.length) {
      this.layerHistory = new L.Polygon(historyLayer, { color: '#eb4031', opacity: 1 });
      this.polyline = new L.Polyline(historyLine, { color: '#eb4031', weight: 12 });
      this.layerHistory.addTo(this.map);
      this.polyline.addTo(this.map);
      this.eveSide = historyLine;
      this.onCreateMenu(this.layerHistory, this.project.areaid[0]);
      this.map.fitBounds(this.layerHistory.getBounds());
      this.map.setZoom(21);
      this.setEdgeListener(this.layerHistory);
      this.layerHistory.addTo(this.featureGroup);
      this.polyline.addTo(this.featureGroup);
    } else { return; }
  }

  onPolygonCreated(event: any) {
    if (this.polyline !== undefined) {
      this.polyline.removeFrom(this.map);
    }
    if (this.layerHistory !== undefined) {
      this.layerHistory.removeFrom(this.map);
    }
    event.layer.addTo(this.map);
    this.addEveSide(event.layer);
    event.layer.addTo(this.featureGroup);
    // Create a Menu on top of drawn polygon
    this.onCreateMenu(event.layer, '');
  }


  savePolygonMap(layer: any) {
    const mapCenter = layer.getBounds().getCenter();
    const layerCenter = [mapCenter.lat, mapCenter.lng];
    this.mapService.setMap('layerCenter', layerCenter);
    const centerXY = [
      this.map.latLngToLayerPoint(mapCenter).x,
      this.map.latLngToLayerPoint(mapCenter).y
    ];
    this.mapService.setMap('layerPixelCenter', centerXY);

    this.mapService.setMap('layerBounds', layer.getBounds());
    const northEastgeo = new firebase.firestore.GeoPoint(layer.getBounds()._northEast.lat, layer.getBounds()._northEast.lng);
    const southWestgeo = new firebase.firestore.GeoPoint(layer.getBounds()._southWest.lat, layer.getBounds()._southWest.lng);
    // tslint:disable-next-line: object-literal-key-quotes
    this.mapService.setMap('layerBounds', { '_northEast': northEastgeo, '_southWest': southWestgeo });

    const layerArray = [];
    const geolayerarray = [];
    layer.getLatLngs()[0].forEach((value: any) => {
      layerArray.push(value);
      geolayerarray.push(new firebase.firestore.GeoPoint(value.lat, value.lng));
    });
    this.mapService.setMap('layer', layerArray);
    this.mapService.setMap('layer', geolayerarray);
    this.savePolygonPixels(layerArray);
  }



  // Save Eave line Info
  savePolygonPixels(polygonArray: any) {
    const pointsArray = [];
    polygonArray.forEach((value: any) => {
      const tempPoint = this.map.latLngToLayerPoint(value);
      pointsArray.push({ x: tempPoint.x, y: tempPoint.y });
    });
    this.mapService.setMap('layerPixel', pointsArray);

    const evePixelPoints = [];
    const pointA = this.map.latLngToLayerPoint(this.eveSide[0]);
    const pointB = this.map.latLngToLayerPoint(this.eveSide[1]);
    evePixelPoints.push({ x: pointA.x, y: pointA.y }, { x: pointB.x, y: pointB.y });
    this.mapService.setMap('layerPixelEveSide', evePixelPoints);
  }


  calculateMapToPixelRatio(layer: any) {
    const layerLatLngArray = layer.getLatLngs()[0];
    const mapDistance = this.map.distance(layerLatLngArray[0], layerLatLngArray[1]) * 39.37; // Inch
    const point1 = this.map.latLngToLayerPoint(layerLatLngArray[0]);
    const point2 = this.map.latLngToLayerPoint(layerLatLngArray[1]);
    const pixelDistance = point1.distanceTo(point2);
    const ratio = mapDistance / pixelDistance; // Ratio = Inch per pixel
    this.mapService.setMap('mapRatio', ratio);
  }


  calculateArea(layer: any) {
    const areaMeter = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]); // returns m^2
    const areaFeet = areaMeter * 10.764;  // convert m^2 to sqft
    this.mapService.setMap('layerArea', areaFeet);
  }


  async getScreenshot() {
    const pluginOptions = {
      // by default hide map controls All else must be child of _map._container
      hideElementsWithSelectors: ['.leaflet-control-container']
    };
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
    const simpleMapScreenshoter = L.simpleMapScreenshoter(pluginOptions).addTo(this.map);
    await simpleMapScreenshoter.takeScreen('image').then((image: any) => {
      this.mapService.setMap('imgSrc', image);
      return 'Resolved';
    }).catch((error: any) => {
      console.error('oops, something went wrong!', error);
      return 'Rejected';
    });
  }


  handleAddressChange(address: Address) {
    this.parselocation(address);
    if (address.photos && address.photos.length > 0) {
      console.dir(address.photos[0].getUrl({ maxHeight: 500, maxWidth: 500 }));
    }
    this.map.setView([address.geometry.location.lat(), address.geometry.location.lng()], 22);

    if (this.marker !== undefined) {
      this.map.removeLayer(this.marker);
    }
    this.marker = L.marker([address.geometry.location.lat(), address.geometry.location.lng()], {
      icon: icon({
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: 'assets/map-pin.png',
        shadowUrl: 'assets/marker-shadow.png'
      })
    });
    this.marker.addTo(this.map);
    this.setEdgeListener(this.marker);
    this.removeMarkerOnZoom();
  }


  parselocation(address: any) {
    const addcomp = address.address_components;
    this.projectService.setProject('address', address.formatted_address);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < addcomp.length; ++i) {
      if (addcomp[i].types[0] === 'administrative_area_level_1') {
        this.projectService.setProject('state', addcomp[i].short_name);
      }
      if (addcomp[i].types[0] === 'postal_code') {
        this.projectService.setProject('zip', addcomp[i].short_name);
      }
    }
  }

  removeMarkerOnZoom() {
    this.map.on('zoomend', () => {
      if (this.marker !== undefined) {
        if (this.map.getZoom() > 18) {
          this.map.removeLayer(this.marker);
        } else {
          this.marker.addTo(this.map);
        }
      }
    });
  }


  // Add a separate line to distinguish Eve side, set the path to the first line
  addEveSide(layer: any) {
    const pointA = layer.getLatLngs()[0][0];
    const pointB = layer.getLatLngs()[0][1];
    this.eveSide.length = 0;
    this.eveSide.push(pointA, pointB);
    this.polyline = new L.Polyline(this.eveSide, { color: '#eb4031', weight: 12 });
    this.polyline.addTo(this.map);
    this.polyline.addTo(this.featureGroup);
    this.setEdgeListener(layer);
    this.mapService.setMap('layerEveSide', [pointA, pointB]);
    const geopointA = new firebase.firestore.GeoPoint(pointA.lat, pointA.lng);
    const geopointB = new firebase.firestore.GeoPoint(pointB.lat, pointB.lng);
    this.mapService.setMap('layerEveSide', [geopointA, geopointB]);
  }


  setEdgeListener(polygon: any) {
    polygon.on('click', (event: any) => {
      let A: any;
      let B: any;
      let pointA: any;
      let pointB: any;
      const pointC = {
        x: this.map.latLngToContainerPoint(event.latlng).x,
        y: this.map.latLngToContainerPoint(event.latlng).y
      };
      const corners = polygon.getLatLngs()[0];

      const points = [];
      for (const corner of corners) {
        const tempPoint = this.map.latLngToLayerPoint(corner);
        points.push({ x: tempPoint.x, y: tempPoint.y });
      }
      for (let index = 0; index < corners.length; index++) {
        A = corners[index];
        pointA = {
          x: this.map.latLngToContainerPoint(A).x,
          y: this.map.latLngToContainerPoint(A).y
        };
        if (index < points.length - 1) {
          B = corners[index + 1];
          pointB = {
            x: this.map.latLngToContainerPoint(B).x,
            y: this.map.latLngToContainerPoint(B).y
          };
        } else {
          B = corners[0];
          pointB = {
            x: this.map.latLngToContainerPoint(B).x,
            y: this.map.latLngToContainerPoint(B).y
          };
        }
        if (this.isCollinear(pointA, pointB, pointC)) {
          this.eveSide.length = 0; // reset the Eve Side points
          this.eveSide.push(A, B);  // Save the new Eve side points
          this.mapService.setMap('layerEveSide', this.eveSide);
          this.polyline.setLatLngs(this.eveSide);
        }
      }
    });
  }


  isCollinear(a: any, b: any, c: any) {
    const deltaX = b.x - a.x;
    const deltaXc = c.x - a.x;
    const deltaY = b.y - a.y;
    let slope: any;
    let Yc: any;

    if (deltaX === 0) {
      if (deltaXc === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      slope = Math.round((deltaY / deltaX) * 100) / 100;
      Yc = (slope * (c.x - a.x)) + a.y;
      if (Math.abs(c.y - Yc) < 50) {
        return true;
      } else {
        return false;
      }
    }
  }


  onCreateMenu(layer: any, id) {
    let menuIcon: any;
    let deleteicon: any;
    const menuTitle = '';
    const delpos = layer.getBounds().getCenter();
    delpos.lng = delpos.lng + 0.01;
    menuIcon = L.marker(layer.getBounds().getCenter(), {
      icon: icon({
        iconSize: [30, 30],
        iconAnchor: [20, 20],
        iconUrl: '../../../../assets/images/plus-red.png'
      })
    });
    if (this.areacount !== 0) {
      deleteicon = L.marker(delpos, {
        icon: icon({
          iconSize: [30, 30],
          iconAnchor: [20, 20],
          iconUrl: '../../../../assets/images/trash.png'
        })
      });
      deleteicon.addTo(this.map);
      deleteicon.id = id;
      deleteicon.on('click', (event) => {
        const removeindex = this.indexofarea(event.target.id);
        this.evearr[removeindex].removeFrom(this.map);
        this.evearr.splice(Number(removeindex), 1);
        layer.removeFrom(this.map);
        menuIcon.removeFrom(this.map);
        deleteicon.removeFrom(this.map);
      });
    }
    menuIcon.id = id;
    menuIcon.addTo(this.map);
    menuIcon.addTo(this.featureGroup);
    this.menuItems.push(menuIcon);
    this.featureGroup.addLayer(menuIcon);

    menuIcon.on('click', (event) => {
      this.checkForms();
      if (this.project.state.length && this.project.rt.length) {
        if (this.workspace === 1) {
          this.loading = true;
          (this.onMapScreenReady(layer, menuIcon, menuTitle))
            .then(() => {
              this.getScreenshot()
                .then(() => {
                  this.ngZone.run(() => this.router.navigate(['/design']));
                  this.loading = false;
                });
            }).catch((error: any) => {
              console.error('Rejected Promise from: ', error);
            });
        } else {
          this.projectService.selectedid = event.target.id;
          this.createnewprojects();
          this.ngZone.run(() => this.router.navigate(['/design']));
        }
      }
    });

  }

  indexofarea(id) {
    for (const i in this.project.areaid) {
      if (id === this.project.areaid[i]) {
        this.projectService.removeroofarea(i);
        this.project = this.projectService.getProject();
        return i;
      }
    }
  }


  onMapScreenReady = (layer: any, menuIcon: any, menuTitle: any) => {
    // Center and zoom out for background image
    const mapCenter = layer.getBounds().getCenter();
    this.map.fitBounds(layer.getBounds());
    this.map.setView([mapCenter.lat, mapCenter.lng], (this.map.getMaxZoom() - 3));
    // this.map.on ('zoomend', (e) => { console.log('ZOOMEND', e); });
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        if (this.map.getZoom() === 19) {
          // Save all the information about that polygon
          this.savePolygonMap(layer);
          this.calculateArea(layer);
          this.calculateMapToPixelRatio(layer);
          this.map.removeLayer(menuIcon);
          // this.map.removeLayer(menuTitle);
          this.map.removeLayer(layer);
          this.map.removeLayer(this.polyline);
          resolve(this.map.getZoom());
        } else {
          reject(new Error('Error in making map ready!'));
        }
      }, 1000);
    });
    return promise;
  }


  checkFormField(check: any, msg: string, view: number) {
    if (!check) {
      this.snackBar.open(msg, 'OK', {
        duration: 7000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.toggleOverlay(view);
    }
  }

  checkForms() {
    const state = 'You have to choose a state. Please select the location information on the left ' +
      'sidebar and select a state from the list.';
    const roofType = 'You have to choose a roof attachment. Please select the builing and roof ' +
      'information on the left sidebar and select a roof attachment type from the list.';
    const terrain = 'You have to choose a terrain. Please select the location information on the left' +
    'sidebar and select a terrain from the list.';
      if (!this.project.state) {
      this.checkFormField(this.project.state, state, 1);
    } else if (!this.project.rt) {
      this.checkFormField(this.project.rt, roofType, 2);
    }
    else if(this.country_bool == 'canada' && this.project.terr.length == 0){
      this.checkFormField(false, terrain, 1);
    }
  }

  async gotoDesign() {
    this.checkForms();
    var mapcond = true;
    if(this.country_bool == 'canada')
    {
      mapcond = this.project.terr.length == 0 ? false : true;
    }
    if (this.workspace === 1) {
      this.snackBar.open('Please select a roof area on the map.', 'OK', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    } else if (this.project.state.length && this.project.rt.length && mapcond) {
	  await this.project.areaid.length == 0 ? '' : this.projectService.changeallroofname()	
	  this.createnewprojects();
      this.ngZone.run(() => this.router.navigate(['/design']));
    }
  }

  createnewprojects() {
  //  if (this.projectService.navigatecount === 0) {
   //   this.projectService.increasecount();
      this.projectService.postproject();
   // }
  }



  mapZoomOut() {
    this.map.setView([this.map.getCenter().lat, this.map.getCenter().lng], (this.map.getZoom() - 1));
  }


  mapZoomIn() {
    this.map.setView([this.map.getCenter().lat, this.map.getCenter().lng], (this.map.getZoom() + 1));
  }


  drawPolygon() {
    const e = document.createEvent('Event');
    e.initEvent('click', true, true);
    const cb = document.getElementsByClassName('leaflet-draw-draw-polygon');
    return !cb[0].dispatchEvent(e);
  }


  editPolygon() {
    const e = document.createEvent('Event');
    e.initEvent('click', true, true);
    const cb = document.getElementsByClassName('leaflet-draw-edit-edit');
    return !cb[0].dispatchEvent(e);
  }


  deletepolygon() {
    const allLayers = this.featureGroup.getLayers();
    if (allLayers.length === 0) {
      this.snackBar.open('Nothing to Delete!', 'OK', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    } else {
      allLayers.forEach(layer => {
        layer.removeFrom(this.map);
      });
      this.snackBar.open('Roof area deleted!', 'OK', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    }
  }

  showHelp() {
    this.dialog.open(HelpComponent);
  }


}





