<div class="bottomright">
  <div class="container" style="width:1200px; background-color: #ffffff; padding: 50px 90px; opacity: 0.9;">

    <a mat-raised-button class="back" [routerLink]="['/devissues']">&larr; Back</a>

    <div>
      <div>
        <h5>Issue Date: {{(openIssue.date | date: 'dd-MM-yyyy  h:mm:ss')  || 'Old Submit'}}</h5>
      </div>
      <div><br />
        <h5>Submitted by: {{openIssue.name}}</h5>
      </div>
      <div><br />
        <h5>Email: {{openIssue.email}}</h5>
      </div>
      <div><br />
        <h5>Company: {{openIssue.company}}</h5>
      </div>
      <div><br />
        <h5>State: {{openIssue.state}}</h5>
      </div>
      <div><br />
        <h5>Related Project Name: {{openIssue.projname || 'None'}}</h5>
      </div>
      <div><br />
        <h5>Issue's Description: <p>{{openIssue.description || 'Empty'}}</p>
        </h5>
      </div>
      <div *ngIf="openIssue.filePath != undefined"> <br />
        <h5>Attached File(s): </h5>
        <p *ngIf="!openIssue.filePath">** No Files Uploaded! **</p>
        <div *ngIf="openIssue.filePath.length > 0">
          <a mat-button (click)="downloadFile(openIssue.filePath, false)">{{openIssue.filePath.split('/')[1]}}</a>
          <!-- <button mat-button (click)="downloadFile(openIssue.filePath, true)" class="ml-auto">
            <mat-icon>save_alt</mat-icon></button> -->
        </div>
      </div>
      <!-- <div><h3>User Name: <h4>{{openIssue.name}}</h4>
      </h3>
      </div> -->

    </div>


  </div>
</div>

<div class="row footer">
<app-footer></app-footer>
</div>