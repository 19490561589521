import { Component, OnInit } from '@angular/core';
import { UsersOperation } from '@src/app/service/usersoperation.service';
import { ActivatedRoute } from '@angular/router';
import { DisableAccountRequest } from 'src/models/DisableAccountRequest.model';
import { headercomp } from 'src/models/headercomp.model';

@Component({
  selector: 'app-disableaccount',
  templateUrl: './disableaccount.component.html',
  styleUrls: ['./disableaccount.component.css']
})
export class DisableaccountComponent implements OnInit {

  HeaderRequest: headercomp = {
    emailid: '',
    currentpage: '',
    navigatedtpage: ''
  };

  actDisable: number;

  disableAccountRequest: DisableAccountRequest = {
    emailid: '',
    disableaccount: 0
  };

  constructor(private userService: UsersOperation, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.disableAccountRequest.emailid = params['{emailid}'];
      this.HeaderRequest.emailid = this.disableAccountRequest.emailid;
      this.HeaderRequest.currentpage = 'disableaccount';
      this.userService.headercomprequest.emit(this.HeaderRequest);
    });
  }

  onAccountDisable(disableaccount: number) {
    this.disableAccountRequest.disableaccount = disableaccount;
    this.userService.disableAccount(this.disableAccountRequest);
    // console.log(disableaccount);
  }

}
