import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectService } from 'src/app/service/project.service';
import { Router } from '@angular/router';
import { MapService } from '@src/app/service/map.service';


@Component({
  selector: 'app-roof',
  templateUrl: './roof.component.html',
  styleUrls: ['./roof.component.css']
})
export class RoofComponent implements OnInit, OnDestroy {


  material: any;
  rafter: any;
  rt: any;
  type: any;
  country_bool = this.projectService.getProject().country;


  constructor(public projectService: ProjectService, private router: Router, public mapservice: MapService) { }


  ngOnInit() {
    this.initializeVariables();
  }

  ngOnDestroy() {
    this.saveChanges();
  }

  initializeVariables() {
    const data = this.projectService.getProject();
    this.country_bool = this.country_bool ==  undefined ? 'us' : this.country_bool;
    this.rafter = data.rafter;
    this.material = data.material;
    this.rt = data.rt;
    this.type = data.type;
  }

  saveChanges() {
    this.projectService.setProject('rafter', this.rafter);
    this.projectService.setProject('material', this.material);
    this.projectService.setProject('rt', this.rt);
    this.projectService.setProject('type', this.type);
    (this.rt.slice(0, 6) === 'rafter') ? this.projectService.setProject('failed', true) : this.projectService.setProject('failed', false);
	this.projectService.postprojnotif();
 }

		closemodel() {
  document.getElementById('module_butt').click();
  }
	
  loadVariables(project: any) {
    this.rafter = project.rafter;
    this.material = project.material;
    this.rt = project.rt;
    this.type = project.type;
  }

	postfromhead() {
	this.projectService.postprojectfromheader(true)
	}

  // intro code
  onPrev() {
    this.mapservice.startnext('step2');
  }
  onNext() {
    this.mapservice.startnext('step4');
  }
  closeintro() {
    this.mapservice.laststep = 'step3';
    this.mapservice.startnext('finished');
  }

}
