
<div style="padding: 20px;">
  <div class="row searchbox">
    <p>FILTER</p>
    <input type="text" placeholder="Search.." [(ngModel)]="searchTerm" (ngModelChange)="setFilteredItems(searchTerm)">
  </div>

  <table class="table table-condensed">
    <thead>
      <tr>
        <th>PROJECT NAME &nbsp; <i [class]="sortname ? 'fas fa-sort-amount-down' : 'fas fa-sort-amount-up'"
            (click)="sort()"></i></th>
        <th>CLIENT NAME &nbsp; <i [class]="sortclient ? 'fas fa-sort-amount-down' : 'fas fa-sort-amount-up'"
            (click)="sortbyclient()"></i></th>
        <th>CREATED &nbsp; <i [class]="sortdate ? 'fas fa-sort-amount-down' : 'fas fa-sort-amount-up'"
            (click)="sortbydate()"></i></th>
        <th>State</th>
        <th>Copy</th>
        <th>Delete</th>
      </tr>
    </thead>


			<div *ngIf="!DONE" class="wait">
  <app-spinner></app-spinner>
</div>

	
    <tbody *ngIf="DONE" >
      <tr *ngFor="let pro of projects | slice: lowValue : highValue;let i=index; ">
        <td class="namecol" (click)="gotoproj(pro)">{{pro.name}}</td>
        <td>{{pro.clientname}}</td>
        <td>{{dateformat(pro.timestamp)}}</td>
        <td>{{pro.state}}</td>
        <td (click)="copyfunc(pro)"><i class="far fa-copy"></i><span class="tooltiptext">Copy</span></td>
        <td (click)="deletefunc(pro)"><i class="fas fa-trash"></i> <span class="tooltiptext">Delete</span></td>
	 </tr>

      <!-- <h4 *ngIf="projects.length == 0">There is no projects to laod!</h4> -->
    </tbody>
  </table>




  <div *ngIf="DONE">
    <mat-paginator #paginator [length]="projects.length" [pageSizeOptions]="[10, 20, 50, 100]"
      (page)="getPaginatorData($event)">
    </mat-paginator>
  </div>
</div>

<div class="row footer">
<app-footer></app-footer>
</div>