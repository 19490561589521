import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectService } from 'src/app/service/project.service';
import { MapService } from '@src/app/service/map.service';

@Component({
  selector: 'app-delete-alert',
  template: `
<div class="overlay">
  <h1 mat-dialog-title class="center">How to complete a Rafter Projects</h1>
  <div mat-dialog-content>
    <video width="640" height="480" controls autoplay>
    <source src="{{vidSrc}}" type="video/mp4">
    Your browser does not support the video tag.
    </video>
  </div>
</div>`,
  styles: [`
    .center {
      text-align: center;
    }
`]
})


export class HelpComponent implements OnInit {
  vidSrc = '';

  constructor(
    public dialogRef: MatDialogRef<HelpComponent>,
    public projectService: ProjectService,
    public mapService: MapService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit() {
    const roofType = this.projectService.getProject().rt;
    this.vidSrc = (this.mapService.getMap().workspace === 1)
      ? '../../../../assets/videos/helpMap.mp4'
      : (roofType.slice(0, 6) === 'rafter')
        ? '../../../../assets/videos/helpRafter.mp4' :
        '../../../../assets/videos/helpDeck.mp4';
  }

}
