import { Component, OnInit, AfterViewInit, ViewChild, HostListener, ElementRef, OnDestroy } from '@angular/core';
import Konva from 'konva';
import { PanelService } from 'src/app/service/panel.service';
import { ProjectService } from 'src/app/service/project.service';
import { AuthService } from 'src/app/service/auth.service';
import { Overlay } from '@angular/cdk/overlay';
import { ZoneComponent } from 'src/app/design/information/zone/zone.component';
import { ComponentPortal } from '@angular/cdk/portal';
import * as L from 'leaflet';
import { MapService } from 'src/app/service/map.service';
import { icon } from 'leaflet';
import * as htmlToImage from 'html-to-image';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SpanService } from 'src/app/service/span.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from 'src/app/design/draw/confirm/confirm.component';
import * as polycheck from 'point-in-polygon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IssuesComponent } from 'src/app/issues/issues.component';
import { HelpComponent } from 'src/app/design/draw/confirm/help.component';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { OverlayService } from 'src/app/service/overlay.service';

export enum Icons {
  landscape = 'landscape',
  portrait = 'portrait',
  addPanel = 'add_panel',
  addPanelTyped = 'add_type_panel',
  removePanel = 'remove_panel',
  populatePanel = 'populate',
  span = 'span'
}


@Component({
  selector: 'app-draw',
  templateUrl: './draw.component.html',
  styleUrls: ['./draw.component.css']
})


export class DrawComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('drawing') stageElement: ElementRef;
  @ViewChild('compass') compassElement: ElementRef;

  stage: Konva.Stage;
  designLayer: Konva.Layer;
  background: Konva.Layer;
  designGroup: Konva.Group;
  gridGroup: Konva.Group;
  clampGroup: Konva.Group;
  conflictGroup: Konva.Group;
  cursorLayer: Konva.Layer;
  tableEvent: Konva.Rect;
  tableDraw: Konva.Rect;
  mapLine: Konva.Line;
  eaveLine: Konva.Line;

  W: number = Number((window.innerWidth * 0.97).toFixed());
  H: number = Number((window.innerHeight * 0.83).toFixed());
  stageCenterPoint = { x: this.W / 2, y: this.H / 2 };
  ZOOM_LEVEL = 2.8;
  workspace = this.mapService.getMap().workspace;
  MAP_TO_PIXEL_PROPORTION = this.getRatio(); // Ratio = Inch per pixel
  PANEL_STROKEWITH = (this.projectService.getProject().width / this.MAP_TO_PIXEL_PROPORTION) * 0.08;
  WIDTH = (this.projectService.getProject().width / this.MAP_TO_PIXEL_PROPORTION) - (1 * this.PANEL_STROKEWITH);
  LENGTH = (this.projectService.getProject().length / this.MAP_TO_PIXEL_PROPORTION) - (1 * this.PANEL_STROKEWITH);
  BLANK_ZOOM = 5;
  OFFSET = {
    x: (this.W / 8) * 3,
    y: (this.H / 8) * 3
  };
  MAXPOINT = {
    x: (this.W / 4) * 3,
    y: (this.H / 4) * 3
  };
  SPAN = this.projectService.getProject().span;
  SPACING = { x: (this.SPAN / this.MAP_TO_PIXEL_PROPORTION), y: (1.0625 / this.MAP_TO_PIXEL_PROPORTION) };
  RAFTER_SPACE = Number(this.projectService.getProject().rafter) / this.MAP_TO_PIXEL_PROPORTION;
  RAFTERLINE_INDEX = 0;
  RAFTER_DRAG = 0;
  ZOOM_SCALE = 1.125;
  MAX_ZOOM = (this.workspace === 1) ? 40 : 30;
  MIN_ZOOM = (this.workspace === 1) ? 1 : 0.6;
  PANEL_ORIENTATION = false;
  SPLICE_LENGTH = 8.5;
  totalPanels = 0;
  panelCursor: any;
  ENABLE_TOOLS = false;
  ENABLE_DRAWING = true;
  TABLE_DRAWING = false;
  TABLE_TOOLTIP = false;
  DRAG_START: any;
  DRAG_END: any;
  historyPanels = [];
  opened = false;
  overlayRef: any;
  toolZone = 1;
  COLOR_DEFAULT = '#6495ed';
  pitch = this.getPitch();
  mapBackground: any;
  toolPanel: string;
  editing: string;
  posDelta = { x: 0, y: 0 };
  cursor = { x: 0, y: 0 };
  USE_SPLICE: any;
  clampsFinal = { mid: [], end: [] };
  clampsLand = { mid: [], end: [] };
  clampsPort = { mid: [], end: [] };
  spliceFinal = { mid: [], end: [] };
  spliceFloatFinal = { mid: [], end: [] };
  addClamps = { mid: [], end: [] };
  addSplices = { mid: [], end: [] };
  cantilever = 0.15;
  addTitle = false;
  CLAMP_RATIO = (this.workspace === 1) ? 0.8 : (3.5 / this.MAP_TO_PIXEL_PROPORTION) * 1.25;
  CLAMP_RATIO_CENTER = { x: (1.5 * this.CLAMP_RATIO), y: (0.5 * this.CLAMP_RATIO) };
  CLAMP_RATIO_DIM = { x: (3 * this.CLAMP_RATIO), y: this.CLAMP_RATIO };
  MAX_SPANS = { land: { R: [], D: [], RDR: [] }, port: { R: [], D: [], RDR: [] } };
  SHOW_RAFTERLINE = true;
  ROOF_ATTACHMENT = this.projectService.getProject().rt;
  COMPARED_SPAN = [];
  COMPARED_SPAN_PORT = [];
  HAS_CONFLICT = false;
  hideGlossary = true;
  map: L.Map;
  editableLayers = new L.FeatureGroup();
  mapOptions = this.mapService.leafletOptions;
  mapArea: any;
  mapCenter = this.mapService.getMap().layerCenter;
  pixelCenter = this.mapService.getMap().layerPixelCenter;
  mapAreaLayer: any;
  eaveAngle: any;
  mapreference: any;
  moduleblock: any;
  dragarea: any;
  polygonarea = [];
  polygonareaOrg = [];
  finalimage: any;
  rafterClampArea = [];
  rafterSpliceArea = [];
  rotationDelta = { x: 0, y: 0 };
  polygoncenter: any;
  topleftcorner: any;
  toprightcorner: any;
  bottleftcorner: any;
  bottrightcorner: any;
  middleleft: any;
  middleright: any;
  topmiddlepoly: any;
  bottmiddlepoly: any;
  notRafterClamps = 0;
  bottriang: any;
  bottsmalltri: any;
  toptriang: any;
  topsmalltri: any;
  gableRoof = true;
  leftpoly: any;
  rightpoly: any;
  rightsmallpoly: any;
  leftsmallpoly: any;

  // part of undo
  firstchange: any;
  secondchange = [];
  changecount = 0;
  stepsarr = [];
  clampsteps = [];
  clampcount = 0;
  delboll = false;
  editboll = false;
  undoenable = false;
  // dragbool = true;
  colorchangecount = 0;
  seccolorcount = 0;
  snapcount = 1;
  // for snapping
  testpoints = [];
  // phase 2
  yourProject: any;
  buttarr = [];
  selectedarea = '';
  roofareasub: any;
  isexit = true;
  areabuttnames = [];
  // new snapping
  blcktopleft = [];
  blcktopright = [];
  blckbottright = [];
  blckbottleft = [];
  xpoints = [];
  ypoints = [];
  blocksnapid = [];

  // V2
  WIZARD = false;
  static = Number(this.projectService.getProject().static);
  staggered = this.projectService.getProject().staggered;
  EW: any;
  NS: any;
  xrevsnap = false;
  yrevsnap = false;
  // intro code
  stepsubs: Subscription;
  step = '';
  panelattbool = false;
  hinteightbool = true;

  // V3
  projectZones = [];
  asterisk = this.projectService.getProject().asterisk;
  zoneTitle = '1';
  starTitle = '';
  zone = 0;
  checkProgress = false;

  country_bool = this.projectService.getProject().country == undefined ? 'us' : this.projectService.getProject().country;
  clip: any;
  canadazone = 'R';
  canadazonecount = 1;
  areabuttdata = [];
  textcol = null;
  textrow = null;
  typepos = null;
  typemodbool = true;
  ADMIN_ONLY = this.auth.currentUser.admin;

  headernotifsub: any;

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    this.saveBlocks();
  }


  constructor(
    private panelService: PanelService,
    public projectService: ProjectService,
    public mapService: MapService,
    private maxSpanService: SpanService,
    private overlay: Overlay,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private snackBar: MatSnackBar,
    private overlayservice: OverlayService,
    private auth: AuthService
  ) {
    // Add Icons
    this.registerIcons();

    // Getting header save button click event
    this.headernotifsub = this.projectService.getheadersavenotif().subscribe(async (change) => {
      this.saveBlocks();
      if (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter') {
        this.saveRafterClampingDB();
      }
      else {
        this.saveDeckClampingDB();
      }
      if (!this.roofareacheck()) {
        await this.projectService.changenameofproj();
        this.projectService.updateroofarea(this.selectedarea).then(async data => {
          await this.projectService.addtotalpanel();
        });
      }

    });

  }

  initMap(map: L.Map) {
    if (this.workspace === 1) {
      this.map = map;
      try {
        this.createLayerHistory();
      } catch (error) {
        console.log('Setview Error: ', error);
      }
    }
  }



  registerIcons() {
    this.loadIcons(Object.values(Icons), '../../../assets/images/icons');
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    iconKeys.forEach(key => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }


  ngOnInit() {
    this.initializeStage();
    // If choose workspace as map, prepare the background
    if (this.workspace === 1) { this.prepareMapBackground(); } else {
      this.setZoomStage(this.BLANK_ZOOM, this.stageCenterPoint);
    }
    this.setPanelCursorColor();
    this.restoreStage();

    if (this.ROOF_ATTACHMENT.slice(0, 6) !== 'rafter') { this.projectService.setProject('failed', false); }

    this.stage.on('contentContextmenu', (e) => {
      e.evt.preventDefault();
    });

    this.projectService.savespinner('on');
    this.projectService.getspinnerValue().subscribe(data => {
      this.checkProgress = (data === 'on') ? true : false;
    });
  }

  setPanelCursorColor() {
    this.panelCursor.fill(this.COLOR_DEFAULT);
    this.panelService.setPanelColor(this.COLOR_DEFAULT);
  }


  initializeStage() {
    // Initialize the stage
    this.stage = this.panelService.addStage(this.W, this.H);

    // Initialize drawing layer and it's group
    this.designLayer = this.panelService.addLayer();
    this.designGroup = this.panelService.addGroup();
    this.designLayer.add(this.designGroup);
    this.stage.add(this.designLayer);

    // Initiate Panel Cursor
    this.cursorLayer = this.panelService.addLayer();
    this.stage.add(this.cursorLayer);
    this.panelCursorDraw(this.getOrientation(), this.cursor);
    this.addCursor();
    //this.typemodbool = true;
    this.tableDraw = this.panelService.createTableDrawOnMouse();
    this.cursorLayer.add(this.tableDraw);
    this.stage.draw();

    // Initiate the attachment Group
    this.clampGroup = this.panelService.addGroup();
    this.designLayer.add(this.clampGroup);

    // Make the working stage able to zoom in & out relative to cursor position
    this.zoomStage();

    // Enable adding mode in the begining
    this.toolPanel = 'add';

    // Enable drag to draw array of panels
    this.findDrag();
  }


  prepareMapBackground() {
    const eavePoints = this.mapService.getMap().layerPixelEveSide;
    const pointA = new L.Point(eavePoints[0].x, eavePoints[0].y);
    const pointB = new L.Point(eavePoints[1].x, eavePoints[1].y);
    this.eaveAngle = this.calculatePolygonAngle(pointA, pointB);
    this.background = this.panelService.addLayer();
    this.stage.add(this.background);
    const mapsrc = this.mapService.getMap().imgSrc;
    this.addMapBackground(this.eaveAngle);

    this.topleftcorner = { x: 0.0, y: 0.0, width: 0.0, height: 0.0 };
    this.toprightcorner = { x: 0.0, y: 0.0, width: 0.0, height: 0.0 };
    this.bottleftcorner = { x: 0.0, y: 0.0, width: 0.0, height: 0.0 };
    this.bottrightcorner = { x: 0.0, y: 0.0, width: 0.0, height: 0.0 };
    this.middleleft = [0, 0];
    this.middleright = [0, 0];
    this.topmiddlepoly = [0, 0];
    this.bottmiddlepoly = [0, 0];
    // 11-15-2020
    this.stepsarr = [];
    this.firstchange = [];
    this.stepsarr.push(this.firstchange);
    this.clampsteps.push(this.firstchange);
  }

  getBase64FromUrl = async (url: any) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };


  restoreStage() {
    // Set the project id if null, otherwise get it from projectService
    this.dragarea = [];
    this.polygonarea = [];
    this.yourProject = this.projectService.getProject();
    // detect multiple roof area project
    if (this.yourProject.areaid !== undefined && this.yourProject.areaid.length > 0) {
      this.selectedarea = this.projectService.selectedid;
      this.initializeroofareas();
    } else {
      // tslint:disable-next-line: forin
      for (const key in this.yourProject) {
        this.projectService.setProject(key, this.yourProject[key]);
      }
      this.setupmoduleblocks();
      this.projectService.saveprojname();
    }
  }


  // phase 2
  initializeroofareas() {
    this.areabuttnames = [];
    this.roofareasub = this.projectService.getroofareadb(this.selectedarea).subscribe((singarea: any) => {
      this.projectService.setprojects(singarea);
      this.showOverlay(ZoneComponent);
      this.projectService.saveprojname();
      this.yourProject = this.projectService.getProject();
      this.roofareasub.unsubscribe();
      this.ROOF_ATTACHMENT = this.yourProject.rt;
      this.setupmoduleblocks();
      this.getareaname();
      if (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter') { this.setRafterEnvironment(); }
      this.stepsarr = [];
      this.clampsteps = [];
      this.firstchange = [];
      this.checkoldblocks();
      this.checkProgress = false;
    });
  }


  // phase 2
  getareaname() {
    const servarr = [];
    this.yourProject.areaid.forEach((id) => {
      servarr.push(this.projectService.getroofareaname(id).pipe(take(1)));
    });
    forkJoin(servarr).subscribe((data: any) => {
      this.areabuttnames = [];
      this.areabuttdata = data;
      this.projectService.postareanotif(this.areabuttdata);
      data.forEach((singdat) => {
        this.areabuttnames.push(singdat.zonetitle);
      });
    });

  }



  guideDone() {
    this.router.navigate(['/map']);
    this.projectService.issaved = true;
  }


  setupmoduleblocks() {
    // if redirecting from YourProject, initialize the project
    if (this.projectService.getmoduleblock().length > 0) {

      this.moduleblock = this.projectService.getmoduleblock();
      this.moduleblock.forEach((element: any) => {
        const panel = new Konva.Rect(element);
        this.designGroup.add(panel);
        panel.moveToTop();
        this.dragarea.push(panel);
        // snapping code
        this.savesnappingpoints(panel._id, panel.attrs);
        // new snapping
      });
      this.designLayer.draw();
      this.totalPanels = this.designGroup.children.length;
      this.setPanelCount();
      this.updateDimension();
      // new snapping
      this.detectcorners();
      if (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter' && this.COMPARED_SPAN.length === 0) {
        // Set Max Span Display
        this.setMaxSpanLocal();
        this.projectZones = this.getProjectZones([]);
      }
    }
  }


  // V3
  changeroofarea(id) {
    this.resetDesignSetting();
    this.saveBlocks();
    this.isexit = false;
    this.checkProgress = true;
    this.destroysubfunc();
    this.projectService.changenameofproj();
    this.getareaname();
    setTimeout(() => {
      this.projectService.updateroofarea(this.selectedarea);
      setTimeout(() => {
        this.deleteallfunc();
        this.subdeletefunc();
        this.projectService.selectedid = id;
        this.selectedarea = id;
        this.initializeroofareas();
      }, 500);
    }, 900);
  }


  centerDesignToView() {
    this.restoreZoomView();
    const designGroupBox = this.designGroup.getClientRect({ skipStroke: false });
    const designPos = {
      x: designGroupBox.x + (designGroupBox.width * 0.5),
      y: designGroupBox.y + (designGroupBox.height * 0.5)
    };
    this.setZoomStage(this.BLANK_ZOOM, designPos);
  }


  // Scale everything back to start - Recenter the stage
  restoreZoomView() {
    this.setZoomStage((1 / this.stage.scaleY()), this.stageCenterPoint);
    this.stage.position({ x: 0, y: 0 });
  }


  ngAfterViewInit() {
    if (this.workspace === 1) { // Rotate the compass
      this.compassElement.nativeElement.style.transform = 'rotate(' + this.eaveAngle + 'deg)';
    }
    // If project is a rafter or rafter combo, draw rafterlines and create clamping shapes
    if (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter') {
      this.drawGridLines();
      this.setRafterEnvironment();
    }
    // intro code
    setTimeout(() => {
      this.stepsubs = this.mapService.currentMessage.subscribe(step => {
        const laststep = this.mapService.laststep;
        this.step = (laststep === 'step1' || laststep === 'step2' || laststep === 'step3'
          || laststep === 'step4' || laststep === 'step5') ? 'step6' : step;
        if (this.step === 'step7' || this.step === 'step16') {
          this.hideOverlay();
        } else if (
          this.step === 'step6' &&
          this.roofareacheck()) {
          // this.mapService.startnext('step6');
          this.toggleOverlay();
        }
      });
    }, 100);
    // Undo code
    this.setundoenvironment();
    this.panelCursorShow();
  }

  closeintro(step) {
    this.mapService.laststep = step;
    this.mapService.startnext('finished');
  }

  onNext(step) {
    this.mapService.startnext(step);
  }
  onPrev(step) {
    this.mapService.startnext(step);
  }
  startintroguide() {
    this.mapService.startnext(this.mapService.laststep);
  }


  roofareacheck() {
    return this.yourProject.areaid === undefined || this.yourProject.areaid.length === 0;
  }



  // Undo code
  setundoenvironment() {
    const tempsteps = this.stepsarr;
    if (tempsteps == null || tempsteps.length === 0) {
      this.checkoldblocks();
    } else {
      this.changecount = (tempsteps == null) ? 0 : tempsteps.length - 1;
      this.stepsarr = tempsteps;
      setTimeout(() => {
        this.undoenable = true;
      }, 200);
    }
  }

  checkoldblocks() {
    if (this.designGroup.children.length > 0) {
      this.secondchange = [];
      this.designGroup.children.each(shape => {
        this.secondchange.push(shape.attrs);
      });
      this.stepsarr.push(JSON.parse(JSON.stringify(this.secondchange)));
      this.fillclampsteps();
    } else {
      this.stepsarr = [];
      this.firstchange = [];
      this.stepsarr.push(this.firstchange);
      this.clampsteps.push(this.firstchange);
    }
  }


  fillclampsteps() {
    this.secondchange = [];
    this.designLayer.find('.splice').each(shape => {
      this.secondchange.push(shape.attrs);
    });
    this.designLayer.find('.clamp').each(shape => {
      this.secondchange.push(shape.attrs);
    });
    this.clampsteps.push(JSON.parse(JSON.stringify(this.secondchange)));
  }

  openpopup() {
    const dialogRef = this.dialog.open(IssuesComponent);
  }


  setRafterEnvironment() {
    this.RAFTER_DRAG = this.projectService.getProject().rafterLines;
    if (this.RAFTER_DRAG !== 0) {
      this.gridGroup.setPosition({
        x: this.RAFTER_DRAG
      });
      this.designLayer.batchDraw();
    }
    this.conflictGroup = this.panelService.addGroup();
    this.designLayer.add(this.conflictGroup);

    // Remove old clamping
    if (this.clampGroup.children.length > 0) {
      this.clampGroup.destroyChildren();
    }

    if (this.projectService.getbackclamp()) {
      this.USE_SPLICE = Number(this.projectService.getProject().splice);
      const endClampR = this.projectService.getclamping('endClampR');
      const endClampD = this.projectService.getclamping('endClampD');
      const midClampR = this.projectService.getclamping('midClampR');
      const midClampD = this.projectService.getclamping('midClampD');
      const endSpliceRaf = this.projectService.getclamping('endSpliceR');
      const midSpliceRaf = this.projectService.getclamping('midSpliceR');
      this.spliceFinal.end = this.projectService.getclamping('endSpliceD');
      this.spliceFinal.mid = this.projectService.getclamping('midSpliceD');
      this.spliceFloatFinal.mid = this.projectService.getclamping('midSpliceF');
      this.spliceFloatFinal.end = this.projectService.getclamping('endSpliceF');

      if (this.spliceFinal.end.length > 0) {
        this.drawSplicesArr(this.spliceFinal.end, 'green', this.clampGroup, false, false);
      }
      if (this.spliceFinal.mid.length > 0) {
        this.drawSplicesArr(this.spliceFinal.mid, 'orangered', this.clampGroup, false, false);
      }
      if (endSpliceRaf.length > 0) {
        this.drawSplicesArr(endSpliceRaf, 'green', this.clampGroup, true, false);
      }
      if (midSpliceRaf.length > 0) {
        this.drawSplicesArr(midSpliceRaf, 'orangered', this.clampGroup, true, false);
      }
      if (this.spliceFloatFinal.mid.length > 0) {
        this.drawSplicesArr(this.spliceFloatFinal.mid, 'lightsalmon', this.clampGroup, false, true);
      }
      if (this.spliceFloatFinal.end.length > 0) {
        this.drawSplicesArr(this.spliceFloatFinal.end, 'yellowgreen', this.clampGroup, false, true);
      }
      if (endClampR.length > 0) {
        this.drawClampsArr(endClampR, 'green', this.clampGroup, true);
      }
      if (endClampD.length > 0) {
        this.drawClampsArr(endClampD, 'green', this.clampGroup, false);
      }
      if (midClampR.length > 0) {
        this.drawClampsArr(midClampR, 'orangered', this.clampGroup, true);
      }
      if (midClampD.length > 0) {
        this.drawClampsArr(midClampD, 'orangered', this.clampGroup, false);
      }
      this.clampGroup.moveToTop();
    }
    this.designLayer.batchDraw();
  }


  ngOnDestroy() {
    this.headernotifsub.unsubscribe();
    this.projectService.postprojnotif();
    this.hideOverlay();
    this.saveBlocks();
    // this.setMapInfo();
    if (this.snackBar) { this.snackBar.dismiss(); }
    if (this.stepsubs) { this.stepsubs.unsubscribe(); }
    if (this.workspace === 1) {
      this.map.remove();
      this.updateDimension();
      // Update the roof area by roof pitch effect ratio
      this.projectService.setProject('dimension', [this.EW, this.NS, this.pitchEffect(this.getPitch())]);
    }
    // phase 2
    if (this.router.url !== '/parts' && this.router.url !== '/map' && this.projectService.issaved) {
      //   this.projectService.navigatecount = 0;
      //      this.projectService.deleteunsavedprojects(this.yourProject.areaid, this.yourProject.id);
      //     this.projectService.setProject('areaid', []);
    }
    this.isexit = (this.roofareacheck()) ? true : false;
    (this.roofareacheck()) ? this.destroysubfunc() : this.multidestroysubfunc();
  }


  // phase 2
  async multidestroysubfunc() {
    this.isexit = true;
    this.destroysubfunc();
    this.projectService.savespinner('on');
    this.projectService.tableBody = [];
    await this.projectService.changenameofproj();
    // total panel code
    this.projectService.updateroofarea(this.selectedarea).then(async data => {
      await this.projectService.addtotalpanel();
      this.projectService.savespinner('off');
    });
  }

  destroysubfunc() {
    if (this.totalPanels > 0) {
      this.projectService.tableBody = [];
      this.calculateBottomRowPanelsSkirts();
      // v2 - If project involves w/ Rafter, save the changes of rafter line start, Otherwise calculate clamp/splice positions
      if (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter') {
        this.projectService.setProject('rafterLines', this.RAFTER_DRAG);
        this.saveRafterClampingDB();
        this.saveStageImage();
      } else {
        this.getBothMaxSpan().then(() => {
          this.showAllPossibleDeckAttachments();
          this.saveDeckClampingDB();
          this.saveStageImage();
        });
      }
    }
  }


  setMapInfo() {
    this.mapService.setMap('workspace', this.workspace);
    // TOD: Google Map - set the selected roof area variables to show on the small map!
  }


  saveBlocks() {
    const history = [];
    this.projectService.setProject('moduleblocks', []);
    this.designLayer.find('.panel').each(shape => {
      history.push(shape.getAttrs());
      this.projectService.pushmoduleblock(shape);
    });
  }


  saveRafterClampingDB() { // save clamps & splices info for Rafter projects
    this.projectService.clearclamping();
    this.designLayer.find('.clamp').each(shape => {
      if (shape.getAttrs().stroke === 'green') {
        if (shape.getAttrs().fill === 'green') {
          this.projectService.pushclamping('endClampR', shape);
        } else if (shape.getAttrs().fill === 'white') {
          this.projectService.pushclamping('endClampD', shape);
        }
      } else if (shape.getAttrs().stroke === 'orangered') {
        if (shape.getAttrs().fill === 'orangered') {
          this.projectService.pushclamping('midClampR', shape);
        } else if (shape.getAttrs().fill === 'white') {
          this.projectService.pushclamping('midClampD', shape);
        }
      }
    });
    this.designLayer.find('.splice').each(shape => {
      if (shape.getAttrs().stroke === 'green') {
        if (shape.getAttrs().fill === 'green') {
          this.projectService.pushclamping('endSpliceR', shape);
        } else if (shape.getAttrs().fill === 'white') {
          this.projectService.pushclamping('endSpliceD', shape);
        }
      } else if (shape.getAttrs().stroke === 'orangered') {
        if (shape.getAttrs().fill === 'orangered') {
          this.projectService.pushclamping('midSpliceR', shape);
        } else if (shape.getAttrs().fill === 'white') {
          this.projectService.pushclamping('midSpliceD', shape);
        }
      } else if (shape.getAttrs().fill === 'lightsalmon' && shape.getAttrs().stroke === 'white') {
        this.projectService.pushclamping('midSpliceF', shape);
      } else if (shape.getAttrs().fill === 'yellowgreen' && shape.getAttrs().stroke === 'white') {
        this.projectService.pushclamping('endSpliceF', shape);
      }
    });
  }


  saveDeckClampingDB() { // save clamps & splices info for Deck projects
    this.projectService.clearclamping();
    this.designLayer.find('.clamp').each(shape => {
      if (shape.getAttrs().stroke === 'green') {
        this.projectService.pushclamping('endClampD', shape);
      } else if (shape.getAttrs().stroke === 'orangered') {
        this.projectService.pushclamping('midClampD', shape);
      }
    });
    this.designLayer.find('.splice').each(shape => {
      if (shape.getAttrs().stroke === 'green') {
        this.projectService.pushclamping('endSpliceD', shape);
      } else if (shape.getAttrs().stroke === 'orangered') {
        this.projectService.pushclamping('midSpliceD', shape);
      } else if (shape.getAttrs().fill === 'lightsalmon' && shape.getAttrs().stroke === 'white') {
        this.projectService.pushclamping('midSpliceF', shape);
      } else if (shape.getAttrs().fill === 'yellowgreen' && shape.getAttrs().stroke === 'white') {
        this.projectService.pushclamping('endSpliceF', shape);
      }
    });
  }


  calculateBottomRowPanelsSkirts() {
    let count = 0;
    const allPanels = [];
    const bottomsPanels = [];

    this.designGroup.children.each((panel: any) => {
      allPanels.push(panel);
    });
    for (const panel of allPanels) {
      const lowerPointBox1 = {
        x: panel.x() + (panel.width() * 0.1), y: panel.y() + (panel.height() * 1.5),
        width: this.PANEL_STROKEWITH, height: this.PANEL_STROKEWITH
      };
      const lowerPointBox2 = {
        x: panel.x() + (panel.width() * 0.9), y: panel.y() + (panel.height() * 1.5),
        width: this.PANEL_STROKEWITH, height: this.PANEL_STROKEWITH
      };
      const intersected1 = this.getPanelIntersection(lowerPointBox1, this.PANEL_STROKEWITH);
      const intersected2 = this.getPanelIntersection(lowerPointBox2, this.PANEL_STROKEWITH);
      if (!intersected1.length || !intersected2.length) { bottomsPanels.push(panel); }
      const ratio1 = (!intersected1.length) ? 0.5 : 0;
      const ratio2 = (!intersected2.length) ? 0.5 : 0;
      count += (this.getPanelOrientation(panel) === 'land') ? 1 * (ratio1 + ratio2) : 0.5 * (ratio1 + ratio2);
    }
    this.projectService.setProject('skirtNum', Math.ceil(count));
  }


  getRatio() {
    const mapRatio = this.mapService.getMap().mapRatio;
    return (this.workspace === 1) ? mapRatio / 2.5 : 3.2;
  }

  getPitch() {
    return Number(this.projectService.getProject().pitch);
  }


  addMapBackground(angle: any) {
    // Crop the map Image URL, then add map image to background
    let map: any;
    const mapImage = new Image();
    mapImage.src = this.mapService.getMap().imgSrc;
    mapImage.onload = () => {
      // Add and rotate background map iamge
      map = this.panelService.createBackgroundImage(mapImage, this.W, this.H);
      this.rotateShape(map, this.stageCenterPoint, angle);
      this.background.add(map);
      this.setZoomStage(this.ZOOM_LEVEL, this.stageCenterPoint);
      this.background.draw();
    };

    const backgroundImage = new Image();
    htmlToImage.toPng(document.getElementById('drawing'))
      .then((dataUrl) => {
        backgroundImage.src = dataUrl;
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });

    backgroundImage.onload = () => {
      // Remove old image & Reset Stage
      map.destroy();
      this.setZoomStage((1 / this.ZOOM_LEVEL), this.stageCenterPoint);


      // Add the new background image
      const final = this.panelService.createBackgroundImage(backgroundImage, this.W, this.H);
      this.mapBackground = final; // copy the original bg image for pitch effect
      this.background.add(final);
      this.background.moveToBottom();
      this.background.draw();

      // Add a dark watermark
      const watermark = this.panelService.createBackgroundWatermark(this.W, this.H);
      this.background.add(watermark);
      this.background.draw();

      this.addMapAreaLayer(angle);
      this.stepsarr = [];
      this.clampsteps = [];
      this.firstchange = [];
      this.checkoldblocks();
      if (this.pitch > 0) {
        const pitchRatio = this.pitchEffect(this.getPitch());
        const move = this.changeBackgroundByPitch(pitchRatio);
        this.changeRoofAreaByPitch(pitchRatio, move);
      }
      this.ENABLE_TOOLS = true;
    };
  }


  addMapAreaLayer(angle: any) {
    // const points = this.mapService.getMap().layerPixel;
    const points = this.mapService.getMap().layerPixel;
    const layerCenterPoints = { x: this.pixelCenter[0], y: this.pixelCenter[1] };
    const mapOffset = {
      x: this.stageCenterPoint.x - layerCenterPoints.x,
      y: this.stageCenterPoint.y - layerCenterPoints.y
    };
    // const centeredPoints = points.map((pos: any) => {
    //   return { x: pos.x - mapOffset.x, y: pos.y - mapOffset.y };
    // });

    const polygonPoints = points;

    // scale polygon to Zoom level towards center
    points.forEach((element: any) => {
      element.x *= this.ZOOM_LEVEL;
      element.y *= this.ZOOM_LEVEL;
    });

    const flattenPoints = points.reduce((r: any, obj: any) => r.concat(obj.x, obj.y), []);
    const polygon = this.panelService.createMapPolygonArea(flattenPoints);

    const polygonAttr = polygon.getClientRect({ skipStroke: true });
    const polygonCenter = {
      x: polygonAttr.x + (polygonAttr.width / 2),
      y: polygonAttr.y + (polygonAttr.height / 2)
    };
    const polygonOffset = {
      x: this.stageCenterPoint.x - polygonCenter.x,
      y: this.stageCenterPoint.y - polygonCenter.y
    };

    polygonPoints.forEach((element: any) => {
      element.x += polygonOffset.x;
      element.y += polygonOffset.y;
    });

    if (mapOffset.y === 0) {
      polygonPoints.forEach((element: any) => {
        element.x -= 2 * (mapOffset.x);
        element.y -= 2 * (mapOffset.y);
      });
    } else {
      polygonPoints.forEach((element: any) => {
        element.x -= 2 * 8.5;
      });
    }

    const flattenFinalPoints = polygonPoints.reduce((r: any, obj: any) => r.concat(obj.x, obj.y), []);
    const roofPolygonRaw = this.panelService.createMapPolygonArea(flattenFinalPoints);
    this.background.add(roofPolygonRaw);
    this.rotateShape(roofPolygonRaw, this.stageCenterPoint, angle);

    const finalPoints = this.updateTransformedPolygon(flattenFinalPoints, roofPolygonRaw); finalPoints.pop();
    this.polygonareaOrg = finalPoints.map(obj => Object.values(obj)).flat();
    roofPolygonRaw.destroy();

    this.mapLine = this.panelService.createMapPolygonArea(this.polygonareaOrg);
    this.background.add(this.mapLine);
    this.background.batchDraw();


    // Find the two points on the Eve side
    this.addEveLine(finalPoints);
  }


  // Find the two points with highest y and draw a line to show Eve side
  addEveLine(finalPolygon: any) {
    // Sort the polygon points based on Y
    finalPolygon.sort((a, b) => (a.y < b.y) ? 1 : (a.y === b.y) ? ((a.x > b.x) ? 1 : -1) : -1);

    const eavePoints = finalPolygon.slice(0, 2);
    eavePoints.forEach((element: any) => { element.y += 1; });
    this.eaveLine = this.panelService.createMapPolygonEveSide(eavePoints.map(obj => Object.values(obj)).flat());
    this.background.add(this.eaveLine);
    this.eaveLine.moveToTop();
    this.background.draw();
  }

  // finding center of polygon
  getPolygonCentroid(pts: any) {
    const first = pts[0]; const last = pts[pts.length - 1];
    let twicearea = 0; let x = 0; let y = 0; const nPts = pts.length;
    let p1; let p2; let f;
    if (first.x !== last.x || first.y !== last.y) { pts.push(first); }
    for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
      p1 = pts[i]; p2 = pts[j];

      f = (p1.y - first.y) * (p2.x - first.x) - (p2.y - first.y) * (p1.x - first.x);
      twicearea += f;
      x += (p1.x + p2.x - 2 * first.x) * f;
      y += (p1.y + p2.y - 2 * first.y) * f;
    }
    f = twicearea * 3;
    this.polygoncenter = { x: x / f + first.x, y: y / f + first.y };
  }


  updateTransformedPolygon(points: any, polygon: any) {
    const polygonPoints = []; const polygonPointsChanged = []; let inc = 0;
    for (let p = 0; p < points.length; p += 2) {
      inc = p + 1;
      polygonPoints.push({ x: points[p], y: points[inc] });
    }
    for (let i = 0; i < polygonPoints.length; i++) {
      polygonPointsChanged[i] = polygon.getAbsoluteTransform().point(polygonPoints[i]);
    }
    if (polygonPointsChanged.length > polygonPoints.length) { polygonPointsChanged.pop(); }
    this.polygonarea = polygonPointsChanged.map(obj => Object.values(obj)).flat();

    this.getPolygonCentroid(polygonPointsChanged);
    this.getpolygoncorner(polygonPointsChanged);
    // tslint:disable-next-line: no-unused-expression
    this.projectService.getProject().type === 'hip' ? this.hiproofpoints(polygonPointsChanged) : '';
    this.gableRoof = this.projectService.getProject().type === 'hip' ? false : true;

    return polygonPointsChanged;
  }


  getpolygoncorner(pts: any) {
    const cornerswidth = 4.5;
    const cornersheight = 4;
    const polyarr = pts;
    if (polyarr.length < 5) {
      // tslint:disable-next-line: prefer-for-of
      for (let p = 0; p < polyarr.length; p++) {
        // Top Left
        if (polyarr[p].x < this.polygoncenter.x && polyarr[p].y < this.polygoncenter.y) {
          this.topleftcorner = {
            x: polyarr[p].x, y: polyarr[p].y,
            width: cornerswidth, height: cornersheight
          };
        } else if (polyarr[p].x > this.polygoncenter.x && polyarr[p].y < this.polygoncenter.y) {
          this.toprightcorner = {
            x: polyarr[p].x - cornerswidth, y: polyarr[p].y,
            width: cornerswidth, height: cornersheight
          };
        } else if (polyarr[p].x < this.polygoncenter.x && polyarr[p].y > this.polygoncenter.y) {
          this.bottleftcorner = {
            x: polyarr[p].x + cornerswidth / 2,
            y: polyarr[p].y - cornersheight,
            width: cornerswidth,
            height: cornersheight
          };
        } else if (polyarr[p].x > this.polygoncenter.x && polyarr[p].y > this.polygoncenter.y) {
          this.bottrightcorner = {
            x: polyarr[p].x - cornerswidth,
            y: polyarr[p].y - cornersheight, width: cornerswidth, height: cornersheight
          };
        }
      }

      // Code for three side polygon
      if (this.bottrightcorner.y === 0) {
        this.bottrightcorner = this.bottleftcorner;
      } else if (this.bottleftcorner.y === 0) {
        this.bottleftcorner = this.bottrightcorner;
        this.bottleftcorner.x = this.bottrightcorner.x + cornerswidth;
      } else if (this.toprightcorner.y === 0) {

        this.toprightcorner = this.topleftcorner;
      } else if (this.topleftcorner.y === 0) {
        this.topleftcorner = this.toprightcorner;
      }

      // creating top middle polygon
      this.topmiddlepoly = [this.topleftcorner.x + cornerswidth, this.topleftcorner.y,
      this.topleftcorner.x + cornerswidth, this.topleftcorner.y + cornersheight,
      this.toprightcorner.x, this.toprightcorner.y + 2 * cornersheight, this.toprightcorner.x,
      this.toprightcorner.y
      ];
      //  this.topmiddlepoly = this.panelService.createMapPolygonEveSide(this.topmiddlepoly);
      // this.background.add(this.topmiddlepoly);

      // creating midddle right polygon
      // code to edit
      this.middleright = [this.toprightcorner.x + 10, this.toprightcorner.y + cornersheight,
      this.toprightcorner.x - cornerswidth, this.toprightcorner.y + cornersheight,
      this.bottrightcorner.x - cornerswidth, this.bottrightcorner.y, this.bottrightcorner.x + cornerswidth, this.bottrightcorner.y
      ];
      // this.middleright = this.panelService.createMapPolygonEveSide(this.middleright);
      // this.background.add(this.middleright);

      // creating bottom middle polygon
      this.bottmiddlepoly = [this.bottleftcorner.x, this.bottleftcorner.y + cornersheight,
      this.bottleftcorner.x, this.bottleftcorner.y, this.bottrightcorner.x, this.bottrightcorner.y, this.bottrightcorner.x,
      this.bottrightcorner.y + cornersheight];
      //  this.bottmiddlepoly = this.panelService.createMapPolygonEveSide(this.bottmiddlepoly);
      // this.background.add(this.bottmiddlepoly);

      // creating midddle left polygon
      this.middleleft = [this.topleftcorner.x - 10, this.topleftcorner.y + cornersheight,
      this.topleftcorner.x + 2 * cornerswidth, this.topleftcorner.y + cornersheight, this.bottleftcorner.x + cornerswidth,
      this.bottleftcorner.y + cornersheight, this.bottleftcorner.x, this.bottleftcorner.y + cornersheight
      ];
      //  this.middleleft = this.panelService.createMapPolygonEveSide(this.middleleft);
      // this.background.add(this.middleleft);
    }
  }



  hiproofpoints(pts) {
    let topmid; let bottmid;
    const cornerswidth = 4;
    let height;
    const cornersheight = 4;
    topmid = (this.topleftcorner.y + this.toprightcorner.y) / 2.0;
    bottmid = (this.bottleftcorner.y + cornersheight + this.bottrightcorner.y + cornersheight) / 2.0;
    height = bottmid - topmid;
    height = height / 3;
    // top triangle for hip roofs
    this.toptriang = [this.topleftcorner.x, this.topleftcorner.y, this.toprightcorner.x + cornerswidth,
    this.toprightcorner.y, this.polygoncenter.x, topmid + height];
    // top small triangle
    this.topsmalltri = this.straight_skeleton(this.toptriang, -4);
    this.topsmalltri.push(this.topsmalltri[0]);
    this.topsmalltri.push(this.topsmalltri[1]);
    this.topsmalltri = this.panelService.createMapPolygonEveSide(this.topsmalltri);
    this.background.add(this.topsmalltri);
    // bottom triangle for hip roofs
    this.bottriang = [this.bottleftcorner.x - cornerswidth / 2, this.bottleftcorner.y + cornersheight,
    this.bottrightcorner.x + cornerswidth, this.bottrightcorner.y + cornersheight, this.polygoncenter.x, bottmid - height];
    // bottom  small triangle
    this.bottsmalltri = this.straight_skeleton(this.bottriang, 4);
    this.bottsmalltri.push(this.bottsmalltri[0]);
    this.bottsmalltri.push(this.bottsmalltri[1]);
    this.bottsmalltri = this.panelService.createMapPolygonEveSide(this.bottsmalltri);
    this.background.add(this.bottsmalltri);

    // left poly for hip
    this.leftpoly = [this.topleftcorner.x, this.topleftcorner.y, this.polygoncenter.x, topmid + height,
    this.polygoncenter.x, bottmid - height, this.bottleftcorner.x - cornerswidth / 2, this.bottleftcorner.y + cornersheight];
    this.leftpoly = this.panelService.createMapPolygonEveSide(this.leftpoly);
    this.background.add(this.leftpoly);

    // left small poly for Hip
    this.leftsmallpoly = this.straight_skeleton(this.leftpoly.attrs.points, -7);
    this.leftsmallpoly.push(this.leftsmallpoly[0]);
    this.leftsmallpoly.push(this.leftsmallpoly[1]);
    this.leftsmallpoly = this.panelService.createMapPolygonEveSide(this.leftsmallpoly);
    this.background.add(this.leftsmallpoly);
    // Right polygon for Hip
    this.rightpoly = [this.toprightcorner.x + cornerswidth, this.toprightcorner.y,
    this.polygoncenter.x, topmid + height, this.polygoncenter.x, bottmid - height,
    this.bottrightcorner.x + cornerswidth, this.bottrightcorner.y + cornersheight];
    this.rightpoly = this.panelService.createMapPolygonEveSide(this.rightpoly);
    this.background.add(this.rightpoly);


    // right small polygon
    this.rightsmallpoly = this.straight_skeleton(this.rightpoly.attrs.points, 7);
    this.rightsmallpoly.push(this.rightsmallpoly[0]);
    this.rightsmallpoly.push(this.rightsmallpoly[1]);
    this.rightsmallpoly = this.panelService.createMapPolygonEveSide(this.rightsmallpoly);
    this.background.add(this.rightsmallpoly);

  }


  straight_skeleton(newpoly, spacing) {

    const polyarr = []; let inc = 0;
    for (let p = 0; p < newpoly.length; p += 2) {
      inc = p + 1;
      polyarr.push({ x: newpoly[p], y: newpoly[inc] });
    }

    const resultingPath = [];
    let poly = [];
    poly = polyarr;
    const polyN = poly.length;
    // tslint:disable-next-line: one-variable-per-declaration
    let mi, mi1, li, li1, ri, ri1, si, si1, Xi1, Yi1;
    for (let i = 0; i < polyN; i++) {
      mi = (poly[(i + 1) % polyN].y - poly[i].y) / (poly[(i + 1) % polyN].x - poly[i].x);
      mi1 = (poly[(i + 2) % polyN].y - poly[(i + 1) % polyN].y) / (poly[(i + 2) % polyN].x - poly[(i + 1) % polyN].x);
      li = Math.sqrt((poly[(i + 1) % polyN].x - poly[i].x) * (poly[(i + 1) % polyN].x - poly[i].x) +
        (poly[(i + 1) % polyN].y - poly[i].y) * (poly[(i + 1) % polyN].y - poly[i].y));
      li1 = Math.sqrt((poly[(i + 2) % polyN].x - poly[(i + 1) % polyN].x) * (poly[(i + 2) % polyN].x - poly[(i + 1) % polyN].x) +
        (poly[(i + 2) % polyN].y - poly[(i + 1) % polyN].y) * (poly[(i + 2) % polyN].y - poly[(i + 1) % polyN].y));
      ri = poly[i].x + spacing * (poly[(i + 1) % polyN].y - poly[i].y) / li;
      ri1 = poly[(i + 1) % polyN].x + spacing * (poly[(i + 2) % polyN].y - poly[(i + 1) % polyN].y) / li1;
      si = poly[i].y - spacing * (poly[(i + 1) % polyN].x - poly[i].x) / li;
      si1 = poly[(i + 1) % polyN].y - spacing * (poly[(i + 2) % polyN].x - poly[(i + 1) % polyN].x) / li1;
      Xi1 = (mi1 * ri1 - mi * ri + si - si1) / (mi1 - mi);
      Yi1 = (mi * mi1 * (ri1 - ri) + mi1 * si - mi * si1) / (mi1 - mi);
      // Correction for vertical lines
      if (poly[(i + 1) % polyN].x - poly[i % polyN].x === 0) {
        Xi1 = poly[(i + 1) % polyN].x + spacing * (poly[(i + 1) % polyN].y - poly[i % polyN].y) /
          Math.abs(poly[(i + 1) % polyN].y - poly[i % polyN].y);
        Yi1 = mi1 * Xi1 - mi1 * ri1 + si1;
      }
      if (poly[(i + 2) % polyN].x - poly[(i + 1) % polyN].x === 0) {
        Xi1 = poly[(i + 2) % polyN].x + spacing * (poly[(i + 2) % polyN].y - poly[(i + 1) % polyN].y) /
          Math.abs(poly[(i + 2) % polyN].y - poly[(i + 1) % polyN].y);
        Yi1 = mi * Xi1 - mi * ri + si;
      }
      resultingPath.push(
        Xi1,
        Yi1
      );
    }
    return resultingPath;
  }



  rotateShape(shape: any, center: any, angle: any) {
    const degToRad = Math.PI / 180;
    const rotatePoint = ({ x, y }, deg: any) => {
      const rcos = Math.cos(deg * degToRad);
      const rsin = Math.sin(deg * degToRad);
      return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
    };

    // current rotation origin (0, 0) relative to desired origin - center (shape.width()/2, shape.height()/2)
    const topLeft = { x: -center.x, y: -center.y };
    const current = rotatePoint(topLeft, shape.rotation());
    const rotated = rotatePoint(topLeft, angle);
    this.rotationDelta.x = rotated.x - current.x;
    this.rotationDelta.y = rotated.y - current.y;
    shape.rotation(angle);
    shape.x(shape.x() + this.rotationDelta.x);
    shape.y(shape.y() + this.rotationDelta.y);
  }


  pitchEffect(angle: any) {
    if (typeof angle === 'string') {
      angle = Number(angle);
    }
    return (angle > 0) ? 1 + (1 - (Math.cos((angle * Math.PI) / 180))) : 1;
  } // returns a ratio


  calculatePolygonAngle(pointA: any, pointB: any) {
    const deltaX = pointA.x - pointB.x;
    const deltaY = pointA.y - pointB.y;

    const arctanNoAbs = Math.atan((deltaY) / (deltaX)) * 180 / Math.PI;

    const topLeftPoint = Math.min(pointA.y, pointB.y);
    const smallerYpointOnEave = (pointA.y === topLeftPoint) ? pointA : pointB;
    // const middlePointEave = { x: (pointA.x + pointB.x) / 2, y: (pointA.y + pointB.y) / 2 };

    // if (middlePointEave.x < this.pixelCenter[0] || middlePointEave.y < this.pixelCenter[1]) { console.log('Middle is less'); }
    // if (smallerYpointOnEave.x < this.pixelCenter[0] || smallerYpointOnEave.y < this.pixelCenter[1]) { console.log('Min is less'); }

    // console.log(smallerYpointOnEave, middlePointEave, this.pixelCenter);

    // console.log(this.isCollinear(pointA, pointB, { x: this.pixelCenter[0], y: this.pixelCenter[1] }));


    return (smallerYpointOnEave.x < this.pixelCenter[0] && smallerYpointOnEave.y < this.pixelCenter[1]) ?
      (-arctanNoAbs) + 180 : (-arctanNoAbs);
  }


  isCollinear(a: any, b: any, c: any) {
    const deltaX = b.x - a.x;
    const deltaXc = c.x - a.x;
    const deltaY = b.y - a.y;
    const slope = Math.round((deltaY / deltaX) * 100) / 100;
    // let Yc: any;

    // if (deltaX === 0) {
    //   if (deltaXc === 0) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else {
    //   slope = Math.round((deltaY / deltaX) * 100) / 100;
    //   Yc = (slope * (c.x - a.x)) + a.y;
    //   if (Math.abs(c.y - Yc) < 50) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
    return slope;
  }


  // V3
  createLayerHistory() {
    const history = this.mapService.getMap().layer;
    const line = this.mapService.getMap().layerEveSide;
    const hist = [];
    const chngline = [];
    if (typeof history === 'undefined' || history === null) {
      // console.log('history is null');
    } else {
      history.forEach((element) => {
        hist.push({ lat: element.u_, lng: element.h_ });
      });
      line.forEach((element) => {
        chngline.push({ lat: element.u_, lng: element.h_ });
      });
      const layerHistory = new L.Polygon(history, { color: '#eb4031', opacity: 1 });
      const eaveHistory = new L.Polyline(line, { color: '#eb4031', weight: 7 });
      layerHistory.addTo(this.map);
      eaveHistory.addTo(this.map);
      this.map.fitBounds(layerHistory.getBounds());
      // this.map.setZoom(this.map.getZoom() - 1);
      // this.onCreateMenu(layerHistory);
    }
  }


  onCreateMenu(layer: any) {
    let menuIcon: any;
    menuIcon = L.marker(layer.getBounds().getCenter(), {
      icon: icon({
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        iconUrl: 'assets/plus-red.png'
      })
    });
    menuIcon.addTo(this.map);
  }


  draw(tools: any) {
    document.getElementById('menu').style.display = 'none';
    switch (tools) {
      case 'add':
        this.panelCursorShow();
        this.onAddPanel();
        // this.toolPanel = tools;
        return;
      case 'add-type-panel':
        this.showtypemenuedit();
        return;
      case 'delete':
        this.onDeletePanel();
        // this.toolPanel = tools;
        return;
      case 'move':
        this.onMove();
        //   this.toolPanel = tools;
        return;
      case 'zoneChange':
        this.onchangePanelColor();
        //  this.toolPanel = tools;
        return;
      case 'editing':
        this.onAddAttachments();
        //   this.toolPanel = tools;
        return;
      case 'deleteClamp':
        this.onDeleteAttachments();
        return;
      default:
        return;
    }
  }



  onchangePanelColor() {
    this.seccolorcount = 0;
    (this.tableDraw.width() === 0 && this.tableDraw.height() === 0)
      ? this.changePanelColorOnClick()
      : this.changeTabelePanelColor();
    this.designLayer.batchDraw();
  }


  changePanelColorOnClick() {
    const position = this.stage.getPointerPosition();
    const shape = this.designLayer.getIntersection({ x: position.x, y: position.y });
    if (shape != null && shape.name() === 'panel') {
      shape.fill(this.COLOR_DEFAULT);
      this.undoforzone();
    }
  }


  changeTabelePanelColor() {
    const drawnPanels = [];
    this.designGroup.children.each(shape => {
      drawnPanels.push(shape);
    });
    for (const panel of drawnPanels) {
      if (this.haveIntersection(this.tableDraw.getClientRect({ skipStroke: false }), panel.getClientRect({ skipStroke: false }))) {
        panel.fill(this.COLOR_DEFAULT);
      }
    }
    this.designLayer.draw();
    this.undoforzone();
  }


  // Undo code
  undoforzone() {
    this.clampsteps.push(this.clampsteps[this.clampsteps.length - 1]);
    this.savepanelstep();
    this.changecount = this.changecount + 1;
    this.undoenable = true;
  }


  toggleGrid() {
    this.SHOW_RAFTERLINE = !this.SHOW_RAFTERLINE;
    this.gridStateChange(this.SHOW_RAFTERLINE);
  }

  // On/Off Wizard for Design Tab
  toggleWizard() {
    this.WIZARD = !this.WIZARD;
  }

  gridStateChange(flag: boolean) {
    flag ? this.showGridLines() : this.removeGridLines();
  }

  showGridLines() {
    if (this.gridGroup !== undefined) {
      this.gridGroup.show();
    } else {
      this.drawGridLines();
    }
    this.designLayer.draw();
  }

  removeGridLines() {
    if (this.gridGroup !== undefined) {
      this.gridGroup.hide();
      this.designLayer.draw();
    }
  }

  toggleOverlay() {
    if (this.opened) {
      this.hideOverlay();
    } else {
      this.showOverlay(ZoneComponent);
      setTimeout(() => {
        this.projectService.postareanotif(this.areabuttdata);
      }, 250);
    }
  }


  showOverlay(view: any) {
    const backdrop = (this.pitch === undefined || this.pitch === null) ? 'cdk-overlay-dark-backdrop' : 'cdk-overlay-transparent-backdrop';

    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().flexibleConnectedTo(this.stageElement)
        .withPositions([{
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        }, {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        }]),
      hasBackdrop: true,
      backdropClass: backdrop
    });

    this.overlayservice.initialize(this.overlayRef);
    this.overlayRef.attach(new ComponentPortal(view));
    this.overlayRef.backdropClick().subscribe(() => {
	 // if (this.getPitch() !== 0 || this.country_bool == 'canada') {
        this.overlayRef.dispose();
        this.opened = !this.opened;
        // phase 2
        this.checkInterSpacing();
        this.staggered = this.projectService.getProject().staggered;
        this.static = Number(this.projectService.getProject().static);
        // change roof pitch effect on Google Map
        if (this.workspace === 1) {
          const pitchRatio = this.pitchEffect(this.getPitch());
          const moved = this.changeBackgroundByPitch(pitchRatio);
          this.changeRoofAreaByPitch(pitchRatio, moved);
        }
        if (this.projectService.getProject().eave === '0' && this.getPitch() === 2) {
          const eaveFloatMsg = 'A Floating splice may be used once a skirt is installed between' +
            'two adjacent PV modules.Verify the PV module Installation' +
            'Manual specifications for the "floating Splice".The maximum' +
            'span between bases must be verified with the PE Stamped' +
            'Letters and the max.grd.snow allowed for the skirt is 40 PSF.';
          this.openSnackBar(eaveFloatMsg, 'OK', 'center', 'top', 10000);
        }
      //}
    });

    this.opened = !this.opened;
  }

  hideOverlay() {
    if (this.overlayRef !== undefined) {
      this.overlayRef.dispose();
      this.opened = !this.opened;
    }
  }


  // Check if the Interspacing is more than 0.125" when splice is selected
  checkInterSpacing() {
    if (Number(this.projectService.getProject().splice) > 0) {
      const spacing = this.projectService.getProject().span;
      if (spacing !== 0.125) {
        this.projectService.setProject('span', 0.125);
        const msg = 'You have choosen to use splice with Inter-Module Spacing more than 0.125 inches, which is not possible. ' +
          'It has been reset to 0.125 inches.';
        this.openSnackBar(msg, 'OK', 'center', 'top', 10000);
      }
    }
  }


  changeRoofAreaByPitch(ratio: any, delta: any) {
    // Scale everything back to start - Recenter the stage
    this.restoreZoomView();
    this.setZoomStage(1, this.stageCenterPoint);
    this.mapLine.scaleY(1);
    this.mapLine.y(0);
    this.eaveLine.scaleY(1);
    this.eaveLine.y(0);
    // scale the Roof Pitch effect
    this.mapLine.scaleY(ratio);
    this.mapLine.y(this.mapLine.y() - (delta / 2));
    this.eaveLine.scaleY(ratio);
    this.eaveLine.y(this.eaveLine.y() - (delta / 2));
    this.background.draw();
    this.updateTransformedPolygon(this.mapLine.getAttrs().points, this.mapLine);
  }


  changeBackgroundByPitch(ratio: any) {
    const bg = this.mapBackground;
    // scale to normal
    bg.scaleY(1);
    bg.y(0);
    bg.scaleY(ratio);
    const heightDelta = bg.height() * (ratio - 1);
    bg.y(bg.y() - (heightDelta / 2));
    this.background.draw();
    return heightDelta;
  }

  resetDesignSetting() {
    // reset project design submmission, if any
    this.resetDesignCheck();
    // remove all popup alerts
    if (this.snackBar) { this.snackBar.dismiss(); }
  }


  getOrientation() {
    return this.PANEL_ORIENTATION;
  }


  setPanelCount() {
    //this.totalPanels = this.designGroup.children.length;
    this.totalPanels = this.designLayer.find('.panel').length;
    this.projectService.setProject('panels', this.totalPanels);
    this.projectService.postprojnotif();
  }


  getSnappingPosition() {
    const position = this.getRelativeStagePosition();
    const snap = this.SPACING;
    position.x = (Math.round(position.x / (snap.x)) * (snap.x));
    position.y = (Math.round(position.y / (snap.y)) * (snap.y));
    return position;
  }


  // toggle the orientation
  toggleOrnt() {

    this.PANEL_ORIENTATION = !this.PANEL_ORIENTATION;
    this.panelCursorReDraw();
  }


  panelCursorDraw(orientation: any, position: any) {
    this.panelCursor = (orientation) ?
      this.panelService.showPanelOnMouse(position, this.WIDTH, this.LENGTH, this.PANEL_STROKEWITH) :
      this.panelService.showPanelOnMouse(position, this.LENGTH, this.WIDTH, this.PANEL_STROKEWITH);
    this.cursorLayer.add(this.panelCursor);
    this.cursorLayer.draw();
  }

  panelCursorReDraw() {
    const w = this.panelCursor.width();
    const h = this.panelCursor.height();
    this.panelCursor.setAttr('width', h);
    this.panelCursor.setAttr('height', w);
    this.cursorLayer.draw();
  }


  panelCursorHide() {
    if (this.panelCursor !== undefined) {
      this.panelCursor.hide();
      this.cursorLayer.draw();
    }
  }


  panelCursorShow() {
    if (this.cursorLayer !== undefined) {
      this.panelCursor.show();
      this.cursorLayer.draw();
    }
  }


  addCursor() {
    this.tableDrawCursor();
    this.noDragsCursor();
    this.panelCursorShow();
    this.TABLE_TOOLTIP = true;
    this.stage.on('mousemove', _ => {
      this.stage.container().style.cursor = (this.tableDraw.width() === 0 && this.tableDraw.height() === 0) ? 'default' : 'crosshair';
      let move = this.getSnappingPosition();   // getRelativeStagePosition
      move = this.centerPanelPosition(move, this.getOrientation());
      this.panelCursor.x(move.x);
      this.panelCursor.y(move.y);
      this.cursorLayer.draw();
      // change the panelCursor to gary if there is any intersection with other shapes
      const drawnPanels = [];
      this.designGroup.children.each(shape => {
        drawnPanels.push(shape);
      });

      let mouseCursor: any;
      // On Google
      if (this.workspace === 1) {
        mouseCursor = this.panelCursor.attrs;
        this.disableDrawing();
        if (this.mapIntersection(this.polygonarea, mouseCursor)) {
          this.enableDrawing();
          this.gableRoof === true ? this.gabledetection(mouseCursor) : this.hipdetection(mouseCursor);
          for (const shape of drawnPanels) {
            if (this.haveIntersection(shape.attrs, mouseCursor)) {
              this.disableDrawing();
              break;
            } else if (this.haveleftsnapping(shape.attrs, this.panelCursor.attrs)) {
              this.leftsnapping(shape.attrs, this.panelCursor.attrs);
              break;
            } else if (this.haverightsnapping(shape.attrs, this.panelCursor.attrs)) {
              this.rightsnapping(shape.attrs, this.panelCursor.attrs);
              break;
            } else if (this.havetopsnapping(shape.attrs, this.panelCursor.attrs)) {
              this.topsnapping(shape.attrs, this.panelCursor.attrs);
              break;
            } else if (this.havebottomsnapping(shape.attrs, this.panelCursor.attrs)) {
              this.bottomsnapping(shape.attrs, this.panelCursor.attrs);
              break;
            } else if (this.havetoprightsnapping(shape.attrs, this.panelCursor.attrs)) {
              this.topcornersnapping(shape.attrs, this.panelCursor.attrs, true, false);
              break;
            } else if (this.havetopleftsnapping(shape.attrs, this.panelCursor.attrs)) {
              this.topcornersnapping(shape.attrs, this.panelCursor.attrs, true, true);
              break;
            } else if (this.havebottrightsnapping(shape.attrs, this.panelCursor.attrs)) {
              this.topcornersnapping(shape.attrs, this.panelCursor.attrs, false, true);
              break;
            } else if (this.havebottleftsnapping(shape.attrs, this.panelCursor.attrs)) {
              this.topcornersnapping(shape.attrs, this.panelCursor.attrs, false, false);
              break;
            } else {
              this.enableDrawing();
              this.gableRoof === true ? this.gabledetection(mouseCursor) : this.hipdetection(mouseCursor);
            }
          }
        } else {
          this.disableDrawing();
        }

        // On Blank
      } else {
        mouseCursor = this.panelCursor.getClientRect({ skipStroke: true });
        if (drawnPanels.length === 0) { this.enableDrawing(); }
        for (const shape of drawnPanels) { // Change skipStroke==true on 7/6/2021
          if (this.haveIntersection(shape.getClientRect({ skipStroke: true }), mouseCursor)) {
            this.disableDrawing();
            break;
          } else if (this.haveleftsnapping(shape.attrs, this.panelCursor.attrs)) {
            this.leftsnapping(shape.attrs, this.panelCursor.attrs);
            break;
          } else if (this.haverightsnapping(shape.attrs, this.panelCursor.attrs)) {
            this.rightsnapping(shape.attrs, this.panelCursor.attrs);
            break;
          } else if (this.havetopsnapping(shape.attrs, this.panelCursor.attrs)) {
            this.topsnapping(shape.attrs, this.panelCursor.attrs);
            break;
          } else if (this.havebottomsnapping(shape.attrs, this.panelCursor.attrs)) {
            this.bottomsnapping(shape.attrs, this.panelCursor.attrs);
            break;
          } else if (this.havetoprightsnapping(shape.attrs, this.panelCursor.attrs)) {
            this.topcornersnapping(shape.attrs, this.panelCursor.attrs, true, false);
            break;
          } else if (this.havetopleftsnapping(shape.attrs, this.panelCursor.attrs)) {
            this.topcornersnapping(shape.attrs, this.panelCursor.attrs, true, true);
            break;
          } else if (this.havebottrightsnapping(shape.attrs, this.panelCursor.attrs)) {
            this.topcornersnapping(shape.attrs, this.panelCursor.attrs, false, true);
            break;
          } else if (this.havebottleftsnapping(shape.attrs, this.panelCursor.attrs)) {
            this.topcornersnapping(shape.attrs, this.panelCursor.attrs, false, false);
            break;
          } else {
            this.enableDrawing();
          }
        }
      }
    });
  }


  snapdrawing() {
    this.ENABLE_DRAWING = true;
    this.panelCursor.fill('green');
  }

  // mid snapping
  midsnapdrawing() {
    this.ENABLE_DRAWING = true;
    this.panelCursor.fill('lightgreen');
  }


  snapdrawingStaggered() {
    this.ENABLE_DRAWING = true;
    this.panelCursor.fill('lightgreen');
  }


  havebottomsnapping(r1: any, r2: any) {
    return (r2.y > r1.y && r2.x + 2 > r1.x && r2.x < r1.x + r1.width
      && r2.y - (r1.y + r1.height) < 2.5);
  }


  bottomsnapping(r1: any, r2: any) {
    const move = { x: r1.x, y: r1.y, width: r1.width, height: r1.height };
    const orientation = this.getOrientation();
    // snapping size
    move.width = r2.width;
    move.height = r2.height;

    move.y = r1.y + r1.height + this.SPACING.y + 1;
    const diff = Math.abs(r2.x - r1.x);
    if (diff > 0.1 && diff < 1.0) { move.x = r1.x; } else { move.x = r2.x; }

    if (this.snappingtest(move, this.testpoints)) {
      this.disableDrawing();
    } else {
      // mid snapping
      const midpoint = r1.x + r1.width / 2;
      (move.x > midpoint && move.x < midpoint + 0.8) ? this.midsnapdrawing() : this.snapdrawing();
      this.panelCursor.x(move.x);
      this.panelCursor.y(move.y);
      this.cursorLayer.draw();
    }
  }


  havetopsnapping(r1: any, r2: any) {
    return (r2.y + r2.height < r1.y && r1.y - (r2.y + r2.height) < 2.5
      && !(r2.y > r1.y + r1.height + this.SPACING.y) && r2.x + 2 > r1.x && r2.x < r1.x + r1.width);
  }


  topsnapping(r1: any, r2: any) {
    const move = { x: r1.x, y: r1.y, width: r1.width, height: r1.height };
    const orientation = this.getOrientation();
    // snapping size
    move.width = r2.width;
    move.height = r2.height;
    move.y = move.y - 1 - move.height - this.SPACING.y;
    const diff = (r2.x - r1.x);
    if (diff > -0.6 && diff < 1.1) { move.x = r1.x; } else { move.x = r2.x; }
    if (this.snappingtest(move, this.testpoints)) {
      this.disableDrawing();
    } else {
      // mid snapping
      const midpoint = r1.x + r1.width / 2;
      (move.x > midpoint && move.x < midpoint + 0.8) ? this.midsnapdrawing() : this.snapdrawing();
      this.panelCursor.x(move.x);
      this.panelCursor.y(move.y);
      this.cursorLayer.draw();
    }
  }


  rightsnapping(r1: any, r2: any) {
    const move = { x: r1.x, y: r1.y, width: r1.width, height: r1.height };
    const orientation = this.getOrientation();
    // snapping size
    move.height = (orientation) ? r1.width : r1.height;
    move.x = move.x + move.width + 1 + this.SPACING.x;
    const diff = Math.abs(r2.y - r1.y);
    if (diff > 0.1 && diff < 1.9) {
      move.y = r1.y;
    } else {
      move.y = r2.y;
    }
    move.width = r2.width;
    // corner snapping
    if (this.snappingtest(move, this.testpoints)) {
      this.disableDrawing();
    } else {
      this.snapdrawing();
      this.panelCursor.x(move.x);
      this.panelCursor.y(move.y);
      this.cursorLayer.draw();
    }
  }

  // corner snapping
  snappingtest(move, testpoints) {
    let bool = false;
    const testpoly = [[move.x, move.y], [move.x + move.width, move.y],
    [move.x + move.width, move.y + move.height], [move.x, move.y + move.height], [move.x, move.y]];
    // console.log(testpoly);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < testpoints.length; i++) {
      if (polycheck(testpoints[i].points, testpoly)) {
        // console.log(this.testpoints[i]);
        bool = true;
        break;
      } else { bool = false; }
    }
    return bool;
  }


  haverightsnapping(r1: any, r2: any) {
    return ((r2.x - (r1.x + r1.width) < 4) && r2.x > r1.x + r1.width &&
      !(r2.y + r2.height < r1.y) && !(r2.y > r1.y + r1.height + this.SPACING.y)
    );
  }

  haveleftsnapping(r1: any, r2: any) {
    return (
      (r2.x + r2.width + 2 < r1.x) && !(r2.y + r2.height < r1.y) && !(r2.y > r1.y + r1.height + this.SPACING.y)
      && (r1.x - (r2.x + r2.width)) < 3.5
    );
  }


  leftsnapping(r1: any, r2: any) {
    const move = { x: r1.x, y: r1.y, width: r1.width, height: r1.height };
    const orientation = this.getOrientation();
    // snapping size
    move.width = r2.width;
    move.height = r2.height;
    const diff = Math.abs(r2.y - r1.y);
    if (diff > 0.1 && diff < 1.9) {
      move.y = r1.y;
    } else {
      move.y = r2.y;
    }
    move.x = move.x - (move.width + 1 + this.SPACING.x);
    // corner snapping
    if (this.snappingtest(move, this.testpoints)) {
      this.disableDrawing();
    } else {
      this.snapdrawing();
      this.panelCursor.x(move.x);
      this.panelCursor.y(move.y);

      this.cursorLayer.draw();
    }
  }


  topleftsnapping(r1: any, r2: any) {
    const move = { x: r1.x, y: r1.y, width: r1.width, height: r1.height };
    const orientation = this.getOrientation();
    // 14-11-20
    const width = this.WIDTH;
    const height = this.LENGTH;
    move.width = (orientation) ? height : width;
    move.height = (orientation) ? width : height;
    const diff = Math.abs(r2.y - r1.y);
    if (diff > 0.1 && diff < 1.9) {
      move.y = r1.y;
    } else {
      move.y = r2.y;
    }
    move.x = move.x - (move.width + 1 + this.SPACING.x);
    // corner snapping
    if (this.snappingtest(move, this.testpoints)) {
      this.disableDrawing();
    } else {
      this.snapdrawing();
      this.panelCursor.x(move.x);
      this.panelCursor.y(move.y);

      this.cursorLayer.draw();
    }
  }

  // corner snapping
  havetoprightsnapping(r1: any, r2: any) {
    return ((r2.x - (r1.x + r1.width) < 4) && r2.x > r1.x + r1.width
      && (r2.y + r2.height < r1.y - 2) && (r2.y + r2.height > r1.y - 5));
  }
  havetopleftsnapping(r1: any, r2: any) {
    return (
      (r2.x + r2.width + 2 < r1.x)
      && !(r2.y + r2.height < r1.y - 2)
      && (r1.x - (r2.x + r2.width)) < 3.5
      && !(r2.y > r1.y + r1.height + this.SPACING.y));
  }
  havebottrightsnapping(r1: any, r2: any) {
    return ((r2.x - (r1.x + r1.width) < 4)
      && r2.x > r1.x + r1.width
      && (r2.y > r1.y + r1.height)
      && (r2.y < r1.y + r1.height + 5));
  }
  havebottleftsnapping(r1: any, r2: any) {
    return (
      (r2.x + r2.width + 2 < r1.x)
      && (r1.x - (r2.x + r2.width)) < 3.5
      && (r2.y > r1.y + r1.height)
      && (r2.y < r1.y + r1.height + 5));
  }

  topcornersnapping(r1: any, r2: any, vertical, horizon) {
    const move = { x: r1.x, y: r1.y, width: r1.width, height: r1.height };
    const orientation = this.getOrientation();
    // snapping size
    move.width = r2.width;
    move.height = r2.height;
    if (vertical) {
      move.y = r1.y - (move.height + this.SPACING.y + this.PANEL_STROKEWITH);
      move.x = (horizon === true) ? move.x - (move.width + 1 + this.SPACING.x) :
        r1.x + (r1.width + 1 + this.SPACING.x);
    } else {
      move.y = r1.y + (r1.height + this.SPACING.y + this.PANEL_STROKEWITH);
      move.x = (horizon === true) ? r1.x + (r1.width + this.PANEL_STROKEWITH + this.SPACING.x) :
        move.x - (move.width + this.PANEL_STROKEWITH + this.SPACING.x);
    }
    if (this.snappingtest(move, this.testpoints)) {
      this.disableDrawing();
    } else {
      this.snapdrawing();
      this.panelCursor.x(move.x);
      this.panelCursor.y(move.y);
      this.cursorLayer.draw();
    }
  }
  // corner snapping End


  // Detect Panel overlapping in Gable Roof
  gabledetection(mouseCursor: any) {
    if (this.haveIntersection(this.topleftcorner, mouseCursor) || this.haveIntersection(this.toprightcorner, mouseCursor)
      || this.haveIntersection(this.bottrightcorner, mouseCursor) || this.haveIntersection(this.bottleftcorner, mouseCursor)) {
      this.toggleZone(3); this.setPanelCursorColor();
    } else if (this.cornerintersection(this.middleleft, mouseCursor) || this.cornerintersection(this.middleright, mouseCursor)
      || this.cornerintersection(this.topmiddlepoly, mouseCursor) || this.cornerintersection(this.bottmiddlepoly, mouseCursor)) {
      this.toggleZone(2); this.setPanelCursorColor();
    } else {
      this.toggleZone(1); this.setPanelCursorColor();
    }
  }


  // Detect Panel overlapping in Hip Roof
  hipdetection(mouseCursor: any) {
    if (this.haveIntersection(this.topleftcorner, mouseCursor) || this.haveIntersection(this.toprightcorner, mouseCursor)
      || this.haveIntersection(this.bottrightcorner, mouseCursor) || this.haveIntersection(this.bottleftcorner, mouseCursor)) {
      this.toggleZone(3); this.setPanelCursorColor();
    } else if (this.cornerintersection(this.rightpoly.attrs.points, mouseCursor) ||
      this.cornerintersection(this.leftpoly.attrs.points, mouseCursor) ||
      this.cornerintersection(this.bottriang, mouseCursor) ||
      this.cornerintersection(this.toptriang, mouseCursor)) {
      this.toggleZone(2); this.setPanelCursorColor();
    } else if (this.mapIntersection(this.rightsmallpoly.attrs.points, mouseCursor) ||
      this.mapIntersection(this.leftsmallpoly.attrs.points, mouseCursor) ||
      this.mapIntersection(this.bottsmalltri.attrs.points, mouseCursor) ||
      this.mapIntersection(this.topsmalltri.attrs.points, mouseCursor)) {
      this.toggleZone(1); this.setPanelCursorColor();
    }
  }


  cornerintersection(r1: any, r2: any) {
    const polyarr = []; let inc = 0;
    const topLeft = [r2.x - this.PANEL_STROKEWITH, r2.y - this.PANEL_STROKEWITH];
    const topRight = [r2.x + r2.width + this.PANEL_STROKEWITH, r2.y + this.PANEL_STROKEWITH];
    const bottomRight = [r2.x + r2.width + this.PANEL_STROKEWITH, r2.y + r2.height + this.PANEL_STROKEWITH];
    const bottomLeft = [r2.x - this.PANEL_STROKEWITH, r2.y + r2.height - this.PANEL_STROKEWITH];

    for (let p = 0; p < r1.length; p += 2) {
      inc = p + 1;
      polyarr.push([r1[p], r1[inc]]);
    }
    if (polycheck(topLeft, polyarr) || polycheck(topRight, polyarr)
      || polycheck(bottomRight, polyarr) || polycheck(bottomLeft, polyarr)) {
      return true;
    } else {
      return false;
    }
  }


  mapIntersection(r1: any, r2: any) {
    const polyarr = [];
    let inc = 0;
    const topLeft = [r2.x - this.PANEL_STROKEWITH, r2.y - this.PANEL_STROKEWITH];
    const topRight = [r2.x + r2.width + this.PANEL_STROKEWITH, r2.y + this.PANEL_STROKEWITH];
    const bottomRight = [r2.x + r2.width + this.PANEL_STROKEWITH, r2.y + r2.height + this.PANEL_STROKEWITH];
    const bottomLeft = [r2.x - this.PANEL_STROKEWITH, r2.y + r2.height - this.PANEL_STROKEWITH];
    for (let p = 0; p < r1.length; p += 2) {
      inc = p + 1;
      polyarr.push([r1[p], r1[inc]]);
    }
    if (polycheck(topLeft, polyarr) && polycheck(topRight, polyarr)
      && polycheck(bottomRight, polyarr) && polycheck(bottomLeft, polyarr)) {
      return true;
    } else {
      return false;
    }
  }


  findDrag() {
    let position: any;
    let droppos: any;
    let tooltip: any;
    // Drag Start
    this.stage.on('mousedown', () => {
      this.typemodbool = this.toolPanel == 'add' ? true : false;
      this.TABLE_DRAWING = true;
      this.tableDraw.setAttrs({
        width: 0,
        height: 0
      });
      this.DRAG_START = { x: 0, y: 0 };
      this.DRAG_END = { x: 0, y: 0 };
      position = { x: 0, y: 0 };
      droppos = { x: 0, y: 0 };
      position = this.getRelativeStagePosition();
      tooltip = this.panelService.createTooltipOnMouse(this.stage.scaleX());
      if (this.TABLE_TOOLTIP) {
        this.cursorLayer.add(tooltip);
      }
      if (this.workspace === 1) {
        tooltip.fill('white');
      }
    });

    this.stage.on('mousemove', () => {
      if (this.TABLE_DRAWING) {
        droppos = this.UpdateDrag(position, tooltip);
      }
    });

    // Drag End

    this.stage.on('mouseup', _ => {
      this.deletepossmark();
      this.typemodbool = false;
      this.TABLE_DRAWING = false;
      if (tooltip) { tooltip.destroy(); }
      this.tableDraw.visible(false);
      this.cursorLayer.draw();
      this.DRAG_START = { x: this.tableDraw.x(), y: this.tableDraw.y() };
      this.DRAG_END = { x: this.tableDraw.x() + this.tableDraw.width(), y: this.tableDraw.y() + this.tableDraw.height() };
    });
  }



  UpdateDrag(position: any, tooltip: any) {
    this.deletepossmark();
    this.stage.container().style.cursor = 'crosshair';
    let droppos = this.getRelativeStagePosition();
    const newPos = this.reverse(position, droppos);
    position = newPos.r1;
    droppos = newPos.r2;
    this.drawTableCursor(position, droppos);
    const table = this.calculateTableSize(position, droppos, this.SPACING);
    tooltip.position({
      x: droppos.x,
      y: droppos.y
    });
    tooltip.text(table.col + 'x' + table.row);
    const orientation = this.getOrientation();
    const width = (orientation) ? (this.WIDTH + this.SPACING.x + this.PANEL_STROKEWITH) : (this.LENGTH + this.SPACING.x + this.PANEL_STROKEWITH);
    const height = (orientation) ? (this.LENGTH + this.SPACING.y + this.PANEL_STROKEWITH) : (this.WIDTH + this.SPACING.y + this.PANEL_STROKEWITH);
    let opacpos = { x: position.x + (width / 2), y: position.y + (height / 2) };
    this.typemodbool ? this.populateOnDrag(opacpos, droppos, this.SPACING) : '';
    this.cursorLayer.draw();
    return droppos;
  }


  drawTableCursor(start: any, end: any) {
    this.tableDraw.x(start.x);
    this.tableDraw.y(start.y);
    this.tableDraw.width(end.x - start.x);
    this.tableDraw.height(end.y - start.y);
    this.tableDraw.visible(true);
  }


  enableDrawing() {
    this.ENABLE_DRAWING = true;
    this.panelCursor.fill('#6495ed');
  }

  disableDrawing() {
    this.ENABLE_DRAWING = false;
    this.panelCursor.fill('dimgray');
  }


  tableDrawCursor() {
    if (this.cursorLayer !== undefined) {
      this.cursorLayer.show();
    }
    // Hide panelCursor
    this.panelCursorHide();
    this.TABLE_TOOLTIP = false;
    // Reset Table Draw
    if (this.tableDraw !== undefined) {
      this.tableDraw.width(0);
      this.tableDraw.height(0);
    }
    // Drags are not allowed
    this.noDragsCursor();
  }

  noTableDrawCursor() {
    // Hide panelCursorLayer
    this.cursorLayer.hide();
    this.TABLE_TOOLTIP = false;
    this.TABLE_DRAWING = false;
  }

  noDragsCursor() {
    // Drags are NOT allowed
    this.stage.setDraggable(false);
    this.gridlineDragFalse();
    this.clampsDragFalse();
  }

  dragsCursor() {
    // Drags are allowed
    this.stage.setDraggable(true);
    this.gridlineDragTrue();
    this.clampsDragTrue();
  }


  deletePanelCursor() {
    this.tableDrawCursor();
    this.noDragsCursor();

    // undo code
    this.seccolorcount = 1;

    // change the cursor style when it's inside a shape
    this.stage.on('mousemove', () => {
      const position = this.stage.getPointerPosition();
      const shape = this.stage.getIntersection({ x: position.x, y: position.y });
      if (shape != null && shape.name() === 'panel') {
        this.stage.container().style.cursor = 'pointer';
      } else {
        this.stage.container().style.cursor = (this.tableDraw.width() === 0 && this.tableDraw.height() === 0) ? 'default' : 'crosshair';
      }
    });
  }


  moveCursor() {
    this.noTableDrawCursor();
    this.dragsCursor();
    // change the cursor style when it's inside the stage for Move
    this.stage.on('mousemove', () => {
      const position = this.stage.getPointerPosition();
      const shape = this.stage.getIntersection({ x: position.x, y: position.y });
      if (shape != null) {
        if (shape.name() === 'splice') {
          this.stage.container().style.cursor = 'not-allowed';
        } else {
          this.stage.container().style.cursor = 'pointer';
        }
      } else {
        this.stage.container().style.cursor = 'default';
      }
    });

    this.stage.on('dragmove', () => {
      this.stage.container().style.cursor = 'grabbing';
    });
  }

  typeCursor() {
    this.noTableDrawCursor();
    this.noDragsCursor();
  }

  deleteClampsCursor() {
    this.tableDrawCursor();
    this.noDragsCursor();

    this.stage.on('mousemove', () => {
      const position = this.stage.getPointerPosition();
      const shape = this.stage.getIntersection({ x: position.x, y: position.y });
      if (shape != null && (shape.name() === 'clamp' || shape.name() === 'splice')) {
        this.stage.container().style.cursor = 'pointer';
      } else {
        this.stage.container().style.cursor = (this.tableDraw.visible()) ? 'crosshair' : 'default';
      }
    });
  }


  plainCursor() {
    this.noTableDrawCursor();
    this.noDragsCursor();

    // change the cursor style when it's inside the panels
    this.stage.on('mousemove', () => {
      const position = this.stage.getPointerPosition();
      const shape = this.stage.getIntersection({ x: position.x, y: position.y });
      if (shape != null && shape.name() === 'panel') {
        this.stage.container().style.cursor = 'pointer';
      } else { this.stage.container().style.cursor = 'default'; }
    });
  }


  // 11-15-2020
  haveIntersection(r1: any, r2: any) {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    );
  }


  centerPanelPosition(position: any, orientation: any) {
    position.x -= (orientation) ? this.WIDTH / 2 : this.LENGTH / 2;
    position.y -= (orientation) ? this.LENGTH / 2 : this.WIDTH / 2;
    return position;
  }

  reCenterPanelPosition(position: any, orientation: any) {
    position.x += (orientation) ? this.WIDTH / 2 : this.LENGTH / 2;
    position.y += (orientation) ? this.LENGTH / 2 : this.WIDTH / 2;
    return position;
  }


  hidePanelCursor() {
    if (this.panelCursor !== undefined) {
      this.cursorLayer.hide();
      this.cursorLayer.draw();
    }
  }

  manualCursor() {
    this.stage.setDraggable(false);
    // Hide panelCursor
    if (this.panelCursor !== undefined) {
      this.panelCursorHide();
    }
    // change the cursor style when it's inside a shape
    this.stage.on('mousemove', () => {
      const position = this.stage.getPointerPosition();
      const shape = this.stage.getIntersection({ x: position.x, y: position.y });
      if (shape != null && (shape.name() === 'clamp' || shape.name() === 'splice')) {
        this.stage.container().style.cursor = 'pointer';
      } else {
        this.stage.container().style.cursor = (this.tableDraw.visible()) ? 'crosshair' : 'default';
      }
    });
    this.gridlineDragFalse();
    this.clampsDragFalse();
  }


  cursorLayerHide() {
    if (this.panelCursor !== undefined) {
      this.cursorLayer.hide();
      this.cursorLayer.draw();
    }
  }

  gridlineDragFalse() {
    if (this.gridGroup !== undefined) {
      this.gridGroup.setDraggable(false);
    }
  }


  gridlineDragTrue() {
    if (this.gridGroup !== undefined) {
      this.gridGroup.setDraggable(true);
    }
  }


  clampsDragFalse() {
    if (this.clampGroup !== undefined) {
      this.clampGroup.setDraggable(false);
      this.designLayer.find('.clamp').each(shape => {
        shape.setDraggable(false);
      });
    }
  }


  clampsDragTrue() {
    if (this.clampGroup !== undefined) {
      this.clampGroup.setDraggable(true);
      this.designLayer.find('.clamp').each(shape => {
        shape.setDraggable(true);
      });
    }
  }


  onAddPanel() {
    this.staggered = this.projectService.getProject().staggered;
    (this.tableDraw.width() === 0 && this.tableDraw.height() === 0) ? this.addSinglePanel()
      : this.populateOnDrag(this.DRAG_START, this.DRAG_END, this.SPACING);
    if (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter') {
      setTimeout(() => { // Set Max Span Display
        if (this.COMPARED_SPAN.length === 0) {
          this.setMaxSpanLocal();
        }
      }, 500);
    }

  }


  onDeletePanel() {
    this.seccolorcount = 1;
    (this.tableDraw.width() === 0 && this.tableDraw.height() === 0)
      ? this.deletePanelOnClick()
      : this.deleteTabelePanel();
  }


  onDeleteAttachments() {
    (this.tableDraw.width() === 0 && this.tableDraw.height() === 0)
      ? this.deleteClampOnClick() : this.deleteTabeleClamp();
    this.designLayer.batchDraw();
  }


  deleteClampOnClick() {
    const position = this.stage.getPointerPosition();
    const shape = this.designLayer.getIntersection({ x: position.x, y: position.y });
    if (shape != null && (shape.name() === 'clamp' || shape.name() === 'splice')) {
      shape.destroy();
      this.delboll = true;
      this.undoenable = true;
      this.undoforchangeclamp();
    }
  }

  deleteTabeleClamp() {
    this.delboll = false;
    const drawnClamps = [];
    this.clampGroup.children.each(shape => {
      drawnClamps.push(shape);
    });
    for (const clamp of drawnClamps) {
      if (this.haveIntersection(this.tableDraw.getClientRect({ skipStroke: true }), clamp.getClientRect({ skipStroke: true }))) {
        clamp.destroy();
        this.delboll = true;
      }
    }
    if (this.delboll) {
      this.undoforchangeclamp();
      this.undoenable = true;
    }
  }


  onAddAttachments() {
    // Create Menu on Click
    this.createMenuOnClick();
    this.findPossibleAttachmentsPos();
  }


  createMenuOnClick() {
    const clickPos = this.stage.getPointerPosition();
    const menuNode = document.getElementById('menu');
    const containerRect = this.stage.container().getBoundingClientRect();
    menuNode.style.top = containerRect.top + clickPos.y + 4 + 'px';
    menuNode.style.left = containerRect.left + clickPos.x + 4 + 'px';
    // Hide Menu
    menuNode.style.display = 'none';
    // Show Menu
    const shape = this.stage.getIntersection(clickPos);
    if (shape !== null && shape.name() === 'panel') { menuNode.style.display = 'initial'; }
  }


  showtypemenuedit() {
    this.deletepossmark();
    this.typemodbool = true;
    this.textcol = 1;
    this.textrow = 1;
    const clickPos = this.stage.getPointerPosition();
    const menuNode = document.getElementById('typemenu');
    const containerRect = this.stage.container().getBoundingClientRect();
    this.typepos = { x: this.getRelativeStagePosition().x, y: this.getRelativeStagePosition().y };
    menuNode.style.right = 30 + 'px';
    menuNode.style.top = 200 + 'px';
    menuNode.style.display = 'block';
    //let posmark = this.panelService.createpossmark( this.typepos.x , this.typepos.y )
    //this.designGroup.add(posmark);
    //posmark.moveToTop();
    //this.designLayer.batchDraw();
    setTimeout(() => {
      this.showpossibletextpanels();
    }, 50);
  }

  canceltextmod() {
    document.getElementById('typemenu').style.display = 'none';
    this.deletepossmark();
    this.typemodbool = false;
  }

  toolchange(val) {
    document.getElementById('typemenu').style.display = 'none';
    this.deletepossmark();
    this.toolPanel = val;
    this.typemodbool = (val == 'add') ? true : false;
  }

  showpossibletextpanels() {
    if (this.textcol < 1) {
      this.textcol = 1;
    }
    else if (this.textrow < 1) {
      this.textrow = 1;
    }
    else {
      this.deletepossmark();
      this.subdrawpossmod();
    }
  }

  drawtextmodules() {
    this.deletepossmark();
    document.getElementById('typemenu').style.display = 'none';
    this.typemodbool = false;
    this.subdrawpossmod();
  }

  subdrawpossmod() {
    const orientation = this.getOrientation();
    const width = (orientation) ? (this.WIDTH + this.SPACING.x + this.PANEL_STROKEWITH) : (this.LENGTH + this.SPACING.x + this.PANEL_STROKEWITH);
    const height = (orientation) ? (this.LENGTH + this.SPACING.y + this.PANEL_STROKEWITH) : (this.WIDTH + this.SPACING.y + this.PANEL_STROKEWITH);
    let startpos = { x: this.typepos.x + (width / 2), y: this.typepos.y + (height / 2) };
    let endpos = { x: (startpos.x + (width * this.textcol) + (this.SPACING.x / 2)), y: (startpos.y + (height * this.textrow) + (this.SPACING.y / 2)) };
    this.populateOnDrag(startpos, endpos, this.SPACING);
  }


  deletepossmark() {
    this.designLayer.find('.poss_mark').each(shape => {
      shape.destroy();
    });
    this.designLayer.find('.poss_panel').each(shape => {
      this.deletesnappoints(shape);
      shape.destroy();
    });
    this.designLayer.batchDraw();
  }

  findPossibleAttachmentsPos() {
    const position = this.getRelativeStagePosition();
    // create a square around the given point (+/- 1 pixel of the point)
    const selectBox = {
      x: position.x - this.PANEL_STROKEWITH, y: position.y - (2 * this.PANEL_STROKEWITH + this.SPACING.y),
      width: (2 * this.PANEL_STROKEWITH), height: (4 * this.PANEL_STROKEWITH) + this.SPACING.y
    };
    // Find panels that fall in the selection area
    const panels = this.getPanelIntersection(selectBox, this.PANEL_STROKEWITH);
    // Find possible clamps on the selected panels from the selected position
    this.addClamps = this.findIntersectedClamps(panels, selectBox, position);
    // Find possible splices on the selected panels from the selected position
    this.addSplices = this.findIntersectedSplices(panels, selectBox, position);
    // Find if any of the found end splice falls on eave row
    const eavesSplices = this.findEaveRowsAttachments(this.addSplices.end);
    // Adjust the displayed buttons based on possible attachments
    this.onMenuButtonDisplay(eavesSplices); this.designLayer.draw();
    this.addTitle = (this.addSplices.end.length > 0 || this.addSplices.mid.length > 0
      || this.addClamps.end.length > 0 || this.addClamps.mid.length > 0) ? true : false;
    this.adjustMenuPosition();
  }

  // Adjust the menu position based on where it clicks on stage
  adjustMenuPosition() {
    const menuNode = document.getElementById('menu');
    const menuTop = Number(menuNode.style.top.split('px')[0]);
    const menuLeft = Number(menuNode.style.left.split('px')[0]);
    const clickedY = this.stage.getPointerPosition().y;
    const clickedX = this.stage.getPointerPosition().x;
    const containerRect = this.stage.container().getBoundingClientRect();
    const menuHeight = menuNode.offsetHeight;
    const menuWidth = menuNode.offsetWidth;
    if (Math.abs(menuTop - containerRect.bottom) < menuHeight) {
      menuNode.style.top = containerRect.top + clickedY - menuHeight - 4 + 'px';
    }
    if (Math.abs(menuLeft - containerRect.right) < menuWidth) {
      menuNode.style.left = containerRect.left + clickedX - menuWidth - 4 + 'px';
    }
  }


  // Find all possible clamps that intersects with a given selection area and intersected panels
  findIntersectedClamps(panelsArr: any, select: any, pos: any) {
    const allYs = new Set();
    const clampsXselect = { end: [], mid: [] };
    const RDR = (this.ROOF_ATTACHMENT.length > 8) ? true : false;
    for (const panel of panelsArr) {
      allYs.add(panel.y()); allYs.add(panel.y() + panel.height());
    }
    for (const Y of [...allYs]) {
      if (Y >= select.y && Y <= select.y + select.height) {
        if (panelsArr.length > 2 || (panelsArr.length === 2 && panelsArr[0].y() != panelsArr[1].y())) {
          const newY = this.findPanelsMidY(panelsArr);
          const clampPos = { x: pos.x, y: newY };
          const rafter = this.haveRafterIntersection(clampPos);
          if (!RDR) {
            (rafter) ? clampsXselect.mid.push({ x: pos.x, y: newY, t: 'r' }) : clampsXselect.mid.push({ x: pos.x, y: newY, t: 'd' });
          } else {
            (rafter) ? clampsXselect.mid.push({ x: pos.x, y: newY, t: 'r' }) : clampsXselect.mid.push({ x: pos.x, y: newY, t: 'd' });
          }
        } else if (panelsArr.length === 1 || (panelsArr.length === 2 && panelsArr[0].y() == panelsArr[1].y())) {
          const clampPos = { x: pos.x, y: Y };
          const rafter = this.haveRafterIntersection(clampPos);
          if (!RDR) {
            (rafter) ? clampsXselect.end.push({ x: pos.x, y: Y, t: 'r' }) : clampsXselect.end.push({ x: pos.x, y: Y, t: 'd' });
          } else {
            (rafter) ? clampsXselect.end.push({ x: pos.x, y: Y, t: 'r' }) : clampsXselect.end.push({ x: pos.x, y: Y, t: 'd' });
          }
        }
      }
    }
    // Remove duplicates
    clampsXselect.end = clampsXselect.end.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i);
    clampsXselect.mid = clampsXselect.mid.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i);
    return clampsXselect;
  }


  // Find all possible splices that intersects with a given selection area and intersected panels
  findIntersectedSplices(panelsArr: any, selectionBox: any, pos: any) {
    const splicesXselect = { end: [], mid: [] };
    const allSplicesPos = this.findAllSplicePos(panelsArr);
    for (const splice of allSplicesPos.end) {
      const spliceBox = { x: splice.x - 3, y: splice.y - 2, with: 6, height: 4 };
      if (this.haveIntersection(spliceBox, selectionBox)) {
        const rafter = this.haveRafterIntersection({ x: splice.x, y: splice.y });
        const spl = (rafter) ? { x: splice.x, y: splice.y, t: 'r' } : { x: splice.x, y: splice.y, t: 'd' };
        splicesXselect.end.push(spl);
      }
    }
    for (const splice of allSplicesPos.mid) {
      const spliceBox = { x: splice.x - 1, y: splice.y - 1, with: 2, height: 2 };
      if (this.haveIntersection(spliceBox, selectionBox)) {
        const rafter = this.haveRafterIntersection({ x: splice.x, y: splice.y });
        const spl = (rafter) ? { x: splice.x, y: splice.y, t: 'r' } : { x: splice.x, y: splice.y, t: 'd' };
        splicesXselect.mid.push(spl);
      }
    }
    return splicesXselect;
  }

  onMenuButtonDisplay(eaves: any) {
    const endClampButton = document.getElementById('add-end-clamp') as HTMLButtonElement;
    const midClampButton = document.getElementById('add-mid-clamp') as HTMLButtonElement;
    const endSpliceButton = document.getElementById('add-end-splice') as HTMLButtonElement;
    const midSpliceButton = document.getElementById('add-mid-splice') as HTMLButtonElement;
    const endFSpliceButton = document.getElementById('add-end-f-splice') as HTMLButtonElement;
    const midFSpliceButton = document.getElementById('add-mid-f-splice') as HTMLButtonElement;

    if (this.addClamps.end.length === 0) {
      endClampButton.style.display = 'none';
    } else {
      endClampButton.style.display = 'block';
      midClampButton.style.display = 'none';
    }
    if (this.addClamps.mid.length === 0) {
      midClampButton.style.display = 'none';
    } else {
      midClampButton.style.display = 'block';
      endClampButton.style.display = 'none';
    }
    if (this.addSplices.end.length === 0) {
      endSpliceButton.style.display = 'none';
      endFSpliceButton.style.display = 'none';
    } else {
      endSpliceButton.style.display = 'block';
      endFSpliceButton.style.display = 'block';
      midSpliceButton.style.display = 'none';
      midFSpliceButton.style.display = 'none';
      midClampButton.style.display = 'none';
      // allowing Floating splice on Eave row
      // if (eaves.length === 0) { endFSpliceButton.style.display = 'block'; } else { endFSpliceButton.style.display = 'none'; }
    }
    if (this.addSplices.mid.length === 0) {
      midSpliceButton.style.display = 'none';
      midFSpliceButton.style.display = 'none';
    } else {
      midSpliceButton.style.display = 'block';
      midFSpliceButton.style.display = 'block';
      endSpliceButton.style.display = 'none';
      endFSpliceButton.style.display = 'none';
      endClampButton.style.display = 'none';
    }
  }


  onMenuAction(button: any) {
    const menuNode = document.getElementById('menu');
    switch (button) {
      case 'endC':
        for (const clamp of this.addClamps.end) {
          this.drawClamp(clamp, 'green', this.clampGroup, clamp.t === 'r');
        }
        this.designLayer.draw();
        menuNode.style.display = 'none';
        // undo update
        this.undoforchangeclamp();
        break;
      case 'midC':
        for (const clamp of this.addClamps.mid) {
          this.drawClamp(clamp, 'orangered', this.clampGroup, clamp.t === 'r');
        }
        this.designLayer.draw();
        menuNode.style.display = 'none';
        this.undoforchangeclamp();
        break;
      case 'endS':
        for (const splice of this.addSplices.end) {
          this.drawSplice(splice, 'green', this.clampGroup, splice.t === 'r', false);
        }
        this.designLayer.draw();
        menuNode.style.display = 'none';
        this.undoforchangeclamp();
        break;
      case 'midS':
        for (const splice of this.addSplices.mid) {
          this.drawSplice(splice, 'orangered', this.clampGroup, splice.t === 'r', false);
        }
        this.designLayer.draw();
        menuNode.style.display = 'none';
        this.undoforchangeclamp();
        break;
        return;
      case 'endFS':
        this.drawSplicesArr(this.addSplices.end, 'yellowgreen', this.clampGroup, false, true);
        this.designLayer.draw();
        menuNode.style.display = 'none';
        this.undoforchangeclamp();
        break;
      case 'midFS':
        this.drawSplicesArr(this.addSplices.mid, 'lightsalmon', this.clampGroup, false, true);
        this.designLayer.draw();
        menuNode.style.display = 'none';
        // undo update
        this.undoforchangeclamp();
        break;
    }
    // setting drag false for new added clamp
    this.clampsDragFalse();
  }


  addSinglePanel() {
    // get panel orientation
    const orientation = this.getOrientation();
    // get cursor porsition from panel cursor
    const position = { x: this.panelCursor.x(), y: this.panelCursor.y() };
    if (this.ENABLE_DRAWING) {
      this.drawSinglePanel(orientation, position);
      this.detectcorners();
    }
    this.designLayer.draw();
    this.finalizeDrawingSinglePanel();
  }


  populate() {
    let polygonStart = this.OFFSET;
    let polygonEnd = this.MAXPOINT;
    this.staggered = this.projectService.getProject().staggered;
    if (this.workspace === 1) {
      const polygonSelfBox = this.mapLine.getSelfRect();
      const polygonClientBox = this.mapLine.getClientRect({ skipStroke: true });
      const polygonArea = {
        x: polygonSelfBox.x,
        y: (polygonClientBox.y - this.posDelta.y) / this.stage.scaleY(),
        width: polygonSelfBox.width,
        height: polygonClientBox.height / this.stage.scaleY()
      };
      polygonStart = { x: polygonArea.x, y: polygonArea.y };
      polygonEnd = { x: polygonArea.x + polygonArea.width, y: polygonArea.y + polygonArea.height };
    }
    const startIntersection = this.stage.getIntersection(polygonStart);
    const endIntersection = this.stage.getIntersection(polygonEnd);
    if (startIntersection === null || startIntersection.getClassName() !== 'Rect'
      || endIntersection == null || endIntersection.getClassName() !== 'Rect') {
      this.onPopulate(polygonStart, polygonEnd, this.SPACING, false, (this.workspace === 1));
    }
  }


  onPopulate(start: any, end: any, space: any, drag: boolean, map: boolean) {
    let stagSpace = 0;
    const orientation = this.getOrientation();
    const width = (orientation) ? (this.WIDTH + space.x + this.PANEL_STROKEWITH) : (this.LENGTH + space.x + this.PANEL_STROKEWITH);
    const height = (orientation) ? (this.LENGTH + space.y + this.PANEL_STROKEWITH) : (this.WIDTH + space.y + this.PANEL_STROKEWITH);
    if (drag) {
      // change the drawing position with the center of the panel on click 'n drag
      start = this.centerPanelPosition(start, orientation);
      end = this.centerPanelPosition(end, orientation);
    }
    const bottomLeft = { x: start.x, y: start.y + (end.y - start.y) };
    const topRight = { x: start.x + (end.x - start.x), y: start.y };
    const position = { x: bottomLeft.x, y: bottomLeft.y };
    const originX = bottomLeft.x;
    while ((position.y - height) > topRight.y) {
      while (position.x + width < topRight.x) {
        if (map) {
          this.gableRoof ? this.gabledetection(position) : this.hipdetection(position);
          const temppanel = { x: position.x, y: position.y, width, height };
          if (this.mapIntersection(this.polygonarea, temppanel)) {
            this.drawSinglePanel(orientation, position);
          }
        } else {
          this.drawSinglePanel(orientation, position);
        }
        position.x += width;
      }
      if (this.staggered > 0) { stagSpace = (stagSpace === 0) ? (this.staggered * width) : 0; }
      position.x = originX + stagSpace;
      position.y -= height;
    }
    this.designLayer.batchDraw();
    this.finalizeDrawingSinglePanel();
  }

  populateOnDrag(start: any, end: any, space: any) {
    let snapbool = false;
    let leftxbool = false;
    const orientation = this.getOrientation();
    // change the drawing position with the center of the panel on click
    start = this.centerPanelPosition(start, orientation);
    end = this.centerPanelPosition(end, orientation);
    let newy = start.y;
    let newx = start.x;
    const dragWidth = end.x - start.x;
    const dragHeight = end.y - start.y;
    const width = (orientation) ? (this.WIDTH + space.x + this.PANEL_STROKEWITH) : (this.LENGTH + space.x + this.PANEL_STROKEWITH);
    const height = (orientation) ? (this.LENGTH + space.y + this.PANEL_STROKEWITH) : (this.WIDTH + space.y + this.PANEL_STROKEWITH);
    //  console.log(this.blcktopleft.length)
    if (this.blcktopleft.length > 0) {
      //[newy, snapbool, newx, leftxbool] = this.typemodbool ? [newy, snapbool, newx, leftxbool]  :  this.arrangeblock(start.y, start.x, end, width, height);
      [newy, snapbool, newx, leftxbool] = this.arrangeblock(start.y, start.x, end, width, height);
      if (leftxbool && this.xrevsnap && !this.yrevsnap) {
        //console.log("leftdragfunc")
        this.leftdragfunc(start, dragWidth, dragHeight, width, height, newx, newy);
      } else if (leftxbool && this.xrevsnap && this.yrevsnap) {
        console.log("leftrevdragfunc");
        this.leftrevdragfunc(start, dragWidth, dragHeight, width, height, newx, newy);
      }
      start.x = newx;
    }

    // corner snapping
    if (!leftxbool && !this.xrevsnap && this.yrevsnap && !snapbool) {
      //console.log("right reverse")
      this.rightreversedrag(start, dragWidth, dragHeight, width, height, snapbool, newy);
    } else if (!leftxbool && this.xrevsnap && this.yrevsnap && snapbool) {
      //console.log("top  reverse")
      this.topreversedrag(start, dragWidth, dragHeight, width, height, snapbool, newy);
    } else if (!leftxbool) {
      //console.log("sub drag")	
      // console.log('bottom drag');
      this.subdragfunc(start, dragWidth, dragHeight, width, height, snapbool, newy);
    }
    this.setPanelCount();
    this.designLayer.batchDraw();
    this.finalizeDrawingSinglePanel();

  }

  // corner snapping
  rightreversedrag(start, dragWidth, dragHeight, width, height, snapbool, newy) {
    // console.log('reverse runs');
    let stagSpace = 0;
    const orientation = this.getOrientation();
    const bottomLeft = { x: start.x, y: newy };
    const topRight = { x: start.x + dragWidth, y: newy - dragHeight };
    const position = bottomLeft;
    const originX = bottomLeft.x;
    while ((position.y - height) > topRight.y) {
      while (position.x + width < topRight.x) {
        if (this.workspace === 1) {
          const temppanel = { x: position.x, y: position.y, width, height };
          if (this.mapIntersection(this.polygonarea, temppanel)) { this.drawSinglePanel(orientation, position); }
        } else { this.drawSinglePanel(orientation, position); }
        position.x += width;
      }
      if (this.staggered > 0) { stagSpace = (stagSpace === 0) ? (this.staggered * width) : 0; }
      position.x = originX + stagSpace;
      position.y = position.y - height;
    }
  }

  topreversedrag(start, dragWidth, dragHeight, width, height, snapbool, newy) {
    // console.log('top reverse');
    let stagSpace = 0;
    const orientation = this.getOrientation();
    const bottomLeft = { x: start.x, y: newy };
    const topRight = { x: start.x - dragWidth, y: start.y };
    const position = bottomLeft;
    const originX = bottomLeft.x;
    while ((position.y - height) > topRight.y) {
      while (position.x - width > topRight.x) {
        const panel = (orientation) ? this.panelService.addPanel(position, this.WIDTH, this.LENGTH, this.PANEL_STROKEWITH) :
          this.panelService.addPanel(position, this.LENGTH, this.WIDTH, this.PANEL_STROKEWITH);
        this.designGroup.add(panel);
        panel.moveToTop();
        position.x -= width;
        this.totalPanels = this.designGroup.children.length;
        // snapping code
        this.savesnappingpoints(panel._id, panel.attrs);
      }
      if (this.staggered > 0) { stagSpace = (stagSpace === 0) ? (this.staggered * width) : 0; }
      position.x = originX + stagSpace;
      position.y = position.y - height;
    }
  }

  subdragfunc(start, dragWidth, dragHeight, width, height, snapbool, newy) {
    // console.log('sub drag');
    let stagSpace = 0;
    const orientation = this.getOrientation();
    const bottomLeft = { x: snapbool ? start.x : start.x + dragWidth, y: snapbool ? newy : newy + dragHeight };
    const topRight = { x: snapbool ? start.x + dragWidth : start.x, y: snapbool ? start.y : newy };
    const position = snapbool ? bottomLeft : topRight;
    const originX = snapbool ? bottomLeft.x : topRight.x;
    while (snapbool ? ((position.y - height) > topRight.y) : (position.y + height) < bottomLeft.y) {
      while (snapbool ? (position.x + width < topRight.x) : position.x + width < bottomLeft.x) {
        if (this.workspace === 1) {
          const temppanel = { x: position.x, y: position.y, width, height };
          if (this.mapIntersection(this.polygonarea, temppanel)) { this.drawSinglePanel(orientation, position); }
        } else { this.drawSinglePanel(orientation, position); }
        position.x += width;
      }
      if (this.staggered > 0) { stagSpace = (stagSpace === 0) ? (this.staggered * width) : 0; }
      position.x = originX + stagSpace;
      position.y = snapbool ? position.y - height : position.y + height;
    }
  }

  leftrevdragfunc(start, dragWidth, dragHeight, width, height, newx, newy) {
    // console.log('left rev drag');
    let stagSpace = 0;
    start.y = newy;
    const orientation = this.getOrientation();
    const topRight = { x: start.x, y: newy - dragHeight };
    const bottomLeft = { x: newx, y: newy };
    const position = bottomLeft;
    const originX = bottomLeft.x;
    while ((position.y - height) > topRight.y) {
      while (position.x - width > topRight.x) {
        if (this.workspace === 1) {
          const temppanel = { x: position.x, y: position.y, width, height };
          if (this.mapIntersection(this.polygonarea, temppanel)) { this.drawSinglePanel(orientation, position); }
        } else { this.drawSinglePanel(orientation, position); }
        position.x -= width;
      }
      if (this.staggered > 0) { stagSpace = (stagSpace === 0) ? (this.staggered * width) : 0; }
      position.x = originX + stagSpace;
      position.y = position.y - height;
    }
  }

  leftdragfunc(start, dragWidth, dragHeight, width, height, newx, newy) {
    // console.log('left drag');
    let stagSpace = 0;
    start.y = newy;
    const orientation = this.getOrientation();
    const topRight = { x: newx, y: start.y };
    const bottomLeft = { x: start.x, y: start.y + dragHeight };
    const position = topRight;
    const originX = topRight.x;
    while ((position.y + height) < bottomLeft.y) {
      while (position.x - width > bottomLeft.x) {
        if (this.workspace === 1) {
          const temppanel = { x: position.x, y: position.y, width, height };
          if (this.mapIntersection(this.polygonarea, temppanel)) { this.drawSinglePanel(orientation, position); }
        } else { this.drawSinglePanel(orientation, position); }
        position.x -= width;
      }
      if (this.staggered > 0) { stagSpace = (stagSpace === 0) ? (this.staggered * width) : 0; }
      position.x = originX + stagSpace;
      position.y = position.y + height;
    }
  }

  // corner snapping
  arrangeblock(starty, startx, end, width, height) {
    let levels;
    const endx = end.x;
    const endy = end.y;
    for (let i = 0; i < this.blcktopleft.length; i++) {
      const topcond = this.blcktopleft[i].y - endy;
      const bottcond = this.blckbottright[i].y - starty;
      const rightcond = this.blckbottright[i].x - startx;
      const leftcond = this.blcktopleft[i].x - endx;
      // top runs
      if (topcond < 24 && topcond > -2 && startx < this.blckbottright[i].x - 6
        && (endx > this.blcktopleft[i].x)) {
        // corner snapping
        width = this.blcktopleft[i].width;
        levels = (this.blckbottright[i].x - this.blcktopleft[i].x) / width;
        for (let x = 0; x < levels; x++) {
          if (!this.xrevsnap && this.yrevsnap) {
            if (startx > this.blcktopleft[i].x + (x * width) - 2 && startx < this.blcktopleft[i].x + ((x + 1) * width) - (width / 1.3)) {
              starty = this.blcktopleft[i].y;
              startx = this.blcktopleft[i].x + (x * width);
              return [starty, true, startx, false];
            } else if (startx < this.blcktopleft[i].x) {
              starty = this.blcktopleft[i].y;
              startx = this.blcktopleft[i].x - width;
              return [starty, true, startx, false];
            } else if (startx > this.blcktopleft[i].x + ((x + 1) * width) - ((width / 1.3)) &&
              startx < this.blcktopleft[i].x + (x + 1) * (width - 2)) {
              starty = this.blcktopleft[i].y;
              return [starty, true, startx, false];
            }
          } else {
            if (end.x > this.blcktopleft[i].x + (x * width) - 2 && end.x < this.blcktopleft[i].x + ((x + 1) * width) - (width / 1.3)) {
              starty = this.blcktopleft[i].y;
              startx = this.blcktopleft[i].x + (x * width);
              return [starty, true, startx, false];
            } else if (end.x > this.blcktopleft[i].x + ((x + 1) * width) - ((width / 1.3)) &&
              end.x < this.blcktopleft[i].x + (x + 1) * (width - 2)) {
              starty = this.blcktopleft[i].y;
              return [starty, true, end.x, false];
            } else if (end.x > this.blckbottright[i].x) {
              starty = this.blcktopleft[i].y;
              startx = this.blckbottright[i].x;
              return [starty, true, startx, false];
            }
          }
        }
      } else if (bottcond < 20 && bottcond > -3 // bottom runs
        && startx < this.blckbottright[i].x - 10
        && endx > this.blckbottright[i].x) {
        starty = this.blckbottright[i].y;
        width = this.blckbottright[i].width;
        levels = (this.blckbottright[i].x - this.blcktopleft[i].x) / width;
        for (let x = 0; x < levels; x++) {
          if (startx > this.blcktopleft[i].x + (x * width) && startx < this.blcktopleft[i].x + ((x + 1) * width) - (width / 1.3)) {
            startx = this.blcktopleft[i].x + (x * width);
            return [starty, false, startx, false];
          } else if (startx < this.blcktopleft[i].x) {
            startx = this.blcktopleft[i].x - width;
            return [starty, false, startx, false];
          } else if (startx > this.blcktopleft[i].x + ((x + 1) * width) - ((width / 1.3)) &&
            startx < this.blcktopleft[i].x + (x + 1) * (width - 1)) {
            return [starty, false, startx, false];
          }
        }
      } else if (rightcond < 50 && rightcond > -3 && startx > this.blckbottright[i].x - 2) {
        startx = this.blckbottright[i].x;
        levels = (this.blckbottright[i].y - this.blcktopleft[i].y) / height;
        height = this.blckbottright[i].height;
        if (!this.xrevsnap && this.yrevsnap) {
          for (let x = 0; x < levels; x++) {
            if (end.y > (this.blcktopleft[i].y + (x * height) - 2) && end.y < this.blcktopleft[i].y + (x + 1) * height - (height / 1.3)) {
              starty = this.blcktopleft[i].y + x * height;
              return [starty, false, startx, false];
            } else if (end.y > this.blckbottright[i].y) {
              starty = this.blckbottright[i].y;
              return [starty, false, startx, false];
            } else if (end.y > (this.blcktopleft[i].y + (x + 1) * height - (height / 1.3))
              && end.y < (this.blcktopleft[i].y + (x + 1) * (height - 2))) {
              return [end.y, false, startx, false];
            }
          }
        } else {
          for (let x = 0; x < levels; x++) {
            if (starty > (this.blcktopleft[i].y + (x * height) - 2) && starty < this.blcktopleft[i].y + (x + 1) * height - (height / 1.3)) {
              starty = this.blcktopleft[i].y + x * height;
              return [starty, false, startx, false];
            } else if (starty < this.blcktopleft[i].y - 2) {
              starty = this.blcktopleft[i].y - height;
              return [starty, false, startx, false];
            } else if (starty > (this.blcktopleft[i].y + (x + 1) * height - (height / 1.3))
              && starty < (this.blcktopleft[i].y + (x + 1) * (height - 2))) {
              return [starty, false, startx, false];
            }
          }
        }
      } else if (leftcond < 45 && leftcond > -9) {   // left runs
        //		console.log("left runs")
        height = this.blckbottright[i].height;
        levels = (this.blckbottright[i].y - this.blcktopleft[i].y) / height;
        for (let x = 0; x < levels; x++) {
          if (this.xrevsnap && this.yrevsnap) {
            if (end.y > (this.blcktopleft[i].y + (x * height)) && end.y < this.blcktopleft[i].y + (x + 1) * height - (height / 1.3)) {
              startx = this.blcktopleft[i].x;
              starty = this.blcktopleft[i].y + x * height;
              return [starty, true, startx, true];
            } else if (end.y > (this.blcktopleft[i].y + (x + 1) * height - (height / 1.3)) &&
              end.y < (this.blcktopleft[i].y + (x + 1) * (height - 1))) {
              startx = this.blcktopleft[i].x;
              return [end.y, true, startx, true];
            } else if (end.y > this.blckbottright[i].y) {
              startx = this.blcktopleft[i].x;
              starty = this.blckbottright[i].y + height;
              return [starty, true, startx, true];
            }
          } else {
            if (starty > (this.blcktopleft[i].y + (x * height)) && starty < this.blcktopleft[i].y + (x + 1) * height - (height / 1.3)) {
              startx = this.blcktopleft[i].x;
              starty = this.blcktopleft[i].y + x * height;
              return [starty, true, startx, true];
            } else if (starty > (this.blcktopleft[i].y + (x + 1) * height - (height / 1.3)) &&
              starty < (this.blcktopleft[i].y + (x + 1) * (height - 1))) {
              startx = this.blcktopleft[i].x;
              return [starty, true, startx, true];
            } else if (starty < this.blcktopleft[i].y) {
              startx = this.blcktopleft[i].x;
              starty = this.blcktopleft[i].y - height;
              return [starty, true, startx, true];
            }
          }
        }
      } else { continue; }
    }
    // console.log('else runs');
    this.yrevsnap = false;
    //console.log([starty, false, startx, false])
    return [starty, false, startx, false];
  }

  drawSinglePanel(ornt: any, position: any) {
    let panel;
    if (this.typemodbool) {
      panel = (ornt) ? this.panelService.addPossPanel(position, this.WIDTH, this.LENGTH, this.PANEL_STROKEWITH) :
        this.panelService.addPossPanel(position, this.LENGTH, this.WIDTH, this.PANEL_STROKEWITH);
    }
    else {
      panel = (ornt) ? this.panelService.addPanel(position, this.WIDTH, this.LENGTH, this.PANEL_STROKEWITH) :
        this.panelService.addPanel(position, this.LENGTH, this.WIDTH, this.PANEL_STROKEWITH);
    }
    this.designGroup.add(panel);
    panel.moveToTop();
    this.savesnappingpoints(panel._id, panel.attrs);
    // new snapping
    this.detectcorners();
  }


  finalizeDrawingSinglePanel() {
    this.setPanelCount();
    this.updateDimension();
    this.removeoverlapping();
    this.typemodbool ? '' : this.savechanges();
  }


  // snapping code
  savesnappingpoints(id, panel) {
    this.testpoints.push({ id, points: [panel.x - (this.PANEL_STROKEWITH + this.SPACING.x / 1.67), panel.y] });
    this.testpoints.push({ id, points: [panel.x + panel.width / 2, panel.y - (this.PANEL_STROKEWITH + this.SPACING.y)] });
    this.testpoints.push({ id, points: [panel.x + panel.width / 2, panel.y + 1] });
    this.testpoints.push({ id, points: [panel.x + panel.width + this.PANEL_STROKEWITH + this.SPACING.x / 2, panel.y] });
    this.testpoints.push({ id, points: [panel.x + panel.width / 2, panel.y + panel.height / 2] });

    this.testpoints.push({ id, points: [panel.x + panel.width / 2, panel.y + panel.height + 1 + this.SPACING.y / 2] });

    this.testpoints.push({
      id, points: [panel.x - (this.PANEL_STROKEWITH + this.SPACING.x / 2),
      panel.y + panel.height + 1 + this.SPACING.y / 2]
    });
    this.testpoints.push({ id, points: [panel.x + panel.width + this.PANEL_STROKEWITH + this.SPACING.x / 2, panel.y + panel.height - 2] });
    // new point
    this.testpoints.push({ id, points: [panel.x - (this.PANEL_STROKEWITH + this.SPACING.x / 1.67), panel.y + panel.height - 1] });
  }

  // corner snapping
  reverse(r1: { x: any; y: any; }, r2: { x: any; y: any; }) {
    let r1x = r1.x;
    let r1y = r1.y;
    let r2x = r2.x;
    let r2y = r2.y;
    let d: number;
    // this.reversesnap = false;
    this.xrevsnap = false;
    this.yrevsnap = false;
    if (r1x > r2x) {
      d = Math.abs(r1x - r2x);
      r1x = r2x; r2x = r1x + d;
      //  this.reversesnap = true;
      if (this.blcktopleft.length > 0) { this.xrevsnap = true; }
    }
    if (r1y > r2y) {
      d = Math.abs(r1y - r2y);
      r1y = r2y; r2y = r1y + d;
      // 	  console.log("y reversal");
      if (this.blcktopleft.length > 0) { this.yrevsnap = true; }
    }
    r1 = { x: r1x, y: r1y };
    r2 = { x: r2x, y: r2y };

    return { r1, r2 };
  }

  detectcorners() {
    this.xpoints = []; this.ypoints = [];
    let width; let height;
    this.designLayer.find('.panel').each(shape => {
      if (this.blocksnapid.indexOf(shape._id) === -1) {
        this.xpoints.push({ x: shape.attrs.x, id: shape._id });
        this.ypoints.push({ y: shape.attrs.y, id: shape._id });
        this.blocksnapid.push(shape._id);
        width = shape.attrs.width + this.SPACING.x + this.PANEL_STROKEWITH;
        height = shape.attrs.height + this.SPACING.y + this.PANEL_STROKEWITH;
      }
    });
    const xmin = Math.min.apply(null, this.xpoints.map(item => item.x));
    const ymin = Math.min.apply(null, this.ypoints.map(item => item.y));
    const xmax = Math.max.apply(null, this.xpoints.map(item => item.x));
    const ymax = Math.max.apply(null, this.ypoints.map(item => item.y));

    if (width !== undefined) {
      this.blcktopleft.push({ x: xmin, y: ymin, width, height });
      this.blckbottright.push({ x: xmax + width, y: ymax + height, width, height });
    }
  }


  removeoverlapping() {
    this.dragarea = [];
    // Getting shapes in screen
    this.designGroup.children.each(shape => {
      this.dragarea.push(shape);
    });
    // matching loop with others
    for (let i = 1; i < this.dragarea.length; i++) {
      for (let j = 0; j < i; j++) {
        if (this.haveIntersection(this.dragarea[i].attrs, this.dragarea[j].attrs)) {
          // removing overlapping block
          this.dragarea[i].remove();
          // snapping code
          this.deletesnappoints(this.dragarea[i]);
          // getting panel count
          this.panelcount();
          this.designLayer.draw();
          this.setPanelCount();
          this.updateDimension();
        }
      }
    }
  }


  // undo code  *******************
  savechanges() {
    if (this.changecount === 0) {
      this.savepanelstep();
      this.clampsteps.push(this.clampsteps[this.clampsteps.length - 1]);
    } else {
      this.savepanelstep();
      this.clampsteps.push(this.clampsteps[this.clampsteps.length - 1]);
    }
    this.changecount = this.changecount + 1;
    this.undoenable = true;
  }


  // Undo code
  savepanelstep() {
    this.secondchange = [];
    this.designGroup.children.each(shape => {
      this.secondchange.push(shape.attrs);
    });
    this.stepsarr.push(JSON.parse(JSON.stringify(this.secondchange)));
  }


  // Undo code
  undoforchangeclamp() {
    this.stepsarr.push(this.stepsarr[this.stepsarr.length - 1]);
    this.fillclampsteps();
    this.changecount = this.changecount + 1;
    this.undoenable = true;
  }


  // Undo code
  undo() {
    if (this.undoenable) {
      this.toolPanel = 'undo';
      this.noTableDrawCursor();
      this.noDragsCursor();
      this.stage.on('mousemove', () => {
        this.stage.container().style.cursor = 'not-allowed';
      });
      this.changecount = (this.changecount <= 0) ? 0 : this.changecount - 1;
      if (this.changecount >= 0) {
        this.deleteallfunc();
        // 11-15-2020
        this.testpoints = [];
        // ends
        this.totalPanels = 0;
        // new snapping
        this.blcktopleft.pop();
        this.blckbottright.pop();
        this.stepsarr[this.changecount].forEach((element: any) => {
          if (element.name === 'panel') {
            const panel = new Konva.Rect(element);
            this.designGroup.add(panel);
            // snapping code
            this.savesnappingpoints(panel._id, panel.attrs);
            this.totalPanels++;
          }
        });
        // console.log(this.clampsteps)
        this.clampsteps[this.changecount].forEach((element: any) => {
          if (element.name === 'clamp') {
            //const circ = new Konva.Circle(element );
            const circ = this.panelService.createundoClamps(element.x, element.y, element.fill, element.radius, element.strokeWidth, element.stroke);
            this.clampGroup.add(circ);
          } else if (element.name === 'splice') {
            const splice = new Konva.Rect(element);
            this.clampGroup.add(splice);
          } else { }
        });
        this.clampcount = this.clampcount - 1;
        this.stepsarr.splice(this.changecount + 1, 1);
        this.clampsteps.splice(this.changecount + 1, 1);
        this.designLayer.draw();
        this.setPanelCount();
        this.updateDimension();
        if (this.changecount === 0) {
          this.cursorLayer.show();
          this.stage.on('mousemove', () => {
            this.stage.container().style.cursor = 'default';
          });
          this.undoenable = false;
          if (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter' && this.ROOF_ATTACHMENT.length > 8) {
            this.addCursor();
          }
          this.panelCursorShow();
          setTimeout(() => {
            this.toolPanel = 'add';
          }, 350);
        }
      }
    }
  }
  // ****************** End of Undo code


  panelcount() {
    this.totalPanels = this.designGroup.children.length;
  }


  calculateTableSize(start: any, end: any, space: any) {
    const orientation = this.getOrientation();
    const dragWidth = end.x - start.x;
    const dragHeight = end.y - start.y;
    // change the drawing position with the center of the panel on click
    start = this.centerPanelPosition(start, orientation);
    const bottomLeft = { x: start.x, y: start.y + dragHeight };
    const topRight = { x: start.x + dragWidth, y: start.y };
    const position = bottomLeft;
    const originX = bottomLeft.x;
    const width = (orientation) ? (this.WIDTH + space.x + this.PANEL_STROKEWITH) : (this.LENGTH + space.x + this.PANEL_STROKEWITH);
    const height = (orientation) ? (this.LENGTH + space.y + this.PANEL_STROKEWITH) : (this.WIDTH + space.y + this.PANEL_STROKEWITH);
    let row = 0;
    let col = 0;
    while ((position.y - height - (2 * this.PANEL_STROKEWITH)) > topRight.y) {
      col = 0;
      while (position.x + width < topRight.x) {
        col++;
        position.x += width;
      }
      position.x = originX;
      position.y -= height;
      row++;
    }
    return { row, col };
  }


  deletePanelOnClick() {
    // delete the shape that intersects with where cursor clicks
    const position = this.stage.getPointerPosition();
    const shape = this.designLayer.getIntersection({ x: position.x, y: position.y });
    if (shape != null && shape.name() === 'panel') {
      shape.destroy();
      this.setPanelCount();
      this.updateDimension();
      // Undo code
      this.savechanges();
      // snapping code
      this.deletesnappoints(shape);
    }
    this.designLayer.draw();
  }


  // snapping code
  deletesnappoints(shape: any) {
    const temppoints = this.testpoints;
    for (let i = 0; i < this.testpoints.length; i++) {
      if (temppoints[i].id === shape._id) {
        temppoints.splice(i, 9);
        break;
      }
    }
    this.testpoints = temppoints;
  }


  deleteTabelePanel() {
    const drawnPanels = [];
    this.designGroup.children.each(shape => {
      drawnPanels.push(shape);
    });
    for (const panel of drawnPanels) {
      if (this.haveIntersection(this.tableDraw.getClientRect({ skipStroke: false }), panel.getClientRect({ skipStroke: false }))) {
        // snapping code
        this.deletesnappoints(panel);
        panel.destroy();
        this.setPanelCount();
        this.updateDimension();
      }
    }
    this.designLayer.draw();
    // Undo code
    this.savechanges();
  }


  deleteAll() {
    // delete all the layers & it's children if checkpoint is true
    const dialogRef = this.dialog.open(ConfirmComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // undo code
        this.deleteallfunc();
        this.subdeletefunc();
        if (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter') { this.projectService.setProject('failed', true); }
      }
    });
  }

  subdeletefunc() {
    // undo code
    this.changecount = 0;
    this.stepsarr = [];
    this.clampsteps = [];
    this.firstchange = [];
    this.stepsarr.push(this.firstchange);
    this.clampsteps.push(this.firstchange);
    this.totalPanels = 0;
    this.undoenable = false;
    this.setPanelCount();
    this.updateDimension();
    // snapping code
    this.testpoints = [];
    // new snapping
    this.blcktopleft = [];
    this.blckbottright = [];
  }


  // Delete code
  deleteallfunc() {
    this.designLayer.find('.panel').each(shape => {
      shape.destroy();
    });
    this.designLayer.find('.clamp').each(shape => {
      shape.destroy();
    });
    this.designLayer.find('.splice').each(shape => {
      shape.destroy();
    });
    this.designLayer.find('.selection').each(shape => {
      shape.destroy();
    });
    //adjust type draw panel
    document.getElementById('typemenu').style.display = 'none';
    this.deletepossmark();
    this.designLayer.draw();
  }

  //change zone logic for canada
  changecanadazone() {
    this.canadazonecount++;
    if (this.canadazonecount == 2) {
      this.canadazone = 'S';
    }
    else if (this.canadazonecount == 3) {
      this.canadazone = 'C';
    }
    else {
      this.canadazonecount = 1;
      this.canadazone = 'R';
    }
    this.toggleZone(this.canadazonecount);
  }


  changeZone() {
    if (this.toolZone < 3) {
      this.toolZone++;
    } else {
      this.toolZone = 1;
    }
    this.toggleZone(this.toolZone);
  }


  toggleZone(zone: any) {
    switch (zone) {
      case 1:
        this.COLOR_DEFAULT = '#6495ed';
        return;
      case 2:
        this.COLOR_DEFAULT = '#ffa500';
        return;
      case 3:
        this.COLOR_DEFAULT = '#ff0000';
        return;
    }
    // console.log(this.COLOR_DEFAULT)
  }


  zoomStage() {
    // zoom in & out of the stage
    this.stage.on('wheel', e => {
      const oldScale = this.stage.scaleX();

      let newScale =
        e.evt.deltaY < 0 ? oldScale * this.ZOOM_SCALE : oldScale / this.ZOOM_SCALE;

      // Set zooming limitation
      if (newScale <= this.MIN_ZOOM) {
        newScale = this.MIN_ZOOM;
      } else if (newScale >= this.MAX_ZOOM) {
        newScale = this.MAX_ZOOM;
      }

      this.stage.scale({ x: newScale, y: newScale });

      // Set zooming relative to cursor position
      const mousePointTo = {
        x: (this.stage.getPointerPosition().x - this.stage.x()) / oldScale,
        y: (this.stage.getPointerPosition().y - this.stage.y()) / oldScale
      };

      let newPos = {
        x:
          -(mousePointTo.x - this.stage.getPointerPosition().x / newScale) *
          newScale,
        y:
          -(mousePointTo.y - this.stage.getPointerPosition().y / newScale) *
          newScale
      };

      if (newScale <= (this.MIN_ZOOM + (this.MIN_ZOOM / 2)) && newScale < oldScale) {
        // reset this.group position
        newPos = (this.workspace === 1) ? { x: 0, y: 0 } : { x: (this.W / 2), y: (this.H / 2) };
        newPos = { x: 0, y: 0 };
      }

      this.posDelta = newPos;
      this.stage.position(newPos);
      this.stage.batchDraw();
    });
  }


  getRelativeStagePosition() {
    const position = this.stage.getPointerPosition();
    const scale = this.stage.scaleX();
    position.x = (position.x - this.posDelta.x) / scale;
    position.y = (position.y - this.posDelta.y) / scale;
    return position;
  }


  // Set the stage scaling relative to a point
  setZoomStage(scaleBy: any, center: any) {
    const oldScale = this.stage.scaleX();

    const mousePointTo = {
      x: center.x / oldScale - this.stage.x() / oldScale,
      y: center.y / oldScale - this.stage.y() / oldScale
    };

    let newScale = oldScale * scaleBy;

    // Set zooming limitation
    const zoomOut = (this.workspace === 1) ? 1 : this.MIN_ZOOM;
    if (newScale <= zoomOut) {
      newScale = zoomOut;
    } else if (newScale >= this.MAX_ZOOM) {
      newScale = this.MAX_ZOOM;
    }

    this.stage.scale({ x: newScale, y: newScale });

    const newPos = {
      x:
        -(mousePointTo.x - center.x / newScale) *
        newScale,
      y:
        -(mousePointTo.y - center.y / newScale) *
        newScale
    };
    this.posDelta = newPos;
    this.stage.position(newPos);
    this.stage.batchDraw();
  }


  onMove() {
    this.moveStage();
    if (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter') {
      this.gridlineDragTrue();
      this.moveRafterLine();
      if (this.ROOF_ATTACHMENT.length > 8 && this.clampGroup !== undefined) {
        this.clampGroup.setDraggable(true);
        this.moveClamps();
      } else { this.clampsDragFalse(); }
    }
  }

  moveStage() {
    this.stage.on('dragend', () => {
      const newPos = {
        x: this.stage.x(),
        y: this.stage.y()
      };
      this.posDelta = newPos;
      this.stage.off('dragend');
    });
  }


  // Moving Clamps
  moveClamps() {
    this.clampGroup.on('dragend', (event) => {
      if (event.target.name() === 'clamp') {
        this.changeClampFillOnDrag(event.target);
        // this.undoforchangeclamp();
      }
      this.clampGroup.off('dragend');
    });
  }


  // Moving Rafter lines
  moveRafterLine() {
    this.gridGroup.on('dragend', (e) => {
      this.adjustClampsOnMove(e.target.x() - this.RAFTER_DRAG);
      if (this.USE_SPLICE > 0) { setTimeout(() => { this.adjustSplicesOnMove(); }, 200); }
      this.RAFTER_DRAG = e.target.x();
      this.gridGroup.off('dragend');
    });
    // .then(e => { console.log(e); });
  }


  // V2 - Adjsut the clamps positions when rafter lines are moved
  adjustClampsOnMove(dx: any) {
    if (this.clampGroup.children.length > 0) {
      this.clampGroup.children.each((clamp: any) => {
        if (clamp.name() === 'clamp' && clamp.fill() === clamp.stroke()) {
          clamp.x(clamp.x() + dx);
        }
      });
    }
    this.designLayer.batchDraw();
  }


  // V2 - Adjust Rafter/Deck/Floating Splices when moving Rafter lines
  adjustSplicesOnMoveNoEave() {
    if (this.clampGroup.children.length > 0) {
      const bottomSplices = [];
      const remainSplices = [];
      if (this.USE_SPLICE === 1) { // Regular Splice
        this.clampGroup.find('.splice').each((splice: any) => { this.changeDeckSplice(splice); });
      } else if (this.USE_SPLICE === 2) { // Floating Splice
        this.clampGroup.find('.splice').each((splice: any) => {
          // const splBox = {
          //   x: splice.x() + (splice.width() * 1.5) - this.PANEL_STROKEWITH,
          //   y: splice.y() + (splice.height() * 0.5) - this.PANEL_STROKEWITH,
          //   width: (splice.width() * 1.5) + this.PANEL_STROKEWITH,
          //   height: (splice.height() * 0.5) + this.PANEL_STROKEWITH
          // };
          const splBox = {
            x: splice.x(), y: splice.y() + (this.WIDTH / 2),
            width: this.CLAMP_RATIO, height: this.CLAMP_RATIO
          };
          const intersected = this.getPanelIntersection(splBox, this.PANEL_STROKEWITH);
          if (!intersected.length) { bottomSplices.push(splice); } else { remainSplices.push(splice); }
        });
        bottomSplices.forEach(spl => { this.changeDeckSplice(spl); });
        remainSplices.forEach(eave => { this.changeFloatingSplice(eave); });
      }
    }
    this.designLayer.batchDraw();
  }

  adjustSplicesOnMove() {
    if (this.clampGroup.children.length > 0) {
      if (this.USE_SPLICE === 1) { // Regular Splice
        this.clampGroup.find('.splice').each((splice: any) => this.changeDeckSplice(splice));
      } else if (this.USE_SPLICE === 2) { // Floating Splice
        this.clampGroup.find('.splice').each((splice: any) => {
          this.changeFloatingSplice(splice);
        });
      }
      // const deckSplices = [];
      // const floatingSplices = [];
      // this.clampGroup.find('.splice').each((splice: any) => {
      //   console.log('splice', splice)
      //   splice.stroke() === 'white' ? floatingSplices.push(splice) : deckSplices.push(splice);
      // });
      // deckSplices.forEach(splice => this.changeDeckSplice(splice));
      // floatingSplices.forEach(splice => this.changeFloatingSplice(splice));
    }
    this.designLayer.batchDraw();
  }

  // Change the Rafter to Deck Splices and vice versa based on current Rafterlines
  changeDeckSplice(splice: any) {
    const pos = { x: splice.x() + this.CLAMP_RATIO_CENTER.x, y: splice.y() + this.CLAMP_RATIO_CENTER.y };
    if (this.haveRafterIntersection(pos)) {
      const color = splice.stroke();
      splice.fill(color.toString());
    } else { splice.fill('white'); }
  }


  // Change the Rafter to Floating Deck Splices and vice versa based on current Rafterlines
  changeFloatingSplice(splice: any) {
    const pos = { x: splice.x() + this.CLAMP_RATIO_CENTER.x, y: splice.y() + this.CLAMP_RATIO_CENTER.y };
    if (this.haveRafterIntersection(pos)) {
      if (splice.stroke() === 'white') {
        const color = this.toggleSpliceColor(splice.fill());
        splice.fill(color); splice.stroke(color);
      }
    } else {
      if (splice.stroke() === splice.fill()) {
        const color = this.toggleSpliceColor(splice.fill());
        splice.fill(color); splice.stroke('white');
      }
    }

  }


  toggleSpliceColor(color: any) {
    switch (color) {
      case 'yellowgreen':
        return 'green';
      case 'green':
        return 'yellowgreen';
      case 'lightsalmon':
        return 'orangered';
      case 'orangered':
        return 'lightsalmon';
    }
  }


  // Adjust Deck <-> Rafter Clamps on Drag
  changeClampFillOnDrag(clamp: any) {
    const rafterOnPanels = this.findPanelXRafterline();
    for (const line of rafterOnPanels) {
      if (clamp.x() >= line.getAttrs().points[0] + this.RAFTER_DRAG - clamp.width() / 2 &&
        clamp.x() <= line.getAttrs().points[0] + this.RAFTER_DRAG + clamp.width() / 2) {
        clamp.fill(clamp.stroke());
        break;
      } else {
        clamp.fill('white');
      }
    }
    this.designLayer.draw();
  }


  // Given a point, return Rafter line(s) that intersects with shape or empty array (range +- 1")
  haveRafterIntersection(pos: any) {
    let flag = false;
    const range = 0.5;  // 1 inch in pixel -> 0.5 for each side
    const rafterOnPanels = this.findPanelXRafterline();
    for (const line of rafterOnPanels) {
      const lineX = line.getAttrs().points[0] + this.RAFTER_DRAG;
      if (pos.x >= lineX - range && pos.x <= lineX + range) {
        flag = true;
        break;
      }
    }
    this.designLayer.draw();
    return flag;
  }


  // Draw Grid lines for Rafter spacing reference
  drawGridLines() {
    this.gridGroup = this.panelService.addGroup();
    this.designLayer.add(this.gridGroup);

    for (let index = 0; index <= this.W;) {
      const line = this.panelService.createGridLines(index, 0, index, this.H);
      this.gridGroup.add(line);
      this.gridGroup.moveDown();
      index += this.RAFTER_SPACE;
    }
    this.designLayer.batchDraw();
  }


  updateDimension() {
    const EW = ((this.designGroup.getClientRect({ skipStroke: false }).width / this.stage.scaleX()) * this.MAP_TO_PIXEL_PROPORTION) / 12;
    const NS = ((this.designGroup.getClientRect({ skipStroke: false }).height / this.stage.scaleY()) * this.MAP_TO_PIXEL_PROPORTION) / 12;
    this.projectService.setProject('dimension', [EW.toFixed(2), NS.toFixed(2),
    (this.workspace === 1) ? this.pitchEffect(this.getPitch()) : 1]);
    this.EW = EW.toFixed(2);
    this.NS = NS.toFixed(2);
  }


  // Find RafterLines that intersects with drawn polygons
  findPanelXRafterline() {
    const rafterPanles = [];
    const drawnPanelsBox = this.designGroup.getClientRect({ skipStroke: true });
    this.gridGroup.children.each((line: any) => {
      const lineX = line.getAttrs().points[0] + this.RAFTER_DRAG;
      if (lineX >= (drawnPanelsBox.x - this.posDelta.x) / this.stage.scaleX() &&
        lineX <= (drawnPanelsBox.x + drawnPanelsBox.width - this.posDelta.x) / this.stage.scaleX()) {
        rafterPanles.push(line);
      }
    });
    return rafterPanles;
  }


  // Find Drawn Panels' Zones
  getProjectZones(panelList: any) {
    const zonesSet = new Set();
    if (!panelList.length) {
      this.designGroup.children.each((panel: any) => {
        panelList.push(panel);
      });
    }
    for (const panel of panelList) {
      zonesSet.add(this.findPanelZone(panel));
    }
    return [...zonesSet].sort();
  }


  // Find Drawn Panels' Orientation
  getProjectOrientation() {
    const orientations = new Set();
    this.designGroup.children.each((panel: any) => {
      orientations.add(this.getPanelOrientation(panel));
    });
    // If any of the orientations of the intersected panel(s) are Portrait, consider it portrait, otherwise landscape
	return orientations.has('port') ? 'port' : 'land';
  }


  // Return the orientation of a given panel
  getPanelOrientation(panel: any) {
    return (panel.width() > panel.height()) ? 'land' : 'port';
  }


  // V2 - Get Max allowed span of both Orientations from database
  async getBothMaxSpan() {
    return this.country_bool == 'us' ? this.usmaxspan() : this.canadamaxspan();
  }

  //span calculation for canada
  async canadamaxspan() {
    let orientation = this.getProjectOrientation() == 'land' ? '0' : '1';
    let maxSpan: any;
    const currentProject = this.projectService.getProject();
    let clip = currentProject.clip;
    this.projectService.setProject('zone', this.canadazone);
    const canadianZones = ['R', 'S', 'C'];
    const zeroSpanMsg = 'Your setup will result in zero span in at least one of the possible roof zones. Please change your project input!';
    try {
      for (let i = 0; i < 3; i++) {
        const canadianData = {
          snow: currentProject.snow, terr: currentProject.terr, wind: currentProject.speed, zone: canadianZones[i], pitch: currentProject.pitch,
          width: currentProject.width, length: currentProject.length, orientation, pv: currentProject.pv
        };
        if (orientation === '0') { // Landscape
          maxSpan = await this.maxSpanService.getMaxSpanLandCan(this.ROOF_ATTACHMENT, 'apex', canadianData, clip);
          if (this.ROOF_ATTACHMENT.toLocaleLowerCase().includes('rafter')) this.MAX_SPANS.land.R[i] = maxSpan[0] * 39.37;
          else this.MAX_SPANS.land.D[i] = maxSpan[0] * 39.37;
        } else if (orientation === '1') { // Portrait
          maxSpan = await this.maxSpanService.getMaxSpanPortCan(this.ROOF_ATTACHMENT, 'apex', canadianData, clip);
          if (this.ROOF_ATTACHMENT.toLocaleLowerCase().includes('rafter')) this.MAX_SPANS.port.R[i] = maxSpan[0] * 39.37;
          else this.MAX_SPANS.port.D[i] = maxSpan[0] * 39.37;
        }
      }

      if (this.MAX_SPANS.land.D.some(span => span == 0) || this.MAX_SPANS.land.R.some(span => span == 0)
        || this.MAX_SPANS.port.D.some(span => span == 0) || this.MAX_SPANS.port.R.some(span => span == 0)) {
        this.openSnackBar(zeroSpanMsg, 'OK', 'center', 'top', 10000);
        this.projectService.setProject('failed', true);
      }
      return this.MAX_SPANS;
    } catch (error) {
      console.log(error);
    }
  }

  async usmaxspan() {
    let rafterLand: any;
    let deckLand: any;
    let rafterPort: any;
    let deckPort: any;
    try {
      this.asterisk = this.projectService.getProject().asterisk;
      // Rafter-Deck-Rafter Combination is selected
      if (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter') {
        rafterLand = await this.maxSpanService.getMaxSpanLand(this.ROOF_ATTACHMENT.slice(0, 6), 'apex');
        rafterPort = await this.maxSpanService.getMaxSpanPort(this.ROOF_ATTACHMENT.slice(0, 6), 'apex');
        deckLand = (this.ROOF_ATTACHMENT.length > 8)
          ? await this.maxSpanService.getMaxSpanLand(this.ROOF_ATTACHMENT.slice(6), 'apex')
          : await this.maxSpanService.getMaxSpanLand('osb', 'apex');
        deckPort = (this.ROOF_ATTACHMENT.length > 8)
          ? await this.maxSpanService.getMaxSpanPort(this.ROOF_ATTACHMENT.slice(6), 'apex')
          : await this.maxSpanService.getMaxSpanPort('osb', 'apex');

        if (this.asterisk) {
          for (let i = 0; i < 3; i++) {
            // Rafter
            this.MAX_SPANS.land.R[i] = Number(rafterLand.spanSDST[i]);
            this.MAX_SPANS.port.R[i] = Number(rafterPort.spanSDST[i]);
            // Deck
            this.MAX_SPANS.land.D[i] = Number(deckLand.spanSDST[i]);
            this.MAX_SPANS.port.D[i] = Number(deckPort.spanSDST[i]);
            // Hybrid
            this.MAX_SPANS.land.RDR[i] = (Number(rafterLand.spanSDST[i]) + Number(deckLand.spanSDST[i])) / 2;
            this.MAX_SPANS.port.RDR[i] = (Number(rafterPort.spanSDST[i]) + Number(deckPort.spanSDST[i])) / 2;
          }

        } else {
          for (let i = 0; i < 3; i++) {
            // Rafter
            this.MAX_SPANS.land.R[i] = Number(rafterLand.span[i]);
            this.MAX_SPANS.port.R[i] = Number(rafterPort.span[i]);
            // Deck
            this.MAX_SPANS.land.D[i] = Number(deckLand.span[i]);
            this.MAX_SPANS.port.D[i] = Number(deckPort.span[i]);
            // Hybrid
            this.MAX_SPANS.land.RDR[i] = (Number(rafterLand.span[i]) + Number(deckLand.span[i])) / 2;
            this.MAX_SPANS.port.RDR[i] = (Number(rafterPort.span[i]) + Number(deckPort.span[i])) / 2;
          }
        }
      } else { // Deck
        deckLand = await this.maxSpanService.getMaxSpanLand(this.ROOF_ATTACHMENT, 'apex');
        deckPort = await this.maxSpanService.getMaxSpanPort(this.ROOF_ATTACHMENT, 'apex');

        if (this.asterisk) {
          for (let i = 0; i < 3; i++) {
            this.MAX_SPANS.land.D[i] = Number(deckLand.spanSDST[i]);
            this.MAX_SPANS.port.D[i] = Number(deckPort.spanSDST[i]);
          }
        } else {
          for (let i = 0; i < 3; i++) {
            this.MAX_SPANS.land.D[i] = Number(deckLand.span[i]);
            this.MAX_SPANS.port.D[i] = Number(deckPort.span[i]);
          }
        }
        return this.MAX_SPANS;
      }
    } catch (error) {
      console.log(error);
    }
  }

  // V2
  getWidthByOrientation(orientation: any) {
    return (orientation === 'land') ? this.projectService.getProject().length : this.projectService.getProject().width;
  }


  findPossibleClamps(maxSpan: any, panelLength: any) {
    let count = 2;
    if (maxSpan < (panelLength / 3)) {
      let covered = 3 * maxSpan;
      let remain = panelLength - covered;
      while (remain > maxSpan) {
        count++;
        covered += maxSpan;
        remain -= maxSpan;
      }
    }
    return count;
  }


  // find possible clamps according to Max Span with min clamps number
  findClampsBySpan(rect: any, count: any, maxSpan: any, width: any) {
    const clamps = [];
    const cornerSpan = (width - (maxSpan * (count - 1))) / 2;
    const ratioSpan = (count > 2) ? (maxSpan / width) : (1 / count);
    const ratioCorner = (count > 2) ? cornerSpan / width : (ratioSpan / 2);
    clamps[0] = { x: rect.x + (rect.width * ratioCorner), y: rect.y };
    for (let i = 1; i < (count * 2); i++) {
      if (i < count) { // panel top
        clamps[i] = { x: clamps[i - 1].x + (rect.width * ratioSpan), y: rect.y };
      } else { // panel bottom
        clamps[i] = { x: clamps[i - count].x, y: rect.y + rect.height };
      }
    }
    return clamps;
  }


  //  V2 - Clamps possible points on the corner panels
  findPossibleClampsSpliceSide(maxSpan: any, panelLength: any) {
    let count = 0;
    if (maxSpan < (panelLength * 0.67)) {
      const multiply = Math.floor(panelLength / maxSpan);
      const remainder = panelLength % maxSpan;
      if (remainder > (panelLength * 0.33)) {
        count = multiply + 1;
      } else { count = multiply; }
    } else { count = 1; }
    return count;
  }

  // V2 - Clamps possible points on middle panels
  findPossibleClampsSpliceMiddle(maxSpan: any, panelLength: any) {
    let count = 0;
    if (maxSpan < panelLength) {
      const multiply = Math.floor(panelLength / maxSpan);
      const remainder = panelLength % maxSpan;
      if (remainder === 0) {
        count = multiply - 1;
      } else if (remainder > maxSpan) {
        count = multiply + 1;
      } else { count = multiply; }
    } else { count = 0; }
    return count;
  }


  // V2 - Check if there are panels drawn in different Orientations
  differentOrientationInProject() {
    const allOrnts = new Set();
    this.designGroup.children.each(shape => {
      allOrnts.add(this.getPanelOrientation(shape));
    });
    return (allOrnts.size > 1) ? true : false;
  }


  // V2 - Find where differently Oriented Panles Share Attachmnets - i.e. close X and Y
  getLandPortJoinedClamps(landArr: any, portArr: any) {
    const sharedLand = landArr.filter(({ x: x1, y: y1 }) => portArr.some(({ x: x2, y: y2 }) =>
      Math.abs(y1 - y2) < this.WIDTH / 2 && Math.abs(y1 - y2) > this.SPACING.y && Math.abs(x1 - x2) < 3));
    const sharedPort = portArr.filter(({ x: x1, y: y1 }) => sharedLand.some(({ x: x2, y: y2 }) =>
      Math.abs(y1 - y2) < this.WIDTH / 2 && Math.abs(y1 - y2) > this.SPACING.y && Math.abs(x1 - x2) < 6));

    if (sharedPort.length > 0 || sharedLand.length > 0) {
      const LandYs = sharedLand.map(o => o.y).filter((v, i, a) => a.indexOf(v) === i);
      const PortYs = sharedPort.map(o => o.y).filter((v, i, a) => a.indexOf(v) === i);
      LandYs.sort(); PortYs.sort();
      const sharedYsCenter = [];
      for (let i = 0; i < LandYs.length; i++) {
        sharedYsCenter[i] = (LandYs[i] + PortYs[i]) / 2;
      }
      const remainPort = portArr.filter(({ x: x1, y: y1 }) =>
        !sharedPort.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
      const remainLand = landArr.filter(({ x: x1, y: y1 }) =>
        !sharedLand.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
      for (const y of sharedYsCenter) {
        sharedPort.forEach(pos => {
          if (Math.abs(pos.y - y) < 1) {
            pos.y = y;
          }
        });
        sharedLand.forEach(pos => {
          if (Math.abs(pos.y - y) < 1) {
            pos.y = y;
          }
        });
      }
      return { sharedPort, sharedLand, remainPort, remainLand };
    } else {
      return null;
    }
  }

  // V2 - Find All Possible splice Position and adjust the middle splices
  findAllSplicePos(panelsArr: any) {
    const allCorners = [];
    if (!panelsArr.length) {
      this.designGroup.children.each(shape => {
        panelsArr.push(shape);
      });
    }
    for (const panel of panelsArr) {
      allCorners.push(
        { x: panel.x(), y: panel.y() }, // top Left corner
        { x: panel.x() + panel.width(), y: panel.y() },  // Top Right corner
        { x: panel.x(), y: panel.y() + panel.height() },  // Bottom Left corner
        { x: panel.x() + panel.width(), y: panel.y() + panel.height() }  // Bottom Right corner
      );
    }
    const allSplicePos = [];
    const compare = (this.WIDTH + this.SPACING.x) / this.MAP_TO_PIXEL_PROPORTION; // Portrait is priority, so get the panel's width
	// Sort based on Y first, then X
    allCorners.sort((a, b) => (a.y > b.y) ? 1 : (a.y === b.y) ? ((a.x > b.x) ? 1 : -1) : -1);


   for (let i = 0; i < allCorners.length - 1; i++) {
      if (Math.abs(allCorners[i + 1].y - allCorners[i].y) < compare && Math.abs(allCorners[i + 1].x - allCorners[i].x) < compare) {
	   allSplicePos.push(this.getCenterPos(allCorners[i], allCorners[i + 1]));
      }
    }
    return this.calculateMidSplicePositions(allSplicePos.flat());

  }


  // V2 - find middle splice positions
  calculateMidSplicePositions(allSplicePos: any) {
    const intersected = [];
    for (const splice of allSplicePos) {
      const spliceBox = {
        x: splice.x - (4 * this.CLAMP_RATIO), y: splice.y - (2 * this.CLAMP_RATIO),
        width: (6 * this.CLAMP_RATIO), height: (6 * this.CLAMP_RATIO)
      };
      const intersectEachClamp = this.getPanelIntersection(spliceBox, 0);
      if (intersectEachClamp !== null) {
        intersected.push([splice, intersectEachClamp]);
      }
    }
    const allSplices = { end: [], mid: [] };
	
	
    for (const s of intersected) {
      (s[1].length > 2) ? allSplices.mid.push(s[0]) : allSplices.end.push(s[0]);
    }


	
    const clusters = this.clusterSplices(allSplices.mid);
	
   const clusterArr = [];
    for (const set of clusters) {
      const pair = [...set];
      clusterArr.push(this.getCenterPos(pair[0], pair[1]));
    }


	
    const midPosFinal = allSplices.mid.filter(({ x: x1, y: y1 }) =>
      !(clusters.flat()).some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    allSplices.mid = midPosFinal.concat([...clusterArr].flat());


	
    // Align all the splice positions in same row & set the Final Splice positions
    for (const splXPan of intersected) {
      if (allSplices.mid.indexOf(splXPan[0]) >= 0) {
        const newY = this.findPanelsMidY(splXPan[1]);
        splXPan[0].y = newY;
      }
    }
    return allSplices;
  }


  // V2 - Cluster data together based on their distance
  clusterSplices(spliceArr: any) {
    const map = new Map();
    const differX = 2 * this.CLAMP_RATIO;
    const differY = (2 * this.PANEL_STROKEWITH) + this.SPACING.y;
    for (let i = 0; i < spliceArr.length - 1; i++) {
      for (let j = 1; j < spliceArr.length; j++) {
        if (Math.abs(spliceArr[i].x - spliceArr[j].x) <= differX && Math.abs(spliceArr[i].y - spliceArr[j].y) <= differY &&
          spliceArr[i] !== spliceArr[j]) {
          if (!map.has(spliceArr[i]) && !map.has(spliceArr[j])) { map.set(spliceArr[i], spliceArr[j]); }
        }
      }
    }
    return [...map];
  }



  // V2 - Find Middle of the panels for intersected splices
  findPanelsMidY(panelsArr: any) {
    const setYs = new Set();
    let middleYs = 0;
    const compareY = 2 * this.PANEL_STROKEWITH + this.SPACING.y;
    for (const panel of panelsArr) { setYs.add(panel.y()); setYs.add(panel.y() + panel.height()); }
    let allYs = []; allYs = [...setYs]; allYs.sort();
    for (let i = 0; i < allYs.length - 1; i++) {
      if (allYs[i + 1] - allYs[i] < compareY) {
        middleYs = (allYs[i] + allYs[i + 1]) / 2;
      }
    }
    return middleYs;
  }


  showAllPossibleAttachments() {
    this.panelattbool = true;
    if (!this.ROOF_ATTACHMENT) {
      setTimeout(() => {
        const rafterEditMsg = 'It seems like you didn\'t select any roof attachment. ' +
          'Please go back and select the third form from the left side menu, then select a roof attachment from the list.';
        this.openSnackBar(rafterEditMsg, 'OK', 'center', 'top', 120000);
      }, 500);
    }
    this.getBothMaxSpan().then(() => {
      (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter') ? this.showAllPossibleRafterAttachments() : this.showAllPossibleDeckAttachments();
    });
  }


  // V2 - Find and draw correct attachments for Deck projects
  showAllPossibleDeckAttachments() {
    const allPanels = [];
    this.clampsFinal = { end: [], mid: [] };
    this.spliceFinal = { end: [], mid: [] };
    this.USE_SPLICE = Number(this.projectService.getProject().splice);
    if (!this.projectService.getProject().failed) {
      // Remove old clamping
      if (this.clampGroup.children.length > 0) {
        this.clampGroup.destroyChildren();
      }
      this.designGroup.find('Rect').each(shape => {
        allPanels.push(shape);
      });
      if (this.USE_SPLICE === 0) {  
        this.clampsFinal = this.findAllDeckClamps(allPanels);
	  }

      // If splice selected, check for allowance & calculate splice positions
      if (this.USE_SPLICE === 1) {
        this.spliceFinal = this.findAllSplicePos([]);
        this.drawSplicesbyType(this.USE_SPLICE);
        this.clampsFinal = this.adjustClampsWithSplice(allPanels);
      }
      this.drawClampsByType(this.clampsFinal, false);
      // undo update
      this.undoforchangeclamp();
    }
  }


  // V2 - Find All possible clamps positions
  findAllDeckClamps(panelsArr: any) {
    const allClampsPosLand = [];
    const allClampsPosPort = [];
    for (const panel of panelsArr) {
      // get width of each panel based on it's orientation
      const width = this.getWidthByOrientation(this.getPanelOrientation(panel));
      const maxSpan = (this.getPanelOrientation(panel) === 'land') ? this.MAX_SPANS.land.D[0] : this.MAX_SPANS.port.D[0];
      // Find clmaps count needed based on Max Span
      const clampsCount = (maxSpan <= (width / 2)) ? this.findPossibleClamps(maxSpan, width) : 2;
      // Draw clamps based on the count and max span
      (this.getPanelOrientation(panel) === 'land')
        ? allClampsPosLand.push(this.findClampsBySpan(panel.getAttrs(), clampsCount, maxSpan, width))
        : allClampsPosPort.push(this.findClampsBySpan(panel.getAttrs(), clampsCount, maxSpan, width));
    }
    const joinedClampsCategory = this.getLandPortJoinedClamps(allClampsPosLand.flat(), allClampsPosPort.flat());
    const allClampsPos = (joinedClampsCategory === null) ? allClampsPosLand.flat().concat(allClampsPosPort.flat())
      : joinedClampsCategory.remainLand.concat(joinedClampsCategory.remainPort).concat(joinedClampsCategory.sharedPort);

    allClampsPos.sort((a, b) => (a.x > b.x) ? 1 : (a.x === b.x) ? ((a.y > b.y) ? 1 : -1) : -1);
    const midClamps = this.calculateMidClampsPositions(allClampsPos);
    return {
      mid: midClamps.mid.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i),
      end: midClamps.end.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i)
    };
  }


  // V2 - Adjust extra clamping with splice
  adjustClampsWithSplice(panelsArr: any) {
    const allClampsPos = { land: [], port: [] };
    for (const panel of panelsArr) {
      const panelBox = panel.getClientRect({ skipStroke: false });
      const splices = this.getClampIntersectionByShape(panelBox, false);
      const corner = this.categorizeSplices(panel, splices);

      this.findClampsPosBySplices(allClampsPos, panel, corner.tl, corner.tm, corner.tr, true);
      this.findClampsPosBySplices(allClampsPos, panel, corner.bl, corner.bm, corner.br, false);
    }

    const joinedClampsCategory = this.getLandPortJoinedClamps(allClampsPos.land, allClampsPos.port);
    const possibleClampsPos = (joinedClampsCategory === null) ? allClampsPos.land.flat().concat(allClampsPos.port.flat())
      : joinedClampsCategory.remainLand.concat(joinedClampsCategory.remainPort).concat(joinedClampsCategory.sharedPort);
    possibleClampsPos.sort((a, b) => (a.x > b.x) ? 1 : (a.x === b.x) ? ((a.y > b.y) ? 1 : -1) : -1);
    const midClampsCalculated = this.calculateMidClampsPositions(possibleClampsPos);
    return { // remove duplicates
      mid: midClampsCalculated.mid.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i),
      end: midClampsCalculated.end.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i)
    };
  }


  // Categorize the splices around a splice
  categorizeSplices(panel: any, splicesArr: any) {
    const category = { tl: [], tr: [], bl: [], br: [], tm: [], bm: [] };
    const left = {
      x: panel.x() + (panel.width() * 0.3),
      y: panel.y() + (panel.height() * 0.5)
    };
    const right = {
      x: panel.x() + (panel.width() * 0.58),
      y: panel.y() + (panel.height() * 0.5)
    };
    for (const splice of splicesArr) {
      if (splice.x < left.x && splice.y < left.y) { category.tl.push(splice); }
      if (splice.x < left.x && splice.y > left.y) { category.bl.push(splice); }
      if (splice.x > right.x && splice.y < right.y) { category.tr.push(splice); }
      if (splice.x > right.x && splice.y > right.y) { category.br.push(splice); }
      if (splice.x >= left.x && splice.x <= right.x) {
        (splice.y > left.y) ? category.bm.push(splice) : category.tm.push(splice);
      }
    }
    return category;
  }


  // V2 - Find Clamps position for splices according to max span
  findClampsPosBySplices(clampsPos: any, panel: any, left: any, midle: any, right: any, top: boolean) {
    let pos = { x: 0, y: 0 };
    let clampsCount = 0;
    let ratio = 0;
    const ornt = this.getPanelOrientation(panel);
    const width = this.getWidthByOrientation(ornt);
    const maxSpan = (this.getPanelOrientation(panel) === 'land') ? this.MAX_SPANS.land.D[0] : this.MAX_SPANS.port.D[0];

    // Left Side empty
    if (left.length === 0 && right.length > 0) {
      clampsCount = this.findPossibleClampsSpliceSide(maxSpan, width);
      ratio = (clampsCount > 1) ? 1 / (clampsCount + 1) : 0.25;
      for (let i = 1; i <= clampsCount; i++) {
        pos = (top) ? { x: panel.x() + (panel.width() * (ratio * i)), y: panel.y() }
          : { x: panel.x() + (panel.width() * (ratio * i)), y: panel.y() + panel.height() };
        if (midle.length) {
          for (const s of midle) {
            if (pos.x > s.x + this.CLAMP_RATIO_DIM.x && pos.x < s.x - this.CLAMP_RATIO_DIM.x) {
              (ornt === 'land') ? clampsPos.land.push(pos) : clampsPos.port.push(pos);
            }
          }
        } else { (ornt === 'land') ? clampsPos.land.push(pos) : clampsPos.port.push(pos); }
      }
    }
    // Right side empty
    if (right.length === 0 && left.length > 0) {
      clampsCount = this.findPossibleClampsSpliceSide(maxSpan, width);
      ratio = (clampsCount > 1) ? 1 / (clampsCount + 1) : 0.25;
      for (let i = 1; i <= clampsCount; i++) {
        pos = (top) ? { x: panel.x() + (panel.width() * (1 - (ratio * i))), y: panel.y() }
          : { x: panel.x() + (panel.width() * (1 - (ratio * i))), y: panel.y() + panel.height() };
        if (midle.length) {
          for (const s of midle) {
            if (pos.x > s.x + this.CLAMP_RATIO_DIM.x && pos.x < s.x - this.CLAMP_RATIO_DIM.x) {
              (ornt === 'land') ? clampsPos.land.push(pos) : clampsPos.port.push(pos);
            }
          }
        } else { (ornt === 'land') ? clampsPos.land.push(pos) : clampsPos.port.push(pos); }
      }
    }
    // Middle Panels
    if (left.length > 0 && right.length > 0) {
      clampsCount = this.findPossibleClampsSpliceMiddle(maxSpan, width);
      ratio = 1 / (clampsCount + 1);
      for (let i = 1; i <= clampsCount; i++) {
        pos = (top) ? { x: panel.x() + (panel.width() * (ratio * i)), y: panel.y() }
          : { x: panel.x() + (panel.width() * (ratio * i)), y: panel.y() + panel.height() };
        if (midle.length) {
          for (const s of midle) {
            if (pos.x > s.x + this.CLAMP_RATIO_CENTER.x || pos.x < s.x - this.CLAMP_RATIO_CENTER.x) {
              (ornt === 'land') ? clampsPos.land.push(pos) : clampsPos.port.push(pos);
            }
          }
        } else { (ornt === 'land') ? clampsPos.land.push(pos) : clampsPos.port.push(pos); }
      }
    }
    // No splice on either side
    if (left.length === 0 && right.length === 0) {
      clampsCount = (maxSpan <= (width / 2)) ? this.findPossibleClamps(maxSpan, width) : 2;
      const cornerSpan = (width - (maxSpan * (clampsCount - 1))) / 2;
      const ratioSpan = (clampsCount > 2) ? (maxSpan / width) : (1 / clampsCount);
      const ratioCorner = (clampsCount > 2) ? cornerSpan / width : (ratioSpan / 2);
      for (let i = 0; i < clampsCount; i++) {
        ratio = (i === 0) ? ratioCorner : (i === clampsCount - 1) ? 1 - ratioCorner : (ratioSpan * i) + ratioCorner;
        pos = (top) ? { x: panel.x() + (panel.width() * ratio), y: panel.y() }
          : { x: panel.x() + (panel.width() * ratio), y: panel.y() + panel.height() };
        if (!midle.length) { (ornt === 'land') ? clampsPos.land.push(pos) : clampsPos.port.push(pos); }
      }
    }
  }



  // V2 - Find Center Point between splices
  getInterSplicesPos(splices: any, width: any) {
    const centerPos = [];
    // Sort based on Y first, then X
    splices.sort((a, b) => (a.y > b.y) ? 1 : (a.y === b.y) ? ((a.x > b.x) ? 1 : -1) : -1);
    for (let i = 0; i < splices.length - 1; i++) {
      if (splices[i].y === splices[i + 1].y && Math.abs(splices[i + 1].x - splices[i].x) <= width) {
        centerPos.push(this.getCenterPos(splices[i], splices[i + 1]));
      }
    }
    return centerPos;
  }


  // Find a center position for the midClamps to draw the related shape
  findCenterPos(posList: any) {
    const centerPos = [];
    posList.forEach((element: any) => {
      centerPos.push({
        x: (element[0].x + element[1].x) / 2,
        y: (element[0].y + element[1].y) / 2
      });
    });
    return centerPos;
  }


  // Delete Clamps that falls on Splices for Deck projects
  deleteClampsOnDeckSplice(clampsArr: any, splicesArr: any) {
    const extraClamp = { end: new Set(), mid: new Set() };
    for (const splice of splicesArr.end) {
      const spliceBox = {
        x: splice.x - this.CLAMP_RATIO_CENTER.x, y: splice.y - this.CLAMP_RATIO_CENTER.y,
        width: this.CLAMP_RATIO_DIM.x, height: this.CLAMP_RATIO_DIM.y
      };
      for (const clamp of clampsArr.end) {
        const clampBox = { x: clamp.x - 0.5, y: clamp.y - 0.5, width: 1, height: 1 };
        if (this.haveIntersection(clampBox, spliceBox)) { extraClamp.end.add(clamp); }
      }
    }

    for (const splice of splicesArr.mid) {
      const spliceBox = {
        x: splice.x - this.CLAMP_RATIO_CENTER.x, y: splice.y - this.CLAMP_RATIO_CENTER.y,
        width: this.CLAMP_RATIO_DIM.x, height: this.CLAMP_RATIO_DIM.y
      };
      for (const clamp of clampsArr.mid) {
        const clampBox = { x: clamp.x, y: clamp.y, width: this.CLAMP_RATIO, height: this.CLAMP_RATIO };
        if (this.haveIntersection(clampBox, spliceBox)) { extraClamp.mid.add(clamp); }
      }
    }

    return {
      end: clampsArr.end.filter(({ x: x1, y: y1 }) => ![...extraClamp.end].some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2)),
      mid: clampsArr.mid.filter(({ x: x1, y: y1 }) => ![...extraClamp.mid].some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2))
    };
  }


  // Add rafter lines
  addRafterLinesReport(start: any, groupWidth: any, groupHeight: any) {
    let index = start.x - (3 * this.RAFTER_SPACE);
    while (index <= start.x + groupWidth + (3 * this.RAFTER_SPACE)) {
      const line = this.panelService.createGridLines(index, start.y - (4 * this.stage.scaleX()),
        index, start.y + groupHeight + (4 * this.stage.scaleX()));
      line.dash([1, 1.5]);
      this.designGroup.add(line);
      line.moveToBottom();
      index += this.RAFTER_SPACE;
    }
    this.designLayer.batchDraw();
  }


  showAllPossibleRafterAttachments() {
    let rafterSplices = { end: [], mid: [] };
    this.clampsFinal = { end: [], mid: [] };
    this.spliceFinal = { end: [], mid: [] };
    this.spliceFloatFinal = { end: [], mid: [] };
    // Everything has been reset -> Reset the min4 checkpoint
    this.projectService.setProject('failed', true);
    // Remove old clamping
    if (this.clampGroup.children.length > 0) {
      this.clampGroup.destroyChildren();
    }
    // reset project design submmission, if any
    this.resetDesignCheck();
    // Draw new Clamping
    if (this.totalPanels > 0) {
      // Find all possible clamps on Rafter
      this.clampsFinal = (this.static > 0) ? this.findAllRafterClampsStaticClamping() : this.findAllRafterClamps();
      this.USE_SPLICE = Number(this.projectService.getProject().splice);
      if (this.USE_SPLICE > 0) {
        // Using Splice selected -> find & show all possible Splice positions
        if (this.USE_SPLICE === 1) {
          this.spliceFinal = this.findAllSplicePos([]);
		}
        if (this.USE_SPLICE === 2) {
          this.spliceFloatFinal = this.findAllSplicePos([]);
          if (this.projectService.getProject().eave === '1') {
            const fSpliceEave = this.checkFloatingSplicesOnEave(this.spliceFloatFinal.end);
            this.spliceFloatFinal.end = fSpliceEave.f;
            this.spliceFinal.end = fSpliceEave.r;
          }
        }
        rafterSplices = this.checkSplicesOnRafter();
		this.drawSplicesbyType(this.USE_SPLICE, rafterSplices);
        // Delete Clamps that falls on Rafter Splices
        this.deleteClampsOnRafterSplice(rafterSplices);
      }

      // Draw all possible clamps on Rafter
      this.drawClampsByType(this.clampsFinal, true);
      // Check for Extra clamping on Hybrid Portrait with NO Splice
      if (this.USE_SPLICE === 0 && this.ROOF_ATTACHMENT.length > 8) {
        const extraDeckClamps = this.findExtraDeckClampsPortHybrid(this.clampsFinal);
        // Draw all extra clamps on Deck
        this.drawClampsByType(extraDeckClamps, false);
      }

      setTimeout(() => {
        const rafterEditMsg = 'Please start editing the panel attachments to your desire. ';
        this.openSnackBar(rafterEditMsg, 'OK', 'center', 'top', 120000);
      }, 500);

      // Undo code
      this.undoforchangeclamp();
    } else {
      setTimeout(() => {
        const rafterEditMsg = 'Please draw some panels to be able to see the attachments. ';
        this.openSnackBar(rafterEditMsg, 'OK', 'center', 'top', 120000);
        this.saveRafterClampingDB();
      }, 500);
    }
  }


  // Find Splices that fall on Rafter lines
  checkSplicesOnRafter() {
    const rafterSplices = { mid: [], end: [] };
    for (const pos of this.spliceFloatFinal.end) {
      if (this.haveRafterIntersection(pos)) {
        rafterSplices.end.push(pos);
      }
    }
    for (const pos of this.spliceFinal.end) {
      if (this.haveRafterIntersection(pos)) {
        rafterSplices.end.push(pos);
      }
    }
    for (const pos of this.spliceFloatFinal.mid) {
      if (this.haveRafterIntersection(pos)) {
        rafterSplices.mid.push(pos);
      }
    }
    for (const pos of this.spliceFinal.mid) {
      if (this.haveRafterIntersection(pos)) {
        rafterSplices.mid.push(pos);
      }
    }

    this.spliceFloatFinal.mid = this.spliceFloatFinal.mid.filter(({ x: x1, y: y1 }) =>
      !rafterSplices.mid.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    this.spliceFloatFinal.end = this.spliceFloatFinal.end.filter(({ x: x1, y: y1 }) =>
      !rafterSplices.end.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    this.spliceFinal.end = this.spliceFinal.end.filter(({ x: x1, y: y1 }) =>
      !rafterSplices.end.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    this.spliceFinal.mid = this.spliceFinal.mid.filter(({ x: x1, y: y1 }) =>
      !rafterSplices.mid.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));

    return rafterSplices;
  }


  // Delete Clamps that falls on Rafter Splices
  deleteClampsOnRafterSplice(splices: any) {
    this.clampsFinal.end = this.clampsFinal.end.filter(({ x: x1, y: y1 }) =>
      !(splices.end).flat().some(({ x: x2, y: y2 }) => Math.abs(x1 - x2) < (2 * this.PANEL_STROKEWITH + this.SPACING.x) &&
        Math.abs(y1 - y2) < (2 * this.PANEL_STROKEWITH + this.SPACING.y)));

    this.clampsFinal.end = this.clampsFinal.end.filter(({ x: x1, y: y1 }) =>
      !(splices.mid).flat().some(({ x: x2, y: y2 }) => Math.abs(x1 - x2) < (2 * this.PANEL_STROKEWITH + this.SPACING.x) &&
        Math.abs(y1 - y2) < (2 * this.PANEL_STROKEWITH + this.SPACING.y)));

    this.clampsFinal.mid = this.clampsFinal.mid.filter(({ x: x1, y: y1 }) =>
      !(splices.mid).flat().some(({ x: x2, y: y2 }) => Math.abs(x1 - x2) < (2 * this.PANEL_STROKEWITH + this.SPACING.x) &&
        Math.abs(y1 - y2) < (2 * this.PANEL_STROKEWITH + this.SPACING.y)));

    this.clampsFinal.mid = this.clampsFinal.mid.filter(({ x: x1, y: y1 }) =>
      !(splices.end).flat().some(({ x: x2, y: y2 }) => Math.abs(x1 - x2) < (2 * this.PANEL_STROKEWITH + this.SPACING.x) &&
        Math.abs(y1 - y2) < (2 * this.PANEL_STROKEWITH + this.SPACING.y)));
  }


  // Find Extra Deck clamps if no splice used on portrait
  findExtraDeckClampsPortHybrid(clampsArr: any) {
    const portClamps = { mid: [], end: [] };
    const extraClamps = { mid: [], end: [] };
    for (const pos of clampsArr.end) { if (this.getOrientationPanelIntersection(pos) === 'port') { portClamps.end.push(pos); } }
    for (const pos of clampsArr.mid) { if (this.getOrientationPanelIntersection(pos) === 'port') { portClamps.mid.push(pos); } }
    portClamps.end.sort((a, b) => (a.y > b.y) ? 1 : (a.y === b.y) ? ((a.x > b.x) ? 1 : -1) : -1);
    portClamps.mid.sort((a, b) => (a.y > b.y) ? 1 : (a.y === b.y) ? ((a.x > b.x) ? 1 : -1) : -1);
    for (let i = 0; i < portClamps.end.length - 1; i++) {
      if (portClamps.end[i].y === portClamps.end[i + 1].y && Math.abs(portClamps.end[i].x - portClamps.end[i + 1].x) < this.WIDTH) {
        extraClamps.end.push(this.getCenterPos(portClamps.end[i], portClamps.end[i + 1]));
      }
    }
    for (let i = 0; i < portClamps.mid.length - 1; i++) {
      if (portClamps.mid[i].y === portClamps.mid[i + 1].y && Math.abs(portClamps.mid[i].x - portClamps.mid[i + 1].x) < this.WIDTH) {
        extraClamps.mid.push(this.getCenterPos(portClamps.mid[i], portClamps.mid[i + 1]));
      }
    }
    return extraClamps;
  }


  // Find all Side Panels
  isSidePanels(pos: any) {
    const posBox = { x: pos.x, y: pos.y, width: this.CLAMP_RATIO, height: this.CLAMP_RATIO };
    const panel = this.getPanelIntersection(posBox, 0);
    const sideL = {
      x: panel[0].x() - (panel[0].width() * 0.5), y: panel[0].y() + (panel[0].height() * 0.5),
      width: this.CLAMP_RATIO, height: this.CLAMP_RATIO
    };
    const sideR = {
      x: panel[0].x() + (panel[0].width() * 1.5), y: panel[0].y() + (panel[0].height() * 0.5),
      width: this.CLAMP_RATIO, height: this.CLAMP_RATIO
    };
    const shapeL = this.getPanelIntersection(sideL, 0);
    const shapeR = this.getPanelIntersection(sideR, 0);
    if (!shapeL.length || !shapeR.length) {
      return true;
    } else { return false; }
  }


  // NEW
  // Find Eave rows from end clamps positions
  findEaveRowsAttachments(spliceArr: any) {
    // Find if there exist any panel with half of pixel Width below of any end splice =>
    // If not, then that's eave row
    const intersected = [];
    for (const pos of spliceArr) {
      if (this.findEaveRowSplice(pos).length) { intersected.push(pos); }
    }
    const bottoms = spliceArr.filter(({ x: x1, y: y1 }) => !intersected.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    return bottoms;
  }


  findEaveRowSplice(splice: any) {
    const intersected = [];
    this.designGroup.children.each((panel: any) => {
      const spliceBoxBottom = {
        x: splice.x, y: splice.y + (this.WIDTH / 2),
        width: this.CLAMP_RATIO, height: this.CLAMP_RATIO
      };
      const panelBox = { x: panel.x(), y: panel.y(), width: panel.width(), height: panel.height() };
      if (this.haveIntersection(spliceBoxBottom, panelBox)) {
        intersected.push(splice);
      }
    });
    return intersected;
  }


  // V2 - Find splices on eave row
  checkFloatingSplicesOnEave(splicesArr: any) {
    const splices = { f: [], r: [] };
    // Get all eave side splices
    const eavesSplices = this.findEaveRowsAttachments(splicesArr);
    // Filter out the eave side end splices from Floating End Splice
    splices.f = splicesArr.filter(({ x: x1, y: y1 }) =>
      !eavesSplices.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    splices.r = splicesArr.filter(({ x: x1, y: y1 }) =>
      eavesSplices.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    return splices;
  }


  getFirstEndClamp() {
    const allClamps = [];
    // Find Top Left Clamp
    this.designLayer.find('.clamp').each(shape => {
      allClamps.push({ x: shape.getAttrs().x, y: shape.getAttrs().y });
    });
    allClamps.sort((a, b) => (a.y > b.y) ? 1 : (a.y === b.y) ? ((a.x < b.x) ? 1 : -1) : -1);
    return allClamps[0];
  }


  // V2 - Static Clamping: Find Clamps at specific spacing
  findAllRafterClampsStaticClamping() {
    const allClampsPos = this.findAllRafterClamps();
    // Starting Point => Find Bottom left clamp position
    allClampsPos.end.sort((a, b) => (a.x > b.x) ? 1 : (a.x === b.x) ? ((a.y < b.y) ? 1 : -1) : -1);
    const startClamp = allClampsPos.end[0];
    const staticClamps = { mid: [], end: [] };
    const staticPix = this.static / this.MAP_TO_PIXEL_PROPORTION;
    allClampsPos.end.forEach((clamp: any) => {
      if (Math.abs(clamp.x - startClamp.x) % staticPix === 0) { staticClamps.end.push(clamp); }
    });
    allClampsPos.mid.forEach((clamp: any) => {
      if (Math.abs(clamp.x - startClamp.x) % staticPix === 0) { staticClamps.mid.push(clamp); }
    });
    return staticClamps;
  }


  findAllRafterClamps() {
    const allClampsPos = [];
    this.designGroup.children.each(shape => {
      allClampsPos.push(this.findRafterClampsByPanel(shape.getAttrs()));
    });
    // Remove duplicates
    const allClampsPosFinal = allClampsPos.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i);
    return this.calculateRafterMidClampsPositions(allClampsPosFinal);
  }


  findRafterClampsByPanel(rect: any) {
    const clamps = [];
    const allRafterXs = [];
    this.gridGroup.find('Line').each((line: any) => {
      const rafterX = line.getAttrs().points[0] + this.RAFTER_DRAG;
      if (rafterX >= (rect.x - this.PANEL_STROKEWITH) && rafterX <= (rect.x + rect.width + this.PANEL_STROKEWITH)) {
        allRafterXs.push(rafterX);
      }
    });
    for (const Xs of allRafterXs) {
      clamps.push({ x: Xs, y: rect.y });
      clamps.push({ x: Xs, y: rect.y + rect.height });
    }
    return clamps;
  }


  // V3 - Categorize all clamping based on their type
  getClampsCategorized(rdr: boolean) {
    const allClampsPos = [];
    this.clampGroup.find('.clamp').each((clamp: any) => {
      if (clamp.fill() === 'white') {
        if (clamp.stroke() === 'green' && rdr) { allClampsPos.push({ x: clamp.x(), y: clamp.y(), t: 'd', c: 'end' }); }
        if (clamp.stroke() === 'orangered' && rdr) { allClampsPos.push({ x: clamp.x(), y: clamp.y(), t: 'd', c: 'mid' }); }
      } else {
        if (clamp.stroke() === 'green') { allClampsPos.push({ x: clamp.x(), y: clamp.y(), t: 'r', c: 'end' }); }
        if (clamp.stroke() === 'orangered') { allClampsPos.push({ x: clamp.x(), y: clamp.y(), t: 'r', c: 'mid' }); }
      }
    });
    return allClampsPos;
  }

  // V3 - Categorize all splices based on their type
  getSplicesCategorized(rdr: boolean, eave: boolean) {
    const allSplicesPos = [];
    this.clampGroup.find('.splice').each((splice: any) => {
      const pos = { x: splice.x() + this.CLAMP_RATIO_CENTER.x, y: splice.y() + this.CLAMP_RATIO_CENTER.y };
      if (splice.fill() === 'white') {
        if (splice.stroke() === 'green') {
          const spl = { x: pos.x, y: pos.y, t: 'd', c: 'end' };
          // Check if it's on eave if eave == true; otherwise check rdr == true, then push
          if (!this.findEaveRowSplice(pos).length) {
            if (eave) { allSplicesPos.push(spl); }
          } else if (rdr) { allSplicesPos.push(spl); }
        }
        if (splice.stroke() === 'orangered') {
          const spl = { x: pos.x, y: pos.y, t: 'd', c: 'mid' };
          if (!this.findEaveRowSplice(pos).length) {
            if (eave) { allSplicesPos.push(spl); }
          } else if (rdr) { allSplicesPos.push(spl); }
        }
      } else {
        if (splice.stroke() === 'white') {
          // if (splice.fill() === 'yellowgreen' && rdr) { allSplicesPos.push({ x: pos.x, y: pos.y, t: 'd', c: 'end' }); }
          // if (splice.fill() === 'lightsalmon' && rdr) { allSplicesPos.push({ x: pos.x, y: pos.y, t: 'd', c: 'mid' }); }
        } else {
          if (splice.stroke() === 'green') { allSplicesPos.push({ x: pos.x, y: pos.y, t: 'r', c: 'end' }); }
          if (splice.stroke() === 'orangered') { allSplicesPos.push({ x: pos.x, y: pos.y, t: 'r', c: 'mid' }); }
        }
      }
    });
    return allSplicesPos;
  }


  // V2 - Get all the clamping positions, categorize by each row, then sort by x pos ascending
  getClampingCategorized(rdr: boolean) {
    let allAttachPos = [];
    const eaveRD = (this.projectService.getProject().eave === '0') ? true : false;

    const allClamps = this.getClampsCategorized(rdr);
    const allSplices = this.getSplicesCategorized(rdr, eaveRD);
    allAttachPos = allClamps.concat(allSplices);

    const clampByYMap = new Map();
    for (const attach of allAttachPos) {
      clampByYMap.set(attach.y, (clampByYMap.get(attach.y) || []).concat(attach));
    }

    const allYs = Array.from(clampByYMap.keys());
    const allClampsPosSortedY = new Array(allYs.length).fill([]);
    for (let i = 0; i < allYs.length; i++) {
      allClampsPosSortedY[i] = allAttachPos.filter(pos => Math.abs(pos.y - allYs[i]) < this.PANEL_STROKEWITH);
    }
    return allClampsPosSortedY;
  }


  getMaxZonefromPoints(pt1: any, pt2: any) {
    const allPanels = new Set();
    let allZones = [];
    const pt1Box = {
      x: pt1.x - this.PANEL_STROKEWITH, y: pt1.y - this.CLAMP_RATIO,
      width: (2 * this.PANEL_STROKEWITH), height: (2 * this.CLAMP_RATIO)
    };
    // Find panels that fall in the point 1
    const panels1 = this.getPanelIntersection(pt1Box, this.PANEL_STROKEWITH);
    const pt2Box = {
      x: pt2.x - this.PANEL_STROKEWITH, y: pt2.y - this.CLAMP_RATIO,
      width: (2 * this.PANEL_STROKEWITH), height: (2 * this.CLAMP_RATIO)
    };
    // Find panels that fall in the point 2
    const panels2 = this.getPanelIntersection(pt2Box, this.PANEL_STROKEWITH);
    for (const p of panels1) { allPanels.add(p); }
    for (const p of panels2) { allPanels.add(p); }
    allZones = this.getProjectZones([...allPanels]);
    return Math.max(...allZones);
  }


  // V2
  async checkRafterPosMaxSpan() {
    const conflicts = [];
    const RDR = (this.ROOF_ATTACHMENT.length > 8) ? true : false;
    const allClampsSorted = this.getClampingCategorized(RDR);
    // Check max span allowance
    for (const row of allClampsSorted) {
      row.sort((a, b) => a.x - b.x);
      // console.log(row);
      for (let i = 0; i < row.length - 1; i++) {
        const distance = Math.round((row[i + 1].x - row[i].x) * this.MAP_TO_PIXEL_PROPORTION);
        const k = (row[i + 1].t !== row[i].t) ? 2 : (row[i].t === 'r') ? 0 : 1;
        let newSpan = 0;
        const zone = this.getMaxZonefromPoints(row[i], row[i + 1]) - 1;
        if (this.getOrientationPanelIntersection(row[i + 1]) === 'land'
          || this.getOrientationPanelIntersection(row[i]) === 'land') {
          newSpan = (k === 2) ? this.MAX_SPANS.land.RDR[zone] : (k === 1) ? this.MAX_SPANS.land.D[zone] : this.MAX_SPANS.land.R[zone];
        } else {
          newSpan = (k === 2) ? this.MAX_SPANS.port.RDR[zone] : (k === 1) ? this.MAX_SPANS.port.D[zone] : this.MAX_SPANS.port.R[zone];
        }
        // console.log(distance, newSpan, distance > newSpan);
        if (distance > newSpan) {
          // Check if there exists panel(s) in middle of two points btween two points with same y
          const middlePoint = {
            x: (row[i + 1].x + row[i].x) / 2,
            y: (row[i + 1].y + row[i].y) / 2,
            width: this.CLAMP_RATIO, height: this.CLAMP_RATIO
          };
          const conflictMiddle = this.getPanelIntersection(middlePoint, this.PANEL_STROKEWITH);
          if (conflictMiddle.length > 0 && row[i].y === row[i + 1].y) {
            conflicts.push([row[i + 1], row[i]]);
          }
          // Check if those conflict points are intersecting with panels that are next to each other
          // const conflictBox1 = {
          //   x: row[i].x - this.CLAMP_RATIO_CENTER.x, y: row[i].y - this.CLAMP_RATIO_CENTER.y,
          //   width: this.CLAMP_RATIO, height: this.CLAMP_RATIO
          // };
          // const conflictBox2 = {
          //   x: row[i + 1].x - this.CLAMP_RATIO_CENTER.x, y: row[i + 1].y - this.CLAMP_RATIO_CENTER.y,
          //   width: this.CLAMP_RATIO, height: this.CLAMP_RATIO
          // };
          // const conflictPanels1 = this.getPanelIntersection(conflictBox1, this.PANEL_STROKEWITH);
          // const conflictPanels2 = this.getPanelIntersection(conflictBox2, this.PANEL_STROKEWITH);
          // for (const a of conflictPanels1) {
          //   for (const b of conflictPanels2) {
          //     if (Math.abs(a.x() - b.x()) < a.width() + b.width() + this.SPACING.x) { conflicts.push([row[i + 1], row[i]]); }
          //   }
          // }
        }
      }
    }
    return conflicts.flat();
  }


  // Find the center Position when given two points
  getCenterPos(pos1: any, pos2: any) {
    return { x: (pos1.x + pos2.x) / 2, y: (pos1.y + pos2.y) / 2 };
  }


  // Find the panel's zone of a given clamp on it
  findPanelZone(panel: any) {
    let zone = 1;
    const panelColor = panel.fill();
    switch (panelColor) {
      case '#6495ed':
        zone = 1;
        return zone;
      case '#ffa500':
        zone = 2;
        return zone;
      case '#ff0000':
        zone = 3;
        return zone;
      default:
        zone = 1;
        return zone;
    }
  }


  // Check for a given panel, the intersected attachments in clampGroup; return the intersected attachment(s) id or empty array
  getClampIntersectionByShape(shape: any, notCountingFloatingSplices: boolean) {
	const intersected = [];
    this.clampGroup.children.each((attach: any) => {
      const boundingBox = attach.getClientRect();
      if (notCountingFloatingSplices) {
        if (this.haveIntersection(shape, boundingBox) && attach.stroke() !== 'white') {
		  intersected.push(attach.getAttrs());
        }
      } else {
        if (this.haveIntersection(shape, boundingBox)) {
          intersected.push(attach.getAttrs());
        }
      }
    });
    return intersected;
  }


  // Check for a given pos, the intersected attachments in clampGroup; return the intersected attachment(s) or empty array
  getClampIntersectionByPos(posBox: any) {
    const intersected = [];
    this.clampGroup.children.each((attach: any) => {
      const attachBox = attach.getClientRect({ skipStroke: false });
      if (this.haveIntersection(posBox, attachBox)) {
        intersected.push(attach.getAttrs());
      }
    });
    return intersected;
  }


  // Get the intersected panel(s) in designGroup for a given pointBox; return the intersected panel(s) orientations or empty array
  getPanelIntersection(clampBox: any, stroke: any) {
    const intersected = [];
    this.designGroup.children.each((panel: any) => {
      const panelBox = {
        x: panel.x() - stroke, y: panel.y() - stroke,
        width: panel.width() + stroke, height: panel.height() + stroke
      };
      if (this.haveIntersection(clampBox, panelBox)) {
        intersected.push(panel);
      }
    });
    return intersected;
  }


  // Check for a given attachment pos, the intersected panels in designGroup; return the intersected panel(s) orientations or empty array
  getOrientationPanelIntersection(pos: any) {
    const intersected = [];
    this.designGroup.children.each((panel: any) => {
      const clampBox = { x: pos.x, y: pos.y, width: this.CLAMP_RATIO_DIM.x, height: this.CLAMP_RATIO_DIM.y };
      const panelBox = { x: panel.x(), y: panel.y(), width: panel.width(), height: panel.height() };
      if (this.haveIntersection(clampBox, panelBox)) {
        intersected.push(this.getPanelOrientation(panel));
      }
    });
    // If any of the orientations of the intersected panel(s) are Portrait, consider it portrait, otherwise landscape
    return intersected.find(x => x === 'port') ? 'port' : 'land';
  }


  // Check if there is min. 1 attachment for each panel's side
  checkPanelSideAttachments(left: any, right: any, clampArr: any) {
    const topLeft = []; const topRight = []; const bottomLeft = []; const bottomRight = [];
    for (const clamp of clampArr) {
      if (clamp.x < left.x && clamp.y < left.y) { topLeft.push(clamp); }
      if (clamp.x < left.x && clamp.y > left.y) { bottomLeft.push(clamp); }
      if (clamp.x > right.x && clamp.y < right.y) { topRight.push(clamp); }
      if (clamp.x > right.x && clamp.y > right.y) { bottomRight.push(clamp); }
    }
    return (topLeft.length > 0 && topRight.length > 0 && bottomLeft.length > 0 && bottomRight.length > 0) ? true : false;
  }

  // Check if there is min. 1 attachment on panel's top & bottom
  checkPanelOneSideAttachments(panel: any, clampArr: any) {
    const top = []; const bottom = [];
    for (const clamp of clampArr) {
      if (clamp.x > panel.x() && clamp.x < panel.x() + panel.width() && clamp.y < panel.y() + (panel.height() * 0.5)) { top.push(clamp); }
      if (clamp.x > panel.x() && clamp.x < panel.x() + panel.width() && clamp.y > panel.y() + (panel.height() * 0.5)) { bottom.push(clamp); }
    }
    return (top.length > 0 && bottom.length > 0) ? true : false;
  }


  // Loop through all drawn polygons and check how many attachments each panel has that connects to the roof (deck & rafter only, no floating splices)
  checkMin2PtRoofConnections() {
    const clampIntersects = [];
    const minNotMet = [];
    this.designGroup.children.each((panel: any) => {
	  const panelBox = panel.getClientRect({ skipStroke: false });
      clampIntersects.push([panel, this.getClampIntersectionByShape(panelBox, false)]);
    });
    for (const panel of clampIntersects) {
      if (panel[1].length < 2) {
        minNotMet.push(panel[0]);
      } else {
        // Check the location of the attachments
        if (!this.checkPanelOneSideAttachments(panel[0], panel[1])) {
          minNotMet.push(panel[0]);
        }
      }
    }
    return minNotMet;
  }


  // Loop through all drawn polygons and check how many attachments each panel has
  checkMin4PtAttachments() {
    const clampIntersects = [];
    const minNotMet = [];
    this.designGroup.children.each((panel: any) => {
      const panelBox = panel.getClientRect({ skipStroke: false });
      clampIntersects.push([panel, this.getClampIntersectionByShape(panelBox, false)]);
    });
    for (const panel of clampIntersects) {
      if (panel[1].length < 4) {
        minNotMet.push(panel[0]);
      } else {
        // Check the location of the attachments
        const left = {
          x: panel[0].x() + (panel[0].width() * 0.33),
          y: panel[0].y() + (panel[0].height() * 0.5)
        };
        const right = {
          x: panel[0].x() + (panel[0].width() * 0.66),
          y: panel[0].y() + (panel[0].height() * 0.5)
        };
        if (!this.checkPanelSideAttachments(left, right, panel[1])) {
          minNotMet.push(panel[0]);
        }
      }
    }
    return minNotMet;
  }

  resetDesignCheck() {
    // Remove old conflicts
    if (this.conflictGroup.children.length > 0) {
      this.conflictGroup.destroyChildren();
    }
    this.HAS_CONFLICT = false;
    // Set Max Span Display
    if (this.COMPARED_SPAN.length === 0) {
      this.setMaxSpanLocal();
    }
  }


  SubmitResult() {
    // reset project design submmission, if any
    this.resetDesignCheck();
    // check min 2 points connected to roof per panel
    const minRoofConnectedNot2 = this.checkMin2PtRoofConnections();
    // Check min 4 attachments per panel
    const minNot4 = this.checkMin4PtAttachments();
    if (minRoofConnectedNot2.length > 0) {
      const mainMsg = 'Please check appropriate PV module clamping. Every module must have a minimum of 2 connections to the roof. (not considering floating splices)';
      this.openSnackBar(mainMsg, 'OK', 'center', 'top', 120000);
      for (const panel of minRoofConnectedNot2) {
        panel.setAttrs({ stroke: 'purple' });
      }
      this.designLayer.batchDraw();
      setTimeout(() => {
        for (const panel of minRoofConnectedNot2) {
          panel.setAttrs({ stroke: 'dimgray' });
        }
        this.designLayer.batchDraw();
      }, 5000);
      this.projectService.setProject('failed', true);
    } else if (minNot4.length > 0) {
      const mainMsg = 'Please check appropriate PV module clamping. Every module must have a minimum of 4 connections. ' +
        'Max Cantilever is about 1/3rd of the panel\'s length from each side. ' + 'Clamp locations can be adjusted by moving the rafters.';
      this.openSnackBar(mainMsg, 'OK', 'center', 'top', 120000);
      for (const panel of minNot4) {
        panel.setAttrs({ stroke: 'purple' });
      }
      this.designLayer.batchDraw();
      setTimeout(() => {
        for (const panel of minNot4) {
          panel.setAttrs({ stroke: 'dimgray' });
        }
        this.designLayer.batchDraw();
      }, 5000);
      this.projectService.setProject('failed', true);
    } else {
      this.projectService.savespinner('on');
      this.projectService.setProject('failed', false);
      // Check max Span
      this.getBothMaxSpan().then(() => {
        this.checkRafterPosMaxSpan().then(problems => {
          this.projectService.savespinner('off');
          // Show problems according to max Span
          if (problems.length > 0) {
            this.HAS_CONFLICT = true;
            for (const pos of problems) {
              this.conflictGroup.add(this.panelService.createConflict(pos.x, pos.y, 'purple', this.CLAMP_RATIO));
            }
            this.designLayer.batchDraw();
            setTimeout(() => {
              const exceesSpanMsg = 'Your project design exceeds the max span indicated with a purple circle. ' +
                'Please change your clamping positions accordingly.';
              this.openSnackBar(exceesSpanMsg, 'OK', 'center', 'top', 120000);
            }, 300);
            // Remove the circled problems after 10 sec
            setTimeout(() => {
              this.conflictGroup.destroyChildren();
              this.designLayer.batchDraw();
            }, 10000);
          }
          // Display final approved results & save the image if no problem is detected
          if (problems.length === 0) {
            setTimeout(() => {
              const successMsg = 'Your project design meets the max span allowed. You can go to the Part list!';
              this.openSnackBar(successMsg, 'OK', 'center', 'top', 120000);
              this.projectService.postprojnotif();
            }, 200);
          }
        }).catch(e => {
          console.log('Found error while checking Max Span', e);
        });
      });
    }
  }

  parsecanadazone(zonenum) {
    if (zonenum == 0) {
      return 'R';
    }
    else if (zonenum == 1) {
      return 'S';
    }
    else {
      return 'C';
    }
  }

  setMaxSpanLocal() {
    this.projectZones = this.getProjectZones([]);
    this.zone = Number(this.zone);
    this.zoneTitle = this.country_bool == 'us' ? (this.zone + 1).toString() : this.parsecanadazone(this.zone);
    this.getBothMaxSpan().then(() => {
      this.starTitle = (this.asterisk) ? '*' : '';
      const RDR = (this.ROOF_ATTACHMENT.includes('rafter')) ? true : false;
      this.COMPARED_SPAN = (RDR) ?
        [Number(this.MAX_SPANS.land.R[this.zone]).toFixed(2),
        Number(this.MAX_SPANS.land.D[this.zone]).toFixed(2),
        Number(this.MAX_SPANS.land.RDR[this.zone]).toFixed(2)]
        : (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter') ? [Number(this.MAX_SPANS.land.R[this.zone]).toFixed(2)]
          : [Number(this.MAX_SPANS.land.D[0]).toFixed(2)];


      this.COMPARED_SPAN_PORT = (RDR) ?
        [Number(this.MAX_SPANS.port.R[this.zone]).toFixed(2),
        Number(this.MAX_SPANS.port.D[this.zone]).toFixed(2),
        Number(this.MAX_SPANS.port.RDR[this.zone]).toFixed(2)]
        : (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter') ? [Number(this.MAX_SPANS.port.R[this.zone]).toFixed(2)]
          : [Number(this.MAX_SPANS.land.D[this.zone]).toFixed(2)];


    });
  }


  // V2 - Calaculate mid clamp positions from all deck clamps positions
  calculateMidClampsPositions(allClampsPos: any) {
    let endClampsPos = [];
    let midClampsPos = [];
    const midClampsCenter = [];

    for (const pos of allClampsPos) {
      const posBox = {
        x: pos.x - (0.5 * this.CLAMP_RATIO), y: pos.y - (3 * this.CLAMP_RATIO),
        width: (1 * this.CLAMP_RATIO), height: (6 * this.CLAMP_RATIO)
      };
      const panelsInter = this.getPanelIntersection(posBox, this.PANEL_STROKEWITH);
      if (panelsInter.length > 1) {
        const newY = this.findPanelsMidY(panelsInter);
        const newPos = { x: pos.x, y: newY };
        midClampsCenter.push(newPos);
        midClampsPos.push(pos);
      }
    }
    endClampsPos = allClampsPos.filter(({ x: x1, y: y1 }) =>
      !midClampsPos.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    midClampsPos = [];

    midClampsCenter.sort((a, b) => (a.y > b.y) ? 1 : (a.y === b.y) ? ((a.x > b.x) ? 1 : -1) : -1);
    for (let i = 0; i < midClampsCenter.length; i++) {
      for (let j = i + 1; j < midClampsCenter.length - 1; j++) {
        if (Math.abs(midClampsCenter[i].x - midClampsCenter[j].x) <= (2 * this.PANEL_STROKEWITH) &&
          midClampsCenter[i].y === midClampsCenter[j].y) {
          midClampsPos.push([midClampsCenter[i], midClampsCenter[j]]);
        }
      }
    }
    const allClampsPosFlat = [].concat.apply([], midClampsCenter);
    const midClampsPosFlat = [].concat.apply([], midClampsPos);

    // Get End clamps by getting the NOT overlap of midClamps & allClamps positions
    const midClampsPosLeft = allClampsPosFlat.filter(({ x: x1, y: y1 }) =>
      !midClampsPosFlat.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));

    const midClampsCenterFinal = this.findCenterPos(midClampsPos).concat(midClampsPosLeft);

    return {
      mid: midClampsCenterFinal,
      end: endClampsPos
    };
  }


  // V2 - Calaculate mid clamp positions from all rafter clamps positions
  calculateRafterMidClampsPositions(allClampsPos: any) {
    const midClampsPos = [];
    allClampsPos.sort((a, b) => (a.y > b.y) ? 1 : (a.y === b.y) ? ((a.x > b.x) ? 1 : -1) : -1);
    for (let i = 0; i < allClampsPos.length; i++) {
      for (let j = i + 1; j < allClampsPos.length; j++) {
        if (allClampsPos[i].x === allClampsPos[j].x && Math.abs(allClampsPos[i].y - allClampsPos[j].y) < (2 * this.PANEL_STROKEWITH)) {
          midClampsPos.push([allClampsPos[i], allClampsPos[j]]);
        }
      }
    }
    const allClampsPosFlat = [].concat.apply([], allClampsPos);
    const midClampsPosFlat = [].concat.apply([], midClampsPos);

    // Get End clamps by getting the NOT overlap of midClamps & allClamps positions
    const endClampsPos = allClampsPosFlat.filter(({ x: x1, y: y1 }) =>
      !midClampsPosFlat.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));

    const midClampsCenter = this.findCenterPos(midClampsPos);

    return {
      mid: midClampsCenter,
      end: endClampsPos
    };
  }


  // Draw all the calculated clamping positions
  drawClampsByType(clampsArr: any, rafter: boolean) {
    if (this.totalPanels > 0) {
      if (clampsArr.end.length > 0) {
        this.drawClampsArr(clampsArr.end, 'green', this.clampGroup, rafter);
      }
      if (clampsArr.mid.length > 0) {
        this.drawClampsArr(clampsArr.mid, 'orangered', this.clampGroup, rafter);
      }
      this.designLayer.batchDraw();
    }
  }


  drawClampsArr(allClamps: any, color: string, group: any, rafter: boolean) {
    allClamps.forEach((element: any) => {
      this.drawClamp(element, color, group, rafter);
    });
  }

  drawClamp(element: any, color: string, group: any, rafter: boolean) {
    const clamp = this.panelService.createClamps(element.x, element.y, color, this.CLAMP_RATIO);
    group.add(clamp); clamp.moveToTop();
    if (rafter) {
      clamp.fill(color);
    }
  }


  drawSplicesbyType(sType: any, rafterSplices?: any) {
    if (this.spliceFinal.end.length > 0) {
      this.drawSplicesArr(this.spliceFinal.end, 'green', this.clampGroup, false, false);
    }
    if (this.spliceFinal.mid.length > 0) {
      this.drawSplicesArr(this.spliceFinal.mid, 'orangered', this.clampGroup, false, false);
    }
    if (rafterSplices) {
      if (rafterSplices.end.length > 0) {
        this.drawSplicesArr(rafterSplices.end, 'green', this.clampGroup, true, false);
      }
      if (rafterSplices.mid.length > 0) {
        this.drawSplicesArr(rafterSplices.mid, 'orangered', this.clampGroup, true, false);
      }
    }
    if (sType === 2) {
      if (this.spliceFloatFinal.mid.length > 0) {
        this.drawSplicesArr(this.spliceFloatFinal.mid, 'lightsalmon', this.clampGroup, false, true);
      }
      if (this.spliceFloatFinal.end.length > 0) {
        this.drawSplicesArr(this.spliceFloatFinal.end, 'yellowgreen', this.clampGroup, false, true);
      }
    }
  }


  drawSplicesArr(allSplice: any, color: string, group: any, rafter: boolean, float: boolean) {
    allSplice.forEach((element: any) => {
      this.drawSplice(element, color, group, rafter, float);
    });
  }

  drawSplice(element: any, color: string, group: any, rafter: boolean, float: boolean) {
    const splice = this.panelService.createSplice(element.x, element.y, color, this.CLAMP_RATIO);
    group.add(splice);
    if (float) {
      splice.fill(color);
      splice.setAttr('stroke', 'white');
    } else {
      if (rafter) {
        splice.fill(color);
      } else {
        splice.fill('white');
      }
    }
  }


  getProjectDimensions() {
    // Save the design Group measurements
    const clampBox = this.designGroup.getClientRect({ skipStroke: false });
    const groupWidth = clampBox.width / this.stage.scaleX();
    const groupHeight = clampBox.height / this.stage.scaleY();
    const groupX = (clampBox.x - this.posDelta.x) / this.stage.scaleX();
    const groupY = (clampBox.y - this.posDelta.y) / this.stage.scaleY();
    this.projectService.setProject('stageWidth', groupWidth);
    this.projectService.setProject('stageHeight', groupHeight);
    return { w: groupWidth, h: groupHeight, x: groupX, y: groupY };
  }


  // Get a screenshot of project panels to report at layout
  saveStageImage() {
    // Save an image of Final Design Group
    const pixelRatio = (this.workspace === 1) ? 4 : 6;
    const { w, h, x, y } = this.getProjectDimensions();
    this.restoreZoomView();
    const dataURL = this.designLayer.toDataURL({ x: x - 20, y: y - 20, width: w + 40, height: h + 40, pixelRatio });
    this.projectService.setProject('stageImage', dataURL);
    // Save a copy of whole stage if it's on Google Map
    this.setZoomStage(this.BLANK_ZOOM, this.stageCenterPoint);
    (this.workspace === 1) ? this.stageimageformap() : this.postprojectfunc();
  }

  stageimageformap() {
    this.panelCursorHide();
    const storageurl = this.mapService.tempurl;
    if (storageurl === '') {
      const mapimg = this.mapService.getMap().imgSrc;
      const uploadtask = this.projectService.postimage(mapimg.split(',')[1], 'mapimage');
      uploadtask.then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.mapService.setMap('imgSrc', downloadURL);
          this.postprojectfunc();
          // console.log(downloadURL);
        });
      });
    } else {
      this.mapService.setMap('imgSrc', storageurl);
      this.postprojectfunc();
    }
  }

  postprojectfunc() {
    if (this.router.url === '/parts' && this.roofareacheck()) {
      const projName = this.projectService.getprojname();
      if (this.projectService.getprojectname() === projName && projName != null) {
        this.projectService.updateproject(this.projectService.getProject().id).then(() => {
          if (this.roofareacheck()) { this.projectService.savespinner('off'); }
        });
      } else {
        this.projectService.postproject().then(() => {
          if (this.roofareacheck()) { this.projectService.savespinner('off'); }
        });
      }
    }
  }

  toggleGlossary() {
    this.hideGlossary = !this.hideGlossary;
  }


  showHelp() {
    this.dialog.open(HelpComponent);
  }


  openSnackBar(msg: any, action: any, posHorz: any, posVert: any, time: any) {
    this.snackBar.open(msg, action, {
      duration: time,
      horizontalPosition: posHorz,
      verticalPosition: posVert,
    });
  }


  // V2 - Prevent from going to Part list if Rafter/Hybrid project has a failed attachment design
  gotoPart() {
    const titleMsg = 'Please change title of project';
    const drawMsg = 'Please draw some modules to see the Part list!';
    const clampMsg = 'Please check your attachments and submit your design before proceeding further!';
    if (this.totalPanels > 0 && this.projectService.getprojectname() !== 'Project Name') {
      if (this.ROOF_ATTACHMENT.slice(0, 6) === 'rafter' && this.projectService.getProject().failed) {
        this.openSnackBar(clampMsg, 'OK', 'center', 'bottom', 120000);
      } else { this.router.navigate(['/parts']); }
    } else if (this.projectService.getprojectname() === 'Project Name') {
      this.openSnackBar(titleMsg, 'OK', 'center', 'bottom', 120000);
    } else {
      this.openSnackBar(drawMsg, 'OK', 'center', 'bottom', 120000);
    }
  }





}

