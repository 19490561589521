import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from 'src/app/home';
import { loginComponent } from 'src/app/login/login.component';
import { SignupComponent } from 'src/app/signup/signup.component';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { AdminComponent } from 'src/app/admin/admin.component';
import { DevIssuesComponent } from 'src/app/admin/developer/issues.component';
import { DescriptionComponent } from 'src/app/admin/description/description.component';
import { HeaderComponent } from 'src/app/header/header.component';
import { YourprojectComponent } from 'src/app/yourproject/yourproject.component';
import { ChangepasswordComponent } from 'src/app/changepassword/changepassword.component';
import { DisableaccountComponent } from 'src/app/disableaccount/disableaccount.component';
import { AuthGuardService } from 'src/app/service/auth-guard.service';
import { DesignComponent } from 'src/app/design/design.component';
import { UserprojectsComponent } from 'src/app/userprojects/userprojects.component';
import { AdminGuardService } from 'src/app/service/admin-guard.service';
import { CalspanComponent } from 'src/app/calspan/calspan.component';
import { PostSignupComponent } from 'src/app/postsignup/postsignup.component';
import { BomcreatorComponent } from 'src/app/bomcreator/bomcreator.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: loginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'postsignup', component: PostSignupComponent },
  { path: 'dashboard/:{fromUrl}/:{emailid}', component: DashboardComponent },
  { path: 'admin', component: AdminComponent, canActivate: [AdminGuardService] },
  { path: 'devissues', component: DevIssuesComponent, canActivate: [AdminGuardService] },
  { path: 'issuedescription', component: DescriptionComponent, canActivate: [AdminGuardService] },
  { path: 'yourproject', component: YourprojectComponent, canActivate: [AuthGuardService] },
  { path: 'header', component: HeaderComponent },
  { path: 'changepassword', component: ChangepasswordComponent },
  { path: 'disableaccount/:{emailid}', component: DisableaccountComponent },
  { path: 'userprojects', component: UserprojectsComponent, canActivate: [AdminGuardService] },
  { path: 'calspan', component: CalspanComponent, runGuardsAndResolvers: 'always' },
  { path: 'bomcreator', component: BomcreatorComponent },

  // {
  //   path: 'projects',
  //   loadChildren: () => import('./design/design.module').then(m => m.DesignModule), canActivate: [AuthGuardService]
  // }
  {
    path: 'projects', component: DesignComponent 
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {

}
