<div *ngIf="!DONE">
  <app-transition></app-transition>
</div>

<div *ngIf="DONE" class="menu">
  <h5>Project Reports</h5>
  <mat-divider style="margin-bottom: 10%;"></mat-divider>

  <div class="row">
    <a mat-button (click)="generatePDF(1, false)">BILL OF MATERIALS</a>
    <button mat-button (click)="generatePDF(1, true)" class="ml-auto">
      <mat-icon>save_alt</mat-icon>
    </button>
  </div>
  <div class="row">
    <a mat-button (click)="generatePDF(2, false)">ENGINEERING REPORT</a>
    <button mat-button (click)="generatePDF(2, true)" class="ml-auto">
      <mat-icon>save_alt</mat-icon>
    </button>
  </div>
  <div class="row">
    <a mat-button (click)="generatePDF(3, false)">LAYOUT</a>
    <button mat-button (click)="generatePDF(3, true)" class="ml-auto">
      <mat-icon>save_alt</mat-icon>
    </button>
  </div>
  <div class="row">
    <a mat-button (click)="generatePDF(4, false)">APEX ASCE CRITERIA </a>
    <button mat-button (click)="generatePDF(4, true)" class="ml-auto">
      <mat-icon>save_alt</mat-icon>
    </button>
  </div>
  <div class="row">
    <a mat-button (click)="generatePDF(5, false)">ALL PROJECT REPORTS </a>
    <button mat-button (click)="generatePDF(5, true)" class="ml-auto">
      <mat-icon>save_alt</mat-icon>
    </button>
  </div>

  <h5 style="margin-top: 15%;">RT-APEX Documents</h5>
  <mat-divider style="margin-bottom: 10%;"></mat-divider>

  <div class="row">
    <a mat-button (click)="viewPDF(1, false)">{{ country_bool == 'us' ? 'STATE' : 'PROVINCIAL' }} STAMP PE REPORT</a>
    <button mat-button (click)="viewPDF(1, true)" class="ml-auto">
      <mat-icon>save_alt</mat-icon>
    </button>
  </div>
  <div class="row">
    <a mat-button (click)="viewPDF(2, false)">BROCHURE</a>
    <button mat-button (click)="viewPDF(2, true)" class="ml-auto">
      <mat-icon>save_alt</mat-icon>
    </button>
  </div>
  <div class="row">
    <a mat-button (click)="viewPDF(3, false)">INSTALLATION MANUAL</a>
    <button mat-button (click)="viewPDF(3, true)" class="ml-auto">
      <mat-icon>save_alt</mat-icon>
    </button>
  </div>
  <div class="row">
    <a mat-button (click)="viewPDF(4, false)">QUICK INSTALLATION GUIDE</a>
    <button mat-button (click)="viewPDF(4, true)" class="ml-auto">
      <mat-icon>save_alt</mat-icon>
    </button>
  </div>
</div>


<div class="pdf">
  <!-- <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar> -->
  <object class="pdfViewer" [attr.data]="pdfSource" type="application/pdf"></object>
</div>



<div class="row footer">
  <a mat-raised-button [routerLink]="['/parts']">&larr; Back</a>
 <app-footer></app-footer>
</div>
