import { Component, OnInit, Injector, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ProjectService } from 'src/app/service/project.service';
import { MapService } from 'src/app/service/map.service';
import { OverlayService } from 'src/app/service/overlay.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.css']
})
export class ZoneComponent implements OnInit, OnDestroy, AfterViewInit {

  title: any;
  pitch: any;
  splice: any;
  staticSpace: any;
  NS: any;
  EW: any;
  eave: any;
  FLAG_FLOATING: boolean;
  rafterOnly = false;
  rafterSpan: any;
  staggered = false;
  stagSpace: any;
  step = '';
  roofAttachment: any;
  asterisk: any;
  country_bool = this.projectService.getProject().country;
  clip: any;
  yourProject: any;
  selectedarea = '';
  areabutt = [];
  pitcherror = true;
  // intro code
  public stepsubs = new Subscription();

  constructor(
    public projectService: ProjectService,
    private snackBar: MatSnackBar,
    public mapService: MapService,
    private inj: Injector,
    public router: Router,
    public ref: ChangeDetectorRef,
    public overlay: Overlay,
    public overlayservice: OverlayService
  ) {
    this.initializeVariables();
  }


  ngOnInit() {
    let areanames = [];
    this.FLAG_FLOATING = (this.roofAttachment.slice(0, 6) === 'rafter') ? true : false;
    // intro code
    this.stepsubs = this.mapService.currentMessage.subscribe(step => {
      const laststep = this.mapService.laststep;
      this.step = (laststep === 'step1' || laststep === 'step2' || laststep === 'step3'
        || laststep === 'step4' || laststep === 'step5') ? 'step6' : step;
    });


  }


  ngAfterViewInit() {
    this.yourProject = this.projectService.getProject();
    this.selectedarea = this.projectService.selectedid;
    this.projectService.getareanotif().subscribe(arr => {
      this.areabutt = [];
      arr.forEach((singdat) => {
        this.areabutt.push(singdat.zonetitle);
      });
    });
    this.ref.detectChanges();
  }


  ngOnDestroy() {
    this.stepsubs.unsubscribe();
  }

  // intro code
  onNext(step) {
    if (this.FLAG_FLOATING) { this.step = 'step16'; } else { this.step = step; }
    this.mapService.startnext(this.step);
  }
  closeintro() {
    this.mapService.laststep = 'step6';
    this.mapService.startnext('finished');
  }

  initializeVariables() {

    const data = this.projectService.getProject();
    this.country_bool = this.country_bool == undefined ? 'us' : this.country_bool;
    this.roofAttachment = data.rt;
    this.title = data.zonetitle;
    this.pitch = data.pitch;
    this.pitcherror = this.pitch == undefined ? true : false;
    this.splice = data.splice;
    this.EW = data.dimension[0];
    this.NS = data.dimension[1];
    this.rafterOnly = (this.roofAttachment === 'rafter') ? true : false;
    if (Number(data.splice) < 0) {
      if (this.roofAttachment.slice(0, 6) === 'rafter') {
        this.splice = (this.rafterOnly) ? '1' : '2';
      } else { this.splice = '0'; }
    }
    this.rafterSpan = data.rafter;
    this.eave = data.eave;
    this.staticSpace = (Number(data.static) < 0) ? this.rafterSpan : data.static;
    this.stagSpace = data.staggered;
    this.staggered = (this.stagSpace === 0) ? false : true;
    this.asterisk = data.asterisk;
    this.clip = this.projectService.getProject().clip == '0' ? false : true;
  }

  saveChanges() {
    this.pitcherror = this.pitch == undefined ? true : false;
    this.projectService.setProject('zonetitle', this.title);
    this.projectService.setProject('pitch', this.pitch);
    this.projectService.setProject('splice', this.splice);
    this.projectService.setProject('eave', this.eave);
    if (this.splice === '0') { this.staticSpace = this.rafterSpan; }
    this.projectService.setProject('static', this.staticSpace);
    this.clip ? this.projectService.setProject('clip', 1) : this.projectService.setProject('clip', '0');
    (this.staggered) ? this.projectService.setProject('staggered', 0.5) : this.projectService.setProject('staggered', 0);
    this.projectService.setProject('asterisk', this.asterisk);
    this.projectService.selectedid == '' ? '' : this.projectService.updateroofarea(this.projectService.selectedid);

  }

  saveclip($event) {
    this.clip = $event.checked;
    //this.clip = !this.clip;
  }

  changeroofarea(event, val) {
    this.overlayservice.close();
    document.getElementById(val + '-button').click();
  }



  alertAsterisk() {
    // Popup Alert
    const msg = 'Changing this selection might affect the Max Span calculation. You have to check your'
      + ' attachments design again to be able to proceed furthur.';
    const snackBarRef = this.snackBar.open(msg, 'OK', { duration: 60000, horizontalPosition: 'center', verticalPosition: 'top', });
    snackBarRef.afterDismissed().subscribe(() => {
      this.projectService.setProject('failed', true);
    });
  }

}
