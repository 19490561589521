<div Class="container">

    <!-- <div class="topcenter">
        <div class="container"
            style="height:200px;width:600px; background-color: #ffffff; padding: 50px;">
            <div class="row">
                <h1>Estimator Under Construction...</h1>
                <h4>Please check back later.</h4>
            </div>
        </div>
    </div> -->

    <div class="topcenter">
        <div class="container"
            style="height:250px; background-color: #ffffff; padding: 50px 90px; opacity: 0.9; border-radius: 5px;">
            <div class="row">
                <h1 style="align-items: center;">RT Customer Portal Home</h1>

                <div *ngIf="(regis_status != undefined) && (regis_status != 1)" class="status-div">
                    <h3>Registration Status : <span *ngIf="regis_status == 0">Pending </span> <span
                            *ngIf="regis_status == 1">Registered successfully </span> <span *ngIf="regis_status == 2">
                            Denied</span> </h3>

                </div>
            </div>
        </div>
    </div>

</div>


<!-- <div class="logoright">
    <div class="container">
        <div class="row"><img src="../../assets/images/logo.png" alt="Estimator design">
            <div class="col-md-12">
                <h2></h2>
            </div>
        </div>
    </div>

</div> -->

<!--
<app-footer></app-footer>
-->
<div class="row footer">
    <app-footer></app-footer>
</div>