import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
// Angular Material Modules
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// Design Modules and Components
import { DesignRoutingModule } from 'src/app/design/design-routing.module';
import { DesignComponent } from 'src/app/design/design.component';
import { WorkspaceComponent } from 'src/app/design/workspace.component';
import { ImageComponent } from 'src/app/design/parts/image.component';
import { ModuleComponent } from 'src/app/design/information/module/module.component';
import { LocationComponent } from 'src/app/design/information/location/location.component';
import { RoofComponent } from 'src/app/design/information/roof/roof.component';
import { ZoneComponent } from 'src/app/design/information/zone/zone.component';
import { MapComponent } from 'src/app/design/map/map.component';
import { TransitionComponent } from 'src/app/design/transition.component';
import { DrawComponent } from 'src/app/design/draw/draw.component';
import { PartsComponent } from 'src/app/design/parts/parts.component';
import { EngineeringComponent } from 'src/app/design/engineering/engineering.component';
// import { TransitionComponent } from 'src/app/design/transition.component';
// Others
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { ConfirmComponent } from 'src/app/design/draw/confirm/confirm.component';
import { IssuesComponent } from 'src/app/issues/issues.component';
import { HelpComponent } from 'src/app/design/draw/confirm/help.component';
import { GuideComponent } from 'src/app/design/guide/guide.component';

import { FooterComponent } from 'src/app/footer/footer.component';
import { ReportbuttComponent } from 'src/app/reportbutton/reportbutt.component';



@NgModule({
  declarations: [WorkspaceComponent, DesignComponent, MapComponent, DrawComponent, ConfirmComponent,
    ModuleComponent, LocationComponent, RoofComponent, ZoneComponent, HelpComponent, TransitionComponent,
    PartsComponent, EngineeringComponent, GuideComponent, ImageComponent, ReportbuttComponent, FooterComponent  ],
  entryComponents: [WorkspaceComponent, DesignComponent, MapComponent, DrawComponent, ConfirmComponent,
    ModuleComponent, LocationComponent, RoofComponent, ZoneComponent, HelpComponent, TransitionComponent,
    PartsComponent, EngineeringComponent, IssuesComponent, GuideComponent, ImageComponent],
  imports: [
    CommonModule,
    FormsModule,
    GoogleMapsModule,
    GooglePlaceModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatTableModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatProgressBarModule,
    LeafletDrawModule.forRoot(),
    LeafletModule.forRoot(),
    DesignRoutingModule
  ],
   exports: [FooterComponent,ReportbuttComponent, PartsComponent ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class DesignModule { }