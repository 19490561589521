import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';



@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})

export class SignupComponent implements OnInit {
  email: any;
  password: any;
  reppassword: any;
  name: any;
  company: any;
  phone: any;
  state: any;
  country: any;
  emailvalid: boolean;
  passvalidate: boolean;
  reppassval: boolean;
  nameval: boolean;
  compval: boolean;
  phoneval: boolean;
  terms = false;
  panelOpenState = true;
  canstate = 'AB';

  constructor(public auth: AuthService) { }

  ngOnInit() {
    this.password = '';
    this.name = '';
    this.company = '';
    this.state = 'CA';
	this.country = 'us';
  }


  login() {
    this.auth.login();
  }


  // Calling Sign Up Logic
  signUp() {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.emailvalid = re.test(String(this.email).toLowerCase());
    this.passvalidate = (this.password.length > 5) ? true : false;
    this.reppassval = (this.reppassword === this.password) ? true : false;
    this.nameval = (this.name.length > 2) ? true : false;
    this.compval = (this.company.length > 3) ? true : false;
    this.phoneval = (Math.ceil(Math.log(this.phone + 1) / Math.LN10)) === 10 ? true : false;
    if (!this.nameval) {
      this.auth.error = 'Full name is required';
    } else if (!this.emailvalid) {
      this.auth.error = 'Email is not valid';
    } else if (!this.passvalidate) {
      this.auth.error = 'Password Length Should be greater then 5';
    } else if (!this.reppassval) {
      this.auth.error = 'Repeat Password Doesnot match';
    } else if (!this.compval) {
      this.auth.error = 'Company name is required';
    } else if (!this.phoneval) {
      this.auth.error = 'Phone Number Should be valid';
    } else {
      this.auth.error = '';
	  this.state = this.country == 'canada' ? this.canstate : this.state;
      this.auth.SignUp(this.name, this.phone, this.state, this.country , this.email, this.password, this.company);
    }
  }




}
