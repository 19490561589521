import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WorkspaceComponent } from 'src/app/design/workspace.component';
import { ProjectService } from 'src/app/service/project.service';



@Component({
  selector: 'app-design',
  template: `
 <div class="row footer">
<app-footer></app-footer>
</div>
  `,
  styles: [`
    ::ng-deep .cdk-overlay-backdrop {
    top: 6.5vh;
  }

  .footer {
    position: fixed;
    width: 100vw;
    height: 50px;
    margin: 0;
    bottom: 0;
    background-color: aliceblue;
    color: black;
    text-align: center;
    z-index: 5;
  }

  .footer p {
    text-align: center;
    margin-left: 40%;
    margin-top: 15px;
  }
  `]
})


export class DesignComponent implements OnInit, OnDestroy {

  constructor(private dialog: MatDialog, public projectService: ProjectService) { }

  ngOnInit() {

    this.projectService.refreshproject();
    this.openDialog();

  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    this.dialog.open(WorkspaceComponent, dialogConfig);
  }


}
