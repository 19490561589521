import { Component } from '@angular/core';



@Component({
  selector: 'app-spinner',
  template: `
  <div class="transition">
    <mat-dialog-actions>
        <mat-spinner></mat-spinner>
    </mat-dialog-actions>
</div>`,
  styles: [`
.transition {
  position: absolute;
  top:  45%;
  left: 45%;
}

::ng-deep .mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #eb4031 !important;
}
`]
})

export class TransitionComponent {


}
