import { Injectable } from '@angular/core';
import { PdfMakeWrapper, Txt, Img, Rect, Ellipse, Canvas, Polyline, Table, Columns, Line, Cell } from 'pdfmake-wrapper';
import * as pdfFonts from 'src/app/pdfFonts.js';
import { ProjectService } from 'src/app/service/project.service';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  date = this.getDateOnly();
  title = 'RT BOM Creator';
  projectInfo = [];
  addressInfo = [];
  project: any;
  country_bool: any;
  constructor(public projectservice: ProjectService) {

    PdfMakeWrapper.setFonts(pdfFonts, {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      }
    });
    PdfMakeWrapper.useFont('Roboto');


  }

  async printbompdf() {
    this.project = this.projectservice.getProject();
    this.country_bool = this.projectservice.getProject().country == undefined ? 'us' : this.projectservice.getProject().country;
    const client_name = this.project.name || 'RT BOM Creator';
    const pdf = new PdfMakeWrapper();
    this.setPDFStyle(pdf);
    pdf.footer((currentPage: any, pageCount: any) => {
      return new Txt('Page ' + currentPage.toString() + ' of ' + pageCount).alignment('center').end;
    });
    pdf.info({
      title: this.title + this.date + ' Bill of Materials'
    });
    await this.generateHeader(pdf);
    await this.generatePDFBOM(pdf);
    await this.generatePDFLayout(pdf);
    this.downloadPDF(pdf, client_name);
  }

  async generateHeader(pdf: any) {
    this.projectInfo = [
      ['Project Title', 'Created'],
      [this.title, this.date],
    ];

    if (this.country_bool == 'US') {
      this.addressInfo = [
        ['Client Name', this.project.clientname],
        ['Address', this.project.address],
        ['City, State', this.project.state],
        ['Zip Code', this.project.zip],
      ];
    }
    else {
      this.addressInfo = [
        ['Client Name', this.project.clientname],
        ['Address', this.project.address],
        ['City, Province', this.project.state],
        ['Postal', this.project.zip],
      ];
    }


    pdf.add(await new Img('../../../assets/images/logo-transparent.png').width(151).height(39).build());
    pdf.add('\n\n');
    pdf.add(new Columns(this.projectInfo[0]).columnGap(250).style('headline3').end);
    pdf.add('\n');
    pdf.add(new Columns(this.projectInfo[1]).columnGap(250).style('headline2').end);

    pdf.add('\n\n\n');
    await pdf.add(new Table(this.addressInfo).layout('noBorders').style('headline2').end);

  }

  async generatePDFBOM(pdf: any) {
    const finalBomMap = new Map();
    const finalBom = [];

    const partList = JSON.parse(JSON.stringify(this.projectservice.tableBody[0]));
    //deleting designed quantity
    partList.forEach((singpart) => {
      delete singpart.qnty;
    });
    //this.calculateTotalBOM(finalBomMap, partList);
    const zonetitle = this.project.zonetitle;
    await this.generateBomBody(pdf, zonetitle, partList, 0);

  }


  async generateBomBody(pdf: any, zonetitle: any, partList: any, index: any) {
    let tableBody = [];
    let totalbool = false;
    let total = 0;
    let totalstr = '';

    partList.forEach((singpart) => {
      delete singpart.qnty;
    });

    if (partList.length > 2) {
      tableBody = partList.reduce((acc: any, obj: any) => [...acc, Object.values(obj).map(y => y)], []);
      tableBody.forEach((element: any) => {
        element.shift();
        element.pop();
        const st = (element[5] / element[3]) * element[4];
        if (element[5] === '---') {
          totalstr = '---';
          totalbool = true;
          element.push('---');
          element[5] = element[5];
        } else {
          element.push('$' + st.toFixed(2));
          element[5] = '$' + element[5];
          total += Number(st);
        }
      });
    } else {
      tableBody = partList;
    }
    if (Number(index) !== 0) { pdf.add(await new Img('../../../assets/images/logo-transparent.png').width(151).height(39).build()); }
    pdf.add(new Txt([{ text: '\nBill of Materials ' + ' - ' + zonetitle, style: 'headline1' }]).end);
    pdf.add(new Canvas([new Line([0, 2], [470, 2]).lineColor('#007bff').end]).end);
    pdf.add('\n\n\n\n');

    const tableHeader = [['Part Type'], ['Part Number'], ['Description'], ['Unit/Box'], ['Quantity'], ['MSRP/Box'], ['Subtotal']];
    pdf.add(new Columns(tableHeader).alignment('center').style('headline2').bold().end);
    pdf.add(new Canvas([new Line([0, 2], [500, 2]).end]).end);
    pdf.add('\n');

    tableBody.forEach((element: any) => {
      if (element[4] > 0) {
        pdf.add(new Columns(element).alignment('center').end);
        pdf.add('\n');
        pdf.add(new Canvas([new Line([0, 2], [500, 2]).end]).end);
        pdf.add('\n');
      }
    });

    pdf.add('\n');
    pdf.add(new Columns(['Total', [(totalbool ? totalstr : '$' + total.toFixed(2))]])
      .columnGap(350).style('headline2').columnGap(350).end);
  }
	
  downloadPDF(pdf: any, name: string) {
    pdf.create().download(name);
  }

  async generatePDFLayout(pdf) {
    let roofTitle;
    pdf.add(new Txt([{ text: '\nDesign Layout', style: 'headline1' }]).end);
    pdf.add(new Canvas([new Line([0, 2], [470, 2]).lineColor('#007bff').end]).end);
    pdf.add('\n\n');


    roofTitle = this.project.zonetitle;
    pdf.add(new Txt([{ text: roofTitle, style: 'headline2' }]).end);
    pdf.add(new Canvas([new Line([0, 2], [100, 2]).end]).end);
    pdf.add('\n\n');

    let widthF = 0;
    let imageRatio = 0;
    let heightF = 0;
    const stageImage = this.project.stageImage;
    const width = this.project.stageWidth;
    const height = this.project.stageHeight;
    if (width > height) {
      widthF = 360;
      imageRatio = widthF / width;
      heightF = height * imageRatio;
    } else {
      heightF = 300;
      imageRatio = heightF / height;
      widthF = width * imageRatio;
    }

    const imageBody = [await new Img('../../../assets/images/legend.png').width(75).height(163).build(),
    await new Img(stageImage).width(widthF).height(heightF).build()];
    pdf.add(new Columns(imageBody).columnGap(40).end);
  }

  getDateOnly() {
    // return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    const now = new Date();
    const date = (now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ';
    const minutes = (now.getMinutes() < 10) ? '0' + now.getMinutes().toString() : now.getMinutes().toString();
    const time = (now.getHours() < 12) ?
      + now.getHours() + '-' + minutes + ' am' : (now.getHours() - 12) + ':' + minutes + ' pm';
    return (date + time);
  }

  setPDFStyle(pdf: any) {
    pdf.styles({
      headline1: {
        bold: true,
        fontSize: 22,
        color: '#007bff',
      },
      headline2: {
        bold: true,
        fontSize: 12,
      },
      headline3: {
        bold: true,
        fontSize: 9,
        color: '#007bff',
      },
      report: {
        fontSize: 10,
      },
    });
    pdf.defaultStyle({
      bold: false,
      fontSize: 8
    });
    pdf.pageMargins([60, 40, 60, 40]);
  }




}
