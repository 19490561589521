import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@src/app/service/auth.service';
import { ProjectService } from 'src/app/service/project.service';


@Component({
     templateUrl: 'home.component.html',
     styleUrls: ['./home.component.css' ]
    })
export class HomeComponent implements OnInit, OnDestroy  {
	regis_status;
	regis_status_sub : any;

	  constructor(public auth: AuthService,public projectService: ProjectService) {

	  }

	  ngOnDestroy() {
	  this.regis_status_sub.unsubscribe();
	  }

	ngOnInit() {
		this.regis_status = this.auth.getregisstatus()
		this.regis_status_sub = this.projectService.getchangenotif().subscribe(_ => {
		this.regis_status = this.auth.getregisstatus()
		});
	}
		

}
