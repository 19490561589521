<div class="form-container">

  <h4 class="center">Report Issue</h4>

  <mat-form-field class="full-width">
    <mat-label>Name*</mat-label>
    <input matInput [(ngModel)]="name">
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Email*</mat-label>
    <input matInput type="email" [(ngModel)]="email">
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-select #projectName (selectionChange)="onChange($event)" matNativeControl placeholder="Project Name*"
      [(value)]="project">
      <mat-option value="None">None</mat-option>
      <mat-option *ngFor="let proj of allProjects" [value]="proj.id">{{proj.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- <mat-form-field class="full-width">
    <mat-label>Project Name*</mat-label> 
    <input matInput [(ngModel)]="projname">
  </mat-form-field> -->

  <mat-form-field class="full-width">
    <mat-label>Company Name*</mat-label>
    <input matInput [(ngModel)]="compname">
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-select matNativeControl placeholder="Company State*" [(value)]="compstate">
      <!-- <mat-option value="AL">Alabama</mat-option> -->
      <!-- <mat-option value="AK">Alaska</mat-option> -->
      <mat-option value="AR">Arkansas</mat-option>
      <mat-option value="AZ">Arizona</mat-option>
      <mat-option value="CA">California</mat-option>
      <mat-option value="CO">Colorado</mat-option>
      <mat-option value="CT">Connecticut</mat-option>
      <mat-option value="DC">District of Columbia</mat-option>
      <mat-option value="FL">Florida</mat-option>
      <mat-option value="GA">Georgia</mat-option>
      <mat-option value="HI">Hawaii</mat-option>
      <mat-option value="ID">Idaho</mat-option>
      <mat-option value="IL">Illinois</mat-option>
      <mat-option value="IN">Indiana</mat-option>
      <mat-option value="IA">Iowa</mat-option>
      <mat-option value="KS">Kansas</mat-option>
      <!-- <mat-option value="KY">Kentucky</mat-option> -->
      <mat-option value="LA">Louisiana</mat-option>
      <mat-option value="ME">Maine</mat-option>
      <mat-option value="MD">Maryland</mat-option>
      <mat-option value="MA">Massachusetts</mat-option>
      <mat-option value="MI">Michigan</mat-option>
      <mat-option value="MN">Minnesota</mat-option>
      <!-- <mat-option value="MS">Mississippi</mat-option> -->
      <mat-option value="MO">Missouri</mat-option>
      <!-- <mat-option value="MT">Montana</mat-option> -->
      <!-- <mat-option value="NE">Nebraska</mat-option> -->
      <mat-option value="NV">Nevada</mat-option>
      <mat-option value="NH">New Hampshire</mat-option>
      <mat-option value="NJ">New Jersey</mat-option>
      <mat-option value="NM">New Mexico</mat-option>
      <mat-option value="NY">New York</mat-option>
      <mat-option value="NC">North Carolina</mat-option>
      <!-- <mat-option value="ND">North Dakota</mat-option> -->
      <mat-option value="OH">Ohio</mat-option>
      <!-- <mat-option value="OK">Oklahoma</mat-option> -->
      <mat-option value="OR">Oregon</mat-option>
      <mat-option value="PA">Pennsylvania</mat-option>
      <mat-option value="RI">Rhode Island</mat-option>
      <mat-option value="SC">South Carolina</mat-option>
      <!-- <mat-option value="SD">South Dakota</mat-option> -->
      <mat-option value="TN">Tennessee</mat-option>
      <mat-option value="TX">Texas</mat-option>
      <mat-option value="UT">Utah</mat-option>
      <!-- <mat-option value="VT">Vermont</mat-option> -->
      <mat-option value="VA">Virginia</mat-option>
      <mat-option value="WA">Washington</mat-option>
      <!-- <mat-option value="WV">West Virginia</mat-option> -->
      <mat-option value="WI">Wisconsin</mat-option>
      <!-- <mat-option value="WY">Wyoming</mat-option> -->
    </mat-select>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Description of Issue*</mat-label>
    <textarea matInput rows="6" [(ngModel)]="descrip"></textarea>
  </mat-form-field>
  <div class="help-text">Please be very clear in describing the issue and share as much info as you can!</div>
  <p class="error">{{validerror}}</p>


  <div class="full-width">
    <input type="file" class="browse-file" (change)="onFileSelected($event)" #fileUpload multiple>
    <div class="file-upload">
      <button mat-mini-fab (click)="fileUpload.click()" class="attach-file">
        <mat-icon>attach_file</mat-icon>
      </button>
      {{fileName || "No file uploaded yet."}}
    </div>
    <div class="progress-bar">
      <!-- <progress max="100" [value]="(uploadProgress | async)"></progress> -->
      <mat-progress-bar max="100" [value]="uploadProgress | async">
      </mat-progress-bar>
      <!-- <mat-icon class="cancel-upload" (click)="cancelUpload()" *ngIf="uploadProgress">delete_forever</mat-icon> -->
    </div>
  </div>

  <!-- site key 6Le5G8wZAAAAAICWl47Pr3SNSP-eKzRzoEa5Z5Yu -->
  <!-- secret key 6Le5G8wZAAAAANZrK4fbB1EfPI5Bp3LmKYgxHU1V -->
  <div class="recaptcha">
    <re-captcha (resolved)="resolved($event)" siteKey="6Le5G8wZAAAAAICWl47Pr3SNSP-eKzRzoEa5Z5Yu"></re-captcha>
  </div>

  <div class="butt-block">
    <button mat-raised-button class="cancel" (click)="closefunc()">Cancel</button>
    <button mat-raised-button class="send" (click)="submitfunc()" [disabled]="!activebutt">Send</button>
  </div>
</div>