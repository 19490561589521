<div class="bottomright">
  <div class="container" style="width:600px; background-color: #ffffff; padding: 50px 90px; opacity: 0.9;">
    <div class="row">
      <h3>DISABLE ACCOUNT</h3>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
        <label class="radio-inline"><input type="radio" [checked]="true" value="1" name="optradio"
            [(ngModel)]="actDisable">YES</label>
        <label class="radio-inline"><input type="radio" value="0" name="optradio" [(ngModel)]="actDisable">NO</label>
        <hr>
        <button type="button" [disabled]="actDisable==0" id="btnaccountdisable" (click)="onAccountDisable(actDisable)"
          class="btn btn-primary">DISABLE</button>
      </div>
    </div>
  </div>
</div>