<div style="height: 400px;width: 900px;">
<div class="container">
    <div class="row">
        <div class="col-xs-12" style="color:white;font-size:xx-large;">
            {{message}}
        </div>
    </div>
    <hr>
    <div class="row">

    </div>
</div>
</div>