import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { BehaviorSubject } from 'rxjs';
import { takeLast } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class MapService {

  private overlayname = new BehaviorSubject('step0');
  public laststep = '';
  public laststepdesign = '';
  currentMessage = this.overlayname.asObservable();
  featureGroupMap = new L.FeatureGroup();
  tempurl = '';

  leafletOptions = {
    layers: [
      L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        attribution: '<a href="https://www.google.com/intl/en-US_US/help/terms_maps/">Terms of Use</a>',
        maxZoom: 22,
        minZoom: 14,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      })
    ],
    zoom: 16,
    center: L.latLng(36.108118, -115.180198),
    attributionControl: false,
    zoomControl: false
  };

  leafletDrawOptions = {
    position: 'topright',
    draw: {
      polygon: {
        allowIntersection: false, // Restricts shapes to simple polygons
        drawError: {
          color: '#f8f9fa', // Color the shape will turn when intersects
          message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
        },
        shapeOptions: {
          color: '#eb4031',
          opacity: 1,
          weight: 7,
        }
      },
      // disable toolbar item by setting it to false
      polyline: false,
      circle: false,
      rectangle: false,
      marker: false,
      circlemarker: false,
    },
    featureGroup: this.featureGroupMap,
  };

  leafletOptionsBlank = {
    zoom: 16,
    center: L.latLng(39.407836, -119.7310209),
    attributionControl: false,
    zoomControl: false
  };

  leafletDrawOptionsBlank = {
    draw: {
      polygon: false,
      polyline: false,
      circle: false,
      rectangle: false,
      marker: false,
      circlemarker: false,
    },
    // featureGroup: this.featureGroupMap,
    edit: {
      edit: false,
      remove: false,
    }
  };


  private map = {
    workspace: 2,
    imgSrc: '',
    mapRatio: 3.2,
    // Map info
    layer: [],
    layerArea: 0,
    layerBounds: [],
    layerCenter: [],
    layerEveSide: [],
    // Pixel info
    layerPixel: [],
    layerPixelArea: null,
    layerPixelCenter: [],
    layerPixelEveSide: [],
    lat: null,
    Lng: null,
    stageImage: null
  };


  startnext(message: string) {
    if (message === 'finished') { this.overlayname.next(message); } else {
      this.laststep = message;
      this.overlayname.next(message);
    }
  }

  getFeatureGroup() {
    return this.featureGroupMap;
  }

  refreshmap() {
    this.map = {
      workspace: 2,
      imgSrc: '',
      mapRatio: 3.2,
      // Map info
      layer: [],
      layerArea: 0,
      layerBounds: [],
      layerCenter: [],
      layerEveSide: [],
      // Pixel info
      layerPixel: null,
      layerPixelArea: null,
      layerPixelCenter: [],
      layerPixelEveSide: null,
      lat: null,
      Lng: null,
      stageImage: null
    };
  }

  getMap() {
    return this.map;
  }

  setproject(map) {
    this.map = map;
  }

  setMap(option: any, value: any) {
    this.map[option] = value;
  }

}

