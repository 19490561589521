import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-alert',
  template: `
<div>
  <mat-dialog-content>
    <p>Reset Email Sent Successfully</p>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
</mat-dialog-actions>
</div>`,
  styles: [`

`]
})

export class AlertComponent {
  action: boolean;

  constructor(public dialogRef: MatDialogRef<AlertComponent>, @Inject(MAT_DIALOG_DATA) public data: { title: string }) { }

  onClose(): void {
    this.dialogRef.close(this.action);
  }

}
