import { Injectable, InjectionToken, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';

export const INIT_DATA = new InjectionToken<ContainerData>('INIT_DATA');

export interface ContainerData {
  _service: OverlayService;
}


@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  overlayRef;

  constructor(
    private injector: Injector,
    private overlay: Overlay) { }

  initialize(overlay) {
    this.overlayRef = overlay;
  }

  close() {
    this.overlayRef.dispose();
  }

}
