import { Injectable } from '@angular/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DataService } from './data.service';
/*
import { exceljson } from 'src/app/assets/json_files/excel_json.json';
*/
import exceljson from './../../assets/json_files/excel_json.json';


@Injectable({
	providedIn: 'root'
})
export class ExcelService {
	excelheader = ['User Name', 'Country', 'Client Name', 'Client Address', 'Zip Code', 'State', 'PV Module', 'Roof Type',
		'Orientation', 'Width', 'Length', 'Building Code', 'Snow Load', 'Wind Exposure',
		'Wind Speed', 'Roof Attachments', 'Roof Pitch', 'Panels Row', 'Panels Column', 'Splice Type'
	];
	csv_key = { "ProjectName": "", "prop_cat_code": "", "zone": "", "leadagency": "", "jpacat": "", "cost_to_perform": "" };
	options = { headers: this.excelheader };
	exceljson = exceljson;
	constructor(public dataservice: DataService) { }


	downloadcsv() {
		var csv_data = [];
		var singobj = {};
		var csv_data = [];
		var excelheader = [];
		for (var key in this.exceljson) {
			excelheader.push(this.exceljson[key]);
		}
		var singproj = (JSON.parse(JSON.stringify(this.exceljson)));

		this.dataservice.getcollectiononce('bom_projects').then((bom) => {
			let down_projects = bom;
			down_projects = this.sortdate(down_projects);
			down_projects.forEach(eachproj => {
				if (!eachproj['company'].includes('polygons') && !eachproj['company'].includes('Polygons')) {
					for (var key in this.exceljson) {
						singproj[key] = this.prettifyexcval(eachproj, key);
					}
					csv_data.push(JSON.parse(JSON.stringify(singproj)));
					singobj = this.exceljson;
				}
			});
			var options = { headers: excelheader };
			new AngularCsv(csv_data, "Bom Projects", options);
		});
	}


	sortdate(down_projects) {
		return down_projects.sort((a, b) => (b.date.seconds) - (a.date.seconds));
	}

	prettifyexcval(eachproj, key) {
		let countbool = eachproj['country'] == 'us';
		if (key == 'pv') {
			return eachproj[key] + " Cells";
		}
		else if (key == 'orientation') {
			return eachproj[key] ? "Portrait" : "Landscape";
		}
		else if (key == 'width' || key == 'length') {
			return eachproj[key] + '"';
		}
		else if (key == 'asce') {
			return eachproj[key] == "10" ? "ASCE 7-10" : "ASCE 7-16";
		}
		else if (key == 'asce') {
			return eachproj[key] == "10" ? "ASCE 7-10" : "ASCE 7-16";
		}
		else if (key == 'rt' && countbool) {
			let rtval = eachproj[key];
			if (rtval == 'plywood') { return 'Plywood 15/32"'; }
			else if (rtval == 'osb') { return 'OSB 7/16"'; }
		}
		else if (key == 'rt' && !countbool) {
			let rtval = eachproj[key];
			if (rtval == 'plywood') { return 'Plywood with (5)-60 mm screws'; }
			else if (rtval == 'osb') { return 'OSB with (5)-60 mm screws'; }
		}
		else if (key == 'snow' && countbool) {
			let snowval = eachproj[key];
			if (snowval == "0") { return "0 PSF"; }
			else if (snowval == "10") { return "1-10 PSF"; }
			else if (snowval == "20") { return "11-20 PSF"; }
			else if (snowval == "30") { return "21-30 PSF"; }
			else if (snowval == "40") { return "31-40 PSF"; }
			else if (snowval == "50") { return "41-50 PSF"; }
			else if (snowval == "60") { return "51-60 PSF"; }
			else if (snowval == "70") { return "61-70 PSF"; }
		}
		else if (key == 'snow' && !countbool) {
			return eachproj['cansnow'] + " KPa";
		}
		else if (key == 'terr') { return eachproj[key] == '' ? "NA" : eachproj[key]; }
		else if (key == 'address' || key == 'zip' || key == 'company' || key == 'company' || key == 'clientname') { return eachproj[key] == '' ? "-" : eachproj[key]; }
		else if (key == 'pitch' && countbool) {
			let pitchval = eachproj[key];
			if (pitchval == "0.0") { return "0"; }
			else if (pitchval == "4.8") { return "1/12 = 4.8 Degree"; }
			else if (pitchval == "9.5") { return "2/12 = 9.5 Degree"; }
			else if (pitchval == "14.0") { return "3/12 = 14.0 Degree"; }
			else if (pitchval == "18.4") { return "4/12 = 18.4 Degree"; }
			else if (pitchval == "22.6") { return "5/12 = 22.6 Degree"; }
			else if (pitchval == "26.6") { return "6/12 = 26.6 Degree"; }
			else if (pitchval == "30.3") { return "7/12 = 30.3 Degree"; }
			else if (pitchval == "33.7") { return "8/12 = 33.7 Degree"; }
			else if (pitchval == "39.8") { return "10/12 = 39.8 Degree"; }
			else if (pitchval == "42.5") { return "11/12 = 42.5 Degree"; }
			else if (pitchval == "45.0") { return "12/12 = 45.0 Degree"; }
		}
		else if (key == 'pitch' && !countbool) {
			let pitchval = eachproj[key];
			if (pitchval == "0") { return "0-6 Degree"; }
			else if (pitchval == "7") { return "7-27 Degree"; }
			else if (pitchval == "28") { return "28-45 Degree"; }
		}
		else if (key == 'date') { return new Date(eachproj[key].seconds * 1000).toLocaleString(); }
		else if (key == 'splice') { return eachproj[key] == '0' ? 'No Splice' : 'Splice'; }
		else if (key == 'suggested_base_qty') { return this.getpartitem(eachproj['parts_table'], 1, 'sugg'); }
		else if (key == 'customized_base_qty') { return this.getpartitem(eachproj['parts_table'], 1, 'cust'); }
		else if (key == 'suggested_end_clamp_qty') { return this.getpartitem(eachproj['parts_table'], 5, 'sugg'); }
		else if (key == 'customized_end_clamp_qty') { return this.getpartitem(eachproj['parts_table'], 5, 'cust'); }
		else if (key == 'suggested_mid_clamp_qty') { return this.getpartitem(eachproj['parts_table'], 2, 'sugg'); }
		else if (key == 'customized_mid_clamp_qty') { return this.getpartitem(eachproj['parts_table'], 2, 'cust'); }
		else if (key == 'suggested_end_splice_qty') { return this.getpartitem(eachproj['parts_table'], 6, 'sugg'); }
		else if (key == 'customized_end_splice_qty') { return this.getpartitem(eachproj['parts_table'], 6, 'cust'); }
		else if (key == 'suggested_mid_splice_qty') { return this.getpartitem(eachproj['parts_table'], 3, 'sugg'); }
		else if (key == 'customized_mid_splice_qty') { return this.getpartitem(eachproj['parts_table'], 3, 'cust'); }
		else if (key == 'suggested_end_floating_splice_qty') { return this.getpartitem(eachproj['parts_table'], 7, 'sugg'); }
		else if (key == 'customized_end_floating_splice_qty') { return this.getpartitem(eachproj['parts_table'], 7, 'cust'); }
		else if (key == 'suggested_wood_screws_qty') { return this.getpartitem(eachproj['parts_table'], 8, 'sugg'); }
		else if (key == 'customized_wood_screws_qty') { return this.getpartitem(eachproj['parts_table'], 8, 'cust'); }
		else if (key == 'suggested_skirt_qty') { return this.getpartitem(eachproj['parts_table'], 9, 'sugg'); }
		else if (key == 'customized_skirt_qty') { return this.getpartitem(eachproj['parts_table'], 9, 'cust'); }
		else if (key == 'suggested_skirt_bonding_splice_qty') { return this.getpartitem(eachproj['parts_table'], 10, 'sugg'); }
		else if (key == 'customized_skirt_bonding_splice_qty') { return this.getpartitem(eachproj['parts_table'], 10, 'cust'); }
		else if (key == 'suggested_skirt_end_cap_qty') { return this.getpartitem(eachproj['parts_table'], 11, 'sugg'); }
		else if (key == 'customized_skirt_end_cap_qty') { return this.getpartitem(eachproj['parts_table'], 11, 'cust'); }
		else {
			return eachproj[key];
		}
	}

	getpartitem(parts_tab, index, type) {
		let retval;
		parts_tab.forEach((sing_item) => {
			if (index == sing_item.id) {
				retval = type == 'sugg' ? sing_item.qnty : sing_item.des_qnty;
			}
		});
		retval = retval == undefined ? 0 : retval;
		return retval;
	}

}
