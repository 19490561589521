import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectService } from 'src/app/service/project.service';
import { MapService } from '@src/app/service/map.service';


@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit, OnDestroy {
  state: any;
  zip: any;
  asce: any;
  snow: any;
  exposure: any;
  speed: any;
  address: any;
  client: any;
  error: string;
  country_bool = this.projectService.getProject().country;
  terrain : any;
  
  constructor(public projectService: ProjectService, public mapservice: MapService) { }

  ngOnInit() {
    this.initializeVariables();
  }


  ngOnDestroy() {
    this.saveChanges();
  }

  onPrev() {
    this.mapservice.startnext('step1');
  }

  onNext() {
    this.mapservice.startnext('step3');
  }

  closeintro() {
    this.mapservice.laststep = 'step2';
    this.mapservice.startnext('finished');
  }


  initializeVariables() {
	const data = this.projectService.getProject();
	//Adjusting for older projects
	this.country_bool = this.country_bool ==  undefined ? 'us' : this.country_bool;
	this.state = data.state;
	this.zip = data.zip;
	this.asce = data.asce;
	this.snow = this.country_bool == 'us' ? data.snow : data.cansnow;
	this.speed = this.country_bool == 'us' ? data.speed : data.canspeed;
	this.exposure = data.exposure;
	this.address = data.address;
	this.client = data.clientname;
	this.terrain = data.terr;
  }


  saveChanges() {
	this.error = '';
	this.projectService.setProject('state', this.state);
	this.projectService.setProject('zip', this.zip);
	this.projectService.setProject('asce', this.asce);
	this.projectService.setProject('snow', this.snow);
	this.projectService.setProject('cansnow', this.snow);
	this.projectService.setProject('speed', this.speed);
	this.projectService.setProject('canspeed', this.speed);
	this.projectService.setProject('exposure', this.exposure);
	this.projectService.setProject('address', this.address);
	this.projectService.setProject('clientname', this.client);
	this.country_bool == 'us' ? '' : this.projectService.setProject('terr', this.terrain);
	this.projectService.postprojnotif();
  }

	closemodel() {
  document.getElementById('roof_inf_butt').click();
  }

  loadVariables(project: any) {
    this.state = project.state;
    this.zip = project.zip;
    this.asce = project.asce;
    this.snow = project.snow;
    this.exposure = project.exposure;
    this.speed = project.speed;
    this.address = project.address;
    this.client = project.client;
  }

	postfromhead() {
	this.projectService.postprojectfromheader(true)
	}

  changeMinSpeed(value: any) {
    this.speed = (value === '10') ? '110' : '95';
  }

}
