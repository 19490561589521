import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(public snackBar : MatSnackBar) { }

 checkForms(proj_state,proj_rt,proj_terr,country_bool)  {
	 let save_err = true;
    const state = 'You have to choose a state. Please select the location information on the left ' +
      'sidebar and select a state from the list.';
    const roofType = 'You have to choose a roof attachment. Please select the builing and roof ' +
      'information on the left sidebar and select a roof attachment type from the list.';
    const terrain = 'You have to choose a terrain. Please select the location information on the left' +
    'sidebar and select a terrain from the list.';
      if (!proj_state) {  
      this.checkFormField( state, 'top' );
    return save_err;
	} else if (!proj_rt) {
	 this.checkFormField( roofType, 'top' );
    return save_err;
	}
    else if(country_bool == 'canada' && proj_terr == 0){
	 this.checkFormField( terrain, 'top' );
    return save_err;
	}
	save_err = false;
	return save_err;
  }
  
   checkFormField( msg: string,  pos : any) {
      this.snackBar.open(msg, 'OK', {
        duration: 7000,
        horizontalPosition: pos,
        verticalPosition: pos,
      });
  }


}
