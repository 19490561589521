import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { MapService } from '@src/app/service/map.service';
import { SnackbarService } from '@src/app/service/snackbar.service';
import { Subject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})


export class ProjectService {

  constructor(private firestore: AngularFirestore, public mapService: MapService, public fireStorage: AngularFireStorage,
    public snackbarservice: SnackbarService, public router: Router,
  ) { }

  user: any;
  uniqueID: any;
  spinnerchange: Subject<any> = new Subject<any>();
  projectchange: Subject<any> = new Subject<any>();
  areachange: Subject<any> = new Subject<any>();
  headersave: Subject<any> = new Subject<any>();
  map: any;
  projname = '';
  areabutt = [];

  private project =

    {
      name: 'Project Name',
      pv: '60',
      output: 270,
      width: 40,
      length: 68,
      thickness: 30,
      weight: 40,
      span: 0.125,
      country: "us",
      state: '',
      address: '',
      zip: '',
      asce: '10',
      snow: '0',
      cansnow: '0.5',
      exposure: 'B',
      speed: '110',
      canspeed: '0.20',
      pitch: "4.8",
      material: 'Asphalt',
      terr: "",
      rafter: '24',
      //  rt: '',
      rt: 'plywood',
      type: 'gable',
      mrh: '30',
      asterisk: false,
      clientname: '',
      panels: 0,
      timestamp: null,
      uid: '',
      id: '',
      map: {},
      zonetitle: 'Roof Area 1',
      zone: 'R',
      splice: '-1',
      eave: '0',
      panelarea: 0,
      stageHeight: 0,
      stageImage: '',
      stageMapImage: '',
      stageWidth: 0,
      moduleblocks: [],
      endClamp: { R: [], D: [] },
      midClamp: { R: [], D: [] },
      endSplice: { R: [], D: [], F: [] },
      midSplice: { R: [], D: [], F: [] },
      rafterLines: 0,
      dimension: [],
      failed: true,
      static: '-1',
      staggered: 0,
      clip: '0',
      areaid: [],
      skirtNum: 0,
      totalpanel: '0',
      partlist: []
    };



  // phase 2
  public selectedid = '';
  public navigatecount = 0;
  public postedid = [];
  public issaved = true;
  public tableBody = [];
  F;

  increasecount() {
    this.navigatecount++;
  }

  setProjectid() {
    this.project.id = this.firestore.createId();
  }

  createareaid() {
    let idsuffix;
    if (this.project.areaid.length === 0) {
      idsuffix = this.project.areaid.length + 1;
      this.project.areaid.push(this.project.id + idsuffix.toString());
      this.selectedid = this.project.id + idsuffix;
    }
    idsuffix = this.project.areaid.length + 1;
    this.project.areaid.push(this.project.id + idsuffix.toString());
  }

  async createareadb() {
    for (const [index, areaid] of this.project.areaid.entries()) {
      const arearef = this.firestore.collection('roofarea').doc(areaid);
      if (this.postedid.indexOf(areaid) === -1 && index !== 0) {
        this.postedid.push(areaid);
        this.clearclamping();
        this.project.moduleblocks = [];
        this.project.panels = 0;
        this.project.pitch = '0';
        this.project.dimension = [];
        this.project.zonetitle = 'Roof Area ' + Number(index + 1);
        await arearef.set(this.project);
      } else if (this.postedid.indexOf(areaid) === -1 && index === 0) {
        this.postedid.push(areaid);
        await arearef.set(this.project);
      }
      await this.updateprojprop('roofarea', areaid, { areaid: this.project.areaid });
    }
  }

  async changenameofproj() {
    for (const areaid of this.project.areaid) {
      if (areaid !== this.selectedid) {
        await this.firestore.collection('roofarea').doc(areaid).update({ name: this.project.name });
      }
    }
    await this.firestore.collection('projects').doc(this.project.id).update({ name: this.project.name });
  }

  async changeallroofname() {
    for (const areaid of this.project.areaid) {
      await this.firestore.collection('roofarea').doc(areaid).update({ name: this.project.name });
    }
    await this.firestore.collection('projects').doc(this.project.id).update({ name: this.project.name });
  }


  // total panel code
  async addtotalpanel() {
    let totalpanels = 0;
    if (this.project.areaid !== undefined) {
      for (let index = 0; index < this.project.areaid.length; index++) {
        await this.firestore.collection('roofarea').doc(this.project.areaid[index]).valueChanges().pipe(take(1)).toPromise().then(
          async (singarea: any) => {
            if (index === this.project.areaid.length - 1) {
              totalpanels = totalpanels + singarea.panels;
              await this.updatetotalpanel(totalpanels);
            } else {
              totalpanels = totalpanels + singarea.panels;
            }
          });
      }
    }
  }

  // total panel code
  async updatetotalpanel(totalpanels) {
    this.project.totalpanel = totalpanels;
    for (const areaid of this.project.areaid) {
      await this.firestore.collection('roofarea').doc(areaid).update({ totalpanel: totalpanels });
    }
    await this.firestore.collection('projects').doc(this.project.id).update({ totalpanel: totalpanels });
  }

  async getsingleroofarea() {
    return await this.firestore.collection('roofarea').doc(this.project.areaid[0]).valueChanges().pipe(take(1)).toPromise();
  }

  async updateprojprop(collection, id, property) {
    const updname = await this.firestore.collection(collection).doc(id).update(property).then(() => {
    });
  }

  removeroofarea(index) {
    this.firestore.collection('roofarea').doc(this.project.areaid[index]).delete();
    this.project.areaid.splice(index, 1);
    this.selectedid = this.project.areaid[index - 1];
    this.postedid.splice(index, 1);
    this.project.areaid.forEach(areaid => {
      this.updateprojprop('roofarea', areaid, { areaid: this.project.areaid });
    });
  }

  deleteproject(id: any) {
    return this.firestore.collection('projects').doc(id).delete();
  }


  deleteunsavedprojects(areaid, id) {
    if (areaid !== undefined) {
      areaid.forEach(singarea => {
        this.firestore.collection('roofarea').doc(singarea).delete();
      });
    }
    return this.firestore.collection('projects').doc(id).delete();
  }



  getroofareadb(id: any) {
    return this.firestore.collection('roofarea').doc(id).valueChanges();
  }

  updateroofarea(id) {
    const updarearef = this.firestore.collection('roofarea').doc(id);
    return updarearef.set(this.project);
  }

  getroofareaname(id) {
    return this.firestore.collection('roofarea').doc(id).valueChanges();
  }


  setProject(option: any, value: any) {
    this.project[option] = value;
  }

  getchangenotif(): Observable<any> {
    return this.projectchange.asObservable();
  }

  postprojnotif() {
    this.projectchange.next('changed');
  }

  getareanotif(): Observable<any> {
    return this.areachange.asObservable();
  }

  postareanotif(areaarr) {
    this.areachange.next(areaarr);
  }

  //header project save/get code 
  getheadersavenotif(): Observable<any> {
    return this.headersave.asObservable();
  }

  postheadersavenotif(change) {
    this.headersave.next(change);
  }


  getpanel() {
    return this.project.panels;
  }

  // total panel code
  gettotalpanel() {
    return this.project.totalpanel;
  }


  pushclamping(attachment: any, shape: any) {
    const ratio = (shape.getAttrs().width / 6) || shape.getAttrs().radius;
    switch (attachment) {
      case 'endClampR':
        this.project.endClamp.R.push({ x: shape.x(), y: shape.y() });
        return;
      case 'endClampD':
        this.project.endClamp.D.push({ x: shape.x(), y: shape.y() });
        return;
      case 'midClampR':
        this.project.midClamp.R.push({ x: shape.x(), y: shape.y() });
        return;
      case 'midClampD':
        this.project.midClamp.D.push({ x: shape.x(), y: shape.y() });
        return;
      case 'endSpliceR':
        this.project.endSplice.R.push({ x: (shape.x() + (3 * ratio)), y: (shape.y() + ratio) });
        return;
      case 'midSpliceR':
        this.project.midSplice.R.push({ x: (shape.x() + (3 * ratio)), y: (shape.y() + ratio) });
        return;
      case 'endSpliceD':
        this.project.endSplice.D.push({ x: (shape.x() + (3 * ratio)), y: (shape.y() + ratio) });
        return;
      case 'midSpliceD':
        this.project.midSplice.D.push({ x: (shape.x() + (3 * ratio)), y: (shape.y() + ratio) });
        return;
      case 'midSpliceF':
        this.project.midSplice.F.push({ x: (shape.x() + (3 * ratio)), y: (shape.y() + ratio) });
        return;
      case 'endSpliceF':
        this.project.endSplice.F.push({ x: (shape.x() + (3 * ratio)), y: (shape.y() + ratio) });
        return;
    }
  }

  getclamping(attachment: any) {
    switch (attachment) {
      case 'endClampR':
        return this.project.endClamp.R;
      case 'endClampD':
        return this.project.endClamp.D;
      case 'midClampR':
        return this.project.midClamp.R;
      case 'midClampD':
        return this.project.midClamp.D;
      case 'endSpliceD':
        return this.project.endSplice.D;
      case 'midSpliceD':
        return this.project.midSplice.D;
      case 'endSpliceR':
        return this.project.endSplice.R;
      case 'midSpliceR':
        return this.project.midSplice.R;
      case 'midSpliceF':
        return this.project.midSplice.F;
      case 'endSpliceF':
        return this.project.endSplice.F;
    }
  }

  clearclamping() {
    this.project.endClamp.R = [];
    this.project.endClamp.D = [];
    this.project.midClamp.R = [];
    this.project.midClamp.D = [];
    this.project.endSplice.D = [];
    this.project.midSplice.D = [];
    this.project.endSplice.R = [];
    this.project.midSplice.R = [];
    this.project.endSplice.F = [];
    this.project.midSplice.F = [];
  }


  savespinner(state: any) {
    this.spinnerchange.next(state);
  }

  getspinnerValue(): Observable<any> {
    return this.spinnerchange.asObservable();
  }


  setmap() {
    this.project.map = this.mapService.getMap();
    return this.project;
  }


  getProject() {
    return this.project;
  }

  async setprojects(data: any) {
    this.project = data;
  }

  getprojectname() {
    return this.project.name;
  }

  saveprojname() {
    this.projname = this.project.name;
  }

  getprojname() {
    return this.projname;
  }


  async docopy(proj: any) {
    //copying main project
    this.uniqueID = this.firestore.createId();
    this.project = JSON.parse(JSON.stringify(proj));
    this.project.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    this.project.name = this.project.name + ' copy';
    this.project.id = this.uniqueID;
    const copyjref = this.firestore.collection('projects').doc(this.uniqueID);

    //if project have multiple roof areas
    let tempareaid = [];
    for (const [index, areaid] of this.project.areaid.entries()) {
      tempareaid.push(this.uniqueID + (index + 1));
    }

    for (const [index, areaid] of this.project.areaid.entries()) {
      await this.firestore.collection('roofarea').doc(areaid).valueChanges().pipe(take(1)).toPromise().then(async (singarea: any) => {
        let singparse = JSON.parse(JSON.stringify(singarea));
        const arearef = this.firestore.collection('roofarea').doc(this.uniqueID + (index + 1));
        singparse.areaid = tempareaid;
        singparse.name = this.project.name;
        singparse.id = this.uniqueID;
        singparse.timestamp = await firebase.firestore.FieldValue.serverTimestamp();
        await arearef.set(singparse);
      });
    }
    this.project.areaid = tempareaid;
    return copyjref.set(this.project);

  }


  getprojects() {
    const user = firebase.auth().currentUser;
    return this.firestore.collection('projects'
      , ref => {
        return ref.where('uid', '==', user.uid).orderBy('timestamp', 'desc');
      }
    ).valueChanges();
  }


  refreshproject() {
    this.project =

    {
      name: 'Project Name',
      pv: '60',
      output: 270,
      width: 40,
      length: 68,
      thickness: 30,
      weight: 40,
      span: 0.125,
      country: "us",
      state: '',
      address: '',
      zip: '',
      asce: '10',
      snow: '0',
      cansnow: '0.5',
      exposure: 'B',
      speed: '110',
      canspeed: '0.20',
      pitch: '',
      material: 'Asphalt',
      terr: "",
      rafter: '24',
      rt: '',
      type: 'gable',
      mrh: '30',
      asterisk: false,
      clientname: '',
      panels: 0,
      timestamp: null,
      uid: '',
      id: '',
      map: {},
      zonetitle: 'Roof Area 1',
      zone: 'R',
      splice: '-1',
      eave: '0',
      panelarea: 0,
      stageHeight: 0,
      stageImage: '',
      stageMapImage: '',
      stageWidth: 0,
      moduleblocks: [],
      endClamp: { R: [], D: [] },
      midClamp: { R: [], D: [] },
      endSplice: { R: [], D: [], F: [] },
      midSplice: { R: [], D: [], F: [] },
      rafterLines: 0,
      dimension: [],
      failed: true,
      static: '-1',
      staggered: 0,
      clip: '0',
      areaid: [],
      skirtNum: 0,
      totalpanel: '0',
      partlist: []
    };
  }


  pushmoduleblock(block: any) {
    this.project.moduleblocks.push(block.attrs);
  }


  getmoduleblock() {
    return this.project.moduleblocks;
  }

  getbackclamp() {
    return (this.project.endClamp.R.length > 0 || this.project.endSplice.D.length > 0 || this.project.endSplice.R.length > 0);
  }

  getprojectsbyid(id: any) {
    return this.firestore.collection('projects', ref => {
      return ref.where('uid', '==', id);
    }).valueChanges();
  }

  getprojectbyid(id: any) {
    return this.firestore.collection('projects').doc(id).valueChanges();
  }


  getsingleproj(id: any) {
    return this.firestore.collection('projects', ref => {
      return ref.where('id', '==', id);
    }).valueChanges();
  }


  updateproject(id: any) {
    const updref = this.firestore.collection('projects').doc(id);
    return updref.set(this.project);
  }

  postimage(dataURL: any, folder: any) {
    const storageRef = this.fireStorage.ref(folder + '/' + this.project.id + '.png');
    const uploadtask = storageRef.putString(dataURL, 'base64', { contentType: 'image/png' });
    return uploadtask;
  }

  postprojectfromheader(multiprojbool) {
    let mapcond = this.project.country == 'canada' ? (this.project.terr.length == 0 ? false : true) : true;
    var formret = this.snackbarservice.checkForms(this.project.state, this.project.rt, this.project.terr, this.project.country);
    //(!formret && this.router.url != '/design') ? this.postproject() : '';
    (!formret) ? this.postproject() : '';
    (!multiprojbool && this.router.url != '/design') ? this.changeallroofname() : '';
    formret ? '' : this.snackbarservice.checkFormField('Project Saved', 'top');
    (this.router.url != '/design' && this.project.areaid.length > 0) ? this.addtotalpanel() : '';
  }


  postproject() {
    const user = firebase.auth().currentUser;
    this.project.map = this.mapService.getMap();
    this.project.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    this.project.uid = this.project.uid == '' ? user.uid : this.project.uid;
    const projref = this.firestore.collection('projects').doc(this.project.id);
    return projref.set(this.project);
  }

  async getProjectImage() {
    return this.project.stageImage;
  }


  // View the project by going to route: map
  async setUserProject(project: any) {
    this.issaved = false;
    if (project.areaid && project.areaid.length > 0) {
      this.selectedid = project.areaid[0];
      this.setprojects(project);
      this.mapService.setproject(project.map);
    }
    await this.setprojects(project).then(() => {
      if (project.map.workspace === 1) {
        this.mapService.tempurl = project.map.imgSrc;
        this.getBase64FromUrl(project.map.imgSrc).then(basedata => {
          this.mapService.setMap('imgSrc', basedata);
        });
      }
      return project;
    });
  }


  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  filterundefined() {
    Object.keys(this.project).forEach((key) => {
      if (this.project[key] == undefined) {
        delete this.project[key];
      }
    });
  }


}
