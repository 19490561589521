import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from 'src/app/service/project.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-userprojects',
  templateUrl: './userprojects.component.html',
  styleUrls: ['./userprojects.component.css']
})
export class UserprojectsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  userdet: any;
  projects: any;
  lowValue = 0;
  highValue = 10;
  index = 0;
  searchTerm: any;
  tempproj: any;
  pageSize: any;
  constructor(public projectService: ProjectService, public router: Router, public auth: AuthService) { }

  ngOnInit() {
    this.initialize();
    this.searchTerm = '';
  }

  initialize() {
    this.userdet = this.auth.useruid;
    this.projectService.getprojectsbyid(this.userdet).subscribe(data => {
      this.projects = data;
      this.tempproj = this.projects;
      this.projects.sort((a, b) => (b.timestamp) - (a.timestamp));
    });
  }


  getPaginatorData(event) {
    this.pageSize = event.pageSize;
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
  }

  setFilteredItems(searchTerm: any) {
    this.projects = this.tempproj;
    this.projects =
      this.projects.filter((pro: any) => {
        return (pro.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
      });
    this.lowValue = 0;
    this.highValue = this.pageSize;
    this.paginator.firstPage();
  }


  dateformat(date) {
    if (date) {
      const a = new Date(date.seconds * 1000);
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const year = a.getFullYear();
      const month = months[a.getMonth()];
      const dat = a.getDate();
      const hour = (Number(a.getHours()) < 10) ? '0' + a.getHours() : a.getHours();
      const min = (Number(a.getMinutes()) < 10) ? '0' + a.getMinutes() : a.getMinutes();
      const sec = (Number(a.getSeconds()) < 10) ? '0' + a.getSeconds() : a.getSeconds();
      const time = dat + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
      return time;
    }
  }


  gotoproj(singproj: any) {
    this.projectService.setUserProject(singproj).then(() => { this.router.navigate(['/map']); });
  }


}
