<div *ngIf="!DONE">
    <app-transition></app-transition>
</div>
<div *ngIf="DONE" id="wrapper">


<ng-container *ngIf="!direct_bool">
    <div class="accessory">
        <div id="open" class="openTip">
            <button mat-fab type="button" aria-label="Open" (click)="openTip()">
                <span aria-hidden="false">Tip</span>
            </button>
        </div>



        <h4>Select Components</h4>
        <mat-divider class="divider"></mat-divider>

        <mat-selection-list #option [(ngModel)]="selectedAcc" class="item"
            (selectionChange)="setSelectedOptions(option.selectedOptions.selected)">

            <mat-list-option color="primary" checkboxPosition="before" *ngFor="let each of accessories"
                [value]="each.id" [disabled]="true"> {{each.desc}}
                <img matListAvatar src="{{each.img}}" style="border-radius:0px;" />
            </mat-list-option>
            <mat-list-option color="primary" checkboxPosition="before" *ngFor="let each of options" [value]="each.id">
                {{each.desc}}
                <img matListAvatar src="{{each.img}}" style="border-radius:0px;" />
                <mat-icon *ngIf="each.id == 11" title="SDST(Self Drilling Self Tapping Screw)">
                    info_outline</mat-icon>
            </mat-list-option>
            <!-- <mat-list-option color="primary" checkboxPosition="before" *ngIf="!asterisk" [value]="asteriskPart.id"
                (click)="alertAsterisk()" [disableRipple]="true"> {{asteriskPart.desc}}
                <img matListAvatar src="{{asteriskPart.img}}" style="border-radius:0px;" />
                <mat-icon title="SDST(Self Drilling Self Tapping Screw)"> info_outline</mat-icon>
            </mat-list-option> -->
        </mat-selection-list>


        <h4 style="margin-top:50px;">Layout Screenshots</h4>
        <mat-divider class="divider"></mat-divider>

        <div *ngFor="let singorder of multiorder; let i = index" style="margin:10px;">
            <h5 style="margin-bottom: 5px; display: inline-block;">{{ areasTitles[i].title }} </h5>
            <mat-divider style="margin-bottom: 10px; width: 100px;"></mat-divider>
            <img class="layout" src="{{areasImages[i]}}" width="95%;" (click)="viewLayoutLarge(areasImages[i])" />
        </div>

    </div>
	</ng-container>

    <div class="scroll" [ngClass]="direct_bool ? 'bom-part' : '' ">
	<div class="parts-sub-scroll">
        <div>
		<h4 [ngClass]="direct_bool ? 'remove-bom-price' : 'remove-price' " class="remove-price">Remove Price</h4>
        <mat-checkbox class="areacheck" [(ngModel)]="pricebool"></mat-checkbox>
		</div>

	<button mat-raised-button class="down-butt" *ngIf="direct_bool" [disabled]="totalPanels < 1 " (click)="downloadbompdf()" >
	<mat-icon>download</mat-icon>
	Bill OF MATERIALS
	</button>
</div>

		<div class="list" *ngFor="let singorder of multiorder; let i = index">
            <h4 *ngIf="!direct_bool" style="margin-bottom: 5px; display: inline-block;">Part List - {{areasTitles[i].title }} </h4>
            <mat-checkbox *ngIf="areasTitles.length > 1" class="area_part_list"
                [(ngModel)]="areasTitles[i].selected"></mat-checkbox>

            <table mat-table [dataSource]="singorder" class="mat-elevation-z8">
                <!-- Part Type Column -->
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Part Type </th>
                    <td mat-cell *matCellDef="let item"> {{item.type}} </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- Part Number Column -->
                <ng-container matColumnDef="pn">
                    <th mat-header-cell *matHeaderCellDef class="center-cell" style="min-width:120px;"> Part Number
                    </th>
                    <td mat-cell *matCellDef="let item"> {{item.pn}} </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!--Image Column -->
                <ng-container matColumnDef="img">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item"> <img src="{{item.img}}" width="60px;" /></td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- Description Column -->
                <ng-container matColumnDef="desc">
                    <th mat-header-cell *matHeaderCellDef class="center-cell desc-cell"> Description </th>
                    <td mat-cell *matCellDef="let item" class="center-cell desc-cell"> {{item.desc}} </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <!-- Unit Column -->
                <ng-container matColumnDef="um">
                    <div>
                        <th mat-header-cell *matHeaderCellDef class="head-small center-cell"> Unit/Box </th>
                        <td mat-cell *matCellDef="let item" class="center-cell"> {{item.um}} </td>
                        <td mat-footer-cell *matFooterCellDef> </td>
                    </div>
                </ng-container>

                <!-- Quantity Column -->
                <ng-container matColumnDef="qnty">
                    <th mat-header-cell *matHeaderCellDef class="center-cell head-small"> Suggested Quantity </th>
                    <td mat-cell *matCellDef="let item" class="center-cell ">
                        <input readonly #qnty class="qnty" type="number" min="0" [value]="item.qnty">
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>


                <!--Suggested Subtotal Column -->
                <ng-container matColumnDef="sTotal">
                    <th mat-header-cell *matHeaderCellDef class="center-cell"> Suggested Subtotal </th>
                    <td mat-cell *matCellDef="let item" class="center-cell">
                        {{pricebool ? '---' : (getSubtotal(item.cost, item.um, item.qnty) | currency) }} </td>
                    <td mat-footer-cell *matFooterCellDef class="center-cell"> {{ pricebool ? '---' : (getTotalCost(i) |
                        currency) }} </td>
                </ng-container>



                <!-- Designed Quantity Column -->
                <ng-container matColumnDef="des_qnty">
                    <th mat-header-cell *matHeaderCellDef class="center-cell head-small"> Customized Quantity </th>
                    <td mat-cell *matCellDef="let item" class="center-cell">
                        <input #des_qnty class="cust-qnty" type="number" min="0" [value]="item.des_qnty"
                            (change)="setdesignQuantity(singorder, item.id, des_qnty.value)">
                    </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>



                <!-- Cost Column -->
                <ng-container matColumnDef="cost">
                    <th mat-header-cell *matHeaderCellDef> MSRP/Box </th>
                    <td mat-cell *matCellDef="let item" class="center-cell">
                        {{ pricebool ? '---' : (item.cost | currency)}} </td>
                    <td mat-footer-cell *matFooterCellDef class="center-cell"> Total </td>
                </ng-container>



                <!--Designed Subtotal Column -->
                <ng-container matColumnDef="des_total">
                    <th mat-header-cell *matHeaderCellDef class="center-cell"> Customized Subtotal </th>
                    <td mat-cell *matCellDef="let item" class="center-cell">
                        {{pricebool ? '---' : (getSubtotal(item.cost, item.um, item.des_qnty) | currency) }} </td>
                    <td mat-footer-cell *matFooterCellDef class="center-cell"> {{ pricebool ? '---' :
                        (getdesTotalCost(i) | currency) }} </td>
                </ng-container>

                <!-- Table Header -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="first-header-row"></tr>

                <!-- Table Body -->
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                <!-- Table Footer -->
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="first-footer-row"></tr>
            </table>

            <!-- <p style="margin-top: 20px;">
        * RT3-00-AXBK: 1 kit =RT-APEX Base w/ butyl, 1 extra butyl tape, 2 screws/base
        <br>
        * RT3-03-SK80BL: 1 set = 1 RT-Apex Skirt and 1 Skirt bonding clip, 4 set in 1 box
    </p> -->
        </div>
    </div>



	<br>
	<br>

</div>





<div class="row footer" *ngIf="!direct_bool">
    <a mat-raised-button [routerLink]="['/design']">&larr; Back</a>
    <a mat-raised-button [routerLink]="['/engineering']">Next &rarr;</a>
    <app-footer></app-footer>
</div>
