import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DesignModule } from 'src/app/design/design.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { loginComponent } from 'src/app/login/login.component';
import { HomeComponent } from 'src/app/home';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { AdminComponent } from 'src/app/admin/admin.component';
import { DevIssuesComponent } from 'src/app/admin/developer/issues.component';
import { DescriptionComponent } from 'src/app/admin/description/description.component';
import { HeaderComponent } from 'src/app/header/header.component';
import { UsersOperation } from 'src/app/service/usersoperation.service';
import { YourprojectComponent } from 'src/app/yourproject/yourproject.component';
import { ChangepasswordComponent } from 'src/app/changepassword/changepassword.component';
import { DisableaccountComponent } from 'src/app/disableaccount/disableaccount.component';
import { AngularFireModule } from 'angularfire2';
import { environment } from 'src/environments/environment';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { AuthService } from 'src/app/service/auth.service';
import { AuthGuardService } from 'src/app/service/auth-guard.service';
import { DataService } from 'src/app/service/data.service';
import { ExcelService } from 'src/app/service/excel.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { SignupComponent } from 'src/app/signup/signup.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AdminGuardService } from 'src/app/service/admin-guard.service';
import { UserprojectsComponent } from 'src/app/userprojects/userprojects.component';
import { AlertComponent } from 'src/app/alertbox/alert.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CalspanComponent } from 'src/app/calspan/calspan.component';
import { BomcreatorComponent } from 'src/app/bomcreator/bomcreator.component';

import { IssuesComponent } from 'src/app/issues/issues.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RouterModule } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { TransitionComponent } from 'src/app/transition.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { ProductsComponent } from 'src/app/calspan/products.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PostSignupComponent } from 'src/app/postsignup/postsignup.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

//import { FooterComponent } from 'src/app/footer/footer.component';
//import { ReportbuttComponent } from 'src/app/reportbutton/reportbutt.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    loginComponent,
    DashboardComponent,
    AdminComponent,
    TransitionComponent,
    DevIssuesComponent,
    DescriptionComponent,
    HeaderComponent,
    YourprojectComponent,
    //  FooterComponent,
    ChangepasswordComponent,
    DisableaccountComponent,
    SignupComponent,
    UserprojectsComponent,
    AlertComponent,
    CalspanComponent,
    ProductsComponent,
    IssuesComponent,
    // ReportbuttComponent,
    PostSignupComponent,
    BomcreatorComponent
  ],
  imports: [
    BrowserModule,
    DesignModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTableModule,
    AngularFirestoreModule,
    AppRoutingModule,
    MatDialogModule,
    RouterModule,
    RecaptchaModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

  ],
  providers: [
    UsersOperation,
    AuthService,
    AuthGuardService,
    AdminGuardService,
    DataService,
    ExcelService,
    { provide: BUCKET, useValue: 'issues-files' }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AlertComponent,
    TransitionComponent,
    ProductsComponent
  ],
  // exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
