<div *ngIf="!(auth.userData | async)" class="container" style="background:transparent; position:absolute; top:90px;">

    <div id="signup">
        <label class="error text-danger mt-1 dis-block">{{auth.error}}</label>

        <div class="form-group half">
            <label for="Full Name">Full Name *</label>
            <input type="text" class="form-control" required [(ngModel)]="name" placeholder="Full Name">

        </div>

        <div class="form-group half">
            <label for="Phone Number">Phone Number *</label>
            <input type="number" value="+1" class="form-control" required [(ngModel)]="phone"
                placeholder="Phone Number">
        </div>
		
		
		<div class="form-group half">
		 <label for="State">Country *</label>
		   <select id="country" [(ngModel)]="country">
		   <option value="us">United States</option>
		   <option value="canada">Canada</option>
		   </select>
		</div>

        <div class="form-group half" *ngIf="country =='us'">
            <label for="State">State *</label>
            <select id="state" [(ngModel)]="state">
                <option value="AR">Arkansas</option>
                <option value="AZ" selected>Arizona</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DC">District of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <!-- <option value="KY">Kentucky</option> -->
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <!-- <option value="MS">Mississippi</option> -->
                <option value="MO">Missouri</option>
                <!-- <option value="MT">Montana</option> -->
                <!-- <option value="NE">Nebraska</option> -->
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <!-- <option value="ND">North Dakota</option> -->
                <option value="OH">Ohio</option>
                <!-- <option value="OK">Oklahoma</option> -->
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <!-- <option value="SD">South Dakota</option> -->
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <!-- <option value="VT">Vermont</option> -->
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <!-- <option value="WV">West Virginia</option> -->
                <option value="WI">Wisconsin</option>
				
            </select>
        </div>
	
	
	        <div class="form-group half" *ngIf="country =='canada'">
			 <label for="province">Province *</label>
			  <select id="province" [(ngModel)]="canstate">
                <option value="AB" selected>Alberta</option>
                <option value="BC">British Colombia</option>
                <option value="NS">Nova Scotia</option>
                <option value="ON">Ontario</option>
                <option value="SK">Saskatchewan</option>
                <option value="NB">New Brunswick</option>
                <option value="PEI">Prince Edward Island</option>
				</select>
            </div>
	
	
        <div class="form-group">
            <label for="email">Email address *</label>
            <input type="text" class="form-control" [(ngModel)]="email" placeholder="email">
        </div>
        <div class="form-group">
            <label for="pwd">Password *</label>
            <input type="password" class="form-control" [(ngModel)]="password" id="pwd" placeholder="password">
        </div>

        <div class="form-group">
            <label for="reppwd">Repeat Password *</label>
            <input type="password" class="form-control" [(ngModel)]="reppassword" id="rep-pwd" placeholder="password">
        </div>


        <div class="form-group">
            <label for="Company">Company *</label>
            <input type="text" class="form-control" [(ngModel)]="company" placeholder="Company">
        </div>


        <div>
            <mat-expansion-panel class="expansion-box" (opened)="panelOpenState = false"
                (closed)="panelOpenState = true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Terms of Service
                    </mat-panel-title>
                    <mat-panel-description *ngIf="panelOpenState">
                        The Roof Tech Estimator tool was developed in order............
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p style="font-size:12.5px;">The Roof Tech Estimator tool was developed in order to assist users in
                    creating system designs and bill of materials with Roof Tech Apex Rail-less mounting system. It does
                    not guarantee that the resulting system design is appropriate for the application selected by the
                    user nor does it address the adequacy of the supportive framing of the building or structure upon
                    which the system will be installed. By using the Estimator tool, the user claims all responsibility
                    for the accuracy and validity of the design inputs and, thereby, resulting calculations. Roof Tech
                    recommends the user to verify the maximum span values obtained from the sealed PE structural letters
                    available through the Roof Tech web support pages. It is the responsibility of the user to ensure
                    that the system is installed in a code compliant manner based on accurate site information. Roof
                    Tech makes no representation or warranty, either express or implied, of any kind with respect to
                    this software or its contents (such as product information, recommendations, applicability,
                    descriptions or prices) including, but not limited to, merchantability and fitness for a particular
                    purpose, how current it is, its accuracy, its completeness, product availability, its security or
                    that defects will be corrected. Roof Tech is free to use any information you submit via the use of
                    the Estimator tool for marketing, sales and any other lawful purposes.</p>
            </mat-expansion-panel>


            <mat-checkbox color="warn" class="left-check" [(ngModel)]="terms"></mat-checkbox>
        </div>


        <div class="bottomleft">



            <button (click)="signUp()" [disabled]="!terms" class="btn-default signup" style="margin-left:5px;">Sign
                Up</button>

            <button (click)="signUp()" [disabled]="!terms" class="google-singup">
                <img src="../assets/images/google-logo.jpg" width="35px;" />Login with Google</button>

            <!--  <button (click)="login()"  class="btn btn-primary ml-2">Sign Up with your Gmail account / Google</button>
         

		 <img src="../assets/images/google.png" (click)="login()" width="220px;" height="40px;" />
-->


        </div>

    </div>
</div>