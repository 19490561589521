<div class="form-container" *ngIf="step != 'step5'">
    <h5 class="center" id="module-form-title"> Module Information</h5>
    <div>
        <mat-form-field class="half">
            <mat-select matNativeControl placeholder="PV MODULE" [(value)]="pv"
                (selectionChange)="changeLengthByPV($event.value); changeMaxLength(); saveChanges();">
                <mat-option value="60">60 cells (Up to 40"x68")</mat-option>
                <mat-option value="72">72 cells (Up to 40"x77.5")</mat-option>
                <mat-option value="90">Others</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="half">
            <input matInput type="number" placeholder="OUTPUT" min="1" [(ngModel)]="output">
            <span matSuffix>Watt</span>
        </mat-form-field>
    </div>

    <div>
        <div *ngIf="pv == 90; then limit90 else typical"></div>
        <ng-template #limit90>
            <mat-form-field class="half">
                <input matInput type="number" placeholder="WIDTH" min="40" max="50" step="0.125" [(ngModel)]="width">
                <span matSuffix>Inch</span>
            </mat-form-field>
        </ng-template>
        <ng-template #typical>
            <mat-form-field class="half">
                <input matInput type="number" placeholder="WIDTH" min="0" max="40" step="0.125" value="40"
                    [(ngModel)]="width">
                <span matSuffix>Inch</span>
            </mat-form-field>
        </ng-template>

        <mat-form-field class="half">
            <input matInput type="number" placeholder="LENGTH" min="0" max="{{maxLength}}" step="0.125"
                [(ngModel)]="length">
            <span matSuffix>Inch</span>
        </mat-form-field>
    </div>


    <div>
        <mat-form-field class="half">
            <input matInput type="number" placeholder="THICKNESS" min="30" max="46" [(ngModel)]="thickness">
            <span matSuffix>Millimeter</span>
        </mat-form-field>

        <mat-form-field class="half">
            <input matInput type="number" placeholder="WEIGHT" min="0" [(ngModel)]="weight">
            <span matSuffix>Pounds</span>
            <mat-hint>Area: {{ calculateWeigtPerFeet() }} sqft</mat-hint>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field class="full">
            <input matInput type="number" placeholder="INTER-MODULE SPACING" min="0.125" max="2" step="0.125"
                [(ngModel)]="span">
            <span matSuffix>Inch
                <mat-icon title="Space between modules on the horizontal axis. Max 1/8th inch for splice."
                    style="cursor: pointer;">info_outline</mat-icon>
            </span>
        </mat-form-field>

    </div>


    <button mat-raised-button class="save" (click)="saveChanges(); closemodel(); postfromhead();">Save</button>

</div>



<div class="step-container" *ngIf="step != 'step5' && step != 'finished'">
    <span class="close-butt" (click)="closeintro()"><i class="fas fa-times"></i></span>
    <h3 class="mt-0">Step 1</h3>
    <p class="intro-text">Fill Up Module Info</p>
    <button mat-raised-button class="next-butt" (click)="onNext()">Next</button>
    <p class="intro-text "><b>1/5</b></p>
</div>