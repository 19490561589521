import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { AlertComponent } from 'src/app/alertbox/alert.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  email: any;
  error: any;
  alertbox: any;
  constructor(public auth: AuthService, private dialog: MatDialog) {

  }

  ngOnInit() {

  }

  resetfunc() {

    this.auth.resetPassword(this.email)
      .then(res => {
        // console.log(this.auth.forgoterr);
        if (this.auth.forgoterr === '') {
          const dialogRef = this.dialog.open(AlertComponent);

        }
      })
      .catch((error) => console.log(error));

  }


}
