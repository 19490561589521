<app-header (currentpage)="currentroutepage($event)"></app-header>
<div
  *ngIf="router.url == '/' || router.url == '/login' || router.url == '/hlogin' || router.url == '/postsignup' || router.url == '/signup' || router.url == '/changepassword'; bg">
  <div #bg class="bg"></div>
<!--
  <app-footer #bg></app-footer>

  <p style="text-align: center;">&copy; 2020 Roof-tech.us. Designed by Polygons Media</p>
  -->
</div>
<div>
  <router-outlet></router-outlet>
</div>