import { Injectable } from '@angular/core';
import Konva from 'konva';
import { ProjectService } from 'src/app/service/project.service';

@Injectable({
  providedIn: 'root'
})


export class PanelService {

  constructor(public projectService: ProjectService) {
  }

  COLOR_PANEL = '#6495ed';
  COLOR_CURSOR = this.COLOR_PANEL;
  MIN_ZOOM = 0.6;
  MAX_ZOOM = 30;
  posDelta = { x: 0, y: 0 };

  setPanelColor(color: any) {
    this.COLOR_PANEL = color;
  }

  getPanelColor() {
    return this.COLOR_PANEL;
  }

  setCursorColor(color: any) {
    this.COLOR_CURSOR = color;
  }

  // Create a new stage
  addStage(W: any, H: any) {
    return new Konva.Stage({
      container: 'drawing',
      width: W,
      height: H,
      x: 0,
      y: 0,
      fill: 'transparent',
      draggable: false
    });
  }

  // Create a new layer
  addLayer() {
    return new Konva.Layer({
      fill: 'transparent'
    });
  }

  // Create a new group
  addGroup() {
    return new Konva.Group({
      draggable: false,
      listening: true
    });
  }

  // Create a new rectangle
  addPanel(pos: any, WIDTH: any, LENGTH: any, strokeWidth: any) {
    return new Konva.Rect({
      x: pos.x,
      y: pos.y,
      width: WIDTH,
      height: LENGTH,
      fill: this.COLOR_PANEL,
      stroke: 'dimgray',
      strokeWidth,
      draggable: false,
      name: 'panel'
    });
  }

  addPossPanel(pos: any, WIDTH: any, LENGTH: any, strokeWidth: any) {
    return new Konva.Rect({
      x: pos.x,
      y: pos.y,
      width: WIDTH,
      height: LENGTH,
      fill: this.COLOR_PANEL,
      stroke: 'dimgray',
      strokeWidth,
      draggable: false,
      name: 'poss_panel',
      opacity: 0.5
    });
  }

  // Show panel shadow on mouse
  showPanelOnMouse(pos: any, WIDTH: any, LENGTH: any, strokeWidth: any) {
    return new Konva.Rect({
      x: pos.x - WIDTH / 2,
      y: pos.y - LENGTH / 2,
      width: WIDTH,
      height: LENGTH,
      fill: this.COLOR_CURSOR,
      stroke: 'dimgray',
      strokeWidth,
      opacity: 1,
      draggable: false,
      name: 'cursor'
    });
  }


  // Create an Background Image Object
  createBackgroundImage(img: any, W: any, H: any) {
    return new Konva.Image({
      x: 0,
      y: 0,
      image: img,
      opacity: 1,
      width: W,
      height: H,
      draggable: false,
    });
  }

  // Create an Background Gray Watermark Object
  createBackgroundWatermark(W: any, H: any) {
    return new Konva.Rect({
      x: 0,
      y: 0,
      width: W,
      height: H,
      fill: 'black',
      draggable: false,
      opacity: 0.7
    });
  }

  // create the map polygon overlay
  createMapPolygonArea(points: any) {
    return new Konva.Line({
      points,
      fill: 'white',
      stroke: 'white',
      opacity: 0.5,
      strokeWidth: 0.5,
      closed: true,
      name: 'roof',
    });
  }

  // create the map polygon Eve side line
  createMapPolygonEveSide(points: any) {
    return new Konva.Line({
      points,
      stroke: 'white',
      opacity: 1,
      strokeWidth: 0.5,
      dash: [0.5, 0.5],
      closed: false,
      name: 'eve',
    });
  }


  // Create an empty Rect as Selection Area
  createTableDrawOnMouse() {
    return new Konva.Rect({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      fill: 'transparent',
      stroke: 'black',
      strokeWidth: 1,
      name: 'selection'
    });
  }
  emptyrect(posx, posy) {
    return new Konva.Rect({
      x: posx,
      y: posy,
      width: 20,
      height: 20,
      fill: 'transparent',
      stroke: 'black'
    });
  }

  // Create transparent Rect to catch events of table draw
  createEventRect(x: any, y: any, W: any, H: any) {
    return new Konva.Rect({
      x,
      y,
      width: W,
      height: H,
      fill: 'transparent',
      stroke: 'black',
      strokeWidth: 5
    });
  }

  // Create table size tooltip
  createTooltipOnMouse(zoom: any) {
    return new Konva.Text({
      text: '',
      fontFamily: 'Calibri',
      fontSize: (16 / zoom),
      padding: 5,
      textFill: 'black',
    });
  }

  // Create Gridline
  createGridLines(x: any, y: any, width: any, height: any) {
    const rafter = new Konva.Line({
      points: [x, y, width, height],
      stroke: 'rgb(0, 161, 255)',
      strokeWidth: 0.5,
      name: 'grid',
      dragBoundFunc: (pos: any) => {
        return {
          x: pos.x,
          y: rafter.getAbsolutePosition().y,
        };
      },
    });
    return rafter;
  }

  // Create Clamps
  createClamps(X: any, Y: any, color: any, ratio: any) {
    const clamp = new Konva.Circle({
      x: X,
      y: Y,
      radius: 0.5 * ratio,
      stroke: color,
      strokeWidth: 0.5 * ratio,
      fill: 'white',
      name: 'clamp',
      draggable: true,
      dragBoundFunc: (pos: any) => {
        return {
          x: pos.x,
          y: clamp.getAbsolutePosition().y,
        };
      },
    });
    return clamp;
  }

  createundoClamps(X: any, Y: any, color: any, ratio: any, strokeWidth: any, stroke: any) {
    const clamp = new Konva.Circle({
      x: X,
      y: Y,
      radius: ratio,
      stroke: stroke,
      strokeWidth: strokeWidth,
      fill: color,
      name: 'clamp',
      draggable: true,
      dragBoundFunc: (pos: any) => {
        return {
          x: pos.x,
          y: clamp.getAbsolutePosition().y,
        };
      },
    });
    return clamp;
  }


  // Create Splice
  createSplice(X: any, Y: any, color: any, ratio: any) {
    return new Konva.Rect({
      x: X - (1.5 * ratio),
      y: Y - (0.5 * ratio),
      stroke: color,
      strokeWidth: 0.5 * ratio,
      width: 3 * ratio,
      height: 1 * ratio,
      name: 'splice'
    });
  }


  // Create Clamps Conflict
  createConflict(X: any, Y: any, color: any, ratio: any) {
    const conflict = new Konva.Circle({
      x: X,
      y: Y,
      radius: ratio * 3,
      stroke: color,
      strokeWidth: ratio,
      fill: 'transparent',
      name: 'conflict',
      draggable: false
    });
    return conflict;
  }


  // Add Text (F) to Floating splices
  createNote(X: any, Y: any, color: any, ratio: any) {
    return new Konva.Text({
      x: X - ratio,
      y: Y - ratio,
      text:
        'F',
      fontSize: 2.5,
      fontFamily: 'Calibri',
      fill: color,
      name: 'splice',
      align: 'center',
    });
  }


  setZoomStage(scaleBy: any, center: any, stage) {
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: center.x / oldScale - stage.x() / oldScale,
      y: center.y / oldScale - stage.y() / oldScale
    };
    let newScale = oldScale * scaleBy;
    // Set zooming limitation
    const zoomOut = this.MIN_ZOOM;
    if (newScale <= zoomOut) {
      newScale = zoomOut;
    } else if (newScale >= this.MAX_ZOOM) {
      newScale = this.MAX_ZOOM;
    }
    stage.scale({ x: newScale, y: newScale });
    const newPos = {
      x:
        -(mousePointTo.x - center.x / newScale) *
        newScale,
      y:
        -(mousePointTo.y - center.y / newScale) *
        newScale
    };
    this.posDelta = newPos;
    stage.position(newPos);
    stage.batchDraw();
  }

  //save project layout image
  saveprojectlayout(designLayer, designGroup, stage, stageCenterPoint) {
    const pixelRatio = 6;
    const { w, h, x, y } = this.getProjectDimensions(designGroup, stage);
    this.restoreZoomView(stage, stageCenterPoint);
    const dataURL = designLayer.toDataURL({ x: x - 20, y: y - 20, width: w + 40, height: h + 40, pixelRatio });
    this.projectService.setProject('stageImage', dataURL);
  }

  restoreZoomView(stage, stageCenterPoint) {
    this.setZoomStage((1 / stage.scaleY()), stageCenterPoint, stage);
    stage.position({ x: 0, y: 0 });
  }

  getProjectDimensions(designGroup, stage) {
    // Save the design Group measurements
    const clampBox = designGroup.getClientRect({ skipStroke: false });
    const groupWidth = clampBox.width / stage.scaleX();
    const groupHeight = clampBox.height / stage.scaleY();
    const groupX = (clampBox.x - this.posDelta.x) / stage.scaleX();
    const groupY = (clampBox.y - this.posDelta.y) / stage.scaleY();
    this.projectService.setProject('stageWidth', groupWidth);
    this.projectService.setProject('stageHeight', groupHeight);
    return { w: groupWidth, h: groupHeight, x: groupX, y: groupY };
  }

}
