import { Component, OnInit, OnDestroy} from '@angular/core';
import { ProjectService } from 'src/app/service/project.service';
import { Router } from '@angular/router';
import { SpanService } from 'src/app/service/span.service';
import Konva from 'konva';
import { PanelService } from 'src/app/service/panel.service';
import { DesignService } from 'src/app/service/design.service';
import { AuthService } from 'src/app/service/auth.service';
import { DataService } from 'src/app/service/data.service';


@Component({
  selector: 'app-bomcreator',
  templateUrl: './bomcreator.component.html',
  styleUrls: ['./bomcreator.component.css']
})
export class BomcreatorComponent implements OnInit {

  stage: Konva.Stage;
  designLayer: Konva.Layer;
  background: Konva.Layer;
  designGroup: Konva.Group;
  gridGroup: Konva.Group;
  clampGroup: Konva.Group;
  country = 'us';
  product = 'RT-';
  pv: any;
  panel_WIDTH: any;
  panel_LENGTH: any;
  panelarea: any;
  asce: any;
  snow: any;
  terrain = '';
  wind: any;
  exposure: any;
  speed: any;
  rt: any;
  type: any;
  pitch: any;
  address = "";
  client = "";
  zip = "";
  state: any;
  province: any;
  zone: any;
  mrh: any;
  asterisk = false;
  clip = false;
  splice = "";
  row = 1;
  column = 1;
  maxRow = 1;
  maxColumn = 1;
  panels = 0;
  SPAN = 0.125;
  totalPanels = 0;
  rafter: any;
  MAX_SPANS: any;
  MAP_TO_PIXEL_PROPORTION = 1.6; // Ratio = Inch per pixel
  W: number = Number(Math.floor((document.body.clientWidth * .3)).toFixed());
  H: number = Number(Math.floor((document.body.clientHeight * .3)).toFixed());
  stageCenterPoint = { x: this.W / 2, y: this.H / 2 };
  PANEL_STROKEWITH = 0;
  WIDTH = 0;
  LENGTH = 0;
  SPACING = { x: 0, y: 0 };
  orientation = false;
  STARTING_POINT = { x: 10, y: -15 };
  STAGE_PADDING = { x: 10, y: 15 };
  first_click = false;
  //setting rafter spacing to 48"
  //RAFTER_SPACE =  (48 / this.MAP_TO_PIXEL_PROPORTION  ) ;
  RAFTER_SPACE = Number(this.projectService.getProject().rafter) / this.MAP_TO_PIXEL_PROPORTION;
  RAFTER_DRAG = 0;
  posDelta = { x: 0, y: 0 };

  constructor(
    public projectService: ProjectService,
    public router: Router,
    public panelService: PanelService,
    public designservice: DesignService,
    public authservice: AuthService,
    public dataservice: DataService
  ) { }

  ngOnInit(): void {

    this.projectService.tableBody = [];
    this.projectService.refreshproject();
    this.initializeVariables();
    this.calcdrawvalues();
    this.initializeStage();
    setTimeout(() => {
      this.projectService.savespinner('off');
      this.calculateDrawingPanels();
    }, 100);
  }


  initializeVariables() {
    const data = this.projectService.getProject();
    this.pv = data.pv;
    this.panel_WIDTH = data.width;
    this.panel_LENGTH = data.length;
    this.asce = data.asce;
    this.snow = data.snow;
    this.exposure = data.exposure;
    this.speed = data.speed;
    this.rt = data.rt;
    this.type = data.type;
    this.state = data.state;
    this.province = data.state;
    this.zone = '1';
    this.pitch = data.pitch;
    this.rafter = data.rafter;
    //this.splice = '0';
  }

  calcdrawvalues() {
    this.PANEL_STROKEWITH = (this.panel_WIDTH / this.MAP_TO_PIXEL_PROPORTION) * 0.08;
    this.WIDTH = (this.panel_WIDTH / this.MAP_TO_PIXEL_PROPORTION) - (2 * this.PANEL_STROKEWITH);
    this.LENGTH = (this.panel_LENGTH / this.MAP_TO_PIXEL_PROPORTION) - (2 * this.PANEL_STROKEWITH);
    this.SPACING = { x: (this.SPAN / this.MAP_TO_PIXEL_PROPORTION), y: (1.0625 / this.MAP_TO_PIXEL_PROPORTION) };
    this.calculateMaxRow();
    this.calculateMaxColumn();
  }

  initializeStage() {
    this.stage = this.panelService.addStage(this.W, this.H);
    this.designLayer = this.panelService.addLayer();
    this.designGroup = this.panelService.addGroup();
    this.designLayer.add(this.designGroup);
    this.stage.add(this.designLayer);
    this.panelService.setZoomStage(1, this.STARTING_POINT, this.stage);
    this.stage.draw();

    // Initiate the attachment Group
    this.clampGroup = this.panelService.addGroup();
    this.designLayer.add(this.clampGroup);
  }

  calculateTotalPanelDimensions() {
    const width = this.orientation ? (this.WIDTH + this.SPACING.x + this.PANEL_STROKEWITH) : (this.LENGTH + this.SPACING.x + this.PANEL_STROKEWITH);
    const height = this.orientation ? (this.LENGTH + this.SPACING.y + this.PANEL_STROKEWITH) : (this.WIDTH + this.SPACING.y + this.PANEL_STROKEWITH);
    return { width, height };
  }

  calculateMaxRow() {
    const { height } = this.calculateTotalPanelDimensions();
    this.maxRow = Number(Math.floor((this.H - this.STAGE_PADDING.y * 2) / height).toFixed());
  }

  calculateMaxColumn() {
    const { width } = this.calculateTotalPanelDimensions();
    this.maxColumn = Number(Math.floor((this.W - this.STAGE_PADDING.x * 2) / width).toFixed());
  }

  onPanelOrientationChanges() {
    this.clearstage();
    this.row = 1;
    this.column = 1;
    this.calculateMaxRow();
    this.calculateMaxColumn();
    this.calculateDrawingPanels();
  }

  onPanelCountChanges() {
    this.clearstage();
    this.calculateDrawingPanels();
  }

  clearstage() {
    this.designLayer.find('.panel').each(shape => { shape.destroy(); });
    if (this.clampGroup.children.length > 0) { this.clampGroup.destroyChildren(); }
    this.designLayer.draw();
  }

  calculateDrawingPanels() {
    this.totalPanels = this.row * this.column;
    this.projectService.setProject('panels', this.totalPanels);
	this.calcdrawvalues();
	const { width, height } = this.calculateTotalPanelDimensions();
    const startpos = this.STARTING_POINT;
    const endpos = { x: (startpos.x + (width * this.column) + this.SPACING.x), y: (startpos.y + (height * this.row) + this.SPACING.y) };
	this.populatePanels(startpos, endpos);
  }

  populatePanels(start: any, end: any) {
    const dragWidth = end.x - start.x;
    const dragHeight = end.y - start.y;
    const bottomLeft = { x: start.x, y: start.y + dragHeight };
    const topRight = { x: start.x + dragWidth, y: start.y };
    const position = bottomLeft;
    const originX = bottomLeft.x;
	const { width , height } = this.calculateTotalPanelDimensions();
    while ((position.y - height) > topRight.y) {
      while (position.x + width < topRight.x) {
        const panel = this.orientation ? this.panelService.addPanel(position, this.WIDTH, this.LENGTH, this.PANEL_STROKEWITH) :
          this.panelService.addPanel(position, this.LENGTH, this.WIDTH, this.PANEL_STROKEWITH);
        this.designGroup.add(panel);
        panel.moveToTop();
        position.x += width;
      }
      position.x = originX;
      position.y -= height;
    }
    this.designLayer.batchDraw();
  }

  saveChanges() {
    if (!this.checkdisable()) {
      this.projectService.savespinner('on');
      this.projectService.setProject('pv', this.pv);
	  this.projectService.setProject('width', this.WIDTH);
      this.projectService.setProject('length', this.LENGTH);
	  this.projectService.setProject('asce', this.asce);
      this.projectService.setProject('snow', this.snow);
      this.projectService.setProject('exposure', this.exposure);
      this.projectService.setProject('speed', (this.country === 'us') ? this.speed : this.wind);
      this.projectService.setProject('rt', this.rt);
      this.projectService.setProject('type', this.type);
      this.projectService.setProject('pitch', this.pitch);
      this.projectService.setProject('address', this.address);
      this.projectService.setProject('clientname', this.client);
      this.projectService.setProject('zip', this.zip);
      //this.projectService.setProject('state', (this.country === 'us') ? this.state : this.province);
      this.projectService.setProject('state', this.state);
      this.projectService.setProject('panelarea', this.panelarea);
      this.projectService.setProject('mrh', this.mrh);
      this.projectService.setProject('splice', this.splice);
      this.projectService.setProject('terr', this.terrain);
      this.projectService.setProject('speed', this.speed);
      this.projectService.setProject('country', this.country);
      this.projectService.setProject('name', this.client);
      this.projectService.setProject('orientation', this.orientation);
      let skirtNum = this.designservice.calculateBottomRowPanelsSkirts(this.designGroup, this.PANEL_STROKEWITH);
      this.projectService.setProject('skirtNum', skirtNum);

      this.checkdisable();
      this.designservice.getMaxSpan(this.rt, this.asterisk, this.country, this.orientation)
        .then((span) => {
          this.MAX_SPANS = span;
          //saving extra fields for BOM DB
          this.projectService.setProject('max_span', this.MAX_SPANS);
          this.projectService.setProject('user_name', this.authservice.getusername().name);
          this.projectService.setProject('company', this.authservice.getusername().company);
          this.projectService.setProject('date', new Date());
          this.projectService.setProject('panels_row', this.row);
          this.projectService.setProject('panels_column', this.column);
          //removing undefined fields to post on firebase
          this.projectService.filterundefined();
          this.first_click = true;
          const { width } = this.calculateTotalPanelDimensions();
          this.rt .slice(0, 6) === 'rafter' ? this.designservice.showAllPossibleRafterAttachments(this.orientation, this.designGroup, this.MAX_SPANS, this.SPACING, this.PANEL_STROKEWITH, this.clampGroup, this.designLayer, this.gridGroup, this.rt, this.WIDTH, this.RAFTER_DRAG, this.posDelta, this.stage, ((this.column - 1) * width)) :
            this.designservice.showAllPossibleDeckAttachments(this.orientation, this.designGroup, this.MAX_SPANS, this.SPACING, this.PANEL_STROKEWITH, this.clampGroup, this.designLayer);
          setTimeout(() => {
            this.projectService.savespinner('off');
            this.projectService.setProject('parts_table', this.projectService.tableBody[0]);
            //savelayout for pdf
            this.panelService.saveprojectlayout(this.designLayer, this.designGroup, this.stage, this.stageCenterPoint);
            this.dataservice.savedoc('bom_projects', this.projectService.getProject());
          }, 700);
        })
        .catch(err => {
          console.log('ERROR getting max span', err);
          this.maxSpanPanic();
        });
    }
  }

  maxSpanPanic() {
    if (!window.confirm('Your input combination resulted in error, click ok to refresh!')) return;
    window.location.reload();
  }

  countrychg(value) {
	this.subcountreset();
	if (value == 'canada') {
	this.snow = '0.5';
	this.speed = '0.20';
	}
    this.checkdisable();
  }
	
  subcountreset() {
  	//reset values on country change
	this.removeattachments();
	this.row = 1;
	this.column = 1;
	this.projectService.refreshproject();
	this.ngOnInit();
	this.first_click = false;
	this.rafterspacechg()
  }	  
	
  checkdisable() {
    if (this.country == 'us') {
      return (this.state == '' || this.pitch == '' || this.rt == '' || this.splice == '') ? true : false;
    }
    else if (this.country == 'canada') {
      return (this.state == '' || this.terrain == '' || this.pitch == '' || this.rt == '' || this.speed == '') ? true : false;
    }
  }


  changeLengthByPV(value: any) {
    if (value === '72') {
      this.panel_WIDTH = '40';
      this.panel_LENGTH = '77.5';
    }
    if (value === '60') {
      this.panel_WIDTH = '40';
      this.panel_LENGTH = '68';
    }
    if (value === '90') {
      this.panel_WIDTH = '44';
      this.panel_LENGTH = '68';
    }
  }

  changeMinSpeed(value: any) {
    this.speed = (value === '10') ? '110' : '95';
  }



  screwchg() {
    this.projectService.setProject('asterisk', this.asterisk);
    this.saveChanges();
  }

  clipchg() {
    this.clip ? this.projectService.setProject('clip', 1) : this.projectService.setProject('clip', '0');
  }

  rtchange(val) {
    if (val.includes('rafter')) {
      this.splice = '';
      this.splice = '2';
      this.drawGridLines();
      this.projectService.setProject('static', 48);
    }
    else {
      this.splice = '0';
      if (this.gridGroup != undefined) { this.removegridlines(); }
    }
  }
  // Draw Grid lines for Rafter spacing reference
  drawGridLines() {
    if ((this.gridGroup == undefined || this.gridGroup.children.length == 0) && this.rt.includes('rafter') ) {
      this.RAFTER_SPACE = Number(this.rafter) / this.MAP_TO_PIXEL_PROPORTION;
      this.gridGroup = this.panelService.addGroup();
      this.designLayer.add(this.gridGroup);
      const rafter_line_starting_point = this.calculateRafterlineStartingPoint();
      for (let index = rafter_line_starting_point.x; index <= this.W;) {
        const line = this.panelService.createGridLines(index, rafter_line_starting_point.y, index, this.H);
        this.gridGroup.add(line);
        this.gridGroup.moveDown();
        index += this.RAFTER_SPACE;
      }
      this.designLayer.batchDraw();
    }
  }

  rafterspacechg() {
    if (this.gridGroup != undefined) {
      this.removegridlines();
      this.drawGridLines();
    }
    else {
      this.drawGridLines();
    }

  }


  removegridlines() {
    this.gridGroup.destroyChildren(); this.designLayer.batchDraw();
  }
  
  // calculate rafter line starting point for maximum clamping efficiency
  calculateRafterlineStartingPoint() {
    const { width } = this.calculateTotalPanelDimensions();
    const rafter_space = Number(this.rafter) / this.MAP_TO_PIXEL_PROPORTION;
    return { x: (this.STARTING_POINT.x + (width * 0.125)) - rafter_space, y: this.STARTING_POINT.y };  // cantilever check is 0.15
  }

	removeattachments() {
		this.designLayer.find('.clamp').each(shape => {
		shape.destroy();
		});
		this.designLayer.find('.splice').each(shape => {
		shape.destroy();
		});
		this.designLayer.draw();
	}

}



