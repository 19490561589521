import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/firestore';
import { ExcelService } from 'src/app/service/excel.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})



export class AdminComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  users: any;
  sortname: boolean;
  sortemail: boolean;
  pageSize = 10;
  lowValue = 0;
  highValue = 10;
  pageindex = 0;
  tempusers: any;
  searchTerm: any;


  constructor(public auth: AuthService, public router: Router, private snackBar: MatSnackBar,
    private firestore: AngularFirestore, public excelservice: ExcelService) { }

  ngOnInit() {
    this.initialize();
    this.sortname = true;
    this.sortemail = true;
    this.searchTerm = '';
  }

  ngOnDestroy() {
    this.snackBar.dismiss();
  }

  initialize() {
    this.auth.getusers().subscribe(data => {
      this.users = data;
      this.tempusers = this.users;
      this.users.sort((a, b) => a.name.localeCompare(b.name));
      if (this.searchTerm.length > 0) {
        this.setFilteredItems(this.searchTerm);
      }
    });

  }


  userpropchange(key, value, uid, msg) {
    this.firestore.collection('users').doc(uid).update({
      [key]: value
    });
    this.openSnackbar("User " + msg + " status has been changed", 1500);
  }


  openSnackbar(msg: string, duration: number) {
    this.snackBar.open(msg, 'OK', {
      duration,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }


  getstatusnum(reg_status) {
    return reg_status;
  }


  getPaginatorData(event: any) {
    this.pageSize = event.pageSize;
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
  }

  setFilteredItems(searchTerm: any) {
    const lowval = this.lowValue;
    const highval = this.highValue;
    this.users = this.tempusers;
    this.users =
      this.users.filter((user: any) => {
        return ((user.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
          (user.email.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1));
      });
    this.lowValue = 0;
    this.highValue = this.pageSize;
    // this.paginator.firstPage();
  }



  sort() {
    if (this.sortname) {
      this.sortname = !this.sortname;
      this.users.sort((a, b) => b.name.localeCompare(a.name));
    } else {
      this.sortname = !this.sortname;
      this.users.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  sortemailfunc() {
    if (this.sortemail) {
      this.sortemail = !this.sortemail;
      this.users.sort((a, b) => b.email.localeCompare(a.email));
    } else {
      this.sortemail = !this.sortemail;
      // console.log(this.sortemail);
      this.users.sort((a, b) => a.email.localeCompare(b.email));
    }
  }

  gotoproj(singuser) {
    this.auth.useruid = singuser.uid;
    this.router.navigate(['/userprojects']);
  }

  downloadbom() {
    this.excelservice.downloadcsv();
  }

}
