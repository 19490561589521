import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
// tslint:disable-next-line: class-name
export class loginComponent {

  // here is code for username and password login
  email: string;
  password: string;
  error: string;

  constructor(public auth: AuthService) {
    this.email = '';
  }

  login() {
    this.auth.login();
  }


  signIn() {
    this.auth.SignIn(this.email, this.password);
    this.email = '';
    this.password = '';
  }

  signOut() {
    this.auth.SignOut();
  }

}
