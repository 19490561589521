<div style="padding: 20px;">
  <h3 *ngIf="userdet" class="ml-2">Projects of {{userdet.name}}</h3>

  <div class="row searchbox">

    <p>FILTER</p>

    <input type="text" placeholder="Search.." [(ngModel)]="searchTerm" (ngModelChange)="setFilteredItems(searchTerm)">


  </div>
  <table class="table table-condensed">
    <thead>
      <tr>
        <th>PROJECT NAME</th>
        <th>CLIENT NAME</th>
        <th>CREATED</th>
        <th>State</th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pro of projects | slice: lowValue : highValue;">
        <td class="namecol" (click)="gotoproj(pro)">{{pro.name}}</td>

        <td>{{pro.clientname}}</td>
        <td>{{dateformat(pro.timestamp)}}</td>
        <td>{{pro.state}}</td>
      </tr>

    </tbody>
  </table>

  <div *ngIf="projects">
    <mat-paginator [length]="projects.length" [pageSizeOptions]="[10, 20, 50, 100]" (page)="getPaginatorData($event)">
    </mat-paginator>
  </div>
</div>

<div class="row footer">
<app-footer></app-footer>
</div>