import { Component, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, NgZone, OnInit, ViewChild } from '@angular/core';
import { MapService } from 'src/app/service/map.service';
import { ProjectService } from 'src/app/service/project.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-yourproject',
  templateUrl: './yourproject.component.html',
  styleUrls: ['./yourproject.component.css']
})
export class YourprojectComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  projects = [];
  tempproj: any;
  destroyed = false;
  public searchTerm = '';
  lowValue = 0;
  highValue = 10;
  index = 0;
  pageSize: any;
  sortname: boolean;
  sortdate: boolean;
  sortclient: boolean;
  DONE = false;


  constructor(
    private projectService: ProjectService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private ngZone: NgZone,
    public mapService: MapService,
    private snackBar: MatSnackBar) {
  }


  ngOnInit() {
    // this.ngZone.run(() => {
    // });
	this.projectService.postprojnotif()
    this.initialize().then(() => {
      this.sortname = true;
      this.sortdate = true;
      this.sortclient = true;
     // this.DONE = true;
    });
  }

  ngOnDestroy() {
    this.snackBar.dismiss();
  }

  getPaginatorData(event: any) {
    this.pageSize = event.pageSize;
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;

  }

  async initialize() {
    this.projectService.getprojects().subscribe(data => {
      this.projects = data;
      this.tempproj = this.projects;
      this.projects.sort((a, b) => (b.timestamp) - (a.timestamp));
	  this.DONE = true;	
	});
  }


  sort() {
    if (this.sortname) {
      this.sortname = !this.sortname;
      this.projects.sort((a, b) => b.name.localeCompare(a.name));
    } else {
      this.sortname = !this.sortname;
      this.projects.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  sortbyclient() {
    if (this.sortclient) {
      this.sortclient = !this.sortclient;
      this.projects.sort((a, b) => b.clientname.localeCompare(a.clientname));
    } else {
      this.sortclient = !this.sortclient;
      this.projects.sort((a, b) => a.clientname.localeCompare(b.clientname));
    }
  }

  sortbydate() {
    if (this.sortdate) {
      this.sortdate = !this.sortdate;
      this.projects.sort((a, b) => (a.timestamp) - (b.timestamp));
    } else {
      this.sortdate = !this.sortdate;
      this.projects.sort((a, b) => (b.timestamp) - (a.timestamp));
    }
  }

  dateformat(date) {
    if (date) {
      const a = new Date(date.seconds * 1000);
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const year = a.getFullYear();
      const month = months[a.getMonth()];
      const dat = a.getDate();
      const hour = (Number(a.getHours()) < 10) ? '0' + a.getHours() : a.getHours();
      const min = (Number(a.getMinutes()) < 10) ? '0' + a.getMinutes() : a.getMinutes();
      const sec = (Number(a.getSeconds()) < 10) ? '0' + a.getSeconds() : a.getSeconds();
      const time = dat + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
      return time;
    }
  }

  setFilteredItems(searchTerm: any) {
    this.projects = this.tempproj;
    this.projects =
      this.projects.filter((pro: any) => {
        return (pro.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
      });
    this.lowValue = 0;
    this.highValue = this.pageSize;
    this.paginator.firstPage();
  }


  // V3
  deletefunc(pro: any) {
    const name = pro.name;
    this.projectService.deleteunsavedprojects(pro.areaid, pro.id).then(_ => {
      const copyMsg = 'Successfully deleted the project ' + name + '.';
      this.openSnackbar(copyMsg, 5000);
    }).catch((error) => {
      console.error('Error deleting document: ', error);
    });
  }

 async copyfunc(pro: any) {
	 this.DONE = false;	 
    const name = pro.name;
    this.projectService.docopy(pro).then(async _ => {
      const copyMsg = 'Successfully copied the project ' + name + '.';
      this.openSnackbar(copyMsg, 5000);
	 //  this.DONE = true;	
    }).catch((error) => {
	   this.DONE = true;			
      console.error('Error copying document: ', error);
    });
  }



  // V3
  gotoproj(singproj: any) {
    this.projectService.setUserProject(singproj).then(() => { this.router.navigate(['/map']); });
  }


  openSnackbar(msg: string, duration: number) {
    this.snackBar.open(msg, 'OK', {
      duration,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }


}
