import { Component, OnInit, OnDestroy } from '@angular/core';
import { PdfMakeWrapper, Txt, Img, Rect, Ellipse, Canvas, Polyline, Table, Columns, Line, Cell } from 'pdfmake-wrapper';
import * as pdfFonts from 'src/app/pdfFonts.js';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjectService } from 'src/app/service/project.service';
import { SpanService } from 'src/app/service/span.service.js';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { IssuesComponent } from 'src/app/issues/issues.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MapService } from 'src/app/service/map.service';



@Component({
  selector: 'app-engineering',
  templateUrl: './engineering.component.html',
  styleUrls: ['./engineering.component.css']
})
export class EngineeringComponent implements OnInit, OnDestroy {

  pdfURL: any;
  pdfURLReady = true;
  pdfSource: any;
  asce10 = 'https://design.roof-tech.us/PDF/Stamped-PE-Letters/APEX_7_10/APEX_7_10_';
  asce16 = 'https://design.roof-tech.us/PDF/Stamped-PE-Letters/APEX_7_16/APEX_7_16_';
  project: any;
  addressInfo: any;
  projectInfo: any;
  date = this.getDateOnly();
  spacone = [];
  spacsdst = [];
  spaclands = [];
  spacsdstlands = [];
  spaconeD = [];
  spacsdstD = [];
  spaclandsD = [];
  spacsdstlandsD = [];
  spanLand = [];
  spanPort = [];
  DONE = false;
  workspace = this.mapService.getMap().workspace;
  // phase 2
  projlen = 0;
  multiprojects = [];
  country_bool = this.projectService.getProject().country == undefined ? 'us' : this.projectService.getProject().country;

  // Details of Criteria Page 10 & 16:
  getCriteria10 = [
    '1.    Building mean roof height = 30 ft maximum.',
    '2.    Risk Category = II.',
    '3.    Solar panel maximum width = 40 in for Landscape, 68 in for Portrait 60 Cell & 77.5 in for Portrait 72 Cell.',
    '4.    Solar panel & rail dead load = approximately 4.0 psf.',
    '5.    ASCE 7-10.',
    '6.    OSB shall be 24/16 APA rated sheathing minimum (7/16" thick).',
    '7.    Plywood shall be 32/16 APA rated sheathing minimum (15/32" thick).',
    '8.    Sheathing shall be free of defects including, but not limited to water damage and delamination.',
    '9.   Roof rafters or trusses spaced at 24" on center maximum.',
    '10.   PV panels must be supported per the manufacturer\'s required orientation, location and/or spacing.',
    '11.   The mounts may be installed through a maximum of 2 layers of composite asphalt roof shingles or maximum 20 gage metal decking provided the metal is predrilled as wood screws are not designed to penetrate the metal decking.  Not valid on concrete roof tile roofs.'
  ];
  getCriteria16 = [
    '1.    Building mean roof height = 30 ft maximum.',
    '2.    Risk Category = II.',
    '3.    Solar panel maximum allowable tributary (trib.) widths = 40 inches for Landscape tables, 68 inches for Portrait 60 Cell tables & 77.5 inches for Portrait 72 Cell tables.\n' +
    '\t      a.    Please note that other PV panels may be used, eg. 90 cell panels, provided they do not exceed the maximum allowable tributary width for the given table used for installation.\n' +
    '\t      b.    It is acceptable to reduce the maximum span shown in the tables for larger tributary widths by the following equation: "New Max. Span" = "Table Max. Span" x "Table Trib. Width" /  "New Trib. Width"; eg. If "New Trib.Width" = 44 inches, you can use the Landscape Tables (40 inch Trib. Width), but you will need to reduce the span as follows: "New Max. Span" = "Table Max. Span" x "40 inches" / "44 inches".  So if the "Table Max. Span" = 96 inches the "New Max. Span" = "96 inches" x "40 inches" / "44 inches" = "87 inches".',
    '4.    Solar panel & rail dead load = approximately 4.0 psf.',
    '5.    ASCE 7-16.',
    '6.    Gamma E = edge array factor, has been taken as 1.5.',
    '7.    Gamma a = solar panel pressure equalization factor, has been taken as 0.6 assuming a min. array area of 31.5 sq. ft.',
    '8.    OSB shall be 24/16 APA rated sheathing minimum (7/16"  thick).',
    '9.    Plywood shall be 32/16 APA rated sheathing minimum (15/32"  thick).',
    '10.   Sheathing shall be free of defects including, but not limited to water damage and delamination.',
    '11.   Roof rafters or trusses spaced at 24" on center maximum, and must be evaluated for structural integrity and capacity as required by the governing jurisdiction.',
    '12.   PV panels must be supported per the manufacturer\'s required orientation, location and/or spacing.',
    '13.   The mounts may be installed through a maximum of 2 layers of composite asphalt roof shingles or maximum 20 gage metal decking provided the metal is predrilled as wood screws are not designed to penetrate the metal decking.  Not valid on concrete roof tile roofs.'
  ];


  constructor(
    private sanitizer: DomSanitizer,
    public projectService: ProjectService,
    public mapService: MapService,
    private maxSpanService: SpanService,
    private dialog: MatDialog,
    private http: HttpClient,
    private snackBar: MatSnackBar) {

    PdfMakeWrapper.setFonts(pdfFonts, {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      }
    });
    PdfMakeWrapper.useFont('Roboto');
  }


  ngOnInit() {
    this.project = this.projectService.getProject();
    (this.project.areaid === undefined
      || this.project.areaid.length === 0) ? this.multiprojects.push(this.project) : '';
    if (this.project.areaid !== undefined) {
      this.project.areaid.forEach(async (id, index) => {
        this.projectService.getroofareadb(id).subscribe(async (singarea: any) => {
          this.multiprojects[index] = await (JSON.parse(JSON.stringify(singarea)));
          index == this.project.areaid.length - 1 ? this.initialize() : '';
        });
      });
    }
    this.projlen = this.multiprojects.length - 1;
    this.project.areaid.length === 0 ? this.initialize() : '';
  }


  async initialize() {
    if (this.country_bool == 'us') {
      this.addressInfo = [
        ['Client Name', this.project.clientname],
        ['Address', this.project.address],
        ['City, State', this.project.state],
        ['Zip Code', this.project.zip],
      ];
    }
    else {
      this.addressInfo = [
        ['Client Name', this.project.clientname],
        ['Address', this.project.address],
        ['City, Province', this.project.state],
        ['Postal', this.project.zip],
      ];
    }

    this.projectInfo = [
      ['Project Title', 'Created'],
      [this.project.name, this.date],
    ];
    await this.generatePDF(1, false).then(() => this.DONE = true);
  }

  async canadainitializespan(project) {
    let maxSpan: any;
    const canadianDataL = {
      snow: project.snow, terr: project.terr, wind: project.speed, zone: project.zone, pitch: project.pitch,
      width: project.width, length: project.length, orientation: 0, pv: project.pv
    };
    const canadianDataP = {
      snow: project.snow, terr: project.terr, wind: project.speed, zone: project.zone, pitch: project.pitch,
      width: project.width, length: project.length, orientation: 1, pv: project.pv
    };
    try {
      canadianDataL.zone = 'R';
      this.spanLand[0] = await this.maxSpanService.getMaxSpanLandCan(project.rt, 'apex', canadianDataL, project.clip);
      canadianDataL.zone = 'S';
      this.spanLand[1] = await this.maxSpanService.getMaxSpanLandCan(project.rt, 'apex', canadianDataL, project.clip);
      canadianDataL.zone = 'C';
      this.spanLand[2] = await this.maxSpanService.getMaxSpanLandCan(project.rt, 'apex', canadianDataL, project.clip);
      canadianDataP.zone = 'R';
      this.spanPort[0] = await this.maxSpanService.getMaxSpanPortCan(project.rt, 'apex', canadianDataP, project.clip);
      canadianDataP.zone = 'S';
      this.spanPort[1] = await this.maxSpanService.getMaxSpanPortCan(project.rt, 'apex', canadianDataP, project.clip);
      canadianDataP.zone = 'C';
      this.spanPort[2] = await this.maxSpanService.getMaxSpanPortCan(project.rt, 'apex', canadianDataP, project.clip);
    } catch (error) {
      console.log(error);
    }
  }

  async initializespan() {
    const attachment = this.project.rt;
    if (attachment.slice(0, 6) === 'rafter' && attachment.length > 6) {
      this.spanLand[0] = await this.maxSpanService.getMaxSpanLand(attachment.slice(0, 6), 'apex');
      this.spanPort[0] = await this.maxSpanService.getMaxSpanPort(attachment.slice(0, 6), 'apex');
      this.spanLand[1] = await this.maxSpanService.getMaxSpanLand(attachment.slice(6), 'apex');
      this.spanPort[1] = await this.maxSpanService.getMaxSpanPort(attachment.slice(6), 'apex');
      this.spacone = this.spanPort[0].span;
      this.spacsdst = this.spanPort[0].spanSDST;
      this.spaclands = this.spanLand[0].span;
      this.spacsdstlands = this.spanLand[0].spanSDST;
      this.spaconeD = this.spanPort[1].span;
      this.spacsdstD = this.spanPort[1].spanSDST;
      this.spaclandsD = this.spanLand[1].span;
      this.spacsdstlandsD = this.spanLand[1].spanSDST;
    } else {
      this.spanLand[0] = await this.maxSpanService.getMaxSpanLand(attachment, 'apex');
      this.spanPort[0] = await this.maxSpanService.getMaxSpanPort(attachment, 'apex');
      this.spacone = this.spanPort[0].span;
      this.spacsdst = this.spanPort[0].spanSDST;
      this.spaclands = this.spanLand[0].span;
      this.spacsdstlands = this.spanLand[0].spanSDST;
    }
  }


  ngOnDestroy() {
    this.snackBar.dismiss();
    this.projectService.postprojnotif();
  }


  openpopup() {
    const dialogRef = this.dialog.open(IssuesComponent);
  }


  async addCriteria(pdf: any) {
    pdf.add(await new Img('../../../assets/images/SML_Header.png').width(480).height(165).build());
    pdf.add('\n\n');

    const pdftitle = (this.project.asce === '10') ? '\n\nAPEX ASCE 7-10\n\n' : '\n\nAPEX ASCE 7-16\n\n';
    const crttitle = (this.project.asce === '10') ?
      'Table Notes for Tables RT1 to RT12 - Tabulated values are based on the following criteria:\n' :
      'Table Notes for Galbe Roof Tables RT1 to RT12 & Hip Roof Tables RT1H to RT12H - Tabulated values are based on the following criteria:\n';
    const terms = 'The Roof Tech Estimator tool was developed in order to assist users in creating system designs and bill of materials ' +
      'with Roof Tech Apex Rail-less mounting system. It does not guarantee that the resulting system design is appropriate for the ' +
      'application selected by the user nor does it address the adequacy of the supportive framing of the building or structure upon ' +
      'which the system will be installed.\nBy using the Estimator tool, the user claims all responsibility for the accuracy and validity' +
      ' of the design inputs and, thereby, resulting calculations.  Roof Tech recommends the user to verify the maximum span values ' +
      'obtained from the sealed PE structural letters available through the Roof Tech web support pages.\nIt is the responsibility of ' +
      'the user to ensure that the system is installed in a code compliant manner based on accurate site information. Roof Tech makes ' +
      'no representation or warranty, either express or implied, of any kind with respect to this software or its contents (such as ' +
      'product information, recommendations, applicability, descriptions or prices) including, but not limited to, merchantability ' +
      'and fitness for a particular purpose, how current it is, its accuracy, its completeness, product availability, its security or ' +
      'that defects will be corrected.\n\nRoof Tech is free to use any information you submit via the use of the Estimator tool for ' +
      'marketing, sales and any other lawful purposes.';

    pdf.add(new Txt([{ text: pdftitle, fontSize: 14 }]).bold().end);
    pdf.add(new Txt([{ text: crttitle, fontSize: 12, decoration: 'underline' }]).end);
    pdf.add('\n\n');
    if (this.project.asce === '10') {
      for (const item of this.getCriteria10) {
        pdf.add(new Txt([{ text: item, fontSize: 10 }]).end);
      }
      pdf.add('\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n');
    } else if (this.project.asce === '16') {
      for (const item of this.getCriteria16) {
        pdf.add(new Txt([{ text: item, fontSize: 10 }]).end);
      }
      pdf.add('\n\n\n\n');
    }
    pdf.add(await new Img('../../../assets/images/SML_Footer.png').width(480).height(104).build());

    // Page Break
    pdf.add(new Txt([{ text: '' }]).pageBreak('after').end);
    // Add header-Logo
    pdf.add(await new Img('../../../assets/images/logo-transparent.png').width(151).height(39).build());
    pdf.add('\n\n');
    pdf.add(new Txt([{ text: 'Terms of Service:', fontSize: 14 }]).bold().end);
    pdf.add('\n\n');
    pdf.add(new Txt([{ text: terms, fontSize: 10 }]).end);
  }


  viewPDF(req: number, download: boolean) {
    let url = '';
    let name = '';

    switch (req) {
      case 1:
        var reportsLink;
        if (this.country_bool == 'us') {
          reportsLink = (this.project.asce === '10') ? this.asce10 : this.asce16;
          url = reportsLink + this.project.state + '.pdf';
          name = reportsLink + this.project.state;
        }
        else {
          const basicUrl = 'https://design.roof-tech.us/PDF/Stamped-PE-Letters/Canada/RT_MINI_II/RT_Mini_II_Canada_';
          url = basicUrl + this.project.state + '.pdf';
          name = basicUrl + this.project.state;
        }
        break;
      case 2:
        url = 'https://design.roof-tech.us/PDF/Brochures/Brochure-Rail-Less-RT-APEX.pdf';
        name = '/Brochure-Rail-Less-RT-APEX';
        break;
      case 3:
        url = 'https://design.roof-tech.us/PDF/Installation-Manuals/RT-APEX-installation-Manual.pdf';
        name = 'RT-APEX-installation-Manual';
        break;
      case 4:
        url = 'https://design.roof-tech.us/PDF/Installation-Manuals/APEX-Quick-Installation-Guide.pdf';
        name = 'APEX-Quick-Installation-Guide';
        break;
    }
    if (download) {
      this.http.get(url, { responseType: 'blob' })
        .subscribe(
          response => {
            const blob = new Blob([response], { type: response.type });
            saveAs(blob, name);
          },
          error => {
            console.log(error);
          }
        );
    } else {
      this.showPDF(url);
    }
  }


  showPDF(url: string) {
    this.pdfSource = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  async generatePDF(report: number, download: boolean) {
    const pdf = new PdfMakeWrapper();
    this.setPDFStyle(pdf);
    pdf.footer((currentPage: any, pageCount: any) => {
      return new Txt('Page ' + currentPage.toString() + ' of ' + pageCount).alignment('center').end;
    });
    let name = '';
    switch (report) {
      case 1:
        pdf.info({
          title: this.project.name + ' ' + this.date + ' Bill of Materials'
        });
        name = this.project.name + ' ' + this.date + ' Bill of Materials';
        await this.generatePDFBOM(pdf);
        break;
      case 2:
        pdf.info({ title: this.project.name + ' ' + this.date + ' Engineering Report' });
        name = this.project.name + ' ' + this.date + ' Engineering Report';
        await this.generatePDFEngReport(pdf);
        break;
      case 3:
        pdf.info({ title: this.project.name + ' ' + this.date + ' Design Layout' });
        name = this.project.name + ' ' + this.date + ' Design Layout';
        await this.generatePDFLayout(pdf);
        break;
      case 4:
        pdf.info({ title: 'APEX ASCE 7-' + this.project.asce + ' Criteria Page' });
        name = 'APEX ASCE 7-' + this.project.asce + ' Criteria Page';
        await this.addCriteria(pdf);
        break;
      case 5:
        pdf.info({ title: this.project.name + ' ' + this.date + ' All Reports' });
        name = this.project.name + ' ' + this.date + ' All Reports';
        await this.downloadAll(pdf);
        break;
    }
    if (download) { this.downloadPDF(pdf, name); } else { this.createPDF(pdf); }
  }


  async generateHeader(pdf: any) {
    pdf.add(await new Img('../../../assets/images/logo-transparent.png').width(151).height(39).build());
    pdf.add('\n\n');
    pdf.add(new Columns(this.projectInfo[0]).columnGap(250).style('headline3').end);
    pdf.add('\n');
    pdf.add(new Columns(this.projectInfo[1]).columnGap(250).style('headline2').end);
    pdf.add('\n\n\n');
    pdf.add(new Table(this.addressInfo).layout('noBorders').style('headline2').end);
  }


  // Bill of Material Report
  async generatePDFBOM(pdf: any) {
    const finalBomMap = new Map();
    const finalBom = [];
    await this.generateHeader(pdf);
    this.multiprojects = this.multiprojects.length === 0 ? [0] : this.multiprojects;
    for (const index in this.multiprojects) {
      const partList = JSON.parse(JSON.stringify(this.projectService.tableBody[index]));
      //deleting designed quantity
      partList.forEach((singpart) => {
        delete singpart.qnty;
      });
      this.calculateTotalBOM(finalBomMap, partList);
      const zonetitle = this.multiprojects[index].zonetitle;
      await this.generateBomBody(pdf, zonetitle, partList, index);
    }

    for (const [k, v] of finalBomMap) {
      finalBom.push(v);
    }
    finalBom.sort((a, b) => a.id - b.id);
    if (this.multiprojects.length > 1) {
      await this.generateBomBody(pdf, 'All Areas', finalBom, this.projlen + 1);
    }
  }


  calculateTotalBOM(finalBom: any, partsArr: any) {
    partsArr.forEach((part: any) => {
      if (!finalBom.has(part.id)) { finalBom.set(part.id, part); } else {
        const bom = finalBom.get(part.id);
        bom.des_qnty += Number(part.des_qnty);
        finalBom.set(part.id, bom);
      }
    });
  }


  async generateBomBody(pdf: any, zonetitle: any, partList: any, index: any) {
    let tableBody = [];
    let totalbool = false;
    let total = 0;
    let totalstr = '';

    partList.forEach((singpart) => {
      delete singpart.qnty;
    });

    if (partList.length > 2) {
      tableBody = partList.reduce((acc: any, obj: any) => [...acc, Object.values(obj).map(y => y)], []);
      tableBody.forEach((element: any) => {
        element.shift();
        element.pop();
        const st = (element[5] / element[3]) * element[4];
        if (element[5] === '---') {
          totalstr = '---';
          totalbool = true;
          element.push('---');
          element[5] = element[5];
        } else {
          element.push('$' + st.toFixed(2));
          element[5] = '$' + element[5];
          total += Number(st);
        }
      });
    } else {
      tableBody = partList;
    }
    if (Number(index) !== 0) { pdf.add(await new Img('../../../assets/images/logo-transparent.png').width(151).height(39).build()); }
    pdf.add(new Txt([{ text: '\nBill of Materials ' + ' - ' + zonetitle, style: 'headline1' }]).end);
    pdf.add(new Canvas([new Line([0, 2], [470, 2]).lineColor('#007bff').end]).end);
    pdf.add('\n\n\n\n');

    const tableHeader = [['Part Type'], ['Part Number'], ['Description'], ['Unit/Box'], ['Quantity'], ['MSRP/Box'], ['Subtotal']];
    pdf.add(new Columns(tableHeader).alignment('center').style('headline2').bold().end);
    pdf.add(new Canvas([new Line([0, 2], [500, 2]).end]).end);
    pdf.add('\n');



    tableBody.forEach((element: any) => {
      if (element[4] > 0) {
        pdf.add(new Columns(element).alignment('center').end);
        pdf.add('\n');
        pdf.add(new Canvas([new Line([0, 2], [500, 2]).end]).end);
        pdf.add('\n');
      }
    });

    pdf.add('\n');
    if (index < this.projlen + 1 || this.multiprojects.length === 1) {
      pdf.add(new Columns(['Total', [(totalbool ? totalstr : '$' + total.toFixed(2))]])
        .columnGap(350).style('headline2').end);
    } else {
      pdf.add(new Columns(['Total', [(totalbool ? totalstr : '$' + total.toFixed(2))]])
        .columnGap(350).style('headline2').pageBreak('after').end);
    }
  }




  // Engineering Report
  async generatePDFEngReport(pdf: any) {
    // Add header
    await this.generateHeader(pdf);
    for (let index = 0; index < this.multiprojects.length; index++) {
      this.project = this.multiprojects[index];
      this.projectService.setprojects(this.multiprojects[index]);
      (this.country_bool == 'us') ? await this.initializespan() : await this.canadainitializespan(this.project);
      await this.subengreport(pdf, index);
    }
  }


  async subengreport(pdf, index) {
    const zonetitle = this.multiprojects[index].zonetitle;
    pdf.add(new Txt([{ text: '\nEngineering Report ' + zonetitle, style: 'headline1' }]).end);
    pdf.add(new Canvas([new Line([0, 2], [470, 2]).lineColor('#007bff').end]).end);
    pdf.add('\n');

    const infoTable = [];
    const planTable = [];
    const inspecTable = [];
    const loadTable = [];
    const spanLandTable = [];
    const spanPortTable = [];

    let roofAttach = '';
    switch (this.project.rt) {
      case 'plywood':
        roofAttach = 'Plywood 15/32"';
        break;
      case 'osb':
        roofAttach = 'OSB 7/16"';
        break;
      // case '1x6plank':
      //   roofAttach = '1x6 Plank';
      //   break;
      case 'rafter':
        roofAttach = 'Rafter Only';
        break;
      case 'rafterplywood':
        roofAttach = 'Rafter-Plywood-Rafter';
        break;
      case 'rafterosb':
        roofAttach = 'Rafter-OSB-Rafter';
        break;
      // case 'rafter1x6plank':
      //   roofAttach = 'Rafter-Plank-Rafter';
      //   break;
    }

    let module = '';
    switch (this.project.pv) {
      case '60':
        module = '60 Cells Panel';
        break;
      case '72':
        module = '72 Cells Panel';
        break;
      case '90':
        module = 'Custom Panel';
        break;
    }

    let splice = '';
    switch (this.project.splice) {
      case '0':
        splice = 'No Splice';
        break;
      case '1':
        splice = 'Yes - Regular';
        break;
      case '2':
        splice = 'Yes - Floating';
        break;
    }

    const snowLoad = (this.project.snow > 0) ? (this.project.snow - 9) + '-' + this.project.snow : 0;
    const roofArea = (Number(this.project.dimension[0]) * Number(this.project.dimension[1])
      * Number(this.project.dimension[2])).toFixed(2);
    var projectArea = (Number(this.project.dimension[0]) * Number(this.project.dimension[1]))
      .toFixed(2);

    let areaperpanel = ((this.project.length * this.project.width) / 144);
    let totarea = (((this.project.length * this.project.width) / 144) * this.project.panels);

    let canroofangle = "0-6°";
    if (this.project.pitch == "7") {
      canroofangle = "7 - 27°";
    }
    else if (this.project.pitch == "28") {
      canroofangle = "28 - 45°";
    }


    infoTable.push(
      ['Name', ' '],
      ['Address', this.project.address],
      ['City, State', this.project.state],
      ['Zip Code', this.project.zip],
      ['Created', this.date],
      ['Project Title', this.project.name],
    );
    planTable.push(
      ['Total Number of Modules', this.project.panels],
      ['Total Output', (this.project.panels * this.project.output) / 1000 + ' KW'],
      ['Area per Panel', this.country_bool == 'us' ? areaperpanel.toFixed(2) + ' SQFT' : (areaperpanel / 10.764).toFixed(2) + ' SQM'],
      ['Total Area', this.country_bool == 'us' ? (totarea.toFixed(2) + ' SQFT') : ((totarea / 10.764).toFixed(2) + ' SQM')],
      ['NS Dimension', this.country_bool == 'us' ? (this.project.dimension[1] + ' FT') : (this.project.dimension[1] / 3.281).toFixed(2) + ' M'],
      ['EW Dimension', this.country_bool == 'us' ? (this.project.dimension[0] + ' FT') : ((this.project.dimension[0] / 3.281).toFixed(2) + ' M')],
      ['Total Project Area', this.country_bool == 'us' ? (projectArea + ' SQFT') : (Number(projectArea) / 10.674).toFixed(2) + ' SQM'],
      ['Total Roof Area', this.country_bool == 'us' ? roofArea + ' SQFT' : (Number(roofArea) / 10.674).toFixed(2) + ' SQM'],
    );
    inspecTable.push(
      ['Module Type', module],
      ['Module Output', this.project.output + ' Watts'],
      ['Module Length', this.country_bool == 'us' ? (this.project.length + ' Inches') : ((this.project.length / 39.37).toFixed(2) + ' Meters')],
      ['Module Width', this.country_bool == 'us' ? (this.project.width + ' Inches') : ((this.project.width / 39.37).toFixed(2) + ' Meters')],
      ['Module Thickness', this.project.thickness + ' Millimeter'],
      ['Module Weight', this.project.weight + ' Pounds'],
      ['Building Height', this.country_bool == 'us' ? '30.00 ft or less' : '9.144 M or less'],
      ['Roof Type', this.project.type[0].toUpperCase() + this.project.type.slice(1)],
      ['Rafter Spacing', this.country_bool == 'us' ? (this.project.rafter + ' Inches') : ((this.project.rafter / 39.37).toFixed(2) + ' Meters')],
      ['Total Weight', this.project.weight * this.project.panels + ' Pounds'],
      ['Roof Attachment', roofAttach],
      ['Prefered using splice ', splice],
    );
    loadTable.push(
      ['Building Code', 'ASCE 7-' + this.project.asce],
      ['Basic Wind Speed', this.project.speed + ' MPH'],
      [this.country_bool == 'us' ? 'Ground Snow Load' : 'SNOW & RAIN LOAD', this.country_bool == 'us' ? snowLoad : this.project.snow + ' PSF'],
      ['Wind Exposure', this.project.exposure],
      [this.country_bool == 'us' ? 'Roof Pitch' : 'ROOF ANGLE', this.country_bool == 'us' ? this.project.pitch : canroofangle + ' Degree'],
    );

    //create us modules tabel
    (this.country_bool == 'us') ? this.printusmodules(spanLandTable, spanPortTable) : this.printcanadamodules(spanLandTable, spanPortTable);

    pdf.add(new Columns([
      [new Txt('\n\nPlan Review').style('headline2').end, new Canvas([new Line([0, 2], [150, 2]).end]).end, '\n\n\n'],
      [new Txt('\n\nInspection').style('headline2').end, new Canvas([new Line([0, 2], [150, 2]).end]).end, '\n\n\n']
    ]).columnGap(100).end);

    pdf.add(new Columns(
      [new Table(inspecTable).layout('noBorders').style('report').end,
      new Table(planTable).layout('noBorders').style('report').end]
    ).columnGap(100).end);

    pdf.add(new Txt('\n\nLoad Used for Design').style('headline2').end);
    pdf.add(new Canvas([new Line([0, 2], [150, 2]).end]).end);
    pdf.add('\n\n\n');

    pdf.add(new Table(loadTable).layout('noBorders').style('report').pageBreak('after').end);

    pdf.add(new Txt('\n\nLandscape Modules').style('headline2').end);
    pdf.add(new Canvas([new Line([0, 2], [250, 2]).end]).end);
    pdf.add('\n\n\n');
    pdf.add(new Table(spanLandTable).alignment('right').layout('noBorders').style('report').end);
    pdf.add('\n\n\n\n\n\n');

    pdf.add(new Txt('\n\nPortrait Modules').style('headline2').end);
    pdf.add(new Canvas([new Line([0, 2], [250, 2]).end]).end);
    pdf.add('\n\n\n');
    (index === this.projlen) ?
      pdf.add(new Table(spanPortTable).alignment('right').layout('noBorders').style('report').end) :
      pdf.add(new Table(spanPortTable).alignment('right').layout('noBorders').style('report').pageBreak('after').end);
  }

  printcanadamodules(spanLandTable, spanPortTable) {
    spanLandTable.push(
      ['Maximum Mount Spacing', 'Zone R', 'Zone S', 'Zone C'],
      ['Max Span', Number(this.spanLand[0]).toFixed(2) + 'M', Number(this.spanLand[1]).toFixed(2) + 'M', Number(this.spanLand[2]).toFixed(2) + 'M'],
    );
    spanPortTable.push(
      ['Maximum Mount Spacing', 'Zone R', 'Zone S', 'Zone C'],
      ['Max Span', Number(this.spanPort[0]).toFixed(2) + 'M', Number(this.spanPort[1]).toFixed(2) + 'M', Number(this.spanPort[2]).toFixed(2) + 'M'],
    );
  }
  printusmodules(spanLandTable, spanPortTable) {
    // For R-D-R project, add the extra max span info
    if (this.project.rt.slice(0, 6) === 'rafter' && this.project.rt.length > 6) {
      const spaconeRD = ['', '', ''];
      const spacsdstRD = ['', '', ''];
      const spaclandsRD = ['', '', ''];
      const spacsdstlandsRD = ['', '', ''];
      // Get Avg of Rafter and Deck installation for Rafter-Deck Hybdrid
      for (let i = 0; i < spaconeRD.length; i++) {
        spaconeRD[i] = ((Number(this.spacone[i]) + Number(this.spaconeD[i])) / 2).toFixed(2);
        spacsdstRD[i] = ((Number(this.spacsdst[i]) + Number(this.spacsdstD[i])) / 2).toFixed(2);
        spaclandsRD[i] = ((Number(this.spaclands[i]) + Number(this.spaclandsD[i])) / 2).toFixed(2);
        spacsdstlandsRD[i] = ((Number(this.spacsdstlands[i]) + Number(this.spacsdstlandsD[i])) / 2).toFixed(2);
      }

      spanLandTable.push(
        ['Maximum Mount Spacing - Rafter only', 'Zone 1', 'Zone 2', 'Zone 3'],
        ['Without SDST', this.spaclands[0] + '"', this.spaclands[1] + '"', this.spaclands[2] + '"'],
        ['With SDST', this.spacsdstlands[0] + '"', this.spacsdstlands[1] + '"', this.spacsdstlands[2] + '"'],
      );
      spanLandTable.push(
        ['Maximum Mount Spacing - Rafter with ' + this.project.rt.slice(6), '', '', ''],
        ['Without SDST', spaclandsRD[0] + '"', spaclandsRD[1] + '"', spaclandsRD[2] + '"'],
        ['With SDST', spacsdstlandsRD[0] + '"', spacsdstlandsRD[1] + '"', spacsdstlandsRD[2] + '"'],
      );
      spanPortTable.push(
        ['Maximum Mount Spacing (Rafter)', 'Zone 1', 'Zone 2', 'Zone 3'],
        ['Without SDST', this.spacone[0] + '"', this.spacone[1] + '"', this.spacone[2] + '"'],
        ['With SDST', this.spacsdst[0] + '"', this.spacsdst[1] + '"', this.spacsdst[2] + '"'],
      );
      spanPortTable.push(
        ['Maximum Mount Spacing - Rafter with ' + this.project.rt.slice(6), '', '', ''],
        ['Without SDST', spaconeRD[0] + '"', spaconeRD[1] + '"', spaconeRD[2] + '"'],
        ['With SDST', spacsdstRD[0] + '"', spacsdstRD[1] + '"', spacsdstRD[2] + '"'],
      );
    } else {
      spanLandTable.push(
        ['Maximum Mount Spacing', 'Zone 1', 'Zone 2', 'Zone 3'],
        ['Without SDST', this.spaclands[0] + '"', this.spaclands[1] + '"', this.spaclands[2] + '"'],
        ['With SDST', this.spacsdstlands[0] + '"', this.spacsdstlands[1] + '"', this.spacsdstlands[2] + '"'],
      );
      spanPortTable.push(
        ['Maximum Mount Spacing', 'Zone 1', 'Zone 2', 'Zone 3'],
        ['Without SDST', this.spacone[0] + '"', this.spacone[1] + '"', this.spacone[2] + '"'],
        ['With SDST', this.spacsdst[0] + '"', this.spacsdst[1] + '"', this.spacsdst[2] + '"'],
      );
    }

  }

  // Layout Report
  async generatePDFLayout(pdf: any) {
    // Popup Alert
    const msg = 'Please check appropriate PV module clamping zones. Every module must have a minimum of 4 connections.' +
      '\n\nAlso double check the maximum span against the PE stamped Letters for your State.' +
      '\n\nIf needed, you can make manual adjustments to the Bill Of Materials. ';
    this.openSnackBar(msg, 'OK', 'center', 'top', 120000);
    // Add header
    let roofTitle;
    await this.generateHeader(pdf);

    pdf.add(new Txt([{ text: '\nDesign Layout', style: 'headline1' }]).end);
    pdf.add(new Canvas([new Line([0, 2], [470, 2]).lineColor('#007bff').end]).end);
    pdf.add('\n\n');
    for (const index in this.multiprojects) {
      roofTitle = this.multiprojects[index].zonetitle;
      pdf.add(new Txt([{ text: roofTitle, style: 'headline2' }]).end);
      pdf.add(new Canvas([new Line([0, 2], [100, 2]).end]).end);
      pdf.add('\n\n');

      let widthF = 0;
      let imageRatio = 0;
      let heightF = 0;
      const stageImage = this.multiprojects[index].stageImage;
      const width = this.multiprojects[index].stageWidth;
      const height = this.multiprojects[index].stageHeight;
      if (width > height) {
        widthF = 360;
        imageRatio = widthF / width;
        heightF = height * imageRatio;
      } else {
        heightF = 300;
        imageRatio = heightF / height;
        widthF = width * imageRatio;
      }

      const imageBody = [await new Img('../../../assets/images/legend.png').width(75).height(163).build(),
      await new Img(stageImage).width(widthF).height(heightF).build()];
      (Number(index) === this.projlen) ? pdf.add(new Columns(imageBody).columnGap(40).end)
        : pdf.add(new Columns(imageBody).columnGap(40).pageBreak('after').end);
    }
    // Add Map view if the project is on Map
    if (this.workspace === 1) {
      pdf.add(new Txt([{ text: roofTitle + ' - On Map', style: 'headline2' }]).pageBreak('before').end);
      pdf.add(new Canvas([new Line([0, 2], [150, 2]).end]).end);
      pdf.add('\n\n\n');
      pdf.add(await new Img(this.project.stageImage).width(500).height(300).build());
    }
  }


  async downloadAll(pdf: any) {
    await this.generatePDFBOM(pdf).then(_ =>
      pdf.add(new Txt('').pageBreak('after').end)
    ).then(_ =>
      this.generatePDFEngReport(pdf)
    ).then(_ =>
      pdf.add(new Txt('').pageBreak('after').end)
    ).then(_ =>
      this.generatePDFLayout(pdf)
    ).then(_ =>
      pdf.add(new Txt('').pageBreak('after').end)
    ).then(_ =>
      this.addCriteria(pdf)
    );
  }


  createPDF(pdf: any) {
    pdf.create().getDataUrl((dataURL: any) => {
      this.showPDF(dataURL);
    });
  }


  downloadPDF(pdf: any, name: string) {
    pdf.create().download(name);
  }


  getDateOnly() {
    // return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    const now = new Date();
    const date = (now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ';
    const minutes = (now.getMinutes() < 10) ? '0' + now.getMinutes().toString() : now.getMinutes().toString();
    const time = (now.getHours() < 12) ?
      + now.getHours() + '-' + minutes + ' am' : (now.getHours() - 12) + ':' + minutes + ' pm';
    return (date + time);
  }

  setPDFStyle(pdf: any) {
    pdf.styles({
      headline1: {
        bold: true,
        fontSize: 22,
        color: '#007bff',
      },
      headline2: {
        bold: true,
        fontSize: 12,
      },
      headline3: {
        bold: true,
        fontSize: 9,
        color: '#007bff',
      },
      report: {
        fontSize: 10,
      },
    });
    pdf.defaultStyle({
      bold: false,
      fontSize: 8
    });
    pdf.pageMargins([60, 40, 60, 40]);
  }


  openSnackBar(msg: any, action: any, posHorz: any, posVert: any, time: any) {
    this.snackBar.open(msg, action, {
      duration: time,
      horizontalPosition: posHorz,
      verticalPosition: posVert,
    });
  }


}
