<div class="bottomright">
  <div class="container" style="width:1200px; background-color: #ffffff; padding: 50px 90px; opacity: 0.9;">
    <div class="row">

    </div>
    <div class="row text-center">
      <div class="col-xs-12 col-sm-12 col-md-12 ">


        <h3 class="text-center">Reported Issues</h3>
        <div class="row searchbox">
          <p>FILTER</p>
          <input type="text" placeholder="Search.." [(ngModel)]="searchTerm"
            (ngModelChange)="setFilteredItems(searchTerm)">
        </div>
        <table class="table table-bordered">
          <thead class="thead-dark">
            <tr>
              <th scope="col" class="text-center">#</th>
              <th scope="col" class="text-center">Submitted Date</th>
              <th class="text-center" scope="col">Name <i
                  [class]="sortname ? 'fas fa-sort-amount-down' : 'fas fa-sort-amount-up'" (click)="sort()"></i></th>
              <th class="text-center" scope="col">Email <i
                  [class]="sortemail ? 'fas fa-sort-amount-down' : 'fas fa-sort-amount-up'"
                  (click)="sortemailfunc()"></i></th>
              <th class="text-center" scope="col">State</th>
              <th class="text-center" scope="col">Company</th>
              <th class="text-center" scope="col">Project Name</th>
              <th class="text-center" scope="col">Resolved</th>

            </tr>
          </thead>

          <tbody *ngFor="let issue of issues | slice: lowValue : highValue;let i=index;">
            <tr>
              <td>{{i + 1 + lowValue }}</td>
              <td class="namecol" (click)="openIssue(issue)">
                {{(issue.date | date: 'dd-MM-yyyy h:mm:ss') || 'Old Submit'}}</td>
              <td>{{issue.name}}</td>
              <td class="namecol" (click)="viewAllUserProjects(issue.user)">{{issue.email}}</td>
              <td>{{issue.state}}</td>
              <td>{{issue.company}}</td>
              <td *ngIf="issue.project != 'None'" class="namecol" (click)="getReferencedProject(issue.project)">
                {{issue.projName}}</td>
              <td *ngIf="issue.project == 'None'">{{issue.projName}}</td>
              <td class="namecol" (click)="toggleResolvingIssue(issue.id, issue.checked)">{{(issue.resolved) ?
                'Resolved' : 'Not Resolved'}}</td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div *ngIf="issues">
      <mat-paginator [length]="issues.length" [pageSizeOptions]="[10, 20, 50, 100]" (page)="getPaginatorData($event)">
      </mat-paginator>
    </div>


  </div>
</div>

<div class="row footer">
  <app-footer></app-footer>
</div>