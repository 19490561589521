import { Component, OnInit, VERSION } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  versionBuilt = '';
  linksarr = ['/projects'];
  constructor(public router: Router) { }
  curr_year = new Date().getFullYear();

  ngOnInit() {
    this.versionBuilt = environment.appVersion;
  }

  checkurl(url) {
    return !this.linksarr.includes(url);
  }

}
