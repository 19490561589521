import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UsersOperation } from '@src/app/service/usersoperation.service';
import { Users } from 'src/models/users.model';
import { headercomp } from 'src/models/headercomp.model';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public email: string;
  incomingUrl: string;
  message: string;

  @Output() currentpage = new EventEmitter<string>();

  RegisteredUsers: Users = {
    emailid: '',
    password: '',
    name: '',
    state: '',
    company: '',
  };

  HeaderRequest: headercomp = {
    emailid: '',
    currentpage: '',
    navigatedtpage: ''

  };

  constructor(private route: ActivatedRoute, private router: Router, private userService: UsersOperation) { }

  ngOnInit() {

    // this.userService.RegistrationData.subscribe((usersdata: Users) =>{
    //   debugger;
    //   this.RegisteredUsers.name = usersdata.name;
    //   this.RegisteredUsers.password = usersdata.password;
    //   this.RegisteredUsers.emailid = usersdata.emailid;
    //   this.RegisteredUsers.state = usersdata.state;
    //   this.RegisteredUsers.company = usersdata.company;
    // });

    this.route.params.subscribe((params: Params) => {

      this.email = params['{emailid}'];
      this.incomingUrl = params['{fromUrl}'];

      if (this.incomingUrl === 'reg') {
        this.message = 'Congratulations ' + this.RegisteredUsers.name + ' your registrations is successfull  ';
      } else if (this.incomingUrl === 'login') {
        this.message = 'Welcome ' + this.email + ' you have successfully loggedin ';
      }
      this.HeaderRequest.emailid = this.email;
      this.HeaderRequest.navigatedtpage = 'dashboard';
      this.userService.headercomprequest.emit(this.HeaderRequest);
      this.currentpage.emit('dashboard');
    });
  }

}
