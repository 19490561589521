<div #bg class="bg">

    <div class="form-container">
        <h2 class="center">{{country}} {{product}} Calculator</h2>
        <h3 class="center">Project Information</h3>
        <div>
            <mat-form-field class="half">
                <mat-select matNativeControl placeholder="PV MODULE" [(value)]="pv"
                    (selectionChange)="changeLengthByPV($event.value)">
                    <ng-container *ngIf="asce != 22">
					<mat-option value="60">60 cells (Dims. up to 40 x 68 inches)</mat-option>
                    <mat-option value="72">72 cells (Dims. up to 40 x 77.5 inches)</mat-option>
                    <mat-option value="90">Others</mat-option>
					</ng-container>
					<ng-container *ngIf="asce == 22 && orientation == 0">
					<mat-option value="40">Panels up to 40" in Width</mat-option>
					<mat-option value="45">Panels from 40" to 45" in Width</mat-option>
					</ng-container>
					<ng-container *ngIf="asce == 22 && orientation == 1">
					<mat-option value="68">Panels up to 68" in Length</mat-option>
					<mat-option value="80">Panels from 68" to 80" in Length</mat-option>
					</ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="half">
                <mat-select matNativeControl placeholder="ORIENTATION" (selectionChange)="orienchg()" [(value)]="orientation">
                    <mat-option value="0">Landscape</mat-option>
                    <mat-option value="1">Portrait</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div>
		<!--
            <div *ngIf="pv == 90; then limit90 else typical"></div>
            -->
			<ng-container *ngIf="!widlenbool && pv == 90">
                <mat-form-field class="half">
                    <input matInput type="number" placeholder="WIDTH" min="40" max="50" step="0.125"
                        [(ngModel)]="width">
                    <span matSuffix>Inch</span>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!widlenbool && pv != 90" #typical>
                <mat-form-field  class="half">
                    <input matInput type="number" placeholder="WIDTH" min="0" max="40" step="0.125" value="40" [(ngModel)]="width">
                    <span matSuffix>Inch</span>
                </mat-form-field>	
            </ng-container>
			     <mat-form-field *ngIf="widlenbool" class="half">
                    <input  matInput [disabled]="true" type="text" placeholder="WIDTH"
                        [(ngModel)]="width">
                    <span matSuffix>Inch</span>
                </mat-form-field>	
			
			

            <div *ngIf="pv == 72; then limit72 else limit60"></div>
            <ng-template #limit60>
                <mat-form-field *ngIf="!widlenbool" class="half">
                    <input matInput *ngIf="!widlenbool" type="number" placeholder="LENGTH" min="0" max="68" step="0.125"
                        [(ngModel)]="length">
                    <span matSuffix>Inch</span>
                </mat-form-field>
            </ng-template>
            <ng-template #limit72>
                <mat-form-field *ngIf="!widlenbool" class="half">
                    <input matInput type="number" placeholder="LENGTH" min="0" max="77.5" step="0.125"
                        [(ngModel)]="length">
                    <span matSuffix>Inch</span>
                </mat-form-field>
            </ng-template>
			
			   <mat-form-field *ngIf="widlenbool" class="half">
                    <input matInput [disabled]="true" type="text" placeholder="LENGTH" [(ngModel)]="length">
                    <span matSuffix>Inch</span>
                </mat-form-field>
			
        </div>

        <div *ngIf="country == 'US' then US; else CAN"></div>
        <ng-template #US>
            <div>
                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="BUILDING CODE" [(value)]="asce"
                        (selectionChange)="changeMinSpeed($event.value)">
                        <mat-option value="10">ASCE 7-10</mat-option>
                        <mat-option value="16">ASCE 7-16</mat-option>
                        <mat-option disabled value="22">ASCE 7-22</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="SNOW LOAD" [(value)]="snow">
						<ng-container *ngIf="asce != 22">
					   <mat-option value="0">0</mat-option>
                        <mat-option value="10">1-10</mat-option>
                        <mat-option value="20">11-20</mat-option>
                        <mat-option value="30">21-30</mat-option>
                        <mat-option value="40">31-40</mat-option>
                        <mat-option value="50">41-50</mat-option>
                        <mat-option value="60">51-60</mat-option>
                        <mat-option value="70">61-70</mat-option>
                        <mat-option *ngIf="calculate == 'mini' || 'mini2'" value="80">71-80</mat-option>
                        <mat-option *ngIf="calculate == 'mini' || 'mini2'" value="90">81-90</mat-option>
                    </ng-container>
					<ng-container *ngIf="asce == 22">
					  <mat-option value="0">0</mat-option>    
					  <mat-option value="5">1-5</mat-option>    
					  <mat-option value="10">6-10</mat-option>    
					  <mat-option value="15">11-15</mat-option>    
					  <mat-option value="20">16-20</mat-option>    
					  <mat-option value="25">21-25</mat-option>    
					  <mat-option value="30">26-30</mat-option>    
					  <mat-option value="35">31-35</mat-option>    
					  <mat-option value="40">36-40</mat-option>    
					  <mat-option value="45">41-45</mat-option>    
					  <mat-option value="50">46-50</mat-option>    
					  <mat-option value="55">51-55</mat-option>    
					  <mat-option value="60">56-60</mat-option>    
					  <mat-option value="70">61-70</mat-option>    
					  <mat-option value="80">71-80</mat-option>    
					  <mat-option value="90">81-90</mat-option>    
					  <mat-option value="100">91-100</mat-option>      
					  <mat-option value="110">101-110</mat-option>    
					  <mat-option value="120">111-120</mat-option>    
					</ng-container>
					
					</mat-select>
                    <span matSuffix>PSF</span>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="WIND EXPOSURE" [(value)]="exposure">
                        <mat-option value="B">B</mat-option>
                        <mat-option value="C">C</mat-option>
                        <mat-option value="D">D</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="WIND SPEED" [(value)]="speed">
                        <mat-option *ngIf="(asce == 16 || asce == 22)" value="95">95</mat-option>
                        <mat-option *ngIf="(asce == 16 || asce == 22)" value="100">100</mat-option>
                        <mat-option *ngIf="(asce == 16 || asce == 22)" value="105">105</mat-option>
                        <mat-option value="110">110</mat-option>
                        <mat-option value="115">115</mat-option>
                        <mat-option value="120">120</mat-option>
                        <mat-option *ngIf="asce == 22" value="125">125</mat-option>
                        <mat-option value="130">130</mat-option>
                        <mat-option value="140">140</mat-option>
                        <mat-option value="150">150</mat-option>
                        <mat-option value="160">160</mat-option>
                        <mat-option value="170">170</mat-option>
                        <mat-option value="180">180</mat-option>
                        <mat-option *ngIf="asce == 22" value="190">190</mat-option>
                    </mat-select>
                    <span matSuffix>MPH</span>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="ROOF TYPE" [(value)]="type">
                        <mat-option value="gable">Gable</mat-option>
                        <mat-option value="hip">Hip</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="ROOF ZONE" [(value)]="zone">
                        <mat-option value="1">Zone 1</mat-option>
                        <mat-option value="2">Zone 2</mat-option>
                        <mat-option [ngClass]="{'disabled': calculate == 'mini' && asce == '10'}" value="3">Zone 3
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="calculate == 'mini2' then mini2form; else others"></div>
            <ng-template #others>
                <mat-form-field [ngClass]="widlenbool ? 'half' : 'full'">
                    <mat-select matNativeControl placeholder="ROOF ATTACHMENT" [(value)]="rt">
                        <mat-option value="plywood">Plywood 15/32"</mat-option>
                        <mat-option value="osb">OSB 7/16"</mat-option>
                        <!-- <mat-option [ngClass]="{'disabled': calculate == 'mini'}" value="1x6plank">1x6 Plank
                        </mat-option> -->
                        <mat-option value="rafter">Rafter Only</mat-option>
                        <mat-option value="rafterplywood">Rafter-Plywood-Rafter</mat-option>
                        <mat-option value="rafterosb">Rafter-OSB-Rafter</mat-option>
                        <!-- <mat-option [ngClass]="{'disabled': calculate == 'mini'}" value="rafter1x6plank">
                            Rafter-Plank-Rafter
                        </mat-option> -->
                    </mat-select>
                </mat-form-field>
				
				<mat-form-field *ngIf="widlenbool" class="half">
                    <mat-select matNativeControl placeholder="MEAN ROOF HEIGHT" [(value)]="mrh">
                        <mat-option value="15">15 feet</mat-option>
                        <mat-option value="30">30 feet</mat-option>
                        <mat-option value="45">45 feet</mat-option>
                        <mat-option value="60">60 feet</mat-option>
                    </mat-select>
                </mat-form-field>
				
            </ng-template>
            <ng-template #mini2form>
                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="ROOF ATTACHMENT" [(value)]="rt">
                        <mat-option value="plywood">Plywood 15/32"</mat-option>
                        <mat-option value="osb">OSB 7/16"</mat-option>
                        <mat-option value="rafter6">Rafter (60mm screws)</mat-option>
                        <mat-option value="rafter9">Rafter (90mm screws)</mat-option>
                        <mat-option [disabled]="asce == 22" value="rafter0">Rafter Offset (60mm screws)</mat-option>
                        <mat-option value="rafter6plywood">Rafter-Plywood Hybrid (60mm screws)</mat-option>
                        <mat-option [disabled]="asce == 22" value="rafter9plywood">Rafter-Plywood Hybrid (90mm screws)</mat-option>
                        <mat-option [disabled]="asce == 22" value="rafter0plywood">Offset Rafter-Plywood Hybrid (60mm screws)</mat-option>
                        <mat-option [disabled]="asce == 22" value="rafter6osb">Rafter-OSB Hybrid (60mm screws)</mat-option>
                        <mat-option [disabled]="asce == 22" value="rafter9osb">Rafter-OSB Hybrid (90mm screws)</mat-option>
                        <mat-option [disabled]="asce == 22" value="rafter0plywood">Offset Rafter-OSB Hybrid (60mm screws)</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="MEAN ROOF HEIGHT" [(value)]="mrh">
                        <mat-option value="15">15 feet</mat-option>
                        <mat-option value="30">30 feet</mat-option>
						<ng-container *ngIf="asce == 22">
						<mat-option value="45">45 feet</mat-option>
                        <mat-option value="60">60 feet</mat-option>
						</ng-container>
                    </mat-select>
                </mat-form-field>
            </ng-template>
			
			
            <div>
                <mat-form-field #showPitch class="half">
                    <mat-select matNativeControl placeholder="ROOF PITCH" [(value)]="pitch">
                        <mat-option value="0.0">0&#730;</mat-option>
                        <mat-option [ngClass]="{'disabled': calculate == 'mini2' && asce == '16' && type == 'hip'}"
                            value="4.8">1/12 = 4.8&#730;</mat-option>
                        <mat-option value="9.5">2/12 = 9.5&#730;</mat-option>
                        <mat-option value="14.0">3/12 = 14.0&#730;</mat-option>
                        <mat-option value="18.4">4/12 = 18.4&#730;</mat-option>
                        <mat-option
                            [ngClass]="{'disabled': calculate == 'mini2' && asce == '16' && type == 'hip' && zone == '3'}"
                            value="22.6">5/12 = 22.6&#730;</mat-option>
                        <mat-option
                            [ngClass]="{'disabled': calculate == 'mini2' && asce == '16' && type == 'hip' && zone == '3'}"
                            value="26.6">6/12 = 26.6&#730;</mat-option>
                        <mat-option value="30.3">7/12 = 30.3&#730;</mat-option>
                        <mat-option value="33.7">8/12 = 33.7&#730;</mat-option>
                        <mat-option value="36.9">9/12 = 36.9&#730;</mat-option>
                        <mat-option value="39.8">10/12 = 39.8&#730;</mat-option>
                        <mat-option value="42.5">11/12 = 42.5&#730;</mat-option>
                        <mat-option value="45.0">12/12 = 45.0&#730;</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="STATE" [(value)]="state">
                        <!-- <mat-option value="AL">Alabama</mat-option> -->
                        <!-- <mat-option value="AK">Alaska</mat-option> -->
                        <mat-option [disabled]="asce == 22" value="AR">Arkansas</mat-option>
                        <mat-option [disabled]="asce == 22" value="AZ">Arizona</mat-option>
                        <mat-option [disabled]="asce == 22" value="CA">California</mat-option>
                        <mat-option value="CO">Colorado</mat-option>
                        <mat-option [disabled]="asce == 22" value="CT">Connecticut</mat-option>
                        <mat-option [disabled]="asce == 22" value="DE">Delaware</mat-option>
                        <mat-option [disabled]="asce == 22" value="DC">District of Columbia</mat-option>
                        <mat-option value="FL">Florida</mat-option>
                        <mat-option [disabled]="asce == 22" value="GA">Georgia</mat-option>
                        <mat-option [disabled]="asce == 22" value="HI">Hawaii</mat-option>
                        <mat-option [disabled]="asce == 22" value="ID">Idaho</mat-option>
                        <mat-option [disabled]="asce == 22" value="IL">Illinois</mat-option>
                        <mat-option [disabled]="asce == 22" value="IN">Indiana</mat-option>
                        <mat-option [disabled]="asce == 22" value="IA">Iowa</mat-option>
                        <mat-option  [disabled]="asce == 22" value="KS">Kansas</mat-option>
                        <!-- <mat-option value="KY">Kentucky</mat-option> -->
                        <mat-option [disabled]="asce == 22" value="LA">Louisiana</mat-option>
                        <mat-option [disabled]="asce == 22" value="ME">Maine</mat-option>
                        <mat-option [disabled]="asce == 22" value="MD">Maryland</mat-option>
                        <mat-option [disabled]="asce == 22" value="MA">Massachusetts</mat-option>
                        <mat-option [disabled]="asce == 22" value="MI">Michigan</mat-option>
                        <mat-option [disabled]="asce == 22" value="MN">Minnesota</mat-option>
                        <!-- <mat-option value="MS">Mississippi</mat-option> -->
                        <mat-option [disabled]="asce == 22" value="MO">Missouri</mat-option>
                        <!-- <mat-option value="MT">Montana</mat-option> -->
                        <!-- <mat-option value="NE">Nebraska</mat-option> -->
                        <mat-option [disabled]="asce == 22" value="NV">Nevada</mat-option>
                        <mat-option [disabled]="asce == 22" value="NH">New Hampshire</mat-option>
                        <mat-option [disabled]="asce == 22" value="NJ">New Jersey</mat-option>
                        <mat-option [disabled]="asce == 22" value="NM">New Mexico</mat-option>
                        <mat-option [disabled]="asce == 22" value="NY">New York</mat-option>
                        <mat-option [disabled]="asce == 22" value="NC">North Carolina</mat-option>
                        <!-- <mat-option value="ND">North Dakota</mat-option> -->
                        <mat-option [disabled]="asce == 22" value="OH">Ohio</mat-option>
                        <!-- <mat-option value="OK">Oklahoma</mat-option> -->
                        <mat-option [disabled]="asce == 22" value="OR">Oregon</mat-option>
                        <mat-option [disabled]="asce == 22" value="PA">Pennsylvania</mat-option>
                        <mat-option [disabled]="asce == 22" value="RI">Rhode Island</mat-option>
                        <mat-option [disabled]="asce == 22" value="SC">South Carolina</mat-option>
                        <!-- <mat-option value="SD">South Dakota</mat-option> -->
                        <mat-option [disabled]="asce == 22" value="TN">Tennessee</mat-option>
                        <mat-option [disabled]="asce == 22" value="TX">Texas</mat-option>
                        <mat-option [disabled]="asce == 22" value="UT">Utah</mat-option>
                        <!-- <mat-option value="VT">Vermont</mat-option> -->
                        <mat-option [disabled]="asce == 22" value="VA">Virginia</mat-option>
                        <mat-option [disabled]="asce == 22" value="WA">Washington</mat-option>
                        <!-- <mat-option value="WV">West Virginia</mat-option> -->
                        <mat-option [disabled]="asce == 22" value="WI">Wisconsin</mat-option>
                        <!-- <mat-option value="WY">Wyoming</mat-option> -->
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-template>
        <ng-template #CAN>
            <div>
                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="SNOW & RAIN LOAD" [(value)]="snow">
                        <mat-option value="0.5">0.5</mat-option>
                        <mat-option value="1">1.0</mat-option>
                        <mat-option value="1.5">1.5</mat-option>
                        <mat-option value="2">2.0</mat-option>
                        <mat-option value="2.5">2.5</mat-option>
                        <mat-option value="3">3.0</mat-option>
                        <mat-option value="3.5">3.5</mat-option>
                        <mat-option value="4">4.0</mat-option>
                        <mat-option value="4.5">4.5</mat-option>
                        <mat-option value="5">5.0</mat-option>
                        <mat-option value="5.5">5.5</mat-option>
                        <mat-option value="6">6.0</mat-option>
                        <mat-option value="6.5">6.5</mat-option>
                        <mat-option value="7">7.0</mat-option>
                        <mat-option value="7.5">7.5</mat-option>
                        <mat-option value="8">8.0</mat-option>
                        <mat-option value="8.5">8.5</mat-option>
                        <mat-option value="9">9.0</mat-option>
                        <mat-option value="9.5">9.5</mat-option>
                        <mat-option value="10">10</mat-option>
                    </mat-select>
                    <span matSuffix>KPa</span>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="TERRAIN" [(value)]="terrain">
                        <mat-option value="OPEN">Open</mat-option>
                        <mat-option value="ROUGH">Rough</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="BASIC WIND PRESSURE" [(value)]="wind">
                        <mat-option value="0.20">0.20</mat-option>
                        <mat-option value="0.30">0.30</mat-option>
                        <mat-option value="0.40">0.40</mat-option>
                        <mat-option value="0.50">0.50</mat-option>
                        <mat-option value="0.60">0.60</mat-option>
                        <mat-option value="0.70">0.70</mat-option>
                        <mat-option value="0.80">0.80</mat-option>
                        <mat-option value="0.90">0.90</mat-option>
                        <mat-option value="1.00">1.00</mat-option>
                        <mat-option value="1.10">1.10</mat-option>
                    </mat-select>
                    <span matSuffix>KPa</span>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="ROOF ZONE" [(value)]="zone">
                        <mat-option value="R">R</mat-option>
                        <mat-option value="S">S</mat-option>
                        <mat-option value="C">C</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="calculate == 'mini2' then mini2Can; else othersCan"></div>
            <ng-template #mini2Can>
                <div>
                    <mat-form-field class="full">
                        <mat-select matNativeControl placeholder="ROOF ATTACHMENT" [(value)]="rt">
                            <mat-option value="plywood">Plywood 15/32"</mat-option>
                            <mat-option value="osb">OSB 7/16"</mat-option>
                            <mat-option value="rafter6">Rafter centered with 15/32 plywood and (2)-60 mm screws
                            </mat-option>
                            <mat-option value="rafter9">Rafter centered with 15/32 plywood and (2)-90 mm screws
                            </mat-option>
                            <mat-option value="rafter6osb">Rafter centered with 7/16 OSB and (6)-60 mm screws
                            </mat-option>
                            <mat-option value="rafter0osb">Rafter off-centered with 7/16 OSB and (5)-60 mm screws
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-template>
            <ng-template #othersCan>
                <div>
                    <mat-form-field class="half">
                        <mat-select matNativeControl placeholder="ROOF ATTACHMENT" [(value)]="rt">
                            <mat-option value="plywood">Plywood with (5)-60 mm screws</mat-option>
                            <mat-option value="osb">OSB with (5)-60 mm screws</mat-option>
                            <!-- <mat-option value="plank">Plank with (4)-60 mm screws</mat-option> -->
                            <mat-option value="rafter6">Rafter centered with (2)-60 mm screws
                            </mat-option>
                            <mat-option value="rafter9">Rafter centered with (2)-90 mm screws
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field #showPitch class="half">
                        <mat-select matNativeControl placeholder="SNOW CLIP" [(value)]="clip">
                            <mat-option value="1">YES</mat-option>
                            <mat-option value="0">NO</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-template>

            <div>
                <mat-form-field #showPitch class="half">
                    <mat-select matNativeControl placeholder="ROOF ANGLE" [(value)]="pitch">
                        <mat-option value="0">0-6&#730;</mat-option>
                        <mat-option value="7">7-27&#730;</mat-option>
                        <mat-option value="28">28-45&#730;</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="half">
                    <mat-select matNativeControl placeholder="PROVINCE" [(value)]="province">
                        <mat-option value="AB">Alberta</mat-option>
                        <mat-option value="BC">British Colombia</mat-option>
                        <mat-option value="NS">Nova Scotia</mat-option>
                        <mat-option value="ON">Ontario</mat-option>
                        <mat-option value="SK">Saskatchewan</mat-option>
                        <mat-option value="NB">New Brunswick</mat-option>
                        <mat-option value="PEI">Prince Edward Island</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-template>



        <button mat-raised-button class="save" color="primary" (click)="calculateSpan()"
            [disabled]="disableButton()">CALCULATE SPAN</button>

        <div *ngIf="country == 'US'" class="row result">
            <mat-checkbox class="full" [(ngModel)]="metric" (change)="calculateSpan()">Show the results in Metric
            </mat-checkbox>
        </div>

        <div *ngIf="country == 'Canada'" class="row result">
            <h4 class="full"> {{title}}: {{spanC}} {{unit}}</h4>
        </div>

        <div *ngIf="!RDR && country == 'US'" class="row result">
            <h4 [ngClass]="{'half': !singleSpan, 'full': singleSpan}" class="half">
                {{spanTitle[0]}}: {{span[0]}} {{unit}}</h4>
            <h4 *ngIf="!singleSpan" class="half">{{spanTitle[1]}}:
                {{span[1]}} {{unit}}</h4>
        </div>
        <div *ngIf="RDR && country == 'US'" class="row result">
            <h4 [ngClass]="{'half': !singleSpan, 'full': singleSpan}" class="half">Rafter
                {{spanTitle[0]}}: {{span[0]}} {{unit}}</h4>
            <h4 *ngIf="!singleSpan" class="half">Rafter
                {{spanTitle[1]}}: {{span[1]}} {{unit}}</h4>

            <h4 [ngClass]="{'half': !singleSpan, 'full': singleSpan}" class="half">Deck
                {{spanTitle[0]}}: {{span[2]}} {{unit}}</h4>
            <h4 *ngIf="!singleSpan" class="half">Deck
                {{spanTitle[1]}}: {{span[3]}} {{unit}}</h4>

            <h4 [ngClass]="{'half': !singleSpan, 'full': singleSpan}" class="half">Hybrid
                {{spanTitle[0]}}: {{span[4]}} {{unit}}</h4>
            <h4 *ngIf="!singleSpan" class="half">Hybrid
                {{spanTitle[1]}}: {{span[5]}} {{unit}}</h4>
        </div>

        <div class="row result">
            <a *ngIf="country == 'US'" mat-raised-button class="half noLink" [href]="email()"
                [disabled]="!state || !span[0]">SEND AS EMAIL</a>
            <a *ngIf="country == 'Canada'" mat-raised-button class="half noLink" [href]="email()"
                [disabled]="!province || !spanC">SEND AS EMAIL</a>
            <button mat-raised-button class="half" (click)="createPELink()" [disabled]="!state && !province">
                {{letter}} PE REPORT</button>
        </div>

        <!-- <div class="row result">
            <h5 class="half">Share: </h5>
            <div>
                <button mat-button (click)="preview()" class="ml-auto" title="Preview In PDF">
                    <mat-icon>preview</mat-icon>
                </button>
                <button mat-button (click)="download()" class="ml-auto" title="Download PDF">
                    <mat-icon>save_alt</mat-icon>
                </button>
                <a mat-button href="{{'mailto:' + mailtoInfo }}" class="ml-auto" title="Send An Email">
                    <mat-icon>share</mat-icon>
                </a>
            </div>
        </div> -->

    </div>
</div>


<div class="row footer">
    <app-footer></app-footer>
</div>