import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class DataService {

	constructor(private firestore: AngularFirestore) { }

	getcollection(collectname) {
		return this.firestore.collection(collectname).valueChanges();
	}

	getcollectiononce(collectname) {
		return this.firestore.collection(collectname).valueChanges().pipe(first()).toPromise();
	}

	async savedoc(docname, prop) {
		let docid = await this.firestore.createId();
		prop.id = docid;
		await this.firestore.collection(docname).doc(docid).set(prop);
	}



}
