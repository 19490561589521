import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
// import { Observable } from 'rxjs/Observable'
import { UsersOperation } from '@src/app/service/usersoperation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { headercomp } from 'src/models/headercomp.model';
// import { AngularFireAuth } from 'angularfire2/auth';
// import * as firebase from 'firebase';
import { AuthService } from '@src/app/service/auth.service';
import { ProjectService } from 'src/app/service/project.service';
import * as firebase from 'firebase/app';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertComponent } from '@src/app/alertbox/alert.component';
import { SnackbarService } from 'src/app/service/snackbar.service';


@Component({

  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {


  presentpage = '';
  navigatedpage = '';
  showloggedin = false;
  showlogin = true;
  showk1login = true;
  showregistration = true;
  emailid = '';
  showForAdminUser = false;
  redirectPage = '';
  HeaderRequest: headercomp = {
    emailid: '',
    currentpage: '',
    navigatedtpage: ''
  };
  multiprojects = [];
  project: any;
  alertbox = false;
  state = "";
  roofType = "";
  workspace: any;
  title: any;
  panel: any;
  toggleNavbar = false;
  designFailed: any;
  regis_status = false;
  terrain = "";
  country_bool = this.projectService.getProject().country;
  arealen = 0;
  constructor(
    private userService: UsersOperation,
    private route: ActivatedRoute,
    public auth: AuthService,
    public router: Router,
    public projectService: ProjectService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private snackbarservice: SnackbarService
  ) {

    this.route.queryParams.subscribe((params => {
      this.emailid = params['{emailid}'];
    }));

    this.projectService.getchangenotif().subscribe(_ => {
      this.title = this.projectService.getprojectname();
      // total panel code
      this.arealen = (this.projectService.getProject().areaid === undefined) ? 0 :
        this.projectService.getProject().areaid.length;
      const projtotal = this.projectService.gettotalpanel();
      if (this.router.url === '/design' || this.arealen === 0 || projtotal === undefined) {
        this.panel = this.projectService.getpanel();
      } else {
        this.panel = this.projectService.gettotalpanel();
      }
      this.state = this.projectService.getProject().state;
      this.roofType = this.projectService.getProject().rt;
      this.country_bool = this.projectService.getProject().country;
      this.terrain = this.projectService.getProject().terr;
      this.designFailed = this.projectService.getProject().failed;
      this.useradmincheck();
      this.calculateOutput();
      this.ref.detectChanges();
    });
    this.userService.AdminEvent.subscribe((admindata => {
      this.showForAdminUser = admindata;
    }));

    this.userService.headercomprequest.subscribe((page => {
      this.presentpage = page.currentpage;
      this.navigatedpage = page.navigatedtpage;
      this.emailid = page.emailid;

      if (this.presentpage === 'changepassword'
        || this.presentpage === 'disableaccount') {
        this.showloggedin = true;
        this.showlogin = false;
        this.showregistration = false;
      }

      if (this.presentpage === 'admin') {
        this.showloggedin = true;
        this.showlogin = false;
        this.showregistration = false;
        this.showForAdminUser = true;
      }

      if (this.navigatedpage === 'dashboard') {
        this.showloggedin = true;
        this.showlogin = false;
        this.showregistration = false;
      }

      if (this.navigatedpage === 'changepassword') {
        this.showloggedin = false;
        this.showlogin = true;
        this.showregistration = true;
        this.showForAdminUser = false;
      }

      if (this.navigatedpage === 'disableaccount') {
        this.showloggedin = false;
        this.showlogin = true;
        this.showregistration = true;
        this.showForAdminUser = false;
      }
    }));
  }


  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.emailid = params['{emailid}'];
    });

  }

  currentPageFromDashboard(event: Event) {

  }

  doalert() {
  }

  settings() {

  }


  onChangePassword() {
    this.auth.resetPassword(firebase.auth().currentUser.email)
      .then(res => {
        this.dialog.open(AlertComponent);
      })
      .catch((error) => console.log(error));
  }

  onDisableAccount() {
    const user = firebase.auth().currentUser;
    user.delete();
  }

  designenablecheck() {
    if (this.country_bool == 'us') {
      return this.state == '' || this.roofType == '' || (this.router.url == '/map' && this.workspace == 1);
    }
    else {
      return this.state == '' || this.roofType == '' || this.terrain == '' || (this.router.url == '/map' && this.workspace == 1);
    }

  }


  useradmincheck() {
    this.showForAdminUser = this.auth.isadmin() ? true : false;
    if (this.auth.getregisstatus() == 1 || this.auth.getregisstatus() == undefined) {
      this.regis_status = true;
    }
    else {
      this.regis_status = false;
    }
  }

  onAdminManager() {
    this.router.navigate(['admin', this.emailid], { relativeTo: this.route });
  }

  onLogOut() {
    this.router.navigate(['login']);
    this.showloggedin = false;
    this.showlogin = true;
    this.showregistration = true;
    this.showForAdminUser = false;


  }

  logout() {
    this.auth.logout();
    this.router.navigate(['']);
    window.location.reload();
  }


  titleUpdated(event: any) {
    this.title = event.target.value;
    this.projectService.setProject('name', this.title);
  }

  calculateOutput() {
    if (this.arealen === 0) {
      return this.projectService.getProject().output * this.projectService.getProject().panels / 1000;
    }
    else {
      let retpanels = this.router.url != '/design' ? this.projectService.getProject().output * Number(this.projectService.getProject().totalpanel) / 1000 :
        Number((this.projectService.getProject().output * this.projectService.getProject().panels / 1000).toFixed(2));
      return retpanels;
    }
  }


  newproject() {
    this.toggleNavbar = false;
    localStorage.clear();
    if (firebase.auth().currentUser) {
      this.router.navigate(['/projects']);
    } else {
      this.router.navigate(['/login']);
    }
  }


  saveProject() {
    let multiprojbool = this.projectService.getProject().areaid.length == 0;
    this.projectService.postheadersavenotif("change");
    this.projectService.postprojectfromheader(multiprojbool);
  }


}
