<div class="form-container container">

    <h5 class="center" id="location-form-title">Location Information</h5>
    <div>
        <mat-form-field class="half">
            <input matInput type="text" placeholder="CLIENT NAME" [(ngModel)]="client">
        </mat-form-field>

        <mat-form-field class="half">
            <input matInput type="text" placeholder="ADDRESS" [(ngModel)]="address">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field class="half" *ngIf="country_bool == 'us' ">
            <input matInput type="text" placeholder="ZIP CODE" maxlength="5" [(ngModel)]="zip" pattern="[0-9]*">
        </mat-form-field>

        <mat-form-field class="half" *ngIf="country_bool == 'canada' ">
            <input matInput type="text" placeholder="POSTAL CODE" maxlength="7" [(ngModel)]="zip"
                pattern="/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY][ -]?\d[ABCEGHJKLMNPRSTVXY]\d$/i">
        </mat-form-field>


        <mat-form-field class="half">


            <mat-select matNativeControl (selectionChange)="saveChanges()" *ngIf="country_bool == 'canada'"
                placeholder="Province *" [(value)]="state">
                <mat-option value="AB">Alberta</mat-option>
                <mat-option value="BC">British Colombia</mat-option>
                <mat-option value="NS">Nova Scotia</mat-option>
                <mat-option value="ON">Ontario</mat-option>
                <mat-option value="SK">Saskatchewan</mat-option>
                <mat-option value="NB">New Brunswick</mat-option>
                <mat-option value="PEI">Prince Edward Island</mat-option>
            </mat-select>


            <mat-select (selectionChange)="saveChanges()" matNativeControl *ngIf="country_bool == 'us' "
                placeholder="State *" [(value)]="state">
                <!-- <mat-option value="AL">Alabama</mat-option> -->
                <!-- <mat-option value="AK">Alaska</mat-option> -->
                <mat-option value="AR">Arkansas</mat-option>
                <mat-option value="AZ">Arizona</mat-option>
                <mat-option value="CA">California</mat-option>
                <mat-option value="CO">Colorado</mat-option>
                <mat-option value="CT">Connecticut</mat-option>
                <mat-option value="DE">Delaware</mat-option>
                <mat-option value="DC">District of Columbia</mat-option>
                <mat-option value="FL">Florida</mat-option>
                <mat-option value="GA">Georgia</mat-option>
                <mat-option value="HI">Hawaii</mat-option>
                <mat-option value="ID">Idaho</mat-option>
                <mat-option value="IL">Illinois</mat-option>
                <mat-option value="IN">Indiana</mat-option>
                <mat-option value="IA">Iowa</mat-option>
                <mat-option value="KS">Kansas</mat-option>
                <!-- <mat-option value="KY">Kentucky</mat-option> -->
                <mat-option value="LA">Louisiana</mat-option>
                <mat-option value="ME">Maine</mat-option>
                <mat-option value="MD">Maryland</mat-option>
                <mat-option value="MA">Massachusetts</mat-option>
                <mat-option value="MI">Michigan</mat-option>
                <mat-option value="MN">Minnesota</mat-option>
                <!-- <mat-option value="MS">Mississippi</mat-option> -->
                <mat-option value="MO">Missouri</mat-option>
                <!-- <mat-option value="MT">Montana</mat-option> -->
                <!-- <mat-option value="NE">Nebraska</mat-option> -->
                <mat-option value="NV">Nevada</mat-option>
                <mat-option value="NH">New Hampshire</mat-option>
                <mat-option value="NJ">New Jersey</mat-option>
                <mat-option value="NM">New Mexico</mat-option>
                <mat-option value="NY">New York</mat-option>
                <mat-option value="NC">North Carolina</mat-option>
                <!-- <mat-option value="ND">North Dakota</mat-option> -->
                <mat-option value="OH">Ohio</mat-option>
                <!-- <mat-option value="OK">Oklahoma</mat-option> -->
                <mat-option value="OR">Oregon</mat-option>
                <mat-option value="PA">Pennsylvania</mat-option>
                <mat-option value="RI">Rhode Island</mat-option>
                <mat-option value="SC">South Carolina</mat-option>
                <!-- <mat-option value="SD">South Dakota</mat-option> -->
                <mat-option value="TN">Tennessee</mat-option>
                <mat-option value="TX">Texas</mat-option>
                <mat-option value="UT">Utah</mat-option>
                <!-- <mat-option value="VT">Vermont</mat-option> -->
                <mat-option value="VA">Virginia</mat-option>
                <mat-option value="WA">Washington</mat-option>
                <!-- <mat-option value="WV">West Virginia</mat-option> -->
                <mat-option value="WI">Wisconsin</mat-option>
                <!-- <mat-option value="WY">Wyoming</mat-option> -->
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field class="half" *ngIf="country_bool == 'us' ">
            <mat-select matNativeControl placeholder="BUILDING CODE" [(value)]="asce"
                (selectionChange)="changeMinSpeed($event.value); saveChanges();">
                <mat-option value="10">ASCE 7-10</mat-option>
                <mat-option value="16">ASCE 7-16</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="half" *ngIf="country_bool == 'us' ">
            <mat-select (selectionChange)="saveChanges()" matNativeControl placeholder="SNOW LOAD" [(value)]="snow">
                <mat-option value="0">0</mat-option>
                <mat-option value="10">1-10</mat-option>
                <mat-option value="20">11-20</mat-option>
                <mat-option value="30">21-30</mat-option>
                <mat-option value="40">31-40</mat-option>
                <mat-option value="50">41-50</mat-option>
                <mat-option value="60">51-60</mat-option>
                <mat-option value="70">61-70</mat-option>
            </mat-select>
            <span matSuffix>PSF</span>
        </mat-form-field>

        <mat-form-field class="half" *ngIf="country_bool == 'canada' ">
            <mat-select (selectionChange)="saveChanges()" matNativeControl placeholder="SNOW & RAIN LOAD"
                [(value)]="snow">
                <mat-option value="0.5">0.5</mat-option>
                <mat-option value="1">1.0</mat-option>
                <mat-option value="1.5">1.5</mat-option>
                <mat-option value="2">2.0</mat-option>
                <mat-option value="2.5">2.5</mat-option>
                <mat-option value="3">3.0</mat-option>
                <mat-option value="3.5">3.5</mat-option>
                <mat-option value="4">4.0</mat-option>
                <mat-option value="4.5">4.5</mat-option>
                <mat-option value="5">5.0</mat-option>
                <mat-option value="5.5">5.5</mat-option>
                <mat-option value="6">6.0</mat-option>
                <mat-option value="6.5">6.5</mat-option>
                <mat-option value="7">7.0</mat-option>
                <mat-option value="7.5">7.5</mat-option>
                <mat-option value="8">8.0</mat-option>
                <mat-option value="8.5">8.5</mat-option>
                <mat-option value="9">9.0</mat-option>
                <mat-option value="9.5">9.5</mat-option>
                <mat-option value="10">10</mat-option>
            </mat-select>
            <span matSuffix>KPa</span>
        </mat-form-field>

        <mat-form-field class="half" *ngIf="country_bool == 'canada' ">
            <mat-select (selectionChange)="saveChanges()" matNativeControl placeholder="TERRAIN" [(value)]="terrain">
                <mat-option value="OPEN">Open</mat-option>
                <mat-option value="ROUGH">Rough</mat-option>
            </mat-select>
        </mat-form-field>


    </div>
    <div>
        <mat-form-field class="half" *ngIf="country_bool == 'us' ">
            <mat-select (selectionChange)="saveChanges()" matNativeControl placeholder="WIND EXPOSURE"
                [(value)]="exposure">
                <mat-option value="B">B</mat-option>
                <mat-option value="C">C</mat-option>
                <mat-option value="D">D</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="half" *ngIf="country_bool == 'us' ">
            <mat-select (selectionChange)="saveChanges()" matNativeControl placeholder="WIND SPEED" [(value)]="speed">
                <mat-option *ngIf="asce == 16" value="95">95</mat-option>
                <mat-option *ngIf="asce == 16" value="100">100</mat-option>
                <mat-option *ngIf="asce == 16" value="105">105</mat-option>
                <mat-option value="110">110</mat-option>
                <mat-option value="115">115</mat-option>
                <mat-option value="120">120</mat-option>
                <mat-option value="130">130</mat-option>
                <mat-option value="140">140</mat-option>
                <mat-option value="150">150</mat-option>
                <mat-option value="160">160</mat-option>
                <mat-option value="170">170</mat-option>
                <mat-option value="180">180</mat-option>
            </mat-select>
            <span matSuffix>MPH</span>
        </mat-form-field>

        <mat-form-field class="half" *ngIf="country_bool == 'canada' ">
            <mat-select id="baisc-wind" (selectionChange)="saveChanges()" matNativeControl placeholder="BASIC WIND PRESSURE" [(value)]="speed">
                <mat-option value="0.20">0.20</mat-option>
                <mat-option value="0.30">0.30</mat-option>
                <mat-option value="0.40">0.40</mat-option>
                <mat-option value="0.50">0.50</mat-option>
                <mat-option value="0.60">0.60</mat-option>
                <mat-option value="0.70">0.70</mat-option>
                <mat-option value="0.80">0.80</mat-option>
                <mat-option value="0.90">0.90</mat-option>
                <mat-option value="1.00">1.00</mat-option>
                <mat-option value="1.10">1.10</mat-option>
            </mat-select>
            <span matSuffix>KPa</span>
        </mat-form-field>


    </div>

    <button mat-raised-button class="save" (click)="saveChanges(); closemodel(); postfromhead();">Save</button>

</div>

<div class="step-container" *ngIf="(mapservice.currentMessage | async) != 'finished'">
    <span class="close-butt" (click)="closeintro()"><i class="fas fa-times"></i></span>
    <h3 class="mt-0">Step 2</h3>
    <p class="intro-text">Fill Up Location Info</p>
    <button mat-raised-button class="next-butt" (click)="onNext()">Next</button>
    <button mat-raised-button class="mr-1 next-butt" (click)="onPrev()">Prev</button>
    <p class="intro-text "><b>2/5</b></p>
</div>