import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectService } from 'src/app/service/project.service';
import { SpanService } from 'src/app/service/span.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProductsComponent } from 'src/app/calspan/products.component';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-calspan',
  templateUrl: './calspan.component.html',
  styleUrls: ['./calspan.component.css']
})
export class CalspanComponent implements OnInit, OnDestroy {

  calculate = '';
  country = 'US';
  product = 'RT-';
  pv: any;
  orientation: any;
  width: any;
  length: any;
  panelarea: any;
  asce: any;
  snow: any;
  terrain: any;
  wind: any;
  exposure: any;
  speed: any;
  rt: any;
  type: any;
  pitch: any;
  clip: any;
  state: any;
  province: any;
  zone: any;
  mailtoInfo: string;
  span = [0, 0];
  spanC = null;
  mrh: any;
  spanTitle = ['', ''];
  title = 'Max Span';
  RDR = false;
  metric = false;
  unit = ' Inch';
  letter = 'STATE';
  singleSpan = true;
  dialogRef: any;
  widlenbool = false;

  constructor(
    public projectService: ProjectService,
    private dialog: MatDialog,
    private maxSpanService: SpanService,
    private router: Router) {
    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd && e.url === '/calspan') {
        //   this.ngOnInit();
        this.product = 'RT-';
      }
    });
  }


  ngOnInit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    this.dialogRef = this.dialog.open(ProductsComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((result: any) => {
      this.calculate = result;
      if (this.calculate) {
        //  console.log(this.calculate)
        this.country = (this.calculate[this.calculate.length - 1] === 'C') ? 'Canada' : 'US';
        this.calculate = (this.calculate[this.calculate.length - 1] === 'C') ?
          this.calculate.substring(0, this.calculate.length - 1) : this.calculate;
        this.letter = (this.country === 'US') ? 'STATE' : 'PROVINCIAL';
        this.product += (this.calculate.slice(0, 5) === 'mini2') ? 'MINI II' : this.calculate.toString().toUpperCase();
        this.setDispplayVariables();
      }
    });
    this.initializeVariables();
  }


  ngOnDestroy() {
    this.dialogRef.close();
    this.dialog.closeAll();
  }


  initializeVariables() {
    const data = this.projectService.getProject();
    this.pv = data.pv;
    this.orientation = '0';
    this.width = data.width;
    this.length = data.length;
    this.asce = data.asce;
    this.snow = data.snow;
    this.exposure = data.exposure;
    this.speed = data.speed;
    this.rt = data.rt;
    this.type = data.type;
    this.state = data.state;
    this.province = data.state;
    this.zone = '1';
    this.setDispplayVariables();
  }


  saveChanges() {
    this.projectService.setProject('pv', this.pv);
    this.projectService.setProject('width', this.width);
    this.projectService.setProject('length', this.length);
    this.projectService.setProject('asce', this.asce);
    this.projectService.setProject('snow', this.snow);
    this.projectService.setProject('exposure', this.exposure);
    this.projectService.setProject('speed', (this.country === 'US') ? this.speed : this.wind);
    this.projectService.setProject('rt', this.rt);
    this.projectService.setProject('type', this.type);
    this.projectService.setProject('pitch', this.pitch);
    this.projectService.setProject('state', (this.country === 'US') ? this.state : this.province);
    this.projectService.setProject('panelarea', this.panelarea);
    this.projectService.setProject('mrh', this.mrh);
    this.setDispplayVariables();
  }

  changeLengthByPV(value: any) {
    if (value === '72') {
      this.width = '40';
      this.length = '77.5';
    }
    if (value === '60') {
      this.width = '40';
      this.length = '68';
    }
    if (value === '90') {
      this.width = '44';
      this.length = '68';
    }
	if(value === '40' || value === '45') {
	  this.width = value;
      this.length = 'Any';
	}
	if(value === '68' || value === '80') {
	  this.width = 'Any';
      this.length = value;
	}
  }
	
  orienchg() {
	if(this.asce == 22) {
	  this.pv = this.orientation == 0 ? '40' : '68';
	  this.changeLengthByPV(this.pv)
	  }
  }  
	
	
  changeMinSpeed(value: any) {
	this.setDispplayVariables()
	if(value == 22) {
	this.widlenbool = true;
	this.pv = this.orientation == 0 ? '40' : '68';
	this.changeLengthByPV(this.pv)
	this.state = (this.state == 'CO' || this.state == 'FL') ? this.state : '';
	}
	else {
	this.pv = '60';
	this.changeLengthByPV(this.pv)
	this.widlenbool = false;
	}
    //this.speed = (value === '10') ? '110' : '95';
  }

  async calculateSpan() {
    let maxSpan: any;
    this.saveChanges();
    if (this.country === 'Canada') { this.metric = false; }
    this.unit = (this.country === 'US') ? (this.metric) ? ' CM' : ' Inch' : ' Meter';
    this.singleSpan = ((this.calculate === 'mini2' && this.asce === '10') || this.calculate === 'mini') ? true : false;
	if (this.pitch) {
      if (this.country === 'US') {
        try {
          if (this.rt.length > 8) {
            this.RDR = true;
            const slice = (this.calculate === 'mini2') ? 7 : 6;
            const rt1 = this.rt.slice(0, slice);
            const rt2 = this.rt.slice(slice);
            let maxspan1: any; let maxspan2: any;
            if (this.orientation === '0') { // Landscape
              maxspan1 = await this.maxSpanService.getMaxSpanLand(rt1, this.calculate);
              maxspan2 = await this.maxSpanService.getMaxSpanLand(rt2, this.calculate);
            } else if (this.orientation === '1') { // Portrait
              maxspan1 = await this.maxSpanService.getMaxSpanPort(rt1, this.calculate);
              maxspan2 = await this.maxSpanService.getMaxSpanPort(rt2, this.calculate);
            }
            // console.log(maxspan1, maxspan2);
            const rtAvg1 = ((Number(maxspan1.span[Number(this.zone) - 1]) + Number(maxspan2.span[Number(this.zone) - 1])) / 2);
            const rtAvg2 = (Number(maxspan1.spanSDST[Number(this.zone) - 1]) + Number(maxspan2.spanSDST[Number(this.zone) - 1])) / 2;

            this.span = [
              Number(maxspan1.span[Number(this.zone) - 1]),
              Number(maxspan1.spanSDST[Number(this.zone) - 1]),
              Number(maxspan2.span[Number(this.zone) - 1]),
              Number(maxspan2.spanSDST[Number(this.zone) - 1]),
              Number(rtAvg1), Number(rtAvg2)
            ];
            if (this.metric) { this.span = this.convertToMetric(); }
          } else if (this.rt.length < 9) {
            this.RDR = false;
            if (this.orientation === '0') { // Landscape
              maxSpan = await this.maxSpanService.getMaxSpanLand(this.rt, this.calculate);
            } else if (this.orientation === '1') { // Portrait
              maxSpan = await this.maxSpanService.getMaxSpanPort(this.rt, this.calculate);
            }
            this.span = [ Math.round(maxSpan.span[Number(this.zone) - 1]), Math.round(maxSpan.spanSDST[Number(this.zone) - 1]) ];
			if (this.metric) { this.span = this.convertToMetric(); }
          } else {
            // Not a valid information
            alert('You have not select a valid information');
          }
        } catch (error) {
          console.log(error);
        }
      } else if (this.country === 'Canada') { // Canadian Data
        const project = {
          snow: this.snow, terr: this.terrain, wind: this.wind, zone: this.zone, pitch: this.pitch,
          width: this.width, length: this.length, orientation: this.orientation, pv: this.pv, state: this.province
        };
        try {
          if (this.orientation === '0') { // Landscape
            maxSpan = await this.maxSpanService.getMaxSpanLandCan(this.rt, this.calculate, project, this.clip);
          } else if (this.orientation === '1') { // Portrait
            maxSpan = await this.maxSpanService.getMaxSpanPortCan(this.rt, this.calculate, project, this.clip);
          }
          this.spanC = maxSpan[0].toFixed(2);
          if (this.spanC === 'NaN') { this.spanC = '0.0'; }
          // console.log(this.spanC)
          // console.log(maxSpan)
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      alert('Please complete all the fields in the form.');
    }
  }


  createPELink() {
    // console.log(this.calculate)
    const basicUrl = (this.country === 'US') ?
      'https://design.roof-tech.us/PDF/Stamped-PE-Letters/' :
      'https://design.roof-tech.us/PDF/Stamped-PE-Letters/Canada/RT_MINI_II/RT_Mini_II_Canada_'; 
    const foldername = (this.calculate === 'apex') ? 'APEX_7_' : (this.calculate === 'mini') ? 'RT_MINI_7_' :
      (this.calculate === 'mini2') ? 'RT_MINI_II_7_' : '';
    const filename = (this.calculate === 'apex') ? 'APEX_7_' : (this.calculate === 'mini') ? 'RT_Mini_7_' :
      (this.calculate === 'mini2') ? 'RT_Mini_II_ASCE_7-' : '';
	 let reportsLink;
	 if(this.asce === '10') {
	 reportsLink = basicUrl + foldername + '10/' + filename + '10_';
	 }
	 else if(this.asce === '16') {
	 reportsLink = basicUrl + foldername + '16/' + filename + '16_';
	 }
	 else if(this.asce === '22') {
	 reportsLink = basicUrl + foldername + '22/' + filename + '22_';
	 }
	 else {
	 reportsLink = '';
	 }
	 /*
	const reportsLink = (this.asce === '10') ? basicUrl + foldername + '10/' + filename + '10_' :
      (this.asce === '16') ? basicUrl + foldername + '16/' + filename + '16_' : '';
    */
	const url = (this.country === 'US') ? reportsLink + this.state + '.pdf' : basicUrl + this.province + '.pdf';
    // console.log(url);
    window.open(url, '_blank');
  }

  createBody() {
    const ornt = (this.orientation === '1') ? 'Portrait' : (this.orientation === '0') ? 'Landscape' : '';
    let roofAttach = '';
	let pvtxt = '';
	if(this.pv == '60') { pvtxt = '60 cells (Dims. up to 40 x 68 inches)' 	}
	else if(this.pv == '72') { pvtxt = '60 cells (Dims. up to 40 x 68 inches)' }
	else if(this.pv == '90') { pvtxt = 'Others' }
	else if(this.pv == '40') { pvtxt = 'Panels up to 40" in Width'	}
	else if(this.pv == '45') { pvtxt = 'Panels from 40" to 45" in Width'	}
	else if(this.pv == '68') { pvtxt = 'Panels up to 68" in Length'	}
	else if(this.pv == '80') { pvtxt = 'Panels from 68" to 80" in Length'	}
    
	
	//const pvModule = (this.pv === '90') ? 'Custom' : this.pv + ' cells';
	const pvModule = pvtxt ;
    switch (this.rt) {
      case 'plywood':
        roofAttach = 'Plywood 15/32"';
        break;
      case 'osb':
        roofAttach = 'OSB 7/16"';
        break;
      case '1x6plank':
        roofAttach = '1x6 Plank';
        break;
      case 'rafter':
        roofAttach = 'Rafter Only';
        break;
      case 'rafter6':
        roofAttach = 'Rafter (60mm screws)';
        break;
      case 'rafter9':
        roofAttach = 'Rafter (90mm screws)';
        break;
      case 'rafter0':
        roofAttach = 'Rafter Offset';
        break;
      case 'rafterplywood':
        roofAttach = 'Rafter-Plywood-Rafter';
        break;
      case 'rafterosb':
        roofAttach = 'Rafter-OSB-Rafter';
        break;
      case 'rafter1x6plank':
        roofAttach = 'Rafter-Plank-Rafter';
        break;
      case 'rafter6plywood':
        roofAttach = 'Rafter-Plywood Hybrid (60mm screws)';
        break;
      case 'rafter9plywood':
        roofAttach = 'Rafter-Plywood Hybrid (90mm screws)';
        break;
      case 'rafter0plywood':
        roofAttach = 'Offset Rafter-Plywood Hybrid (60mm screws)';
        break;
      case 'rafter6osb':
        roofAttach = 'Rafter-OSB Hybrid (60mm screws)';
        break;
      case 'rafter6osb':
        roofAttach = 'Rafter-OSB Hybrid (90mm screws)';
        break;
      case 'rafter0osb':
        roofAttach = 'Offset Rafter-OSB Hybrid (60mm screws)';
        break;
    }
    const minRoofHeight = (this.mrh === undefined) ? '30' : this.mrh;
    let body = 'Your Project Information:%0D%0A' +
      '%0D%0AProduct Type: ' + this.product +
      '%0D%0APV module: ' + pvModule +
      '%0D%0AOrientation: ' + ornt +
      '%0D%0AWidth: ' + this.width + ' Inch' +
      '%0D%0ALength: ' + this.length + ' Inch' +
      '%0D%0ABuilding Code: ' + 'ASCE 7-' + this.asce +
      '%0D%0ASnow Load: ' + this.snow + ' PSF' +
      '%0D%0AWind Exposure: ' + this.exposure +
      '%0D%0AWind Speed: ' + this.speed + ' MPH' +
      '%0D%0ARoof Type: ' + this.type +
      '%0D%0ARoof Zone: ' + this.zone +
      '%0D%0ARoof Attachment: ' + roofAttach +
      '%0D%0ARoof Pitch: ' + this.pitch + ' Degree' +
      '%0D%0AMean Roof Height: ' + minRoofHeight + '\' Feet' +
      '%0D%0AState: ' + this.state;
	
let apexspantxt = ( this.asce == '22') ? '%0D%0ASpan Non-Exposed: ' : '%0D%0AMax Span: ';
let apexspansec = ( this.asce == '22') ? '%0D%0ASpan Exposed: ' : '%0D%0AMax Span with SDST: ';
let rafterspantxt = ( this.asce == '22') ? '%0D%0ARafter Max Span Non-Exposed: ' : '%0D%0ARafter Max Span: ';
let rafterspansec = ( this.asce == '22') ? '%0D%0ARafter Max Span Exposed: ' : '%0D%0ARafter Max Span with SDST: ';
let deckspan = ( this.asce == '22') ? '%0D%0ADeck Max Span Non-Exposed: ' : '%0D%0ADeck Max Span: ';
let deckspansec = ( this.asce == '22') ?  '%0D%0ADeck Max Span Exposed: ' : '%0D%0ADeck Max Span with SDST: ';
let hybridtxt = ( this.asce == '22') ? '%0D%0AHybrid Max Span Non-Exposed: ' : '%0D%0AHybrid Max Span: ';
let hybridsec = ( this.asce == '22') ? '%0D%0AHybrid Max Span Exposed: ' : '%0D%0AHybrid Max Span with SDST: ';
	
	
    if (this.span[0] > 0) {
      const spanMini = (this.span.length > 2) ?
        '%0D%0ARafter Max Span: ' + this.span[0] + this.unit +
        '%0D%0ADeck Max Span: ' + this.span[2] + this.unit +
        '%0D%0AHybrid Max Span: ' + this.span[4] + this.unit
        :
        ( this.asce == '22') ?  
		(apexspantxt + this.span[0] + this.unit + apexspansec + this.span[1] + this.unit)
		: '%0D%0AMax Span: ' + this.span[0] + this.unit;
      
	  
	  const spanApex = (this.span.length > 2) ?
	    rafterspantxt + this.span[0] + this.unit +
        rafterspansec + this.span[1] + this.unit +
        deckspan + this.span[2] + this.unit +
        deckspansec + this.span[3] + this.unit +
        hybridtxt + this.span[4] + this.unit +
        hybridsec + this.span[5] + this.unit
        :
		 apexspantxt + this.span[0] + this.unit +
        apexspansec + this.span[1] + this.unit;
		
      const spanMini2 = (this.span.length > 2) ?
        '%0D%0ARafter Max Span Non-Exposed: ' + this.span[0] + this.unit +
        '%0D%0ARafter Max Span Exposed: ' + this.span[1] + this.unit +
        '%0D%0ADeck Max Span Non-Exposed: ' + this.span[2] + this.unit +
        '%0D%0ADeck Max Span Exposed: ' + this.span[3] + this.unit +
        '%0D%0AHybrid Max Span Non-Exposed: ' + this.span[4] + this.unit +
        '%0D%0AHybrid Max Span Exposed: ' + this.span[5] + this.unit
        :
        '%0D%0AMax Span Non-Exposed: ' + this.span[0] + this.unit +
        '%0D%0AMax Span Exposed: ' + this.span[1] + this.unit;

      body += (this.singleSpan) ? spanMini : (this.calculate === 'mini2' && (this.asce === '16' || this.asce === '22')) ? spanMini2
        : (this.calculate === 'apex') ? spanApex : 'There was an error calculting your requested Max Span. Please try again.';
    }
    return body;
  }


  createBodyCan() {
    const ornt = (this.orientation === '1') ? 'Portrait' : (this.orientation === '0') ? 'Landscape' : '';
    let roofAttach = ''; let roofAngle = '';
    const pvModule = (this.pv === '90') ? 'Custom' : this.pv + ' cells';
    switch (this.rt) {
      case 'plywood':
        roofAttach = 'Plywood 15/32"';
        break;
      case 'osb':
        roofAttach = 'OSB 7/16"';
        break;
      case 'rafter6':
        roofAttach = 'Rafter centered with 15/32 plywood and (2)-60 mm screws';
        break;
      case 'rafter9':
        roofAttach = 'Rafter centered with 15/32 plywood and (2)-90 mm screws';
        break;
      case 'rafter6osb':
        roofAttach = 'Rafter centered with 7/16 OSB and (6)-60 mm screws';
        break;
      case 'rafter0osb':
        roofAttach = 'Rafter off-centered with 7/16 OSB and (5)-60 mm screws';
        break;
    }
    switch (this.pitch) {
      case '0':
        roofAngle = '0 to 6';
        break;
      case '7':
        roofAngle = '7 to 27';
        break;
      case '28':
        roofAngle = '28 to 45';
        break;
    }
    let body = 'Your Project Information:%0D%0A' +
      '%0D%0AProduct Type: ' + this.product + ' - CANADA' +
      '%0D%0APV module: ' + pvModule +
      '%0D%0AOrientation: ' + ornt +
      '%0D%0AWidth: ' + this.width + ' Inch' +
      '%0D%0ALength: ' + this.length + ' Inch' +
      '%0D%0ASnow and Rain Load: ' + this.snow + ' KPa' +
      '%0D%0ATerrain: ' + this.terrain +
      '%0D%0ABasic Wind Pressure: ' + this.wind + ' KPa' +
      '%0D%0ARoof Zone: ' + this.zone +
      '%0D%0ARoof Attachment: ' + roofAttach +
      '%0D%0ARoof Pitch: ' + roofAngle + ' Degree' +
      '%0D%0AProvince: ' + this.province;
    if (this.spanC > 0) {
      const spanMini =
        // (this.span.length > 2) ?
        //   '%0D%0ARafter Max Span: ' + this.span[0] + this.unit +
        //   '%0D%0ADeck Max Span: ' + this.span[2] + this.unit +
        //   '%0D%0AHybrid Max Span: ' + this.span[4] + this.unit
        //   :
        '%0D%0AMax Span: ' + this.spanC + this.unit;
      const spanApex =
        // (this.span.length > 2) ?
        //   '%0D%0ARafter Max Span: ' + this.span[0] + this.unit +
        //   '%0D%0ARafter Max Span with SDST: ' + this.span[1] + this.unit +
        //   '%0D%0ADeck Max Span: ' + this.span[2] + this.unit +
        //   '%0D%0ADeck Max Span with SDST: ' + this.span[3] + this.unit +
        //   '%0D%0AHybrid Max Span: ' + this.span[4] + this.unit +
        //   '%0D%0AHybrid Max Span with SDST: ' + this.span[5] + this.unit
        //   :
        '%0D%0AMax Span: ' + this.spanC + this.unit;
      // + '%0D%0AMax Span with SDST: ' + this.span[1] + this.unit;

      const spanMini2 = '%0D%0AMax Spa: ' + this.spanC + this.unit;

      body += (this.calculate === 'mini') ? spanMini : (this.calculate === 'mini2') ? spanMini2
        : (this.calculate === 'apex') ? spanApex : 'There was an error calculting your requested Max Span. Please try again.';
    }
    return body;
  }


  email() {
    if (this.span[0] > 0 && this.country === 'US') {
      const body = this.createBody();
      return 'mailto:' + '?subject=' + this.product + ' SPAN CALCULATOR - ' + this.getDateOnly() + '&body=' + body;
    } else if (this.spanC > 0 && this.country === 'Canada') {
      const body = this.createBodyCan();
      return 'mailto:' + '?subject=' + this.product + ' SPAN CALCULATOR - ' + this.getDateOnly() + '&body=' + body;
    }
  }


  getDateOnly() {
    // return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    const now = new Date();
    const date = (now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ';
    const minutes = (now.getMinutes() < 10) ? '0' + now.getMinutes().toString() : now.getMinutes().toString();
    const time = (now.getHours() < 12) ?
      + now.getHours() + '-' + minutes + ' am' : (now.getHours() - 12) + ':' + minutes + ' pm';
    return (date + time);
  }


  convertToMetric() {
    const spanMetric = [];
    for (const s of this.span) { spanMetric.push((Number(s * 2.54).toFixed(2))); }
    return spanMetric;
  }


  setDispplayVariables() {
  if(this.calculate === 'apex' && this.asce != '22') {
  this.spanTitle = [this.title + ' without SDST', this.title + ' with SDST'];
  }
  else if(this.calculate === 'mini2' && this.asce === '16') {
  this.spanTitle = [this.title + ' Non-Exposed', this.title + ' Exposed'];
  } 
  else if(this.calculate === 'mini2' && this.asce === '10') {
  this.spanTitle = [this.title];
  }
  else if( this.asce == '22') {
  this.spanTitle = ['Non-Exposed Max Span',' Exposed Max Span'];
  }
	/*
   this.spanTitle = (this.calculate === 'apex') ? [this.title + ' without SDST', this.title + ' with SDST']
      : (this.calculate === 'mini2' && this.asce === '16') ? [this.title + ' Non-Exposed', this.title + ' Exposed'] : [this.title];
	*/
   this.singleSpan = ((this.calculate === 'mini2' && this.asce === '10') || this.calculate === 'mini') ? true : false;
  }

  disableButton() {
    if (this.country === 'US') {
      return !this.pv || !this.orientation || !this.width || !this.length || !this.asce || !this.pitch ||
        !this.snow || !this.exposure || !this.speed || !this.rt || !this.type || !this.zone || (this.asce == '22' && !this.mrh) || (this.calculate === 'mini2' && !this.mrh);
    } else if (this.country === 'Canada') {
      return !this.pv || !this.orientation || !this.width || !this.length || !this.asce || !this.pitch ||
        !this.snow || !this.wind || !this.terrain || !this.rt || !this.zone || !this.province;
    } else {
      return false;
    }
  }

}
