import { Injectable } from '@angular/core'; ``;
import { ProjectService } from 'src/app/service/project.service';
import { PanelService } from 'src/app/service/panel.service';
import { SpanService } from 'src/app/service/span.service';

@Injectable({
  providedIn: 'root'
})

export class DesignService {
  //blank draw calculation
  MAP_TO_PIXEL_PROPORTION = 1.6;
  CLAMP_RATIO = (3.5 / this.MAP_TO_PIXEL_PROPORTION) * 1.25;
  CLAMP_RATIO_CENTER = { x: (1.5 * this.CLAMP_RATIO), y: (0.5 * this.CLAMP_RATIO) };
  CLAMP_RATIO_DIM = { x: (3 * this.CLAMP_RATIO), y: this.CLAMP_RATIO };

  clampsFinal = { end: [], mid: [] };
  spliceFinal = { end: [], mid: [] };
  spliceFloatFinal = { end: [], mid: [] };
  static = Number(this.projectService.getProject().static);
  USE_SPLICE: any;


  constructor(public projectService: ProjectService, public panelService: PanelService,
    private maxSpanService: SpanService
  ) { }

  async getMaxSpan(roof_attach, asterik, country, isPortrait) {
    return country == 'us' ? this.usmaxspan(roof_attach, isPortrait, asterik) : this.canadamaxspan(roof_attach, isPortrait);
  }

  async usmaxspan(roof_attach, isPortrait, asterik) {
    if (isPortrait) { // Portrait
      try {
        const span: any = await this.maxSpanService.getMaxSpanPort(roof_attach, 'apex');
        return asterik ? span.spanSDST[0] : span.span[0];
      }
      catch (err) {
        return err;
      }
    } else { // Landscape
      try {
        const span: any = await this.maxSpanService.getMaxSpanLand(roof_attach, 'apex');
        return asterik ? span.spanSDST[0] : span.span[0];
      }
      catch (err) {
        return err;
      }
    }
  }

  async canadamaxspan(roof_attach, isPortrait) {
    const currentProject = this.projectService.getProject();
    const orientation = isPortrait ? '1' : '0';

    const canadianData = {
      snow: currentProject.snow, terr: currentProject.terr, wind: currentProject.speed, zone: 'R', pitch: currentProject.pitch,
      width: currentProject.width, length: currentProject.length, orientation, pv: currentProject.pv
    };
    if (isPortrait) { // Portrait
      try {
        const span = await this.maxSpanService.getMaxSpanPortCan(roof_attach, 'apex', canadianData, currentProject.clip);
        if (span[0] === 0) throw new Error;
        else return span[0] * 39.37;
      } catch (err) {
        throw err;
      }
    } else { // Landscape
      try {
        const span = await this.maxSpanService.getMaxSpanLandCan(roof_attach, 'apex', canadianData, currentProject.clip);
        if (span[0] === 0) throw new Error;
        else return span[0] * 39.37;
      } catch (err) {
        throw err;
      }
    }
  }

  calculateBottomRowPanelsSkirts(designGroup, PANEL_STROKEWITH) {
    let count = 0;
    const allPanels = [];
    const bottomsPanels = [];
    designGroup.children.each((panel: any) => {
      allPanels.push(panel);
    });
    for (const panel of allPanels) {
      const lowerPointBox1 = {
        x: panel.x() + (panel.width() * 0.1), y: panel.y() + (panel.height() * 1.5),
        width: PANEL_STROKEWITH, height: PANEL_STROKEWITH
      };
      const lowerPointBox2 = {
        x: panel.x() + (panel.width() * 0.9), y: panel.y() + (panel.height() * 1.5),
        width: PANEL_STROKEWITH, height: PANEL_STROKEWITH
      };
      const intersected1 = this.getPanelIntersection(designGroup, lowerPointBox1, PANEL_STROKEWITH);
      const intersected2 = this.getPanelIntersection(designGroup, lowerPointBox2, PANEL_STROKEWITH);
      if (!intersected1.length || !intersected2.length) { bottomsPanels.push(panel); }
      const ratio1 = (!intersected1.length) ? 0.5 : 0;
      const ratio2 = (!intersected2.length) ? 0.5 : 0;
      count += this.isPanelOrientationLandscape(panel) ? 1 * (ratio1 + ratio2) : 0.5 * (ratio1 + ratio2);
    }
    return Math.ceil(count);
  }


  isPanelOrientationLandscape(panel: any) {
    return (panel.width() > panel.height());
  }

  getPanelIntersection(designGroup, clampBox: any, stroke: any) {
    const intersected = [];
    designGroup.children.each((panel: any) => {
      const panelBox = {
        x: panel.x() - stroke, y: panel.y() - stroke,
        width: panel.width() + stroke, height: panel.height() + stroke
      };
      if (this.haveIntersection(clampBox, panelBox)) {
        intersected.push(panel);
      }
    });
    return intersected;
  }


  haveIntersection(r1: any, r2: any) {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    );
  }

  //rafter only lines functions
  showAllPossibleRafterAttachments(isPortrait, designGroup, MAX_SPANS, SPACING, PANEL_STROKEWITH, clampGroup, designLayer, gridGroup, ROOF_ATTACHMENT, WIDTH, RAFTER_DRAG, posDelta, stage, lastPanelWidth) {
    let rafterSplices = { end: [], mid: [] };
    this.clampsFinal = { end: [], mid: [] };
    this.spliceFinal = { end: [], mid: [] };
    this.spliceFloatFinal = { end: [], mid: [] };
    // Everything has been reset -> Reset the min4 checkpoint
    this.projectService.setProject('failed', true);
    this.static = Number(this.projectService.getProject().static);
    // Remove old clamping
    if (clampGroup.children.length > 0) {
      clampGroup.destroyChildren();
    }
    // reset project design submmission, if any
    //this.resetDesignCheck();
    // Draw new Clamping

    if (this.panelcount(designGroup) > 0) {
      // Find all possible clamps on Rafter
      this.clampsFinal = (this.static > 0) ? this.findAllRafterClampsStaticClamping(designGroup, gridGroup, RAFTER_DRAG, PANEL_STROKEWITH) : this.findAllRafterClamps(designGroup, gridGroup, RAFTER_DRAG, PANEL_STROKEWITH);
      this.USE_SPLICE = Number(this.projectService.getProject().splice);
      if (this.USE_SPLICE > 0) {
        // Using Splice selected -> find & show all possible Splice positions
        if (this.USE_SPLICE === 1) {
          this.spliceFinal = this.findAllSplicePos(isPortrait, [], designGroup, SPACING, PANEL_STROKEWITH);
        }
        if (this.USE_SPLICE === 2) {
          this.spliceFloatFinal = this.findAllSplicePos(isPortrait, [], designGroup, SPACING, PANEL_STROKEWITH);
          if (this.projectService.getProject().eave === '1') {
            const fSpliceEave = this.checkFloatingSplicesOnEave(this.spliceFloatFinal.end, designGroup, WIDTH);
            this.spliceFloatFinal.end = fSpliceEave.f;
            this.spliceFinal.end = fSpliceEave.r;
          }
        }
        rafterSplices = this.checkSplicesOnRafter(RAFTER_DRAG, designLayer, designGroup, posDelta, stage, gridGroup);
        this.drawSplicesbyType(this.spliceFinal, this.USE_SPLICE, clampGroup, rafterSplices);
        // Delete Clamps that falls on Rafter Splices
        this.deleteClampsOnRafterSplice(rafterSplices, SPACING, PANEL_STROKEWITH);
      }
      // Draw all possible clamps on Rafter
      this.drawClampsByType(this.clampsFinal, true, clampGroup, designLayer);

      // Draw deck clamps to cover cantilver on west panels
      this.addExtraAttachmentsForRafterOnly(designGroup, isPortrait, MAX_SPANS, SPACING, PANEL_STROKEWITH, lastPanelWidth, clampGroup, designLayer, gridGroup);

      setTimeout(() => {
        this.saveRafterClampingDB(designLayer);
      }, 150);
    }

  }


  panelcount(designGroup) {
    return designGroup.children.length;
  }

  // V2 - Static Clamping: Find Clamps at specific spacing
  findAllRafterClampsStaticClamping(designGroup, gridgroup, RAFTER_DRAG, PANEL_STROKEWITH) {
    const allClampsPos = this.findAllRafterClamps(designGroup, gridgroup, RAFTER_DRAG, PANEL_STROKEWITH);
    // Starting Point => Find Bottom left clamp position
    allClampsPos.end.sort((a, b) => (a.x > b.x) ? 1 : (a.x === b.x) ? ((a.y < b.y) ? 1 : -1) : -1);
    const startClamp = allClampsPos.end[0];
    const staticClamps = { mid: [], end: [] };
    const staticPix = this.static / this.MAP_TO_PIXEL_PROPORTION;
    allClampsPos.end.forEach((clamp: any) => {
      if (Math.abs(clamp.x - startClamp.x) % staticPix === 0) { staticClamps.end.push(clamp); }
    });
    allClampsPos.mid.forEach((clamp: any) => {
      if (Math.abs(clamp.x - startClamp.x) % staticPix === 0) { staticClamps.mid.push(clamp); }
    });
    return staticClamps;
  }


  findAllRafterClamps(designGroup, gridgroup, RAFTER_DRAG, PANEL_STROKEWITH) {
    const allClampsPos = [];
    designGroup.children.each(shape => {
      allClampsPos.push(this.findRafterClampsByPanel(shape.getAttrs(), gridgroup, RAFTER_DRAG, PANEL_STROKEWITH));
    });
    // Remove duplicates
    const allClampsPosFinal = allClampsPos.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i);
    return this.calculateRafterMidClampsPositions(allClampsPosFinal, PANEL_STROKEWITH);
  }

  // V2 - Find splices on eave row
  checkFloatingSplicesOnEave(splicesArr: any, designGroup, WIDTH) {
    const splices = { f: [], r: [] };
    // Get all eave side splices
    const eavesSplices = this.findEaveRowsAttachments(splicesArr, designGroup, WIDTH);
    // Filter out the eave side end splices from Floating End Splice
    splices.f = splicesArr.filter(({ x: x1, y: y1 }) =>
      !eavesSplices.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    splices.r = splicesArr.filter(({ x: x1, y: y1 }) =>
      eavesSplices.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    return splices;
  }


  // Delete Clamps that falls on Rafter Splices
  deleteClampsOnRafterSplice(splices: any, SPACING, PANEL_STROKEWITH) {
    this.clampsFinal.end = this.clampsFinal.end.filter(({ x: x1, y: y1 }) =>
      !(splices.end).flat().some(({ x: x2, y: y2 }) => Math.abs(x1 - x2) < (2 * PANEL_STROKEWITH + SPACING.x) &&
        Math.abs(y1 - y2) < (2 * PANEL_STROKEWITH + SPACING.y)));

    this.clampsFinal.end = this.clampsFinal.end.filter(({ x: x1, y: y1 }) =>
      !(splices.mid).flat().some(({ x: x2, y: y2 }) => Math.abs(x1 - x2) < (2 * PANEL_STROKEWITH + SPACING.x) &&
        Math.abs(y1 - y2) < (2 * PANEL_STROKEWITH + SPACING.y)));

    this.clampsFinal.mid = this.clampsFinal.mid.filter(({ x: x1, y: y1 }) =>
      !(splices.mid).flat().some(({ x: x2, y: y2 }) => Math.abs(x1 - x2) < (2 * PANEL_STROKEWITH + SPACING.x) &&
        Math.abs(y1 - y2) < (2 * PANEL_STROKEWITH + SPACING.y)));

    this.clampsFinal.mid = this.clampsFinal.mid.filter(({ x: x1, y: y1 }) =>
      !(splices.end).flat().some(({ x: x2, y: y2 }) => Math.abs(x1 - x2) < (2 * PANEL_STROKEWITH + SPACING.x) &&
        Math.abs(y1 - y2) < (2 * PANEL_STROKEWITH + SPACING.y)));
  }


  // Find Splices that fall on Rafter lines


  checkSplicesOnRafter(RAFTER_DRAG, designLayer, designGroup, posDelta, stage, gridGroup) {
    const rafterSplices = { mid: [], end: [] };
    for (const pos of this.spliceFloatFinal.end) {
      if (this.haveRafterIntersection(pos, RAFTER_DRAG, designLayer, designGroup, posDelta, stage, gridGroup)) {
        rafterSplices.end.push(pos);
      }
    }
    for (const pos of this.spliceFinal.end) {
      if (this.haveRafterIntersection(pos, RAFTER_DRAG, designLayer, designGroup, posDelta, stage, gridGroup)) {
        rafterSplices.end.push(pos);
      }
    }
    for (const pos of this.spliceFloatFinal.mid) {
      if (this.haveRafterIntersection(pos, RAFTER_DRAG, designLayer, designGroup, posDelta, stage, gridGroup)) {
        rafterSplices.mid.push(pos);
      }
    }
    for (const pos of this.spliceFinal.mid) {
      if (this.haveRafterIntersection(pos, RAFTER_DRAG, designLayer, designGroup, posDelta, stage, gridGroup)) {
        rafterSplices.mid.push(pos);
      }
    }

    this.spliceFloatFinal.mid = this.spliceFloatFinal.mid.filter(({ x: x1, y: y1 }) =>
      !rafterSplices.mid.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    this.spliceFloatFinal.end = this.spliceFloatFinal.end.filter(({ x: x1, y: y1 }) =>
      !rafterSplices.end.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    this.spliceFinal.end = this.spliceFinal.end.filter(({ x: x1, y: y1 }) =>
      !rafterSplices.end.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    this.spliceFinal.mid = this.spliceFinal.mid.filter(({ x: x1, y: y1 }) =>
      !rafterSplices.mid.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));

    return rafterSplices;
  }

  // Find Extra Deck clamps if no splice used on portrait
  findExtraDeckClampsPortHybrid(clampsArr: any, WIDTH, designGroup) {
    const portClamps = { mid: [], end: [] };
    const extraClamps = { mid: [], end: [] };
    for (const pos of clampsArr.end) { if (this.getOrientationPanelIntersection(pos, designGroup) === 'port') { portClamps.end.push(pos); } }
    for (const pos of clampsArr.mid) { if (this.getOrientationPanelIntersection(pos, designGroup) === 'port') { portClamps.mid.push(pos); } }
    portClamps.end.sort((a, b) => (a.y > b.y) ? 1 : (a.y === b.y) ? ((a.x > b.x) ? 1 : -1) : -1);
    portClamps.mid.sort((a, b) => (a.y > b.y) ? 1 : (a.y === b.y) ? ((a.x > b.x) ? 1 : -1) : -1);
    for (let i = 0; i < portClamps.end.length - 1; i++) {
      if (portClamps.end[i].y === portClamps.end[i + 1].y && Math.abs(portClamps.end[i].x - portClamps.end[i + 1].x) < WIDTH) {
        extraClamps.end.push(this.getCenterPos(portClamps.end[i], portClamps.end[i + 1]));
      }
    }
    for (let i = 0; i < portClamps.mid.length - 1; i++) {
      if (portClamps.mid[i].y === portClamps.mid[i + 1].y && Math.abs(portClamps.mid[i].x - portClamps.mid[i + 1].x) < WIDTH) {
        extraClamps.mid.push(this.getCenterPos(portClamps.mid[i], portClamps.mid[i + 1]));
      }
    }
    return extraClamps;
  }


  findRafterClampsByPanel(rect: any, gridgroup, RAFTER_DRAG, PANEL_STROKEWITH) {
    const clamps = [];
    const allRafterXs = [];
    gridgroup.find('Line').each((line: any) => {
      const rafterX = line.getAttrs().points[0] + RAFTER_DRAG;
      if (rafterX >= (rect.x - PANEL_STROKEWITH) && rafterX <= (rect.x + rect.width + PANEL_STROKEWITH)) {
        allRafterXs.push(rafterX);
      }
    });
    for (const Xs of allRafterXs) {
      clamps.push({ x: Xs, y: rect.y });
      clamps.push({ x: Xs, y: rect.y + rect.height });
    }
    return clamps;
  }

  // V2 - Calaculate mid clamp positions from all rafter clamps positions
  calculateRafterMidClampsPositions(allClampsPos: any, PANEL_STROKEWITH) {
    const midClampsPos = [];
    allClampsPos.sort((a, b) => (a.y > b.y) ? 1 : (a.y === b.y) ? ((a.x > b.x) ? 1 : -1) : -1);
    for (let i = 0; i < allClampsPos.length; i++) {
      for (let j = i + 1; j < allClampsPos.length; j++) {
        if (allClampsPos[i].x === allClampsPos[j].x && Math.abs(allClampsPos[i].y - allClampsPos[j].y) < (2 * PANEL_STROKEWITH)) {
          midClampsPos.push([allClampsPos[i], allClampsPos[j]]);
        }
      }
    }
    const allClampsPosFlat = [].concat.apply([], allClampsPos);
    const midClampsPosFlat = [].concat.apply([], midClampsPos);

    // Get End clamps by getting the NOT overlap of midClamps & allClamps positions
    const endClampsPos = allClampsPosFlat.filter(({ x: x1, y: y1 }) =>
      !midClampsPosFlat.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));

    const midClampsCenter = this.findCenterPos(midClampsPos);

    return {
      mid: midClampsCenter,
      end: endClampsPos
    };
  }

  // NEW
  // Find Eave rows from end clamps positions
  findEaveRowsAttachments(spliceArr: any, designGroup, WIDTH) {
    // Find if there exist any panel with half of pixel Width below of any end splice =>
    // If not, then that's eave row
    const intersected = [];
    for (const pos of spliceArr) {
      if (this.findEaveRowSplice(pos, designGroup, WIDTH).length) { intersected.push(pos); }
    }
    const bottoms = spliceArr.filter(({ x: x1, y: y1 }) => !intersected.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    return bottoms;
  }

  // Given a point, return Rafter line(s) that intersects with shape or empty array (range +- 1")
  haveRafterIntersection(pos: any, RAFTER_DRAG, designLayer, designGroup, posDelta, stage, gridGroup) {
    let flag = false;
    const range = 0.5;  // 1 inch in pixel -> 0.5 for each side
    const rafterOnPanels = this.findPanelXRafterline(designGroup, RAFTER_DRAG, posDelta, stage, gridGroup);
    for (const line of rafterOnPanels) {
      const lineX = line.getAttrs().points[0] + RAFTER_DRAG;
      if (pos.x >= lineX - range && pos.x <= lineX + range) {
        flag = true;
        break;
      }
    }
    designLayer.draw();
    return flag;
  }

  // Check for a given attachment pos, the intersected panels in designGroup; return the intersected panel(s) orientations or empty array
  getOrientationPanelIntersection(pos: any, designGroup) {
    const intersected = [];
    designGroup.children.each((panel: any) => {
      const clampBox = { x: pos.x, y: pos.y, width: this.CLAMP_RATIO_DIM.x, height: this.CLAMP_RATIO_DIM.y };
      const panelBox = { x: panel.x(), y: panel.y(), width: panel.width(), height: panel.height() };
      if (this.haveIntersection(clampBox, panelBox)) {
        intersected.push(this.getPanelOrientation(panel));
      }
    });
    // If any of the orientations of the intersected panel(s) are Portrait, consider it portrait, otherwise landscape
    return intersected.find(x => x === 'port') ? 'port' : 'land';
  }

  findEaveRowSplice(splice: any, designGroup, WIDTH) {
    const intersected = [];
    designGroup.children.each((panel: any) => {
      const spliceBoxBottom = {
        x: splice.x, y: splice.y + (WIDTH / 2),
        width: this.CLAMP_RATIO, height: this.CLAMP_RATIO
      };
      const panelBox = { x: panel.x(), y: panel.y(), width: panel.width(), height: panel.height() };
      if (this.haveIntersection(spliceBoxBottom, panelBox)) {
        intersected.push(splice);
      }
    });
    return intersected;
  }

  // Find RafterLines that intersects with drawn polygons
  findPanelXRafterline(designGroup, RAFTER_DRAG, posDelta, stage, gridGroup) {
    const rafterPanles = [];
    const drawnPanelsBox = designGroup.getClientRect({ skipStroke: true });
    //should have grid group
    gridGroup.children.each((line: any) => {
      const lineX = line.getAttrs().points[0] + RAFTER_DRAG;
      if (lineX >= (drawnPanelsBox.x - posDelta.x) / stage.scaleX() &&
        lineX <= (drawnPanelsBox.x + drawnPanelsBox.width - posDelta.x) / stage.scaleX()) {
        rafterPanles.push(line);
      }
    });
    return rafterPanles;
  }

  // Return the orientation of a given panel
  getPanelOrientation(panel: any) {
    return (panel.width() > panel.height()) ? 'land' : 'port';
  }

  //rafter only lines end


  //simple version of showAllPossibleDeckAttachments function
  showAllPossibleDeckAttachments(isPortrait, designGroup, MAX_SPANS, SPACING, PANEL_STROKEWITH, clampGroup, designLayer) {
    const allPanels = [];
    let clampsFinal = { end: [], mid: [] };
    let spliceFinal = { end: [], mid: [] };
    let USE_SPLICE = Number(this.projectService.getProject().splice);
    if (clampGroup.children.length > 0) { clampGroup.destroyChildren(); }
    designGroup.find('Rect').each(shape => {
      allPanels.push(shape);
    });
    if (USE_SPLICE === 0) {
      clampsFinal = this.findAllDeckClamps(isPortrait, designGroup, allPanels, MAX_SPANS, SPACING, PANEL_STROKEWITH);
      //return clampsFinal;
    } else if (USE_SPLICE === 1) { // If splice selected, check fo r allowance & calculate splice positions
      spliceFinal = this.findAllSplicePos(isPortrait, allPanels, designGroup, SPACING, PANEL_STROKEWITH);
      this.drawSplicesbyType(spliceFinal, USE_SPLICE, clampGroup);
      clampsFinal = this.adjustClampsWithSplice(isPortrait, designGroup, allPanels, MAX_SPANS, SPACING, PANEL_STROKEWITH, clampGroup);
    }
    this.drawClampsByType(clampsFinal, false, clampGroup, designLayer);
    this.saveDeckClampingDB(designLayer);
  }


  // Find All Possible splice Position and adjust the middle splices
  findAllSplicePos(isPortrait: boolean, panelsArr: any, designGroup, SPACING, PANEL_STROKEWITH) {
    const width = isPortrait ? this.projectService.getProject().width : this.projectService.getProject().length;
	const allCorners = [];
    if (!panelsArr.length) {
      designGroup.children.each(shape => {
        panelsArr.push(shape);
      });
    }
    for (const panel of panelsArr) {
      allCorners.push(
        { x: panel.x(), y: panel.y() }, // top Left corner
        { x: panel.x() + panel.width(), y: panel.y() },  // Top Right corner
        { x: panel.x(), y: panel.y() + panel.height() },  // Bottom Left corner
        { x: panel.x() + panel.width(), y: panel.y() + panel.height() }  // Bottom Right corner
      );
    }
    const allSplicePos = [];
    const compare = (width + SPACING.x) / this.MAP_TO_PIXEL_PROPORTION / 3;
    // Sort based on Y first, then X
    allCorners.sort((a, b) => (a.y > b.y) ? 1 : (a.y === b.y) ? ((a.x > b.x) ? 1 : -1) : -1);
    for (let i = 0; i < allCorners.length - 1; i++) {
      if (Math.abs(allCorners[i + 1].y - allCorners[i].y) < compare && Math.abs(allCorners[i + 1].x - allCorners[i].x) < compare) {
        allSplicePos.push(this.getCenterPos(allCorners[i], allCorners[i + 1]));
      }
    }
    return this.calculateMidSplicePositions(designGroup, allSplicePos.flat(), SPACING, PANEL_STROKEWITH);
  }

  // Find the center Position when given two points
  getCenterPos(pos1: any, pos2: any) {
    return { x: (pos1.x + pos2.x) / 2, y: (pos1.y + pos2.y) / 2 };
  }

  // Find middle splice positions
  calculateMidSplicePositions(designGroup, allSplicePos: any, SPACING, PANEL_STROKEWITH) {
    const intersected = [];
    for (const splice of allSplicePos) {
      const spliceBox = {
        x: splice.x - (6 * this.CLAMP_RATIO), y: splice.y - (3 * this.CLAMP_RATIO),
        width: (9 * this.CLAMP_RATIO), height: (4.5 * this.CLAMP_RATIO)
      };
      const intersectEachClamp = this.getPanelIntersection(designGroup, spliceBox, 0);
      if (intersectEachClamp !== null) {
        intersected.push([splice, intersectEachClamp]);
      }
    }
    const allSplices = { end: [], mid: [] };
    for (const s of intersected) {
      (s[1].length > 2) ? allSplices.mid.push(s[0]) : allSplices.end.push(s[0]);
    }
    const clusters = this.clusterSplices(allSplices.mid, SPACING, PANEL_STROKEWITH);
    const clusterArr = [];
    for (const set of clusters) {
      const pair = [...set];
      clusterArr.push(this.getCenterPos(pair[0], pair[1]));
    }
    const midPosFinal = allSplices.mid.filter(({ x: x1, y: y1 }) =>
      !(clusters.flat()).some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    allSplices.mid = midPosFinal.concat([...clusterArr].flat());
    // Align all the splice positions in same row & set the Final Splice positions
    for (const splXPan of intersected) {
      if (allSplices.mid.indexOf(splXPan[0]) >= 0) {
        const newY = this.findPanelsMidY(splXPan[1], PANEL_STROKEWITH, SPACING);
        splXPan[0].y = newY;
      }
    }
    return allSplices;
  }

  // Cluster data together based on their distance
  clusterSplices(spliceArr: any, SPACING, PANEL_STROKEWITH) {
    const map = new Map();
    const differX = 2 * this.CLAMP_RATIO;
    const differY = (2 * PANEL_STROKEWITH) + SPACING.y;
    for (let i = 0; i < spliceArr.length - 1; i++) {
      for (let j = 1; j < spliceArr.length; j++) {
        if (Math.abs(spliceArr[i].x - spliceArr[j].x) <= differX && Math.abs(spliceArr[i].y - spliceArr[j].y) <= differY &&
          spliceArr[i] !== spliceArr[j]) {
          if (!map.has(spliceArr[i]) && !map.has(spliceArr[j])) { map.set(spliceArr[i], spliceArr[j]); }
        }
      }
    }
    return [...map];
  }

  drawSplicesbyType(spliceFinal, sType: any, clampGroup, rafterSplices?: any) {
    if (spliceFinal.end.length > 0) {
      this.drawSplicesArr(spliceFinal.end, 'green', clampGroup, false, false);
    }
    if (spliceFinal.mid.length > 0) {
      this.drawSplicesArr(spliceFinal.mid, 'orangered', clampGroup, false, false);
    }
    //not supporting floating splice and rafter

    if (rafterSplices) {
      if (rafterSplices.end.length > 0) {
        this.drawSplicesArr(rafterSplices.end, 'green', clampGroup, true, false);
      }
      if (rafterSplices.mid.length > 0) {
        this.drawSplicesArr(rafterSplices.mid, 'orangered', clampGroup, true, false);
      }
    }
    if (sType === 2) {
      if (this.spliceFloatFinal.mid.length > 0) {
        this.drawSplicesArr(this.spliceFloatFinal.mid, 'lightsalmon', clampGroup, false, true);
      }
      if (this.spliceFloatFinal.end.length > 0) {
        this.drawSplicesArr(this.spliceFloatFinal.end, 'yellowgreen', clampGroup, false, true);
      }
    }

  }

  drawSplicesArr(allSplice: any, color: string, group: any, rafter: boolean, float: boolean) {
    allSplice.forEach((element: any) => {
      this.drawSplice(element, color, group, rafter, float);
    });
  }

  drawSplice(element: any, color: string, group: any, rafter: boolean, float: boolean) {
    const splice = this.panelService.createSplice(element.x, element.y, color, this.CLAMP_RATIO);
    group.add(splice);
    if (float) {
      splice.fill(color);
      splice.setAttr('stroke', 'white');
    } else {
      if (rafter) {
        splice.fill(color);
      } else {
        splice.fill('white');
      }
    }
  }


  // Adjust extra clamping with splice
  adjustClampsWithSplice(isPortrait, designGroup, allPanels, MAX_SPANS, SPACING, PANEL_STROKEWITH, clampGroup) {
    const allClampsPos = { land: [], port: [] };

    for (const panel of allPanels) {
      const panelBox = panel.getClientRect({ skipStroke: false });
      const splices = this.getClampIntersectionByShape(panelBox, false, clampGroup);
      const corner = this.categorizeSplices(panel, splices);

      this.findClampsPosBySplices(isPortrait, allClampsPos, panel, corner.tl, corner.tm, corner.tr, true, MAX_SPANS);
      this.findClampsPosBySplices(isPortrait, allClampsPos, panel, corner.bl, corner.bm, corner.br, false, MAX_SPANS);
    }

    const clampsPos = isPortrait ? allClampsPos.port.flat() : allClampsPos.land.flat();
    clampsPos.sort((a, b) => (a.x > b.x) ? 1 : (a.x === b.x) ? ((a.y > b.y) ? 1 : -1) : -1);

    const midClampsCalculated = this.calculateMidClampsPositions(designGroup, clampsPos, PANEL_STROKEWITH, SPACING);

    return { // remove duplicates
      mid: midClampsCalculated.mid.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i),
      end: midClampsCalculated.end.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i)
    };
  }

  // Find Clamps position for splices according to max span
  findClampsPosBySplices(isPortrait: boolean, clampsPos: any, panel: any, left: any, midle: any, right: any, top: boolean, maxSpan) {
    let pos = { x: 0, y: 0 };
    let clampsCount = 0;
    let ratio = 0;
    const width = this.getWidthByOrientation(isPortrait);

    // Left Side empty
    if (left.length === 0 && right.length > 0) {
      clampsCount = this.findPossibleClampsSpliceSide(maxSpan, width);
      ratio = (clampsCount > 1) ? 1 / (clampsCount + 1) : 0.25;
      for (let i = 1; i <= clampsCount; i++) {
        pos = (top) ? { x: panel.x() + (panel.width() * (ratio * i)), y: panel.y() }
          : { x: panel.x() + (panel.width() * (ratio * i)), y: panel.y() + panel.height() };
        if (midle.length) {
          for (const s of midle) {
            if (pos.x > s.x + this.CLAMP_RATIO_DIM.x && pos.x < s.x - this.CLAMP_RATIO_DIM.x) {
              if (isPortrait) { clampsPos.port.push(pos); } else { clampsPos.land.push(pos); }
            }
          }

        } else { if (isPortrait) { clampsPos.port.push(pos); } else { clampsPos.land.push(pos); } }
      }

    }
    // Right side empty
    if (right.length === 0 && left.length > 0) {
      clampsCount = this.findPossibleClampsSpliceSide(maxSpan, width);
      ratio = (clampsCount > 1) ? 1 / (clampsCount + 1) : 0.25;
      for (let i = 1; i <= clampsCount; i++) {
        pos = (top) ? { x: panel.x() + (panel.width() * (1 - (ratio * i))), y: panel.y() }
          : { x: panel.x() + (panel.width() * (1 - (ratio * i))), y: panel.y() + panel.height() };
        if (midle.length) {
          for (const s of midle) {
            if (pos.x > s.x + this.CLAMP_RATIO_DIM.x && pos.x < s.x - this.CLAMP_RATIO_DIM.x) {
              if (isPortrait) { clampsPos.port.push(pos); } else { clampsPos.land.push(pos); }
            }
          }
        } else { if (isPortrait) { clampsPos.port.push(pos); } else { clampsPos.land.push(pos); } }
      }
    }
    // Middle Panels
    if (left.length > 0 && right.length > 0) {
      clampsCount = this.findPossibleClampsSpliceMiddle(maxSpan, width);
      ratio = 1 / (clampsCount + 1);
      for (let i = 1; i <= clampsCount; i++) {
        pos = (top) ? { x: panel.x() + (panel.width() * (ratio * i)), y: panel.y() }
          : { x: panel.x() + (panel.width() * (ratio * i)), y: panel.y() + panel.height() };
        if (midle.length) {
          for (const s of midle) {
            if (pos.x > s.x + this.CLAMP_RATIO_CENTER.x || pos.x < s.x - this.CLAMP_RATIO_CENTER.x) {
              if (isPortrait) { clampsPos.port.push(pos); } else { clampsPos.land.push(pos); }
            }
          }
        } else { if (isPortrait) { clampsPos.port.push(pos); } else { clampsPos.land.push(pos); } }
      }
    }
    // No splice on either side
    if (left.length === 0 && right.length === 0) {
      clampsCount = (maxSpan <= (width / 2)) ? this.findPossibleClamps(maxSpan, width) : 2;
      const cornerSpan = (width - (maxSpan * (clampsCount - 1))) / 2;
      const ratioSpan = (clampsCount > 2) ? (maxSpan / width) : (1 / clampsCount);
      const ratioCorner = (clampsCount > 2) ? cornerSpan / width : (ratioSpan / 2);
      for (let i = 0; i < clampsCount; i++) {
        ratio = (i === 0) ? ratioCorner : (i === clampsCount - 1) ? 1 - ratioCorner : (ratioSpan * i) + ratioCorner;
        pos = (top) ? { x: panel.x() + (panel.width() * ratio), y: panel.y() }
          : { x: panel.x() + (panel.width() * ratio), y: panel.y() + panel.height() };
        if (!midle.length) {
          if (isPortrait) { clampsPos.port.push(pos); } else { clampsPos.land.push(pos); }
        }
      }
    }
  }

  //  Clamps possible points on the corner panels
  findPossibleClampsSpliceSide(maxSpan: any, panelLength: any) {
    let count = 0;
    if (maxSpan < (panelLength * 0.67)) {
      const multiply = Math.floor(panelLength / maxSpan);
      const remainder = panelLength % maxSpan;
      if (remainder > (panelLength * 0.33)) {
        count = multiply + 1;
      } else { count = multiply; }
    } else { count = 1; }
    return count;
  }

  // Clamps possible points on middle panels
  findPossibleClampsSpliceMiddle(maxSpan: any, panelLength: any) {
    let count = 0;
    if (maxSpan < panelLength) {
      const multiply = Math.floor(panelLength / maxSpan);
      const remainder = panelLength % maxSpan;
      if (remainder === 0) {
        count = multiply - 1;
      } else if (remainder > maxSpan) {
        count = multiply + 1;
      } else { count = multiply; }
    } else { count = 0; }
    return count;
  }

  // Categorize the splices around a splice
  categorizeSplices(panel: any, splicesArr: any) {
    const category = { tl: [], tr: [], bl: [], br: [], tm: [], bm: [] };
    const left = {
      x: panel.x() + (panel.width() * 0.3),
      y: panel.y() + (panel.height() * 0.5)
    };
    const right = {
      x: panel.x() + (panel.width() * 0.58),
      y: panel.y() + (panel.height() * 0.5)
    };
    for (const splice of splicesArr) {
      if (splice.x < left.x && splice.y < left.y) { category.tl.push(splice); }
      if (splice.x < left.x && splice.y > left.y) { category.bl.push(splice); }
      if (splice.x > right.x && splice.y < right.y) { category.tr.push(splice); }
      if (splice.x > right.x && splice.y > right.y) { category.br.push(splice); }
      if (splice.x >= left.x && splice.x <= right.x) {
        (splice.y > left.y) ? category.bm.push(splice) : category.tm.push(splice);
      }
    }
    return category;
  }

  // Check for a given panel, the intersected attachments in clampGroup; return the intersected attachment(s) id or empty array
  getClampIntersectionByShape(shape: any, notCountingFloatingSplices: boolean, clampGroup) {
    const intersected = [];
    clampGroup.children.each((attach: any) => {
      const boundingBox = attach.getClientRect();
      if (notCountingFloatingSplices) {
        if (this.haveIntersection(shape, boundingBox) && attach.stroke() !== 'white') {
          intersected.push(attach.getAttrs());
        }
      } else {
        if (this.haveIntersection(shape, boundingBox)) {
          intersected.push(attach.getAttrs());
        }
      }
    });
    return intersected;
  }

  // Draw all the calculated clamping positions
  drawClampsByType(clampsArr: any, rafter: boolean, clampGroup, designLayer) {
    let totpanels = this.projectService.getProject().panels;
    if (totpanels > 0) {
      if (clampsArr.end.length > 0) {
        this.drawClampsArr(clampsArr.end, 'green', clampGroup, rafter);
      }
      if (clampsArr.mid.length > 0) {
        this.drawClampsArr(clampsArr.mid, 'orangered', clampGroup, rafter);
      }
      designLayer.batchDraw();
    }
  }

  drawClampsArr(allClamps: any, color: string, group: any, rafter: boolean) {
    allClamps.forEach((element: any) => {
      this.drawClamp(element, color, group, rafter);
    });
  }

  drawClamp(element: any, color: string, group: any, rafter: boolean) {
    const clamp = this.panelService.createClamps(element.x, element.y, color, this.CLAMP_RATIO);
    group.add(clamp); clamp.moveToTop();
    if (rafter) {
      clamp.fill(color);
    }
  }

  saveDeckClampingDB(designLayer) { // save clamps & splices info for Deck projects
    this.projectService.clearclamping();
    designLayer.find('.clamp').each(shape => {
      if (shape.getAttrs().stroke === 'green') {
        this.projectService.pushclamping('endClampD', shape);
      } else if (shape.getAttrs().stroke === 'orangered') {
        this.projectService.pushclamping('midClampD', shape);
      }
    });
    designLayer.find('.splice').each(shape => {
      if (shape.getAttrs().stroke === 'green') {
        this.projectService.pushclamping('endSpliceD', shape);
      } else if (shape.getAttrs().stroke === 'orangered') {
        this.projectService.pushclamping('midSpliceD', shape);
      } else if (shape.getAttrs().fill === 'lightsalmon' && shape.getAttrs().stroke === 'white') {
        this.projectService.pushclamping('midSpliceF', shape);
      } else if (shape.getAttrs().fill === 'yellowgreen' && shape.getAttrs().stroke === 'white') {
        this.projectService.pushclamping('endSpliceF', shape);
      }
    });
  }

  saveRafterClampingDB(designLayer) { // save clamps & splices info for Rafter projects
    this.projectService.clearclamping();
    designLayer.find('.clamp').each(shape => {
      if (shape.getAttrs().stroke === 'green') {
        if (shape.getAttrs().fill === 'green') {
          this.projectService.pushclamping('endClampR', shape);
        } else if (shape.getAttrs().fill === 'white') {
          this.projectService.pushclamping('endClampD', shape);
        }
      } else if (shape.getAttrs().stroke === 'orangered') {
        if (shape.getAttrs().fill === 'orangered') {
          this.projectService.pushclamping('midClampR', shape);
        } else if (shape.getAttrs().fill === 'white') {
          this.projectService.pushclamping('midClampD', shape);
        }
      }
    });
    designLayer.find('.splice').each(shape => {
      if (shape.getAttrs().stroke === 'green') {
        if (shape.getAttrs().fill === 'green') {
          this.projectService.pushclamping('endSpliceR', shape);
        } else if (shape.getAttrs().fill === 'white') {
          this.projectService.pushclamping('endSpliceD', shape);
        }
      } else if (shape.getAttrs().stroke === 'orangered') {
        if (shape.getAttrs().fill === 'orangered') {
          this.projectService.pushclamping('midSpliceR', shape);
        } else if (shape.getAttrs().fill === 'white') {
          this.projectService.pushclamping('midSpliceD', shape);
        }
      } else if (shape.getAttrs().fill === 'lightsalmon' && shape.getAttrs().stroke === 'white') {
        this.projectService.pushclamping('midSpliceF', shape);
      } else if (shape.getAttrs().fill === 'yellowgreen' && shape.getAttrs().stroke === 'white') {
        this.projectService.pushclamping('endSpliceF', shape);
      }
    });
  }

  // Find All possible clamps positions
  findAllDeckClamps(isPortrait, designGroup, panelsArr: any, maxSpan, SPACING, PANEL_STROKEWITH) {
    const allClampsPosLand = [];
    const allClampsPosPort = [];
    for (const panel of panelsArr) {
      // get width of each panel based on it's orientation
      const width = this.getWidthByOrientation(isPortrait);
      // Find clmaps count needed based on Max Span
      const clampsCount = (maxSpan <= (width / 2)) ? this.findPossibleClamps(maxSpan, width) : 2;
      // Draw clamps based on the count and max span
      if (isPortrait) {
        allClampsPosPort.push(this.findClampsBySpan(panel.getAttrs(), clampsCount, maxSpan, width));
      } else {
        allClampsPosLand.push(this.findClampsBySpan(panel.getAttrs(), clampsCount, maxSpan, width));
      }
    }

    const allClampsArr = isPortrait ? allClampsPosPort.flat() : allClampsPosLand.flat();
    const midClamps = this.calculateMidClampsPositions(designGroup, allClampsArr, PANEL_STROKEWITH, SPACING);

    return {
      mid: midClamps.mid.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i),
      end: midClamps.end.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i)
    };
  }

  getWidthByOrientation(isPortrait: boolean) {
    return isPortrait ? this.projectService.getProject().width : this.projectService.getProject().length;
  }

  findPossibleClamps(maxSpan: any, panelLength: any) {
    let count = 2;
    if (maxSpan < (panelLength / 3)) {
      let covered = 3 * maxSpan;
      let remain = panelLength - covered;
      while (remain > maxSpan) {
        count++;
        covered += maxSpan;
        remain -= maxSpan;
      }
    }
    return count;
  }

  // find possible clamps according to Max Span with min clamps number
  findClampsBySpan(rect: any, count: any, maxSpan: any, width: any) {
    const clamps = [];
    const cornerSpan = (width - (maxSpan * (count - 1))) / 2;
    const ratioSpan = (count > 2) ? (maxSpan / width) : (1 / count);
    const ratioCorner = (count > 2) ? cornerSpan / width : (ratioSpan / 2);
    clamps[0] = { x: rect.x + (rect.width * ratioCorner), y: rect.y };
    for (let i = 1; i < (count * 2); i++) {
      if (i < count) { // panel top
        clamps[i] = { x: clamps[i - 1].x + (rect.width * ratioSpan), y: rect.y };
      } else { // panel bottom
        clamps[i] = { x: clamps[i - count].x, y: rect.y + rect.height };
      }
    }
    return clamps;
  }



  // Calaculate mid clamp positions from all deck clamps positions
  calculateMidClampsPositions(designGroup, allClampsPos: any, PANEL_STROKEWITH, SPACING) {
    let endClampsPos = [];
    let midClampsPos = [];
    const midClampsCenter = [];
    for (const pos of allClampsPos) {
      const posBox = {
        x: pos.x - (0.25 * this.CLAMP_RATIO), y: pos.y - (1.5 * this.CLAMP_RATIO),
        width: (0.5 * this.CLAMP_RATIO), height: (3 * this.CLAMP_RATIO)
      };
      const panelsInter = this.getPanelIntersection(designGroup, posBox, PANEL_STROKEWITH);
      if (panelsInter.length > 1) {
        const newY = this.findPanelsMidY(panelsInter, PANEL_STROKEWITH, SPACING);
        const newPos = { x: pos.x, y: newY };
        midClampsCenter.push(newPos);
        midClampsPos.push(pos);
      }
    }
    endClampsPos = allClampsPos.filter(({ x: x1, y: y1 }) =>
      !midClampsPos.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));
    midClampsPos = [];

    midClampsCenter.sort((a, b) => (a.y > b.y) ? 1 : (a.y === b.y) ? ((a.x > b.x) ? 1 : -1) : -1);
    for (let i = 0; i < midClampsCenter.length; i++) {
      for (let j = i + 1; j < midClampsCenter.length - 1; j++) {
        if (Math.abs(midClampsCenter[i].x - midClampsCenter[j].x) <= (2 * PANEL_STROKEWITH) &&
          midClampsCenter[i].y === midClampsCenter[j].y) {
          midClampsPos.push([midClampsCenter[i], midClampsCenter[j]]);
        }
      }
    }
    const allClampsPosFlat = [].concat.apply([], midClampsCenter);
    const midClampsPosFlat = [].concat.apply([], midClampsPos);

    // Get End clamps by getting the NOT overlap of midClamps & allClamps positions
    const midClampsPosLeft = allClampsPosFlat.filter(({ x: x1, y: y1 }) =>
      !midClampsPosFlat.some(({ x: x2, y: y2 }) => x1 === x2 && y1 === y2));

    const midClampsCenterFinal = this.findCenterPos(midClampsPos).concat(midClampsPosLeft);

    return {
      mid: midClampsCenterFinal,
      end: endClampsPos
    };
  }



  // Find Middle of the panels for intersected splices
  findPanelsMidY(panelsArr: any, PANEL_STROKEWITH, SPACING) {
    const setYs = new Set();
    let middleYs = 0;
    const compareY = 2 * PANEL_STROKEWITH + SPACING.y;
    for (const panel of panelsArr) { setYs.add(panel.y()); setYs.add(panel.y() + panel.height()); }
    let allYs = []; allYs = [...setYs]; allYs.sort();
    for (let i = 0; i < allYs.length - 1; i++) {
      if (allYs[i + 1] - allYs[i] < compareY) {
        middleYs = (allYs[i] + allYs[i + 1]) / 2;
      }
    }
    return middleYs;
  }

  // Find a center position for the midClamps to draw the related shape
  findCenterPos(posList: any) {
    const centerPos = [];
    posList.forEach((element: any) => {
      centerPos.push({
        x: (element[0].x + element[1].x) / 2,
        y: (element[0].y + element[1].y) / 2
      });
    });
    return centerPos;
  }

  // Add Extra clamping for rafter only clamping at 48"
  addExtraAttachmentsForRafterOnly(designGroup, isPortrait, MAX_SPANS, SPACING, PANEL_STROKEWITH, lastPanelWidth, clampGroup, designLayer, gridGroup) {
    let eastPanels = [];
    designGroup.children.each((panel: any) => {
      if (panel.x() > lastPanelWidth) eastPanels.push(panel);
    });

    // Check the cantilever on the right side of the east side panels to add extra rafter clamps first
    const minNot4Check1 = this.checkMin4PtAttachmentsForPanelsArray(eastPanels, clampGroup);

    if (minNot4Check1.length > 0) {
      const extraRafterClamps = this.addRafterClampsForRafterOnly(eastPanels, PANEL_STROKEWITH, gridGroup);
      this.drawClampsByType(extraRafterClamps, true, clampGroup, designLayer);

      // Check the cantilever on the right side of the east side panels to add extra deck clamps finally
      const minNot4Check2 = this.checkMin4PtAttachmentsForPanelsArray(eastPanels, clampGroup);

      if (minNot4Check2.length > 0) {
        const extraDeckClamps = this.addDeckClampsForRafterOnly(eastPanels, PANEL_STROKEWITH);
        this.drawClampsByType(extraDeckClamps, false, clampGroup, designLayer);
      }
    }
  }

  // Add rafter clamps for rafter clamping at 48", if there is any rafter that intersects with east side panels
  addRafterClampsForRafterOnly(panelsArr, PANEL_STROKEWITH, gridGroup) {
    const allClampsPos = [];
    panelsArr.forEach(shape => {
      allClampsPos.push(this.findRafterClampsByPanel(shape.getAttrs(), gridGroup, 0, PANEL_STROKEWITH));
    });
    const maxX = Math.max(...allClampsPos.flat().map(pos => pos.x));
    // Remove duplicates
    let allClampsPosFinal = allClampsPos.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i)
      .filter(pos => pos.x === maxX);  // get the rafetr clamps with highest X, i.e. most east side
    return this.calculateRafterMidClampsPositions(allClampsPosFinal, PANEL_STROKEWITH);
  }


  // Add deck clamps for rafter clamping at 48" to cover cantilever on the east side
  addDeckClampsForRafterOnly(panelsArr, PANEL_STROKEWITH) {
    const allClampsPos = [];
    const middleCantilever = 0.165; // hald of the cantilever of 0.33
    panelsArr.forEach((panel: any) => {
      allClampsPos.push({ x: (panel.x() + panel.width()) - (panel.width() * middleCantilever), y: panel.y() }); // Top Right corner
      allClampsPos.push({ x: (panel.x() + panel.width()) - (panel.width() * middleCantilever), y: panel.y() + panel.height() });  // Bottom Right corner
    });

    // Remove duplicates
    let allClampsPosFinal = allClampsPos.flat().filter((v, i, a) => a.findIndex(t => (t.x === v.x && t.y === v.y)) === i);
    return this.calculateRafterMidClampsPositions(allClampsPosFinal, PANEL_STROKEWITH);
  }


  checkMin4PtAttachmentsForPanelsArray(panelsArr, clampGroup) {
    const clampIntersects = [];
    const minNotMet = [];
    panelsArr.forEach((panel: any) => {
      const panelBox = panel.getClientRect();
      clampIntersects.push([panel, this.getClampIntersectionByShape(panelBox, false, clampGroup)]);
    });

    for (const panel of clampIntersects) {
      if (panel[1].length < 4) {
        minNotMet.push(panel[0]);
      } else {
        // Check the location of the attachments
        const left = {
          x: panel[0].x() + (panel[0].width() * 0.33),
          y: panel[0].y() + (panel[0].height() * 0.5)
        };
        const right = {
          x: panel[0].x() + (panel[0].width() * 0.66),
          y: panel[0].y() + (panel[0].height() * 0.5)
        };
        if (!this.checkPanelSideAttachments(left, right, panel[1])) {
          minNotMet.push(panel[0]);
        }
      }
    }
    return minNotMet;
  }

  // Check if there is min. 1 attachment for each panel's side
  checkPanelSideAttachments(left: any, right: any, clampArr: any) {
    const topLeft = []; const topRight = []; const bottomLeft = []; const bottomRight = [];
    for (const clamp of clampArr) {
      if (clamp.x < left.x && clamp.y < left.y) { topLeft.push(clamp); }
      if (clamp.x < left.x && clamp.y > left.y) { bottomLeft.push(clamp); }
      if (clamp.x > right.x && clamp.y < right.y) { topRight.push(clamp); }
      if (clamp.x > right.x && clamp.y > right.y) { bottomRight.push(clamp); }
    }
    return (topLeft.length > 0 && topRight.length > 0 && bottomLeft.length > 0 && bottomRight.length > 0) ? true : false;
  }


}
