import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Rood-Tech';
  activated = false;
  currentPage = '';

  currentroutepage(event: Event) {
    // console.log(event);
  }

  constructor(public router: Router) {

  }

  ngOnInit() {
  }
}
