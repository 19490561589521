import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { Route } from '@angular/compiler/src/core';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

  constructor(private auth: AuthService, private router: Router) { }

  canActivate() {
   return this.auth.user$.map(user => {
    if (user) { return true; }

    this.router.navigate(['/login']);
    return false;

   });

  }
}
