import { Injectable, Input } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { map, tap, finalize } from 'rxjs/operators';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { ProjectService } from 'src/app/service/project.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  isAdmin: boolean;
  downloadURL: string;

  user$: Observable<firebase.User>;
  userData: Observable<firebase.User>;
  error: any;
  forgoterr: any;
  currentUser: any;
  tempuserdata: any;
  adminbool = false;
  regis_status_bool;
  useruid = '';
  public issueDetails: any;
  userip = '';



  constructor(
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    public router: Router,
    private storage: AngularFireStorage,
    public projectService: ProjectService,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {
    this.user$ = afAuth.authState;
    this.userData = afAuth.authState;
    this.error = '';
    this.forgoterr = '';
    this.admincheck();
    this.getuserip();
  }


  async downloadFile(path: any) {
    return await this.storage.ref(path).getDownloadURL().toPromise();
  }


  async postissuedata(id, user, project, description, date, file, server) {
    const resolved = false;
    return await this.firestore.collection('issues').doc(id).set({
      id, user, project, description, date, file, resolved, server
    });
  }


  async updateIssuedata(id: any, check: any) {
    await this.firestore.collection('issues').doc(id).update({ resolved: check });
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  /* Sign up */
  SignUp(name: string, phone: number, state: string, country : string , email: string, password: string, company: string) {
    this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then(res => {

        this.saveuserdata(name, email, phone, company, state, country,  res.user.uid, res.user.metadata);
        /* Changing User Name */
        const user = firebase.auth().currentUser;
        user.updateProfile({
          displayName: name
        });
        this.router.navigate(['']);
        this.openSnackbar("Your account is sent for approval please visit again to check status", 3000);
        // console.log('You are Successfully signed up!', res);
      }).catch(error => {
        this.error = error;
        console.log(error.message);
        
      });
  }

  openSnackbar(msg: string, duration: number) {
    this.snackBar.open(msg, 'OK', {
      duration,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }


  /* Save User Data in Database */
  saveuserdata(name, email, phone, company, state, country , uid, metadata) {
    return this.firestore.collection('users').doc(uid).set({
      name,
      email,
      phone,
      company,
      state,
	  country,
      lastsignin: metadata.lastSignInTime,
      creationtime: metadata.creationTime,
      admin: false,
      regis_status: 0,
      uid,
      ip: this.userip
    });
  }



  /* Reset Code */
  async resetPassword(email: string) {
    try {
      await this.afAuth.auth.sendPasswordResetEmail(email);
      this.forgoterr = '';
    } catch (err) {
      this.forgoterr = err.message;
    }
  }



  /* Sign in */
  SignIn(email: string, password: string) {
    this.afAuth.auth.signInWithEmailAndPassword(email, password).then(res => {
      this.firestore.collection('users').doc(res.user.uid).update({ lastsignin: res.user.metadata.lastSignInTime });
      this.router.navigate(['']);
    });
  }

  /* Sign out */
  SignOut() {
    this.afAuth.auth.signOut();
  }


  login() {
    // this.afAuth.auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider());
    this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then((res) => {
      if (!res.additionalUserInfo.isNewUser) {
        this.firestore.collection('users').doc(res.user.uid).update({ lastsignin: res.user.metadata.lastSignInTime });
      }
    });
  }

  logout() {
    this.afAuth.auth.signOut();
  }

  getuserip() {
    this.http.get('https://ipv4.jsonip.com/')
      .subscribe((data: any) => {
        this.userip = data.ip;
        // console.log(this.userip);
      });
  }

  getuserdet() {
    return this.tempuserdata;
  }

  admincheck() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.tempuserdata = user;
        // User logged in already or has just logged in.
        this.getsingleuser(user.uid).subscribe((data: any) => {
          this.currentUser = data;
          this.regis_status_bool = data != undefined ? data.regis_status : this.regis_status_bool;
          if (data === undefined) {
            this.router.navigate(['postsignup']);
          } else if (this.currentUser && this.currentUser.admin) {
            this.adminbool = true;
            this.projectService.postprojnotif();
          } else {  
            this.adminbool = false;
            this.projectService.postprojnotif();
		  }
        });
      } else {
        this.adminbool = false;
        this.projectService.postprojnotif();
      }
    });
  }

	getauthfunc() {
	return firebase.auth();
	}


  isadmin() {
    if (this.adminbool) {
      return true;
    } else {
      return false;
    }
  }

  getregisstatus() {
    return this.regis_status_bool;
  }



  getsingleuser(id: any) {
    return this.firestore.collection('users').doc(id).valueChanges();
  }

  getUserById(id: any) {
    return this.firestore.collection('users', ref => {
      return ref.where('uid', '==', id);
    }).valueChanges();
  }

  deleteuser(id: any) {
    return this.firestore.collection('users').doc(id).delete();
  }

  getusers() {
    return this.firestore.collection('users').valueChanges();
  }

  getIssues() {
    return this.firestore.collection('issues').valueChanges();
  }

  createId() {
    return this.firestore.createId();
  }

  getFilepathByIssue(id: any) {
    return this.firestore.collection('issues-files').doc(id).valueChanges();
  }

  getusername() {
    return { "name": this.currentUser.name, "company": this.currentUser.company, "uid": this.currentUser.uid };
  }


}
