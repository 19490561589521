<div class="container">
  <div class="row">
    <div class="col-md-6">
      <a *ngIf="auth && auth.userData | async"></a>
    </div>

    <div *ngIf="!(auth.userData | async)">

      <div class="bottomleft">
        <div class="container"
          style="height:330px;width:900px; background-color: #ffffff; padding: 50px 90px; opacity: 0.9;">


          <div class="form-group">
            <label for="email">Email address:</label>
            <input type="text" class="form-control" [(ngModel)]="email" placeholder="email">
          </div>
          <div class="form-group mb-0">
            <label for="pwd">Password:</label>
            <input type="password" class="form-control" [(ngModel)]="password" id="pwd" placeholder="password">
            <label class="error">{{error}}</label>
          </div>

          <button routerLink="/signup" class=" btn-default">Sign Up</button>
          <button (click)="signIn()" class="btn-default" style="margin-left:5px;">Login</button> or

          <img src="../assets/images/google.png" (click)="login()" width="220px;" height="40px;" />
          <br />
          <a routerLink="/changepassword" class="">Forgot Password</a>

          <label class="error text-danger">{{auth.error}}</label>

        </div>

      </div>
    </div>
  </div>
</div>