import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { MapComponent } from 'src/app/design/map/map.component';
import { DrawComponent } from 'src/app/design/draw/draw.component';
import { DesignComponent } from 'src/app/design/design.component';
import { PartsComponent } from 'src/app/design/parts/parts.component';
import { EngineeringComponent } from 'src/app/design/engineering/engineering.component';



export const designRoutes: Routes = [

  // { path: '', component: DesignComponent },
  { path: 'projects', component: DesignComponent },
  { path: 'map', component: MapComponent },
  { path: 'design', component: DrawComponent },
  { path: 'parts', component: PartsComponent },
  { path: 'bom/parts', component: PartsComponent },
  { path: 'engineering', component: EngineeringComponent },

];

@NgModule({
  imports: [
    RouterModule.forChild(designRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class DesignRoutingModule { }
