<div class="bom-creator-container">
    <div class="left-container">
        <div class="form-container">
            <div class="form">
                <h3 class="center">Project Information</h3>
                <div>
                    <mat-form-field class="half">
                        <mat-select (selectionChange)="countrychg($event.value)" matNativeControl placeholder="Country"
                            [(value)]="country">
                            <mat-option value="us">United States</mat-option>
                            <mat-option value="canada">Canada</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-select matNativeControl (selectionChange)="onPanelOrientationChanges()"
                            [(ngModel)]="orientation" placeholder="ORIENTATION">
                            <mat-option [value]="false">Landscape</mat-option>
                            <mat-option [value]="true">Portrait</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div>
                    <mat-form-field class="half">
                        <input matInput type="text" placeholder="CLIENT NAME" [(ngModel)]="client">
                    </mat-form-field>

                    <mat-form-field class="half">
                        <input matInput type="text" placeholder="ADDRESS" [(ngModel)]="address">
                    </mat-form-field>

                </div>


                <!-- us zip code -->
                <div *ngIf="country == 'us' ">
                    <mat-form-field class="half">
                        <input matInput type="text" placeholder="ZIP CODE" maxlength="5" [(ngModel)]="zip"
                            pattern="[0-9]*">
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-select matNativeControl placeholder="STATE *" [(value)]="state">
                            <!-- <mat-option value="AL">Alabama</mat-option> -->
                            <!-- <mat-option value="AK">Alaska</mat-option> -->
                            <mat-option value="AR">Arkansas</mat-option>
                            <mat-option value="AZ">Arizona</mat-option>
                            <mat-option value="CA">California</mat-option>
                            <mat-option value="CO">Colorado</mat-option>
                            <mat-option value="CT">Connecticut</mat-option>
                            <mat-option value="DE">Delaware</mat-option>
                            <mat-option value="DC">District of Columbia</mat-option>
                            <mat-option value="FL">Florida</mat-option>
                            <mat-option value="GA">Georgia</mat-option>
                            <mat-option value="HI">Hawaii</mat-option>
                            <mat-option value="ID">Idaho</mat-option>
                            <mat-option value="IL">Illinois</mat-option>
                            <mat-option value="IN">Indiana</mat-option>
                            <mat-option value="IA">Iowa</mat-option>
                            <mat-option value="KS">Kansas</mat-option>
                            <!-- <mat-option value="KY">Kentucky</mat-option> -->
                            <mat-option value="LA">Louisiana</mat-option>
                            <mat-option value="ME">Maine</mat-option>
                            <mat-option value="MD">Maryland</mat-option>
                            <mat-option value="MA">Massachusetts</mat-option>
                            <mat-option value="MI">Michigan</mat-option>
                            <mat-option value="MN">Minnesota</mat-option>
                            <!-- <mat-option value="MS">Mississippi</mat-option> -->
                            <mat-option value="MO">Missouri</mat-option>
                            <!-- <mat-option value="MT">Montana</mat-option> -->
                            <!-- <mat-option value="NE">Nebraska</mat-option> -->
                            <mat-option value="NV">Nevada</mat-option>
                            <mat-option value="NH">New Hampshire</mat-option>
                            <mat-option value="NJ">New Jersey</mat-option>
                            <mat-option value="NM">New Mexico</mat-option>
                            <mat-option value="NY">New York</mat-option>
                            <mat-option value="NC">North Carolina</mat-option>
                            <!-- <mat-option value="ND">North Dakota</mat-option> -->
                            <mat-option value="OH">Ohio</mat-option>
                            <!-- <mat-option value="OK">Oklahoma</mat-option> -->
                            <mat-option value="OR">Oregon</mat-option>
                            <mat-option value="PA">Pennsylvania</mat-option>
                            <mat-option value="RI">Rhode Island</mat-option>
                            <mat-option value="SC">South Carolina</mat-option>
                            <!-- <mat-option value="SD">South Dakota</mat-option> -->
                            <mat-option value="TN">Tennessee</mat-option>
                            <mat-option value="TX">Texas</mat-option>
                            <mat-option value="UT">Utah</mat-option>
                            <!-- <mat-option value="VT">Vermont</mat-option> -->
                            <mat-option value="VA">Virginia</mat-option>
                            <mat-option value="WA">Washington</mat-option>
                            <!-- <mat-option value="WV">West Virginia</mat-option> -->
                            <mat-option value="WI">Wisconsin</mat-option>
                            <!-- <mat-option value="WY">Wyoming</mat-option> -->
                        </mat-select>
                    </mat-form-field>
                </div>


                <!-- Canada postal code -->
                <div *ngIf="country == 'canada' ">
                    <mat-form-field class="half">
                        <input matInput type="text" placeholder="POSTAL CODE" maxlength="7" [(ngModel)]="zip"
                            pattern="/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY][ -]?\d[ABCEGHJKLMNPRSTVXY]\d$/i">
                    </mat-form-field>

                    <mat-form-field class="half">
                        <mat-select matNativeControl placeholder="Province *" [(value)]="state">
                            <mat-option value="AB">Alberta</mat-option>
                            <mat-option value="BC">British Colombia</mat-option>
                            <mat-option value="NS">Nova Scotia</mat-option>
                            <mat-option value="ON">Ontario</mat-option>
                            <mat-option value="SK">Saskatchewan</mat-option>
                            <mat-option value="NB">New Brunswick</mat-option>
                            <mat-option value="PEI">Prince Edward Island</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field [ngClass]="country == 'us' ? 'half' : 'full'">
                        <mat-select (selectionChange)="changeLengthByPV($event.value); onPanelCountChanges();"  matNativeControl
                            placeholder="PV MODULE" [(value)]="pv">
                            <mat-option value="60">60 cells (Dims. up to 40 x 68 inches)</mat-option>
                            <mat-option value="72">72 cells (Dims. up to 40 x 77.5 inches)</mat-option>
                            <mat-option value="90">Others</mat-option>
                        </mat-select>
                    </mat-form-field>


                    <!--[(value)]="orientation"

-->

                    <mat-form-field class="half" *ngIf="country == 'us' ">
                        <mat-select matNativeControl placeholder="ROOF TYPE" [(value)]="type">
                            <mat-option value="gable">Gable</mat-option>
                            <mat-option value="hip">Hip</mat-option>
                        </mat-select>
                    </mat-form-field>


                </div>


                <div>
                    <div *ngIf="pv == 90; then limit90 else typical"></div>
                    <ng-template #limit90>
                        <mat-form-field class="half">
                            <input matInput type="number" placeholder="WIDTH" min="40" max="50" step="0.125"
                              (change)="onPanelCountChanges()"  [(ngModel)]="panel_WIDTH">
                            <span matSuffix>Inch</span>
                        </mat-form-field>
                    </ng-template>
                    <ng-template #typical>
                        <mat-form-field class="half">
                            <input matInput type="number" placeholder="WIDTH" min="0" max="40" step="0.125" value="40"
                                (change)="onPanelCountChanges()" [(ngModel)]="panel_WIDTH">
                            <span matSuffix>Inch</span>
                        </mat-form-field>
                    </ng-template>


                    <div *ngIf="pv == 72; then limit72 else limit60"></div>
                    <ng-template #limit60>
                        <mat-form-field class="half">
                            <input matInput type="number" placeholder="LENGTH" min="0" max="68" step="0.125"
                              (change)="onPanelCountChanges()" [(ngModel)]="panel_LENGTH">
                            <span matSuffix>Inch</span>
                        </mat-form-field>
                    </ng-template>
                    <ng-template #limit72>
                        <mat-form-field class="half">
                            <input matInput type="number" placeholder="LENGTH" min="0" max="77.5" step="0.125"
                              (change)="onPanelCountChanges()" [(ngModel)]="panel_LENGTH">
                            <span matSuffix>Inch</span>
                        </mat-form-field>
                    </ng-template>
                </div>


                <div *ngIf="country == 'us' ">
                    <div>
                        <mat-form-field class="half">
                            <mat-select matNativeControl placeholder="BUILDING CODE" [(value)]="asce">
                                <mat-option value="10">ASCE 7-10</mat-option>
                                <mat-option value="16">ASCE 7-16</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="half">
                            <mat-select matNativeControl placeholder="SNOW LOAD" [(value)]="snow">
                                <mat-option value="0">0</mat-option>
                                <mat-option value="10">1-10</mat-option>
                                <mat-option value="20">11-20</mat-option>
                                <mat-option value="30">21-30</mat-option>
                                <mat-option value="40">31-40</mat-option>
                                <mat-option value="50">41-50</mat-option>
                                <mat-option value="60">51-60</mat-option>
                                <mat-option value="70">61-70</mat-option>
                            </mat-select>
                            <span matSuffix>PSF</span>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="half">
                            <mat-select matNativeControl placeholder="WIND EXPOSURE" [(value)]="exposure">
                                <mat-option value="B">B</mat-option>
                                <mat-option value="C">C</mat-option>
                                <mat-option value="D">D</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="half">
                            <mat-select matNativeControl placeholder="WIND SPEED" [(value)]="speed">
                                <mat-option *ngIf="asce == 16" value="95">95</mat-option>
                                <mat-option *ngIf="asce == 16" value="100">100</mat-option>
                                <mat-option *ngIf="asce == 16" value="105">105</mat-option>
                                <mat-option value="110">110</mat-option>
                                <mat-option value="115">115</mat-option>
                                <mat-option value="120">120</mat-option>
                                <mat-option value="130">130</mat-option>
                                <mat-option value="140">140</mat-option>
                                <mat-option value="150">150</mat-option>
                                <mat-option value="160">160</mat-option>
                                <mat-option value="170">170</mat-option>
                                <mat-option value="180">180</mat-option>
                            </mat-select>
                            <span matSuffix>MPH</span>
                        </mat-form-field>
                    </div>
                </div>


                <div *ngIf="country == 'canada' ">
                    <div>
                        <mat-form-field class="half">
                            <mat-select matNativeControl placeholder="SNOW & RAIN LOAD" [(value)]="snow">
                                <mat-option value="0.5">0.5</mat-option>
                                <mat-option value="1">1.0</mat-option>
                                <mat-option value="1.5">1.5</mat-option>
                                <mat-option value="2">2.0</mat-option>
                                <mat-option value="2.5">2.5</mat-option>
                                <mat-option value="3">3.0</mat-option>
                                <mat-option value="3.5">3.5</mat-option>
                                <mat-option value="4">4.0</mat-option>
                                <mat-option value="4.5">4.5</mat-option>
                                <mat-option value="5">5.0</mat-option>
                                <mat-option value="5.5">5.5</mat-option>
                                <mat-option value="6">6.0</mat-option>
                                <mat-option value="6.5">6.5</mat-option>
                                <mat-option value="7">7.0</mat-option>
                                <mat-option value="7.5">7.5</mat-option>
                                <mat-option value="8">8.0</mat-option>
                                <mat-option value="8.5">8.5</mat-option>
                                <mat-option value="9">9.0</mat-option>
                                <mat-option value="9.5">9.5</mat-option>
                                <mat-option value="10">10</mat-option>
                            </mat-select>
                            <span matSuffix>KPa</span>
                        </mat-form-field>

                        <mat-form-field class="half">
                            <mat-select matNativeControl placeholder="TERRAIN *" [(value)]="terrain">
                                <mat-option value="OPEN">Open</mat-option>
                                <mat-option value="ROUGH">Rough</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div>
                        <mat-form-field class="full">
                            <mat-select id="baisc-wind" matNativeControl placeholder="BASIC WIND PRESSURE"
                                [(value)]="speed">
                                <mat-option value="0.20">0.20</mat-option>
                                <mat-option value="0.30">0.30</mat-option>
                                <mat-option value="0.40">0.40</mat-option>
                                <mat-option value="0.50">0.50</mat-option>
                                <mat-option value="0.60">0.60</mat-option>
                                <mat-option value="0.70">0.70</mat-option>
                                <mat-option value="0.80">0.80</mat-option>
                                <mat-option value="0.90">0.90</mat-option>
                                <mat-option value="1.00">1.00</mat-option>
                                <mat-option value="1.10">1.10</mat-option>
                            </mat-select>
                            <span matSuffix>KPa</span>
                        </mat-form-field>
                    </div>
                </div>


                <div>
                    <mat-form-field class="half">
                        <mat-select matNativeControl placeholder="ROOF ATTACHMENT *" [(value)]="rt"
                            (selectionChange)="rtchange($event.value); removeattachments();">
                            <ng-container *ngIf="country == 'us'">
                                <mat-option value="plywood">Plywood 15/32"</mat-option>
                                <mat-option value="osb">OSB 7/16"</mat-option>
                                <mat-option value="rafter">Rafter Only</mat-option>
                            </ng-container>

                            <ng-container *ngIf="country == 'canada'">
                                <mat-option value="plywood">Plywood with (5)-60 mm screws</mat-option>
                                <mat-option value="osb">OSB with (5)-60 mm screws</mat-option>
                             
								<mat-option value="rafter6">Rafter centered with (2)-60 mm screws
								</mat-option>
								<mat-option value="rafter9">Rafter centered with (2)-90 mm screws
								</mat-option>
							 
							</ng-container>

                        </mat-select>
                    </mat-form-field>

                    <mat-form-field #showPitch class="half">
                        <mat-select matNativeControl placeholder="ROOF PITCH" [(value)]="pitch">
                            <ng-container *ngIf="country == 'us'">
                                <mat-option value="0.0">0</mat-option>
                                <mat-option value="4.8">1/12 = 4.8&#730;</mat-option>
                                <mat-option value="9.5">2/12 = 9.5&#730;</mat-option>
                                <mat-option value="14.0">3/12 = 14.0&#730;</mat-option>
                                <mat-option value="18.4">4/12 = 18.4&#730;</mat-option>
                                <mat-option value="22.6">5/12 = 22.6&#730;</mat-option>
                                <mat-option value="26.6">6/12 = 26.6&#730;</mat-option>
                                <mat-option value="30.3">7/12 = 30.3&#730;</mat-option>
                                <mat-option value="33.7">8/12 = 33.7&#730;</mat-option>
                                <mat-option value="36.9">9/12 = 36.9&#730;</mat-option>
                                <mat-option value="39.8">10/12 = 39.8&#730;</mat-option>
                                <mat-option value="42.5">11/12 = 42.5&#730;</mat-option>
                                <mat-option value="45.0">12/12 = 45.0&#730;</mat-option>
                            </ng-container>
                            <ng-container *ngIf="country == 'canada'">
                                <mat-option value="0">0-6&#730;</mat-option>
                                <mat-option value="7">7-27&#730;</mat-option>
                                <mat-option value="28">28-45&#730;</mat-option>
                            </ng-container>

                        </mat-select>
                    </mat-form-field>
                </div>


                <div>
                    <mat-form-field class="half">
                        <input matInput type="number" placeholder="ROW" min="1" max="{{maxRow}}" [(ngModel)]="row"
                            (change)="onPanelCountChanges()">
                    </mat-form-field>


                    <mat-form-field class="half">
                        <input matInput type="number" placeholder="COLUMN" min="1" max="{{maxColumn}}"
                            [(ngModel)]="column" (change)="onPanelCountChanges()">
                    </mat-form-field>
                </div>


                <div>
                    <mat-form-field class="half">
                        <mat-select matNativeControl (selectionChange)="removeattachments();" placeholder="PREFER USING:" [(value)]="splice">
                            <mat-option value="0">No Splice</mat-option>
                            <mat-option value="1">Splice</mat-option>
                            <ng-container *ngIf="rt.includes('rafter') ">
                                <mat-option value="2">Floating Splice</mat-option>
                            </ng-container>

                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="rt == 'rafter'" class="half">
                        <mat-select matNativeControl placeholder="RAFTER SPACING" [(ngModel)]="rafter"
                            (selectionChange)="rafterspacechg()">
                            <mat-option value="16">16</mat-option>
                            <mat-option value="24">24</mat-option>
                        </mat-select>
                        <span matSuffix>Inch</span>
                    </mat-form-field>


                    <mat-checkbox *ngIf="country == 'us'" class="half" [(ngModel)]="asterisk" (change)="screwchg()">SDST
                        Screws
                    </mat-checkbox>

                    <mat-checkbox *ngIf="country == 'canada'" class="half" [(ngModel)]="clip" (change)="clipchg()">Snow
                        Clip
                    </mat-checkbox>
                </div>

                <div>
                    <p class="heght-par">* Max. building mean roof height is 30 ft</p>
                    <p class="heght-par no-marg-top">* Rafter only projects are set to 48" of rafter clamping</p>
                </div>

                <button mat-raised-button class="save" [disabled]="checkdisable()" (click)="saveChanges()"
                    color="primary">CALCULATE SPAN</button>

                <!-- Max span div -->
                <!--showing for zone 1 now   -->
                <div *ngIf="country == 'us' && first_click">
                    <h4 *ngIf="orientation == false ">
                        Max Span <span *ngIf="!asterisk"> without SDST </span>
                        <span *ngIf="asterisk"> with SDST </span> : {{ MAX_SPANS }} Inch
                    </h4>
                    <h4 *ngIf="orientation">
                        Max Span <span *ngIf="!asterisk"> without SDST </span>
                        <span *ngIf="asterisk"> with SDST </span> : {{ MAX_SPANS }} Inch
                    </h4>
                </div>
                <div *ngIf="country == 'canada' && first_click">
                    <h4 *ngIf="orientation == false ">
                        Max Span <span *ngIf="clip"> With Snow Clip </span>
                        <span *ngIf="!clip"> Without Snow Clip </span>
                        : {{ (MAX_SPANS / 39.37).toFixed(2) }} Meter
                    </h4>
                    <h4 *ngIf="orientation">
                        <span *ngIf="clip"> With Snow Clip </span>
                        <span *ngIf="!clip"> Without Snow Clip </span>
                        Max Span : {{ (MAX_SPANS / 39.37).toFixed(2) }} Meter
                    </h4>
                </div>
                <!-- Max span div Ends -->

            </div>
        </div>

        <div class="preview-container">
            <div id="drawing"></div>
        </div>
    </div>

    <div class="right-container">
        <div class="parts-container">
            <app-parts [direct_bool]="true"></app-parts>
        </div>
    </div>
</div>

<div class="footer">
    <app-footer></app-footer>
</div>