import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';
import * as fileSaver from 'file-saver';


@Component({
  selector: 'app-description-issues',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.css']
})



export class DescriptionComponent implements OnInit {

  openIssue: any;
  filePath: any;

  constructor(public auth: AuthService, public router: Router) { }

  ngOnInit() {
    this.initialize();
  }


  initialize() {
    this.openIssue = this.auth.issueDetails;
    this.openIssue.filePath = this.openIssue.id + '/' + this.openIssue.file;
  }

  downloadFile(filePath: any, download: boolean) {
    this.auth.downloadFile(filePath).then(url => {
      if (download) {
        const blob = new Blob([url], { type: 'text/json; charset=utf-8' });
        fileSaver.saveAs(blob, filePath.split('/')[1]);
      } else {
        window.open(url, 'blank');
      }
      // console.log(url);
    },
      err => console.log(err));
  }

}
