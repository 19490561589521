import { Component, OnInit, Input } from '@angular/core';
import { ProjectService } from 'src/app/service/project.service';
import { MapService } from '@src/app/service/map.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.css']
})
export class GuideComponent implements OnInit {

  @Input() nextstep: string;
  @Input() title: string;
  @Input() description: string;
  stepsubs: Subscription;
  step = 'step0';

  constructor(public projectService: ProjectService, public mapservice: MapService) { }

  ngOnInit() {
    this.stepsubs = this.mapservice.currentMessage.subscribe(step => {
      this.step = step;
    });
  }

  onNext() {
    this.mapservice.startnext(this.nextstep.toString());
  }
  closeintro() {
    this.step = 'finished';
    this.mapservice.laststep = 'step1';
    this.mapservice.startnext('finished');
  }

}
