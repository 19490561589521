<div class="form-container">

    <h5 class="center" id="roof-form-title">Building and Roof Information</h5>

    <div>
        <mat-form-field class="half">
            <mat-select  (selectionChange)="saveChanges()" matNativeControl placeholder="ROOF MATERIAL" [(value)]="material">
                <mat-option value="Asphalt">Asphalt</mat-option>
                <mat-option value="Metal">Metal</mat-option>
                <mat-option value="Membrane">Membrane</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-select (selectionChange)="saveChanges()" matNativeControl placeholder="RAFTER SPACING" [(value)]="rafter">
                <mat-option value="16">16</mat-option>
                <mat-option value="24">24</mat-option>
            </mat-select>
            <!-- <input matInput type="number" placeholder="RAFTER SPACING" min="12" max="24" [(ngModel)]="rafter"> -->
            <span matSuffix>Inch</span>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field [ngClass]="{'half' : country_bool == 'us' , 'full' : country_bool == 'canada' }">
            <mat-select (selectionChange)="saveChanges()" matNativeControl placeholder="ROOF ATTACHMENT" [(value)]="rt">

                <!-- <mat-option value="1x6plank">1x6 Plank</mat-option> -->
                <ng-container *ngIf="country_bool == 'us' ">
                    <mat-option value="plywood">Plywood 15/32"</mat-option>
                    <mat-option value="osb">OSB 7/16"</mat-option>
                    <mat-option value="rafter">Rafter Only</mat-option>
                    <mat-option value="rafterplywood">Rafter-Plywood-Rafter</mat-option>
                    <mat-option value="rafterosb">Rafter-OSB-Rafter</mat-option>
                </ng-container>
                <ng-container *ngIf="country_bool == 'canada' ">
                    <mat-option value="plywood">Plywood with (5)-60 mm screws</mat-option>
                    <mat-option value="osb">OSB with (5)-60 mm screws</mat-option>
                    <!-- <mat-option value="plank">Plank with (4)-60 mm screws</mat-option> -->
                    <mat-option value="rafter6">Rafter centered with (2)-60 mm screws
                    </mat-option>
                    <mat-option value="rafter9">Rafter centered with (2)-90 mm screws
                    </mat-option>
                </ng-container>

            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="country_bool == 'us' " [ngClass]="{'half' : country_bool == 'us' }">
            <mat-select (selectionChange)="saveChanges()" matNativeControl placeholder="ROOF TYPE" [(value)]="type">
                <mat-option value="gable">Gable</mat-option>
                <mat-option value="hip">Hip</mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <p class="footnote">* Max. building mean roof height is 30 ft.</p>

    <button mat-raised-button class="save" (click)="saveChanges(); closemodel(); postfromhead();">Save</button>

</div>

<div class="step-container" *ngIf="(mapservice.currentMessage | async) != 'finished'">
    <span class="close-butt" (click)="closeintro()"><i class="fas fa-times"></i></span>
    <h3 class="mt-0">Step 3</h3>
    <p class="intro-text">Fill Up Roof Info</p>
    <button mat-raised-button class="next-butt" (click)="onNext()">Next</button>
    <button mat-raised-button class="mr-1 next-butt" (click)="onPrev()">Prev</button>
    <p class="intro-text"><b>3/5</b></p>
</div>
