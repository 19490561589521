<div class="bottomright">
  <div class="container" style="width:1200px; background-color: #ffffff; padding: 50px 0px; opacity: 0.9;">
    <div class="row">

    </div>
    <div class="row text-center">
      <div class="col-xs-12 col-sm-12 col-md-12 ">


        <h3 class="text-center">Registered User Accounts</h3>
        <div class="row searchbox">
          <p>FILTER</p>
          <input type="text" placeholder="Search.." [(ngModel)]="searchTerm"
            (ngModelChange)="setFilteredItems(searchTerm)">
        </div>
        <table class="table table-bordered">
          <thead class="thead-dark">
            <tr>
              <th scope="col" class="text-center">#</th>
              <th scope="col" class="text-center">Last SignIn</th>
              <th class="text-center" scope="col">Name <i
                  [class]="sortname ? 'fas fa-sort-amount-down' : 'fas fa-sort-amount-up'" (click)="sort()"></i></th>
              <th class="text-center" scope="col">Email <i
                  [class]="sortemail ? 'fas fa-sort-amount-down' : 'fas fa-sort-amount-up'"
                  (click)="sortemailfunc()"></i></th>
              <th class="text-center" scope="col">State</th>
              <th class="text-center" scope="col">Company</th>
              <th class="text-center" scope="col">Phone</th>
              <th class="text-center" scope="col">Registration Status</th>
              <th class="text-center" scope="col">IP Address</th>
              <th class="text-center" scope="col">Admin Status</th>
              <th class="text-center" scope="col">Sales Admin Status</th>

            </tr>
          </thead>

          <tbody *ngFor="let reguser of users | slice: lowValue : highValue;let i=index;">
            <tr>
              <td>{{i + 1 + lowValue }}</td>
              <td>{{reguser.lastsignin | date: 'dd-MM-yyyy h:mm:ss'}}</td>
              <td class="namecol" (click)="gotoproj(reguser)">{{reguser.name}}</td>
              <td>{{reguser.email}}</td>
              <td>{{reguser.state}}</td>
              <td>{{reguser.company}}</td>
              <td>{{reguser.phone}}</td>
              <td>
                <mat-form-field class="regis-select">
                  <mat-label>Registration Status</mat-label>
                  <mat-select [(ngModel)]="reguser.regis_status"
                    (selectionChange)="userpropchange('regis_status' , $event.value,reguser.uid, 'registration')">
                    <mat-option [value]="0">
                      In Process
                    </mat-option>
                    <mat-option [value]="1">
                      Registered successfully
                    </mat-option>
                    <mat-option [value]="2">
                      Denied
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                {{reguser.ip}}
              </td>

              <td>
                <mat-form-field class="admin-select">
                  <mat-label>Admin Status</mat-label>
                  <mat-select [(ngModel)]="reguser.admin"
                    (selectionChange)="userpropchange('admin',$event.value,reguser.uid, 'admin')">
                    <mat-option [value]="true">
                      YES
                    </mat-option>
                    <mat-option [value]="false">
                      NO
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>

              <td>
                <mat-form-field class="admin-select">
                  <mat-label>Sales Admin</mat-label>
                  <mat-select [(ngModel)]="reguser.sales_admin"
                    (selectionChange)="userpropchange('sales_admin',$event.value,reguser.uid, 'sales admin')">
                    <mat-option [value]="true">
                      YES
                    </mat-option>
                    <mat-option [value]="false">
                      NO
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>


            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div *ngIf="users">
      <mat-paginator [length]="users.length" pageSize=10 [pageSizeOptions]="[10, 20, 50, 100]"
        (page)="getPaginatorData($event)">
      </mat-paginator>
    </div>


  </div>
</div>

<div>
  <button mat-button class="issue-list" (click)="router.navigate(['/devissues'])">Reported Issues</button>
  <button mat-button class="issue-list left-butt" (click)="downloadbom()">Download BOM</button>
</div>

<div class="row footer">

  <app-footer></app-footer>
</div>