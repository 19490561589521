<div class="content">
    <div class="forms">
        <div class="icons">
            <a id="module_butt"  (click)="toggleOverlay(0)">
                <mat-icon>view_module</mat-icon>
            </a>
        </div>
        <div class="icons">
            <a id="location_butt" (click)="toggleOverlay(1)">
                <mat-icon>edit_location</mat-icon>
            </a>
        </div>
        <div class="icons">
            <a id="roof_inf_butt" (click)="toggleOverlay(2)">
                <mat-icon>location_city</mat-icon>
            </a>
        </div>
        <div class="icon bottom">
            <a (click)="showHelp()" title="How to?">
                <mat-icon>help_outline</mat-icon>
            </a>
        </div>
        <!-- <div class="icon bottom">
            <a (click)="getHelioProj()" title="How to?">
                <mat-icon>power_settings_new</mat-icon>
            </a>
        </div> -->
    </div>


    <div #map id="map" class="google-map" leaflet [leafletOptions]="mapOptions" (leafletMapReady)="onMapReady($event)"
        leafletDraw (leafletDrawCreated)="onPolygonCreated($event)" [leafletDrawOptions]="drawOptions">
    </div>

    <div *ngIf="workspace==1" class="mapDraw">
        <button mat-button #draw class="tools" title="Draw A Polygon" (click)="drawPolygon()">
            <mat-icon>star</mat-icon>
        </button>
        <button mat-button #edit class="tools" title="Edit drawn Polygon" (click)="editPolygon()">
            <mat-icon>edit_road</mat-icon>
        </button>
        <button mat-button #delete class="tools" title="Delete Polygon" (click)="deletepolygon()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>

    <div *ngIf="workspace==1">
        <input ngx-google-places-autocomplete #placesRef="ngx-places" class="search"
            (onAddressChange)="handleAddressChange($event)" />
        <mat-icon class="search-icon">search</mat-icon>
    </div>

    <div>
        <img src="../../../../assets/images/compass96.png" alt="Compass" class="compass">
    </div>

    <div class="upd-addarea">
    <button mat-button class="tools" title="Add A New Area" (click)="addarea()" [disabled]="addareabool">
      <mat-icon>library_add</mat-icon>
  </button>
</div>

<!---
    <div class="addarea" joyrideStep="fourthStep" title="Step 4" text="Add roofarea">
    </div>
  -->

    <div class="guide">
        <button mat-button (click)="startintroguide()">
            Start Guide
        </button>
    </div>

    <div #zoom class="zoom">
        <button mat-button class="tools" title="Zoom In" (click)="mapZoomIn()">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-button class="tools" title="Zoom Out" (click)="mapZoomOut()">
            <mat-icon>remove</mat-icon>
        </button>
    </div>
</div>


<div class="step-container" *ngIf="step == 'step4'">
    <span class="close-butt" (click)="closeintro('step4')"><i class="fas fa-times"></i></span>
    <h3 class="mt-0">Step 4</h3>
    <p class="intro-text">Add Multiple Roof Icon (Upto 6)</p>
    <button mat-raised-button class="next-butt" (click)="onNext('step5')">Next</button>
    <button mat-raised-button class="mr-1 next-butt" (click)="onPrev('step3')">Prev</button>
    <p class="intro-text"><b>4/5</b></p>
</div>

<div class="step-container fifth" *ngIf="step == 'step5'">
    <span class="close-butt" (click)="closeintro('step5')"><i class="fas fa-times"></i></span>
    <h3 class="mt-0">Step 5</h3>
    <p class="intro-text">Start Designing Your Project</p>
    <button mat-raised-button class="next-butt" (click)="gotoDesign(); onNext('step6');">Next</button>
    <button mat-raised-button class="mr-1 next-butt" (click)="onPrev('step4')">Prev</button>
    <p class="intro-text"><b>5/5</b></p>
</div>



<div *ngIf="loading" class="transition">
    <app-transition></app-transition>
</div>
<div class="row footer">

    <a mat-raised-button (click)="gotoDesign()">Next &rarr;</a>
<app-footer></app-footer>
</div>
<!--
<div class="row footer">


    <a mat-raised-button (click)="gotoDesign()">Next &rarr;</a>
    <button class="report" (click)="openpopup()">Report Issues</button>
    <p class="copyright">&copy; 2022 Roof-Tech.us. Designed by Polygons Media</p>
</div>
-->
