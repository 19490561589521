import { ImageComponent } from './parts/image.component';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@src/app/service/auth.service';
import { MapService } from '@src/app/service/map.service';
import { ProjectService } from 'src/app/service/project.service';
import { Router } from '@angular/router';

/* */
@Component({
  selector: 'app-workspace',
  template: `
  <div class="workspace">
    <mat-dialog-actions align="center">

  
        <button class="workspace-butt" [ngClass]="{'selected-butt' : sel_type == 'map' }"  mat-raised-button [disabled]="!adminUser" (click)="selectedWorkspace(1); sel_type = 'map';">
            <mat-icon>map</mat-icon> Google Map
        </button>

        <button class="workspace-butt" mat-raised-button [ngClass]="{'selected-butt' : sel_type == 'blank' }"
        (click)="selectedWorkspace(2); sel_type = 'blank';">
            <mat-icon>file_copy</mat-icon> Blank Page
        </button>

		
					
        <button class="workspace-butt" [ngClass]="{'selected-butt' : sel_count == 'canada' }" mat-raised-button (click)="countrychange('canada')">
          <img class="select-icon" src="../assets/images/icon-canada-flag.png" />
          <!--  <mat-icon>file_copy</mat-icon>--> Canada
        </button>


        <button class="workspace-butt" (click)="countrychange('us')" mat-raised-button [ngClass]="{'selected-butt' : sel_count == 'us' }">
          <img class="select-icon" src="../assets/images/icon-usa-flag.png"/>
          <!--  <mat-icon>file_copy</mat-icon>--> United States
        </button>
			
			

        <!-- [mat-dialog-close]="true" -->


		  <button mat-raised-button [disabled]="selectbool || sel_type == '' " class="start_butt"
        (click)="navigatetoproj()">
             Go
        </button>




   </mat-dialog-actions>
</div>`,
  styles: [`
.workspace {
  display: flex;
  max-width: 600px;
  margin: 0;
  padding: 2em;
}
.workspace-butt {
    width: 220px;
    height: 100px;
}

.selected-butt {
   background-color: aliceblue !important;
}


.workspace button {
    padding: 1em;
    font-size: 24px;
    margin: 0.5em;
    text-align: center;

}
.start_butt
{
  font-size: 16px !important;
  padding : 0.7em !important;
}
img.select-icon {
    height: 30px;
    width: 30px;
    margin-top : -5px;
}

`]
})


export class WorkspaceComponent implements OnInit {

  adminUser: boolean;
  selectbool = false;
  sel_count = "us";
  sel_type = "blank";
  sales_admin = false;
  constructor(public auth: AuthService, private mapService: MapService, public projectservice: ProjectService,
  public router : Router) { }

  ngOnInit() {
    this.adminUser = (this.auth.currentUser) ? this.auth.currentUser.admin : false;
	this.sales_admin = this.auth.currentUser.sales_admin == undefined ? false : this.auth.currentUser.sales_admin;
	
	this.projectservice.setProjectid();
    // phase 2
    this.projectservice.navigatecount = 0;
    this.projectservice.selectedid = '';
    this.projectservice.issaved = true;
    this.projectservice.postedid = [];
  }

  selectedWorkspace(stage: any) {
    this.mapService.refreshmap();
    this.mapService.setMap('workspace', stage);
  }

	countrychange(value) {
	this.projectservice.setProject('country', value);
  this.sel_count = value;
	this.sel_count == 'us' ? '' : this.projectservice.setProject('mrh', 'apexC');
  this.selectbool = false;
	}

	navigatetoproj() {
	this.router.navigate(['/map']);
	}



}
