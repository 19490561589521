import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from 'src/app/service/auth.service';
import { tap, map, take } from 'rxjs/operators';

@Injectable()
export class AdminGuardService implements CanActivate {

    constructor(private auth: AuthService) { }

    // tslint:disable-next-line: variable-name
    canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.auth.adminbool) {
            return true;
        } else {
            return false;
        }
    }

}
