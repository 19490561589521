import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { Users } from 'src/models/users.model';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { headercomp } from 'src/models/headercomp.model';
import { LoginUserResponse } from 'src/models/loginUserResponse';
import { ChangePasswordRequest } from 'src/models/ChangePasswordRequest';
import { DisableAccountRequest } from 'src/models/DisableAccountRequest.model';


@Directive()
@Injectable()
// tslint:disable-next-line: directive-class-suffix
export class UsersOperation {
    loginUserResponse: LoginUserResponse = {
        isValidUser: false,
        isAdminUser: false
    };

    @Output() headercomprequest = new EventEmitter<headercomp>();
    @Output() AdminEvent = new EventEmitter<boolean>();

    HeaderRequest: headercomp = {
        emailid: '',
        currentpage: '',
        navigatedtpage: ''
    };


    isSuccessStatusCode = false;
    statusCode: number;
    isValidUser = false;
    isAdminUser: boolean;
    fromUrl: string;
    isValid = false;
    regStatus = '';
    updatePasswordMessage = '';
    users: Users[] = [];
    updateStatus = '';
    RegisteredUsers = new EventEmitter<Users[]>();

    // RegistrationData = new Subject<Users>();

    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {

    }

    registerUserData(usersdata: Users) {
        this.fromUrl = 'reg';
        this.http.post('http://rootechwebapiservice.us-west-2.elasticbeanstalk.com/api/UsersInformation/RegisterUser', usersdata).
            subscribe(responsedata => {
                // tslint:disable-next-line: no-string-literal
                this.isSuccessStatusCode = responsedata['isSuccessStatusCode'];
                // tslint:disable-next-line: no-string-literal
                this.statusCode = responsedata['statusCode'];
                if (this.isSuccessStatusCode) {
                    // this.RegistrationData.next(usersdata);
                    this.regStatus = 'UserRegisteredSuccessfully';
                    this.router.navigate(['\dashboard', this.fromUrl, usersdata.emailid], { relativeTo: this.route });
                } else {
                    alert('User already exists with this email id');
                    this.regStatus = 'User Already Exists with this email id ' + usersdata.emailid;
                }

            });
        return this.regStatus;
    }

    updateUserPassword(updatePasswordReq: ChangePasswordRequest) {
        this.http.post(
            'http://rootechwebapiservice.us-west-2.elasticbeanstalk.com/api/UsersInformation/ChangeUserPassword',
            updatePasswordReq).
            subscribe(responsedata => {
                // tslint:disable-next-line: no-string-literal
                this.isSuccessStatusCode = responsedata['isSuccessStatusCode'];
                // tslint:disable-next-line: no-string-literal
                this.updatePasswordMessage = responsedata['message'];
                if (this.isSuccessStatusCode) {
                    // this.RegistrationData.next(usersdata);
                    this.updateStatus = this.updatePasswordMessage;
                    this.HeaderRequest.navigatedtpage = 'changepassword';
                    this.headercomprequest.emit(this.HeaderRequest);
                    alert('Password updated successfully');
                    this.router.navigate(['login']);
                } else {
                    alert('Password or Email id is not matching');
                    this.updateStatus = this.updatePasswordMessage;
                }

            });
        return this.updateStatus;
    }


    disableAccount(disableaccountRequest: DisableAccountRequest) {
        this.http.post(
            'http://rootechwebapiservice.us-west-2.elasticbeanstalk.com/api/UsersInformation/DisableAccount',
            disableaccountRequest).
            subscribe(responsedata => {
                // tslint:disable-next-line: no-string-literal
                this.isSuccessStatusCode = responsedata['isSuccessStatusCode'];
                if (this.isSuccessStatusCode) {
                    // this.RegistrationData.next(usersdata);
                    alert('Account Disabled Successfully');
                    this.HeaderRequest.navigatedtpage = 'disableaccount';
                    this.headercomprequest.emit(this.HeaderRequest);
                    this.router.navigate(['login']);
                } else {
                    alert('Could not disable the account');
                }

            });
        return this.updateStatus;
    }




    loadUserData() {
        this.fromUrl = 'admin';
        this.http.get('http://rootechwebapiservice.us-west-2.elasticbeanstalk.com/api/UsersInformation/LoadRegisteredUsers').
            subscribe(responsedata => {
                // tslint:disable-next-line: no-string-literal
                this.isSuccessStatusCode = responsedata['isSuccessStatusCode'];
                // tslint:disable-next-line: no-string-literal
                this.statusCode = responsedata['statusCode'];
                if (responsedata) {
                    for (const key in responsedata) {
                        if (responsedata.hasOwnProperty(key)) {
                            this.users.push(responsedata[key]);
                        }
                    }
                } else {
                    alert('Error occured while loading the data');
                }
            });
        return this.users;
    }




    loggedinUserData(loggedinUser: Users): LoginUserResponse {

        this.fromUrl = 'login';

        this.http.post('http://rootechwebapiservice.us-west-2.elasticbeanstalk.com/api/UsersInformation/ValidateLogin', loggedinUser).
            subscribe(responsedata => {
                // tslint:disable-next-line: no-string-literal
                this.isValidUser = responsedata['isValidUser'];
                // tslint:disable-next-line: no-string-literal
                this.isAdminUser = responsedata['isAdminUser'];
                if (this.isValidUser) {
                    this.router.navigate(['\dashboard', this.fromUrl, loggedinUser.emailid], { relativeTo: this.route });
                    this.loginUserResponse.isValidUser = this.isValidUser;
                    this.loginUserResponse.isAdminUser = this.isAdminUser;
                    this.AdminEvent.emit(this.isAdminUser);

                } else {
                    alert('Not a valid login');
                    this.loginUserResponse.isValidUser = false;
                }
            });
        return this.loginUserResponse;
    }

}
