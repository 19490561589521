import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectService } from 'src/app/service/project.service';
import { MapService } from '@src/app/service/map.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.css']
})
export class ModuleComponent implements OnInit, OnDestroy {

  pv: any;
  output: any;
  width: any;
  length: any;
  thickness: any;
  weight: any;
  span: any;
  rafter: any;
  wieghtperft: any;
  step = 'step0';
  maxLength: number;
  stepsubs: Subscription;

  constructor(public projectService: ProjectService, public mapservice: MapService) { }

  ngOnInit() {
    this.initializeVariables();
    this.stepsubs = this.mapservice.currentMessage.subscribe(step => {
      this.step = step;
    });

  }

  ngOnDestroy() {
    if (this.stepsubs !== undefined) { this.stepsubs.unsubscribe(); }
    this.saveChanges();
  }


  initializeVariables() {
    const data = this.projectService.getProject();
    this.pv = data.pv;
    this.output = data.output;
    this.width = data.width;
    this.length = data.length;
    this.thickness = data.thickness;
    this.weight = data.weight;
    this.span = data.span;
    this.rafter = data.rafter;
    this.changeMaxLength();
  }

  changeMaxLength() {
    switch (this.pv) {
      case '60':
        this.maxLength = 68;
        break;
      case '72':
        this.maxLength = 77.5;
        break;
      case '90':
        this.maxLength = 105;
        break;
      default:
        this.maxLength = 68;
        break;
    }
  }


  onNext() {
    this.step = 'step2';
    this.mapservice.startnext('step2');
  }
  closeintro() {
    this.step = 'finished';
    this.mapservice.laststep = 'step1';
    this.mapservice.startnext('finished');
  }

  saveChanges() {
    this.projectService.setProject('pv', this.pv);
    this.projectService.setProject('output', this.output);
    this.projectService.setProject('width', this.width);
    this.projectService.setProject('length', this.length);
    this.projectService.setProject('thickness', this.thickness);
    this.projectService.setProject('weight', this.weight);
    this.projectService.setProject('span', this.span);
    this.projectService.setProject('rafter', this.rafter);
    this.projectService.setProject('panelarea', this.calculateWeigtPerFeet());
	
  }
  
  closemodel() {
  document.getElementById('location_butt').click();
  }

  calculateWeigtPerFeet() {
    return (this.width * this.length / 144).toFixed(2);
  }

  changeLengthByPV(value: any) {
    if (value === '72') {
      this.width = '40';
      this.length = '77.5';
    }
    if (value === '60') {
      this.width = '40';
      this.length = '68';
    }
    if (value === '90') {
      this.width = '44';
      this.length = '90';
    }
  }

  loadVariables(project: any) {
    this.pv = project.pv;
    this.output = project.output;
    this.width = project.width;
    this.length = project.length;
    this.thickness = project.thickness;
    this.weight = project.weight;
    this.span = project.span;
    this.rafter = project.rafter;
  }
	
  	postfromhead() {
	this.projectService.postprojectfromheader(true)
	}	

}
