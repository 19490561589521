<div class="form-container" *ngIf="this.router.url == '/design'">

    <h5 class="center">Installation Guide</h5>


    <div>

        <ng-container *ngIf="yourProject != undefined && yourProject.areaid.length > 1">
            <mat-form-field class="full">
                <mat-select placeholder="Change Roof Area" [disabled]="pitcherror"
                    (selectionChange)="changeroofarea($event,$event.value)" [(value)]="selectedarea">
                    <mat-option [value]="proj" *ngFor="let proj of yourProject.areaid; let i = index">
                        {{proj == selectedarea ? yourProject.zonetitle : areabutt[i]}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </div>


    <div>
        <mat-form-field class="full">
            <input matInput placeholder="AREA TITLE" [(ngModel)]="title">
            <mat-icon matSuffix>mode_edit</mat-icon>
        </mat-form-field>
    </div>


    <div>
        <mat-form-field id="showPitch" class="full">
            <mat-select matNativeControl placeholder="{{ country_bool == 'us' ? 'ROOF PITCH' : 'ROOF ANGLE'  }}"
                (selectionChange)="saveChanges()" [(value)]="pitch">
                <ng-container *ngIf="country_bool == 'us' ">
                    <mat-option value="0.0">0</mat-option>
                    <mat-option value="4.8">1/12 = 4.8&#730;</mat-option>
                    <mat-option value="9.5">2/12 = 9.5&#730;</mat-option>
                    <mat-option value="14.0">3/12 = 14.0&#730;</mat-option>
                    <mat-option value="18.4">4/12 = 18.4&#730;</mat-option>
                    <mat-option value="22.6">5/12 = 22.6&#730;</mat-option>
                    <mat-option value="26.6">6/12 = 26.6&#730;</mat-option>
                    <mat-option value="30.3">7/12 = 30.3&#730;</mat-option>
                    <mat-option value="33.7">8/12 = 33.7&#730;</mat-option>
                    <mat-option value="36.9">9/12 = 36.9&#730;</mat-option>
                    <mat-option value="39.8">10/12 = 39.8&#730;</mat-option>
                    <mat-option value="42.5">11/12 = 42.5&#730;</mat-option>
                    <mat-option value="45.0">12/12 = 45.0&#730;</mat-option>
                </ng-container>
                <ng-container *ngIf="country_bool == 'canada' ">
                    <mat-option value="0">0-6&#730;</mat-option>
                    <mat-option value="7">7-27&#730;</mat-option>
                    <mat-option value="28">28-45&#730;</mat-option>
                </ng-container>

            </mat-select>
        </mat-form-field>
        <p *ngIf="pitcherror" class="drop-down-err">please select roof pitch</p>
    </div>

    <div>
        <mat-form-field class="full">
            <mat-select matNativeControl placeholder="PREFER USING:" [(value)]="splice">
                <mat-option value="0">No Splice</mat-option>
                <mat-option value="1">Splice</mat-option>
                <mat-option *ngIf="FLAG_FLOATING" value="2">Floating Splice</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="splice == 2">
        <mat-form-field class="full">
            <mat-select matNativeControl placeholder="EAVE ROW CALCULATIONS:" [(value)]="eave">
                <mat-option value="0">Rafter-Deck</mat-option>
                <mat-option value="1">Rafter Only</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="full" *ngIf="country_bool == 'us' ">
        <mat-checkbox [(ngModel)]="staggered">Staggered Design</mat-checkbox>
    </div>


    <div>
        <mat-form-field *ngIf="rafterOnly && splice > 0" class="full">
            <mat-select matNativeControl placeholder="CLAMPING AT:" [(value)]="staticSpace">
                <mat-option [value]="rafterSpan">{{rafterSpan}} Inch</mat-option>
                <mat-option *ngIf="rafterSpan == 16" value="32">32 Inch</mat-option>
                <mat-option value="48">48 Inch</mat-option>
                <mat-option *ngIf="rafterSpan == 16" value="64">64 Inch</mat-option>
                <mat-option *ngIf="rafterSpan == 24" value="72">72 Inch</mat-option>
                <mat-option *ngIf="rafterSpan == 16" value="80">80 Inch</mat-option>
                <mat-option value="96">96 Inch</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="full">
        <mat-checkbox [(ngModel)]="asterisk" *ngIf="country_bool == 'us' " (change)="alertAsterisk()">SDST Screws
            *</mat-checkbox>
    </div>

    <!---->
    <div class="full" *ngIf="country_bool == 'canada' ">
        <mat-checkbox [checked]="clip" [(ngModel)]="clip" (change)="saveclip($event)">Snow clip</mat-checkbox>
    </div>

    <button mat-raised-button class="save" (click)="saveChanges()">Save</button>

</div>


<!--Intro steps Code -->
<div class="step-container" *ngIf="step == 'step6'">
    <span class="close-butt" (click)="closeintro()"><i class="fas fa-times"></i></span>
    <h3 class="mt-0">Step 1</h3>
    <p class="intro-text">Fill Installation Info</p>
    <button mat-raised-button class="next-butt" [disabled]="undefined" (click)="onNext('step7')">Next</button>
    <p class="intro-text" *ngIf="!FLAG_FLOATING"><b>1/3</b></p>
    <p class="intro-text" *ngIf="FLAG_FLOATING"><b>1/6</b></p>
</div>