<div class="container" style="background:transparent; position:absolute; top:190px;">

    <div id="postsignup" class="mt-3">
        <label class="error text-danger mt-1 dis-block">{{auth.error}}</label>

		  <div class="form-group half">
            <label for="email">Email address *</label>
            <input type="text" readonly class="form-control" [(ngModel)]="email" placeholder="email">
        </div>

        <div class="form-group half">
            <label for="Full Name">Full Name *</label>
            <input type="text" class="form-control" required [(ngModel)]="name" placeholder="Full Name">
        </div>

        <div class="form-group half">
            <label for="Phone Number">Phone Number *</label>
            <input type="number" value="+1" class="form-control" required [(ngModel)]="phone"
                placeholder="Phone Number">
        </div>
	
	        <div class="form-group half">
            <label for="Company">Company *</label>
            <input type="text" class="form-control" [(ngModel)]="company" placeholder="Company">
        </div>
	

	<div class="form-group half">
		 <label for="State">Country *</label>
		   <select id="country" [(ngModel)]="country">
		   <option value="us">United States</option>
		   <option value="canada">Canada</option>
		   </select>
		</div>

        <div class="form-group half" *ngIf="country =='us'">
            <label for="State">State *</label>
            <select id="state" [(ngModel)]="state">
                <option value="AR">Arkansas</option>
                <option value="AZ" selected>Arizona</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DC">District of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <!-- <option value="KY">Kentucky</option> -->
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <!-- <option value="MS">Mississippi</option> -->
                <option value="MO">Missouri</option>
                <!-- <option value="MT">Montana</option> -->
                <!-- <option value="NE">Nebraska</option> -->
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <!-- <option value="ND">North Dakota</option> -->
                <option value="OH">Ohio</option>
                <!-- <option value="OK">Oklahoma</option> -->
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <!-- <option value="SD">South Dakota</option> -->
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <!-- <option value="VT">Vermont</option> -->
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <!-- <option value="WV">West Virginia</option> -->
                <option value="WI">Wisconsin</option>
            </select>
        </div>

		
		   <div class="form-group half" *ngIf="country =='canada'">
			 <label for="province">Province *</label>
			  <select id="province" [(ngModel)]="canstate">
                <option value="AB" selected>Alberta</option>
                <option value="BC">British Colombia</option>
                <option value="NS">Nova Scotia</option>
                <option value="ON">Ontario</option>
                <option value="SK">Saskatchewan</option>
                <option value="NB">New Brunswick</option>
                <option value="PEI">Prince Edward Island</option>
				</select>
            </div>





        <div class="bottomleft">
<button (click)="signUp()" class="btn-default signup" style="margin-left:5px;">Update Profile</button>
        </div>

    </div>
</div>