<div>

  <div class="bottomleft">
    <div class="container"
      style="height:250px;width:650px; background-color: #ffffff; padding: 50px 90px; opacity: 0.9;">

      <h2 class="mt-0">Reset Password</h2>


      <div class="form-group">
        <label for="email">Email address</label>
        <input type="text" class="form-control" placeholder="email" [(ngModel)]="email">
      </div>

      <button class="btn btn-default" (click)="resetfunc()">Reset Password</button>
      <p class="text-danger">{{auth.forgoterr}}</p>



    </div>

  </div>
  <div class="alert alert-success" *ngIf="alertbox">
    <p><strong>Reset Email Sent !!</strong> </p>
  </div>
</div>