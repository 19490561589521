import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@src/app/service/auth.service';
import * as firebase from 'firebase/app';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Subscription, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ProjectService } from 'src/app/service/project.service';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { MatSelect } from '@angular/material/select';



@Component({
    selector: 'app-issues',
    templateUrl: './issues.component.html',
    styleUrls: ['./issues.component.css']
})

export class IssuesComponent implements OnInit {
    @Input() requiredFileType: string;

    name = '';
    email = '';
    project: any;
    descrip = '';
    projName = '';
    compname = '';
    compstate = '';
    validerror = '';
    activebutt = false;
    fileName = '';
    uploadProgress: Observable<number>;
    task: AngularFireUploadTask;
    uploadSub: Subscription;
    issueId: any;
    filePath = '';
    user: any;
    allProjects: any;


    constructor(
        public dialogRef: MatDialogRef<IssuesComponent>, public auth: AuthService,
        public projectService: ProjectService, private storage: AngularFireStorage) { }

    @ViewChild('projectName') matSelect: MatSelect;


    ngOnInit(): void {
        this.user = firebase.auth().currentUser;
        if (this.user) {
            this.email = this.user.email;
            this.name = this.user.displayName;
            this.auth.getsingleuser(this.user.uid).subscribe((curUser: any) => {
                this.compname = curUser.company;
                this.compstate = curUser.state;
            });
        }
        this.issueId = this.auth.createId();
        // get users all projects
        this.projectService.getprojectsbyid(this.user.uid).subscribe(data => {
            this.allProjects = data;
            this.allProjects.sort((a, b) => a.name.localeCompare(b.name));
        });
    }

    closefunc(): void {
        this.dialogRef.close();
    }

    resolved(captchaResponse) {
        // console.log(`Resolved response token: ${captchaResponse}`);
        if (captchaResponse) {
            this.activebutt = true;
        }

    }


    onFileSelected(event) {
        const file: File = event.target.files[0];
        if (file) {
            this.fileName = file.name;
            const formData = new FormData();
            formData.append('thumbnail', file);
            const path = `${this.issueId}/${file.name}`;
            const ref = this.storage.ref(path);
            this.task = this.storage.upload(path, file);
            this.uploadProgress = this.task.snapshotChanges()
                .pipe(map(s => (s.bytesTransferred / s.totalBytes) * 100));

            // this.snapshot = this.task.snapshotChanges().pipe(
            //     tap(console.log),
            //     // The file's download URL
            //     finalize(async () => {
            //         this.downloadURL = await ref.getDownloadURL().toPromise();
            //         this.firestore.collection('issues-files').add({ downloadURL: this.downloadURL, path });
            //     }),
            // );
        }
    }

    cancelUpload() {
        this.uploadSub.unsubscribe();
        this.reset();
    }

    reset() {
        this.uploadProgress = null;
        this.uploadSub = null;
    }

    onChange(ev: any) {
        this.projName = ev.source.selected.viewValue;
    }

    submitfunc() {
        // tslint:disable-next-line: max-line-length
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // this.matSelect.valueChange.subscribe(value => { this.projName = value; });
        if (!this.name) {
            this.validerror = 'Name is required';
        } else if (!re.test(String(this.email).toLowerCase())) {
            this.validerror = 'Email is not valid';
        } else if (!this.project) {
            this.validerror = 'Project name is required. You can also choose "None" on top of the list.';
        } else if (!this.compname) {
            this.validerror = 'Company name is required';
        } else if (!this.descrip) {
            this.validerror = 'Description of issue is required';
        } else {
            const server = {
                name: this.name,
                email: this.email,
                projname: this.projName,
                companystate: this.compstate,
                companyname: this.compname
            };
            this.auth.postissuedata(this.issueId, this.user.uid, this.project,
                this.descrip, Date.now(), this.fileName, server).then(() => { this.dialogRef.close(); });
        }
    }

}
