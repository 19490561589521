import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { ProjectService } from 'src/app/service/project.service';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { IssuesComponent } from '@src/app/issues/issues.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageComponent } from 'src/app/design/parts/image.component';
import { PdfService } from 'src/app/service/pdf.service';

import { AuthService } from 'src/app/service/auth.service';

export interface Parts {
  id: number;
  type: string;
  pn: string;
  desc: string;
  um: number;
  qnty: number;
  cost: number;
  img: string;
  des_qnty: number;
}


export interface DialogData {
  src: string;
}


@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.css']
})
export class PartsComponent implements OnInit, OnDestroy {
  @Input() direct_bool = false;

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;


  DONE = false;
  project: any;
  addedOptions = [];
  selectedAcc = [];
  order: any;
  bom = [];
  totalPanels = this.projectService.getProject().panels;
  splice = Number(this.projectService.getProject().splice);
  attachment = this.projectService.getProject().rt;
  midClampsR: any;
  endClampsR: any;
  midClampsD: any;
  endClampsD: any;
  midSpliceR: any;
  endSpliceR: any;
  midSpliceD: any;
  endSpliceD: any;
  midSpliceFloat: any;
  endSpliceFloat: any;
  endFloatSpliceClamps: any;
  baseqnty = 0;
  skirtPanels: any;
  multiorder = [];
  partsareaid = [];
  areasTitles = [];
  areasImages = [];
  subs: any;
  pricebool = false;
  asterisk = this.projectService.getProject().asterisk;
  singareasub: any;
  tooltip = 'You can modify your Part list by changing the quantity in front of them. If set to '
    + 'zero (0), that part will not be calculated in your final report. You can also check/uncheck more '
    + 'componenst to your list and adjust the quantities as well. Make sure to select the desired roof area(s) '
    + 'before selecting the component.';

  country_bool = this.projectService.getProject().country == undefined ? 'us' : this.projectService.getProject().country;
  areacount = 0;
  //direct_bool = true;

  constructor(
    public projectService: ProjectService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    public pdfservice: PdfService,
    public authservice: AuthService
  ) { }

  accessories: Parts[] = [
    {
      id: 8, type: 'Wood-Screws', pn: 'RT2-04-SD5-60',
      desc: 'Screw 5x60 ', um: 100, qnty: 0, des_qnty: 0, cost: 72.0,
      img: '../../../../assets/images/Screw-5x60.jpg'
    },
    {
      id: 9, type: 'Skirt', pn: 'RT3-03-SK80BL',
      desc: 'RT-APEX Skirt 80" (2032mm)', um: 8, qnty: 0, des_qnty: 0, cost: 228.4,
      img: '../../../../assets/images/RT-APEX Skirt 80_w_Skirt_Bonding_clip_Accessories.jpg'
    },
    {
      id: 10, type: 'Option', pn: 'RT3-04-SKSSL-20',
      desc: 'Skirt Bonding Splice', um: 24, qnty: 1, des_qnty: 1, cost: 35.04,
      img: '../../../../assets/images/Skirt-bonding-Splice.jpg'
    },
    {
      id: 11, type: 'Option', pn: 'RT3-04-ENDCAP',
      desc: 'Skirt End cap: (L + R & 2 screws/set)', um: 120, qnty: 1, des_qnty: 1, cost: 536.40,
      img: '../../../../assets/images/RT-end-caps.jpg'
    },
  ];
  options: Parts[] = [
    {
      id: 13, type: 'RT-Butyl', pn: 'RT3-04-BUTYL',
      desc: 'RT-Butyl for APEX', um: 12, qnty: 1, des_qnty: 1, cost: 20.97,
      img: '../../../../assets/images/RT_Butyl_Accessories.jpg'
    },
  ];
  asteriskPart = {
    id: 12, type: 'Self Tap Screws', pn: 'RT3-04-M4-16S',
    desc: 'Self tapping screw M4x16', um: 100, qnty: 1, des_qnty: 1, cost: 42.0,
    img: '../../../../assets/images/Screw-M4.jpg'
  };

  displayedColumns: string[] = ['type', 'img', 'pn', 'desc', 'um', 'cost', 'qnty', 'sTotal', 'des_qnty', 'des_total'];
  parts: Parts[] = [
    {
      id: 1, type: 'Base', pn: 'RT3-00-AXBK',
      desc: 'RT-APEX Base w/ 2 screws/base and extra RT-Butyl tape', um: 24, qnty: 0, des_qnty: 0, cost: 164.88,
      img: '../../../../assets/images/base_RT3-00-AXBK.jpg'
    },
    {
      id: 2, type: 'Mid Clamp', pn: 'RT3-02-UM-30',
      desc: 'RT-APEX Middle Clamp Universal Height (PV Frame H30-40mm)', um: 12, qnty: 0, des_qnty: 0, cost: 159.60,
      img: '../../../../assets/images/middle_clamp_RT3-02-UM-BK.jpg'
    },
    {
      id: 3, type: 'Mid Clamp Splice', pn: 'RT3-02-UMS30',
      desc: 'RT-APEX Middle Clamp Splice Universal Height (PV Frame H30-40mm)', um: 12, qnty: 0, des_qnty: 0, cost: 148.80,
      img: '../../../../assets/images/RT3-02-UMSBK_MIDDLE_CLAMP.jpg'
    },
    {
      id: 4, type: 'Mid Floating Splice', pn: 'RT3-02-UMFS30',
      desc: 'RT-APEX Middle Floating Splice Universal Height (PV Frame H30-40mm)', um: 16, qnty: 0, des_qnty: 0, cost: 198.40,
      img: '../../../../assets/images/RT3-02-UMFS30_FLOATING_CLAMP.jpg'
    },
    {
      id: 5, type: 'End Clamp', pn: 'RT3-01-UE-30',
      desc: 'RT-APEX End Clamp Universal Height (PV Frame H30-40mm)', um: 12, qnty: 0, des_qnty: 0, cost: 159.60,
      img: '../../../../assets/images/RT3-01-UE-BK_END_CLAMP.jpg'
    },
    {
      id: 6, type: 'End Clamp Splice', pn: 'RT3-01-UES30',
      desc: 'RT-APEX End Clamp Splice Universal Height (PV Frame H30-40mm)', um: 12, qnty: 0, des_qnty: 0, cost: 148.80,
      img: '../../../../assets/images/RT3-01-UESBK_END_CLAMP.jpg'
    },
    {
      id: 7, type: 'End Floating Splice', pn: 'RT3-01-UEFS30',
      desc: 'RT-APEX End Floating Splice Universal Height (PV Frame H30-40mm)', um: 16, qnty: 0, des_qnty: 0, cost: 198.40,
      img: '../../../../assets/images/RT3-02-UMFS30_FLOATING_CLAMP.jpg'
    }
  ];



  ngOnInit() {
    this.order = [];
    this.bom = [];

    this.projectService.savespinner('on'); // Loading data
    this.projectService.getspinnerValue().subscribe(data => {
      this.DONE = (data === 'off') ? true : false;
    });
    this.partsareaid = this.projectService.getProject().areaid;
    if (this.partsareaid === undefined || this.partsareaid.length === 0) {
      this.areasTitles.push({ "title": this.projectService.getProject().zonetitle, "selected": false });
      this.singareasub = this.projectService.getspinnerValue().subscribe(value => {
        if (value === 'off' && this.partsareaid.length === 0) {
          this.setImageBySource(this.projectService.getProject().stageImage).then((resolve) => {
            this.areasImages.push(resolve);
          });
          this.subinitfunc();
        }
      });
      if (this.projectService.tableBody.length !== 0) {
        this.projectService.savespinner('off');
      }
    } else {
      this.projectService.setProject('totalpanel', '--');
      this.singareasub = this.projectService.getspinnerValue().subscribe(multivalue => {
        if (multivalue === 'off' && this.partsareaid.length !== 0) { this.getmultiroof(); }
      });
      if (this.projectService.tableBody.length !== 0) { this.projectService.savespinner('off'); }
    }
  }

  getmultiroof() {
    for (const areaid of this.partsareaid) {
      this.subs = this.projectService.getroofareadb(areaid).subscribe(async (singarea: any) => {
        this.areasTitles.push({ "title": singarea.zonetitle, "selected": false });
        this.projectService.setprojects(singarea);
        this.projectService.postprojnotif();
        this.subinitfunc();
        await this.setImageBySource(singarea.stageImage).then((resolve) => {
          this.areasImages.push(resolve);
        });
      });
    }
  }


  subinitfunc(): any {
    this.totalPanels = this.projectService.getProject().panels;
    this.splice = Number(this.projectService.getProject().splice);
    this.attachment = this.projectService.getProject().rt;
    this.order = [];
    this.bom = [];
    this.midClampsR = this.projectService.getclamping('midClampR').length;
    this.endClampsR = this.projectService.getclamping('endClampR').length;
    this.midClampsD = this.projectService.getclamping('midClampD').length;
    this.endClampsD = this.projectService.getclamping('endClampD').length;
    this.midSpliceR = this.projectService.getclamping('midSpliceR').length;
    this.endSpliceR = this.projectService.getclamping('endSpliceR').length;
    this.midSpliceD = this.projectService.getclamping('midSpliceD').length;
    this.endSpliceD = this.projectService.getclamping('endSpliceD').length;
    this.midSpliceFloat = this.projectService.getclamping('midSpliceF').length;
    this.endSpliceFloat = this.projectService.getclamping('endSpliceF').length;
    this.skirtPanels = this.projectService.getProject().skirtNum;
    this.asterisk = this.asterisk && this.projectService.getProject().asterisk;
    this.initialize();
  }


  async setImageBySource(source: string) {
    return new Promise((resolve, reject) => {
      const screenshot = new Image();
      screenshot.src = source;
      screenshot.onload = () => resolve(screenshot.src);
      // screenshot.onerror = reject;
      screenshot.onerror = () => resolve('');
    });
  }


  ngOnDestroy() {
    if (this.singareasub) { this.singareasub.unsubscribe(); }
    this.setPDFTable();
    this.snackBar.dismiss();
    this.dialog.closeAll();
    let dbpartlist = [];
    let partobj = {};
    //saving designed quantity from db
    let index = 0;
    let partkey = "partlist";
    do {
      this.multiorder[index].forEach((sing) => {
        dbpartlist.push({ 'id': sing.id, 'qty': Number(sing.qnty), 'des_qty': Number(sing.des_qnty), index: index });
      });
      partobj[partkey] = dbpartlist;
      index = index + 1;
    } while (index < this.partsareaid.length);
    for (const areaid of this.partsareaid) {
      this.projectService.updateprojprop("roofarea", areaid, partobj);
    }
    if (!this.direct_bool) {
      this.projectService.updateprojprop("projects", this.projectService.getProject().id, partobj);
      this.projectService.setProject("partlist", partobj[partkey]);
    }
  }


  openpopup() {
    this.dialog.open(IssuesComponent);
  }


  initialize() {
    const tempOrder = this.projectService.tableBody;
    if (!this.accessories.find(item => item.id === 12)) { this.accessories.push(this.asteriskPart); }
    if (tempOrder != null) {
      tempOrder.forEach((element: any) => {
        if (element.id > 9) {
          this.addedOptions.push(element);
          this.selectedAcc.push(element.id);
        }
      });
    }

    if (this.splice === 0) {
      this.order = this.addParts([1, 2, 5]);
      this.baseqnty = this.endClampsR + this.midClampsR + this.endClampsD + this.midClampsD;
      this.setQuantity(this.order, 1, this.baseqnty);
      this.setQuantity(this.order, 2, (this.midClampsR + this.midClampsD));
      this.setQuantity(this.order, 5, (this.endClampsR + this.endClampsD));
    } else if (this.splice === 1) {
      this.order = this.addParts([1, 2, 3, 5, 6]);
      this.baseqnty = this.endClampsR + this.midClampsR + this.endClampsD + this.midClampsD +
        this.midSpliceD + this.endSpliceD + this.midSpliceR + this.endSpliceR;
      this.setQuantity(this.order, 1, this.baseqnty);
      this.setQuantity(this.order, 3, this.midSpliceD + this.midSpliceR);
      this.setQuantity(this.order, 6, this.endSpliceD + this.endSpliceR);
      this.setQuantity(this.order, 2, (this.midClampsR + this.midClampsD));
      this.setQuantity(this.order, 5, (this.endClampsR + this.endClampsD));
    } else if (this.splice === 2) {
      this.order = this.addParts([1, 2, 3, 4, 5, 6, 7]);
      this.baseqnty = this.endClampsR + this.midClampsR + this.endClampsD + this.midClampsD +
        this.midSpliceD + this.endSpliceD + this.midSpliceR + this.endSpliceR;
      this.setQuantity(this.order, 1, this.baseqnty);
      this.setQuantity(this.order, 4, this.midSpliceFloat);
      this.setQuantity(this.order, 7, this.endSpliceFloat);
      this.setQuantity(this.order, 3, this.midSpliceD + this.midSpliceR);
      this.setQuantity(this.order, 6, (this.endSpliceD + this.endSpliceR));
      this.setQuantity(this.order, 2, (this.midClampsR + this.midClampsD));
      this.setQuantity(this.order, 5, (this.endClampsR + this.endClampsD));
    }

    // Add Wood Screws based on Roof Types
    this.addScrews();
    if (this.attachment.slice(0, 6) !== 'rafter' && this.attachment != '') {  // Only Deck Intsallation
      this.setQuantity(this.order, 8, (this.baseqnty * 3));
    }
	//removing screws for country change BOM
	else if(this.attachment == '') {
	this.setQuantity(this.order, 8, 0 );
	}
	else { // All Rafter projects
      if (this.attachment.length > 6) { // R-D-R
        switch (this.splice) {
          case 0:
            this.setQuantity(this.order, 8, ((this.endClampsD + this.midClampsD + this.midSpliceD) * 3));
            break;
          case 1:
            this.setQuantity(this.order, 8, ((this.endSpliceD + this.midSpliceD + this.endClampsD + this.midClampsD) * 3));
            break;
          case 2:
            this.setQuantity(this.order, 8, ((this.endSpliceD + this.endClampsD + this.midClampsD + this.midSpliceD) * 3));
            break;
        }
      } else { // Rafter Only
        switch (this.splice) {
          case 0:
            this.order.filter(item => item.id !== 8);
            break;
          case 1:
            this.setQuantity(this.order, 8, ((this.endSpliceD + this.midSpliceD) * 3));
            break;
          case 2:
            this.setQuantity(this.order, 8, ((this.endSpliceD + this.midSpliceD) * 3));
            break;
        }
      }
    }
    // Add skirt
    this.addSkirt();
    // Add skirt bonding splice
    this.addBonding();
    // Add bonding end cap
    this.addEndcap();
    // Add Asterisk
    this.addAsterisk();
    this.country_bool = this.projectService.getProject().country == undefined ? 'us' : this.projectService.getProject().country;
    //adjust parts for canada
    if (this.country_bool == 'canada') {
      this.setQuantity(this.order, 9, 0);
      this.setQuantity(this.order, 10, 0);
      this.setQuantity(this.order, 11, 0);
    }
    //setting list to 0
    if (this.direct_bool) {
      this.skirtPanels = this.projectService.getProject().skirtNum;
      this.skirtPanels == 0 ? this.setQuantity(this.order, 10, 0) : '';
      this.skirtPanels == 0 ? this.setQuantity(this.order, 11, 0) : this.setQuantity(this.order, 11, 1);
    }
    // Add everything to part list
    this.bom = this.order;
    this.order = this.order.concat(this.addedOptions);
    let dbpartlist = this.projectService.getProject().partlist;
    //Getting Designed Quantity from DB
    if (dbpartlist != undefined) {
      dbpartlist.forEach((sing) => {
        this.areacount == sing.index ? this.setdesignQuantity(this.order, sing.id, sing.des_qty) : '';
      });
    }
    if (this.direct_bool) {
	  this.multiorder = [];
      this.multiorder.push(JSON.parse(JSON.stringify(this.order)));
      this.setPDFTable();
    }
    else {
      this.multiorder.push(JSON.parse(JSON.stringify(this.order)));
    }
    this.areacount = this.areacount + 1;
  }


  addScrews() {
    if (!this.order.find(item => item.id === 8)) {
      const screws = this.accessories.find(item => item.id === 8);
      this.order = this.order.concat(screws);
      this.selectedAcc.push(8);
    }
  }


  addSkirt() {
    if (!this.order.find(item => item.id === 9)) {
      const skirts = this.accessories.find(item => item.id === 9);
      this.order = this.order.concat(skirts);
      this.selectedAcc.push(9);
    }
    this.setQuantity(this.order, 9, this.skirtPanels);
  }


  addAsterisk() {
    this.asterisk = this.projectService.getProject().asterisk;
    if (this.asterisk) {
      if (!this.order.find(item => item.id === 12)) {
        const asterisk = this.accessories.find(item => item.id === 12);
        this.order = this.order.concat(asterisk);
        this.selectedAcc.push(12);
      }
      this.setQuantity(this.order, 12, this.baseqnty);
    }
  }


  addBonding() {
    if (!this.order.find(item => item.id === 10)) {
      const bonding = this.accessories.find(item => item.id === 10);
      this.order = this.order.concat(bonding);
      this.selectedAcc.push(10);
    }
    this.setQuantity(this.order, 10, this.skirtPanels - 1);
  }


  addEndcap() {
    if (!this.order.find(item => item.id === 11)) {
      const endcap = this.accessories.find(item => item.id === 11);
      this.order = this.order.concat(endcap);
      this.selectedAcc.push(11);
    }
    // this.setQuantity(this.order, 9, this.skirtPanels);
  }


  addParts(idList: any) {
    const temp = [];
    idList.forEach((id: any) => {
      temp.push(this.parts.find(item => item.id === id));
    });
    return temp;
  }


  getTotalCost(index) {
    return this.multiorder[index].map(t => this.getSubtotal(t.cost, t.um, t.qnty)).reduce((acc, value) => acc + value, 0);
  }

  getdesTotalCost(index) {
    return this.multiorder[index].map(t => this.getSubtotal(t.cost, t.um, t.des_qnty)).reduce((acc, value) => acc + value, 0);
  }

  getSubtotal(cost: number, unit: number, qnty: number) {
    return ((cost / unit) * qnty);
    //return (unit !== 100) ? ((cost / unit) * qnty) : (Math.ceil(qnty / unit) * cost);
  }


  setSelectedOptions(selectedOptions: any) {
    const indexes = this.getselectedareaindex();
    const options = [];
    const selected = [];
    for (const sel of selectedOptions) {
      if (sel.value > 12) {
        options.push(sel.value);
      }
    }
    for (const op of options) {
      selected.push(this.options.find(item => item.id === op));
    }
    indexes.forEach((index) => {
      this.multiorder[index] = this.multiorder[index].filter(item =>
        (item.id !== 13));
      this.multiorder[index] = this.multiorder[index].concat(JSON.parse(JSON.stringify(selected)));
    });
  }

  getselectedareaindex() {
    const indexes = this.areasTitles.length > 1 ? [] : [0];
    this.areasTitles.forEach((sing, index) => {
      if (sing.selected) { indexes.push(index); }
    });
    return indexes;
  }


  setQuantity(list: any, id: number, qnt: number) {
    list.forEach((pt: any) => {
      if (pt.id === id) {
        pt.qnty = qnt;
        pt.des_qnty = qnt;
        if (qnt === null) {
          pt.des_qnty = 0;
          pt.qnty = 0;
        }
      }
    });
  }

  setdesignQuantity(list: any, id: number, qnt: number) {
    list.forEach((pt: any) => {
      if (pt.id === id) {
        pt.des_qnty = Number(qnt);
        if (qnt === null) {
          pt.des_qnty = 0;
        }
      }
    });
  }


  setPDFTable() {
    const tableBody: string[] = [];
    this.order.forEach((pt: any) => {
      tableBody.push(pt);
    });

    if (this.pricebool) {
      this.multiorder.forEach(order => {
        order.forEach(part => {
          part.cost = '---';
        });
      });
    }
    this.projectService.tableBody = JSON.parse(JSON.stringify(this.multiorder));
  }

  openTip() {
    // Tip Details
    this.snackBar.open(this.tooltip, 'Got it!', { duration: 60000, horizontalPosition: 'center', verticalPosition: 'top', });
  }

  viewLayoutLarge(img: string) {
    const dialogRef = this.dialog.open(ImageComponent, {
      width: (window.innerWidth * .8).toString(),
      data: { src: img }
    });
    dialogRef.afterClosed().subscribe(result => {
      img = result;
    });
  }

  downloadbompdf() {
    this.setPDFTable();
    this.pdfservice.printbompdf();
  }


}
