<div class="content" id="content">
	<div class="forms">
		<div class="icon">
			<a (click)="toggleOverlay()">
				<mat-icon>picture_in_picture</mat-icon>
			</a>
		</div>
		<div *ngIf="ROOF_ATTACHMENT.slice(0, 6) === 'rafter'" class="icon">
			<a (click)="toggleGrid()">
				<mat-icon *ngIf="SHOW_RAFTERLINE" title="Hide Rafter lines">grid_off</mat-icon>
				<mat-icon *ngIf="!SHOW_RAFTERLINE" title="Show Rafter lines">grid_on</mat-icon>
			</a>
		</div>
		<div class="icon">
			<a (click)="showAllPossibleAttachments()" title="Auto-Populate Panel Attachments">
				<mat-icon>construction</mat-icon>
			</a>
		</div>
		<div *ngIf="ROOF_ATTACHMENT.slice(0, 6) === 'rafter'" class="icon">
			<a (click)="SubmitResult()" title="Check Your Attachments Design">
				<mat-icon>done_outline</mat-icon>
			</a>
		</div>
		<div class="bottom">
			<div *ngIf="ROOF_ATTACHMENT.slice(0, 6) === 'rafter'" class="icon">
				<a (click)="toggleGlossary()" title="Glossary">
					<mat-icon>list_alt</mat-icon>
				</a>
			</div>
			<div class="icon">
				<a (click)="showHelp()" title="How to?">
					<mat-icon>help_outline</mat-icon>
				</a>
			</div>
		</div>
		<!-- For Test - Start -->
		<!-- <div class="icon">
			<a (click)="centerDesignToView()" title="Testing">
				<mat-icon>settings</mat-icon>
			</a>
		</div> -->
		<!-- End of Test -->
	</div>

	<div #drawing id="drawing" class="drawing" (click)="draw(tools.value)"></div>

	<mat-button-toggle-group #tools="matButtonToggleGroup" class="panel" [value]="toolPanel" joyrideStep="seventhStep"
		title="Step 7" text="Add / delete Panels" (change)="toolchange($event.value)">
		<mat-slide-toggle #vertical class="tools" value="Orientation" title="Change Module's Orientation"
			(click)="toggleOrnt()">
			<mat-icon *ngIf="vertical.checked" svgIcon="portrait"></mat-icon>
			<mat-icon *ngIf="!vertical.checked" svgIcon="landscape"></mat-icon>
		</mat-slide-toggle>

		<mat-button-toggle id="add-panel" class="tools" value="add" title="Add Modules" (click)="addCursor();">
			<mat-icon svgIcon="add_panel"></mat-icon>
		</mat-button-toggle>


		<mat-button-toggle id="add-type-panel" class="tools" value="add-type-panel" (click)="typeCursor();" title="Add Modules by Rows x Columns" >
			<mat-icon svgIcon="add_type_panel"></mat-icon>
		</mat-button-toggle>
	
			
		<mat-button-toggle id="remove-panel" class="tools" value="delete" title="Erase Modules"
			(click)="deletePanelCursor()">
			<mat-icon svgIcon="remove_panel"></mat-icon>
		</mat-button-toggle>

		<mat-button-toggle class="tools" value="move" title="Move Properties" (click)="moveCursor(); onMove()">
			<mat-icon>pan_tool</mat-icon>
		</mat-button-toggle>

		<mat-button-toggle *ngIf="ROOF_ATTACHMENT.slice(0, 6) === 'rafter'" class="tools" value="deleteClamp"
			title="Delete Attachments" (click)="deleteClampsCursor()">
			<mat-icon>disabled_by_default</mat-icon>
		</mat-button-toggle>

		<mat-button-toggle *ngIf="ROOF_ATTACHMENT.slice(0, 6) === 'rafter'" class="tools" value="editing"
			title="Add Attachments" (click)="plainCursor()">
			<mat-icon>edit</mat-icon>
		</mat-button-toggle>

		<mat-button-toggle class="tools" *ngIf="country_bool == 'us' " value="zoneChange" title="Change Roof Zone"
			(click)="changeZone(); deletePanelCursor()">
			<mat-icon *ngIf="toolZone === 1"> looks_one </mat-icon>
			<mat-icon *ngIf="toolZone === 2">looks_two</mat-icon>
			<mat-icon *ngIf="toolZone === 3">looks_3</mat-icon>
		</mat-button-toggle>

		<mat-button-toggle class="tools" *ngIf="country_bool == 'canada' " value="zoneChange" title="Change Roof Zone"
			(click)="changecanadazone(); deletePanelCursor()">
			<div class="canada_zone" *ngIf="canadazone === 'R'"> R </div>
			<div class="canada_zone" *ngIf="canadazone === 'S'">S</div>
			<div class="canada_zone" *ngIf="canadazone  === 'C'">C</div>
		</mat-button-toggle>


		<button mat-button class="tools" value="fill" title="Fill up with Modules" (click)="populate()">
			<mat-icon>apps</mat-icon>
		</button>

		<button mat-button class="tools" value="deleteAll" title="Clear Workspace" (click)="deleteAll()">
			<mat-icon>delete</mat-icon>
		</button>

		<mat-button-toggle class="tools" value="undo" title="undo" [disabled]="!undoenable" (click)="undo()">
			<mat-icon>undo</mat-icon>
		</mat-button-toggle>


	</mat-button-toggle-group>

	<div>
		<img class="glossary" [src]="'../../../assets/images/legend.png'" [hidden]="hideGlossary">
	</div>

	<div *ngIf="COMPARED_SPAN.length > 0" class="span" [ngClass]="{'redText' : HAS_CONFLICT}">
		<mat-select placeholder="Change Max Span Zone Information" [(value)]="zone" style="width: 250px;"
			(valueChange)="setMaxSpanLocal()">
			<ng-container *ngIf="country_bool == 'us'">
				<mat-option value="0">Zone 1</mat-option>
				<mat-option value="1">Zone 2</mat-option>
				<mat-option value="2">Zone 3</mat-option>
			</ng-container>
			<ng-container *ngIf="country_bool == 'canada'">
				<mat-option value="0">Zone R</mat-option>
				<mat-option value="1">Zone S</mat-option>
				<mat-option value="2">Zone C</mat-option>
			</ng-container>
		</mat-select>
		<!-- <div *ngFor="let zone of projectZones"> -->
		<!-- </div> -->
		<div *ngIf="differentOrientationInProject() || getProjectOrientation() === 'land'">
			<h4>Landscape: Zone {{zoneTitle}} {{starTitle}}</h4>
			<h5>Rafter-Rafter: {{COMPARED_SPAN[0]}}"</h5>
			<h5 *ngIf="COMPARED_SPAN.length > 1">Deck-Deck: {{COMPARED_SPAN[1]}}"</h5>
			<h5 *ngIf="COMPARED_SPAN.length > 1">Rafter-Deck: {{COMPARED_SPAN[2]}}"</h5>
		</div>
		<div *ngIf="differentOrientationInProject() || getProjectOrientation() === 'port'">
			<h4>Portrait: Zone {{zoneTitle}} {{starTitle}}</h4>
			<h5>Rafter-Rafter: {{COMPARED_SPAN_PORT[0]}}"</h5>
			<h5 *ngIf="COMPARED_SPAN.length > 1">Deck-Deck: {{COMPARED_SPAN_PORT[1]}}"</h5>
			<h5 *ngIf="COMPARED_SPAN.length > 1">Rafter-Deck: {{COMPARED_SPAN_PORT[2]}}"</h5>
		</div>
	</div>

	<div *ngIf="workspace==1">
		<div #map id="map" class="google" leaflet (leafletMapReady)="initMap($event)" [leafletOptions]="mapOptions">
		</div>
	</div>

	<div>
		<img #compass src="../../../../assets/images/compass96.png" alt="Compass" class="compass">
	</div>
	<!-- -->
	<div class="roofarea" *ngIf="areabuttnames.length > 0" style="display:none">
		<mat-button-toggle-group class="fix-width" [value]="selectedarea">
			<div class="roof-butt" *ngFor="let proj of yourProject.areaid; let i = index">
				<mat-button-toggle id="{{ proj }}" class="tools full-width" [value]="proj"
					(click)="changeroofarea(proj)">
					{{proj == selectedarea ? yourProject.zonetitle : areabuttnames[i]}}
				</mat-button-toggle>
			</div>
		</mat-button-toggle-group>
	</div>

	<div #zoom class="zoom">
		<button mat-button class="tools" title="Zoom In" (click)="setZoomStage(ZOOM_SCALE, stageCenterPoint)">
			<mat-icon>add</mat-icon>
		</button>
		<button mat-button class="tools" title="Zoom Out" (click)="setZoomStage((1 / ZOOM_SCALE), stageCenterPoint)">
			<mat-icon>remove</mat-icon>
		</button>
	</div>
	<div class="guide">
		<button mat-button (click)="startintroguide()">
			Start Guide
		</button>
	</div>
	<div class="dimension">
		<h5> Dimensions: NS: {{ NS }}ft EW: {{ EW }}ft</h5>
	</div>

	<div>
		<div id="menu">
			<div>
				<h4 *ngIf="addTitle" class="menu-title">Click to add:</h4>
				<h4 *ngIf="!addTitle" class="menu-title">Nothing to add here!</h4>
				<button mat-button id="add-end-clamp" (click)="onMenuAction('endC')">End Clamp</button>
				<button mat-button id="add-mid-clamp" (click)="onMenuAction('midC')">Mid Clamp</button>
				<button mat-button id="add-end-splice" (click)="onMenuAction('endS')">End Splice</button>
				<button mat-button id="add-mid-splice" (click)="onMenuAction('midS')">Mid Splice</button>
				<button mat-button id="add-end-f-splice" (click)="onMenuAction('endFS')">End Floating Splice</button>
				<button mat-button id="add-mid-f-splice" (click)="onMenuAction('midFS')">Mid Floating Splice</button>
			</div>
		</div>
	</div>

</div>


<div id="typemenu">

	<h4 class="menu-title">Multiple Panel Drawing</h4>
		<div class="typemenu-par">
		<p class="type-menu-text">ROW</p>
		<p class="type-menu-text right-marg">COLUMN</p>
		</div>
	<div class="typemenu-par">
	<input matInput class="type-menu-field" type="number" min="1"  placeholder="Column"  [(ngModel)]="textcol" (change)="showpossibletextpanels()">
	<input matInput class="type-menu-field right-marg" type="number" min="1"   placeholder="Row"  [(ngModel)]="textrow" (change)="showpossibletextpanels()">	
	</div>
		<div class="typemenu-par">
	<button mat-button class="cancel-butt" (click)="canceltextmod()">Cancel</button>
	<button mat-button style="background:#eb4031" (click)="drawtextmodules()">OK</button>
	</div>
</div>


<div *ngIf="checkProgress" class="content" id="progress">
	<app-transition class="spinner"></app-transition>
</div>

<!--intro code-->
<div class="step-container seven" *ngIf="step == 'step7'">
	<span class="close-butt" (click)="closeintro('step7')"><i class="fas fa-times"></i></span>
	<h3 class="mt-0">Step 2</h3>
	<p class="intro-text">Add/Remove Panels, Move Canvas, Change Roof zone, Fill up space w/ Panels, Delete All or undo
		panels
		from above toolbox panel</p>
	<button mat-raised-button class="next-butt" (click)="onNext('step8');">Next</button>
	<button mat-raised-button class="mr-1 next-butt" (click)="this.opened=false; onPrev('step6')">Prev</button>
	<p class="intro-text"><b>2/3</b></p>
</div>


<div class="step-container hint-eight" *ngIf="step == 'step8' && hinteightbool">
	<h3>Hint</h3>
	<span class="close-butt" (click)="hinteightbool = !hinteightbool;"><i class="fas fa-times"></i></span>
	<p class="intro-text">Auto-populate Panel attachments</p>
</div>


<div class="step-container eight" *ngIf="step == 'step8'">
	<span class="close-butt" (click)="closeintro('step8')"><i class="fas fa-times"></i></span>
	<h3>Step 3</h3>
	<p class="intro-text">Goto Next page to get parts estimation and BOM</p>
	<button mat-raised-button class="next-butt" (click)="onNext('finished');">Done</button>
	<button mat-raised-button class="mr-1 next-butt" (click)="onPrev('step7')">Prev</button>
	<p class="intro-text"><b>3/3</b></p>
</div>

<!--rafter steps -->
<div class="step-container sixteen" *ngIf="step == 'step16'">
	<span class="close-butt" (click)="closeintro('step16')"><i class="fas fa-times"></i></span>
	<h3 class="mt-0">Step 2</h3>
	<p class="intro-text">Hide/Show Rafter Line</p>
	<button mat-raised-button class="next-butt" (click)="onNext('step17');">Next</button>
	<button mat-raised-button class="mr-1 next-butt" (click)="this.opened=false; onPrev('step6')">Prev</button>
	<p class="intro-text"><b>2/7</b></p>
</div>

<div class="step-container seventeen" *ngIf="step == 'step17'">
	<span class="close-butt" (click)="closeintro('step17')"><i class="fas fa-times"></i></span>
	<h3 class="mt-0">Step 3</h3>
	<p class="intro-text">Add/Remove Panels, Move Canvas, Change Roof zone, Fill up space w/ Panels, Delete All or undo
		panels
		from above toolbox panel</p>
	<button mat-raised-button class="next-butt" (click)="onNext('step18');">Next</button>
	<button mat-raised-button class="mr-1 next-butt" (click)="this.opened=false; onPrev('step16')">Prev</button>
	<p class="intro-text"><b>3/7</b></p>
</div>


<div class="step-container eighteen" *ngIf="step == 'step18'">
	<span class="close-butt" (click)="closeintro('step18')"><i class="fas fa-times"></i></span>
	<h3 class="mt-0">Step 4</h3>
	<p class="intro-text">Populate Roof Attachments Automatically</p>
	<button mat-raised-button class="next-butt" (click)="onNext('step19');" [disabled]="!panelattbool">Next</button>
	<button mat-raised-button class="mr-1 next-butt" (click)="onPrev('step17')">Prev</button>
	<p class="intro-text"><b>4/7</b></p>
</div>


<div class="step-container ninteen" *ngIf="step == 'step19'">
	<span class="close-butt" (click)="closeintro('step19')"><i class="fas fa-times"></i></span>
	<h3 class="mt-0">Step 5</h3>
	<p class="intro-text">Add new attachments,Delete attachments, move clamps and adjust rafter lines using toolbox
		panel</p>
	<button mat-raised-button class="next-butt" (click)="onNext('step20');">Next</button>
	<button mat-raised-button class="mr-1 next-butt" (click)="this.opened=false; onPrev('step18')">Prev</button>
	<p class="intro-text"><b>5/7</b></p>
</div>



<div class="step-container twenty" *ngIf="step == 'step20'">
	<span class="close-butt" (click)="closeintro('step20')"><i class="fas fa-times"></i></span>
	<h3 class="mt-0">Step 6</h3>
	<p class="intro-text">Check Your Attachments Design</p>
	<button mat-raised-button class="next-butt" (click)="onNext('step21');">Next</button>
	<button mat-raised-button class="mr-1 next-butt" (click)="onPrev('step19')">Prev</button>
	<p class="intro-text"><b>6/7</b></p>
</div>

<div class="step-container eight" *ngIf="step == 'step21'">
	<span class="close-butt" (click)="closeintro('step21')"><i class="fas fa-times"></i></span>
	<h3>Step 7</h3>
	<p class="intro-text">Goto Next page to get parts estimation and BOM</p>
	<button mat-raised-button class="next-butt" (click)="onNext('finished');">Done</button>
	<button mat-raised-button class="mr-1 next-butt" (click)="onPrev('step20')">Prev</button>
	<p class="intro-text"><b>7/7</b></p>
</div>



<div class="row footer">
	<a mat-raised-button [routerLink]="['/map']">&larr; Back</a>
	<a mat-raised-button (click)="gotoPart()" joyrideStep="ninthStep" title="Step 8"
		text="Goto Next page to get parts estimation and BOM" stepPosition="top" (done)="guideDone()">Next &rarr;</a>
	<app-footer></app-footer>
</div>