import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { ProjectService } from '@src/app/service/project.service';

@Component({
  selector: 'app-dev-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.css']
})



export class DevIssuesComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  issues: any;
  sortname: boolean;
  sortemail: boolean;
  pageSize: any;
  lowValue = 0;
  highValue = 10;
  pageindex = 0;
  tempusers: any;
  searchTerm: any;
  viewUserId: any;
  viewProjId: any;
  refProjects = new Array();
  project: any;
  projsub: any;

  constructor(public auth: AuthService, public router: Router, public projectService: ProjectService) { }

  ngOnInit() {

    this.initialize();
    this.sortname = true;
    this.sortemail = true;
    this.searchTerm = '';
  }


  initialize() {
    this.getAllIssues();
  }

  ngOnDestroy() {
	 if(this.projsub != undefined) { 
    this.projsub.unsubscribe();
	 }
  }

  getAllIssues() {
    this.auth.getIssues().subscribe((data: any) => {
      this.issues = data;
      this.tempusers = this.issues.filter(x => x.date && x.user && x.id.slice(0, 7) !== 'Testing');
      this.issues = this.tempusers;
      this.getAllProjectName(this.issues);
      this.setUserInfo(this.issues);
      this.issues.sort((a, b) => (a.date < b.date) ? 1 : -1);
    });
  }

  getAllProjectName(allIssues: any) {
    for (const issue of allIssues) {
      if (issue.project) {
        this.projectService.getprojectbyid(issue.project).subscribe((project: any) => {
          issue.projName = (project && issue.project !== 'None') ? project.name : '-- None --';
        });
      }
    }
  }

  setUserInfo(allIssues: any) {
    for (const issue of allIssues) {
      if (issue.project) {
        this.auth.getsingleuser(issue.user).subscribe((curUser: any) => {
          if (curUser != undefined) {
            issue.company = curUser.company;
            issue.state = curUser.state || '--';
            issue.name = curUser.name;
            issue.email = curUser.email;
          }
        });
      }
    }
  }

  getPaginatorData(event: any) {
    this.pageSize = event.pageSize;
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
  }

  setFilteredItems(searchTerm: any) {
    const lowval = this.lowValue;
    const highval = this.highValue;
    this.issues = this.tempusers;
    this.issues =
      this.issues.filter((user: any) => {
        return ((user.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
          (user.email.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1));
      });
    this.lowValue = 0;
    this.highValue = this.pageSize;
    this.paginator.firstPage();
  }



  sort() {
    if (this.sortname) {
      this.sortname = !this.sortname;
      this.issues.sort((a, b) => b.name.localeCompare(a.name));
    } else {
      this.sortname = !this.sortname;
      this.issues.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  sortemailfunc() {
    if (this.sortemail) {
      this.sortemail = !this.sortemail;
      this.issues.sort((a, b) => b.email.localeCompare(a.email));
    } else {
      this.sortemail = !this.sortemail;
      // console.log(this.sortemail);
      this.issues.sort((a, b) => a.email.localeCompare(b.email));
    }
  }

  // Get User object from user Id & navigate to userprojects
  viewAllUserProjects(user: any) {
    this.auth.getUserById(user).subscribe(data => {
      localStorage.setItem('user', JSON.stringify(data[0]));
      this.router.navigate(['/userprojects']);
    });
  }

  // Return the project Object by project Id
  getReferencedProject(projectId: any) {
    this.projsub = this.projectService.getsingleproj(projectId).subscribe(
      data => {
        this.projectService.setUserProject(data[0]).then(() => { this.router.navigate(['/map']); });
      },
      err => console.log('error setting user project with error: ', err));
  }


  openIssue(issue: any) {
    this.auth.issueDetails = issue;
    this.router.navigate(['/issuedescription']);
  }


  toggleResolvingIssue(id: any, issueChecked: any) {
    this.auth.updateIssuedata(id, !issueChecked);
  }




}
