import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-products',
  template: `
  <div class="dialog-area">
    <h3 mat-dialog-title align="center">Choose</h3>
    <div mat-dialog-actions align="center">
      <mat-form-field appearance="fill">
        <mat-label>Products</mat-label>
        <mat-select (selectionChange)="selectedWorkspace($event.value)">
          <mat-option value="apex">
            <img src="../../assets/images/rtapex.png" class="image-icon">US RT-APEX
          </mat-option>
		  <!--
          <mat-option value="mini">
            <img src="../../assets/images/rtmini.png" class="image-icon">US RT-MINI
          </mat-option>
		  -->
          <mat-option value="mini2">
            <img src="../../assets/images/mini2.png" class="image-icon">US RT-MINI II
          </mat-option>
          <!--
      <mat-option value="apexC">
            <img src="../../assets/images/rtapex.png" class="image-icon">Canada RT-APEX
          </mat-option>
-->
          <mat-option value="mini2C">
            <img src="../../assets/images/mini2.png" class="image-icon">Canada RT-MINI II
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
`,
  styles: [`
.dialog-area{
  /* display: flex; */
  margin: 0;
  padding: 2em;
}

.workspace {
  display: flex;
  margin: 0;
  padding: 2em;
}
.workspace button {
    padding: 1em;
    font-size: 22px;
    margin: 1em;
    min-width: 300px;
    white-space: normal;
    text-align: center;
}
.workspace button:hover {
  background-color: #eb4031;
}
.image-icon {
  width: 40px;
  height: 40px;
  margin: 15px;
}
@media (min-width: 410px) and (max-width: 980px) {
  .workspace button {
    font-size: 14px;
    min-width: 100px;
  }
  .image-icon {
    width: 30px;
    height: 30px;
    margin: 10px;
  }
}
@media (min-width: 280px) and (max-width: 409px) {
  .workspace button {
    font-size: 12px;
    min-width: 50px;
  }
  .image-icon {
    width: 20px;
    height: 20px;
    margin: 7px;
  }
}
`]
})


export class ProductsComponent {

  product = '';

  constructor(public dialogRef: MatDialogRef<ProductsComponent>) { }

  selectedWorkspace(product: any) {
    this.dialogRef.close(product);
  }



}
