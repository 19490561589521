import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';

import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-postsignup',
  templateUrl: './postsignup.component.html',
  styleUrls: ['./postsignup.component.css']
})

export class PostSignupComponent implements OnInit {
  email: any;
  name: any;
  company: any;
  phone: any;
  state: any;
  nameval: boolean;
  compval: boolean;
  phoneval: boolean;
  country : any;
  user: any;
  canstate = 'AB';

  constructor(public auth: AuthService, public router: Router, public location: LocationStrategy) { }

  ngOnInit() {
	this.country = 'us';
	this.state = 'CA';
	this.auth.getauthfunc().onAuthStateChanged((user) => {
    this.user = user;
    this.name = this.user.displayName;
    this.company = '';
    this.email = this.user.email;
	});
    // Prevent Back Button
    history.pushState(null, null, location.href);
    window.onpopstate = () => {
      history.go(1);
    };

  }


  // Calling Sign Up Logic
  signUp() {
    // tslint:disable-next-line: max-line-length
    this.nameval = (this.name.length > 2) ? true : false;
    this.compval = (this.company.length > 3) ? true : false;
    this.phoneval = (Math.ceil(Math.log(this.phone + 1) / Math.LN10)) === 10 ? true : false;
    if (!this.nameval) {
      this.auth.error = 'Full name is required';
    } else if (!this.compval) {
      this.auth.error = 'Company name is required';
    } else if (!this.phoneval) {
      this.auth.error = 'Phone Number Should be valid';
    } else {
      this.auth.error = '';
	  this.state = this.country == 'canada' ? this.canstate : this.state;
      this.auth.saveuserdata(this.name, this.email, this.phone, this.company, this.state, this.country, this.user.uid, this.user.metadata).then(res => {
        this.router.navigate(['/']);
      });
    }
  }

}
