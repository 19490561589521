import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IssuesComponent } from 'src/app/issues/issues.component';


@Component({
  selector: 'app-report-butt',
  template: `
     <button class="report" (click)="openpopup()">Report Issues</button>`,
  styles: [`
.transition {
  position: absolute;
  top:  45%;
  left: 45%;
}`]
})

export class ReportbuttComponent implements OnInit, OnDestroy {
  userbool = false;
  constructor(private dialog: MatDialog) {

  }

  ngOnInit() { }

  openpopup() {
    this.dialog.open(IssuesComponent);
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }
}
